export function sensorEquipmentListHasError(state = false, action) {
    switch (action.type) {
        case 'SENSOR_EQUIPMENT_LIST_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function sensorEquipmentListLoading(state = false, action) {
    switch (action.type) {
        case 'SENSOR_EQUIPMENT_LIST_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function sensorEquipment(state = null, action) {
    switch (action.type) {
        case 'SENSOR_EQUIPMENT_LIST_SUCCESS':
            return action.sensorEquipment;

        default:
            return state;
    }
}

export function sensorEquipmentQueryParams(state = {
    page: 1, pageSize: 10, total: -1//, filter: 'All'
}, action) {
    switch (action.type) {
        case 'SENSOR_EQUIPMENT_QUERY_PARAMS':
            return { ...state, ...action.payload };

        default:
            return state;
    }
}