import React, { Component, StrictMode, useState,  } from 'react';
import { Route, Switch, Redirect  } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Login from './auth/Login'
import Main from './app/Main';
// import PrivateRoute from './common/PrivateRoute'
// import SystemSnackbar from './common/SystemSnackbar'

const theme = createMuiTheme({
    palette: {
        primary: { main: '#1a3a69' },
        secondary: { main: '#fdbc2f' },
    },
});

const App = () => {
    return (
        // <StrictMode>
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route path="/*" component={Main} />
                    </Switch>
                </div>
            </MuiThemeProvider>
        // </StrictMode>
    )
}

export default App;