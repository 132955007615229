import React, { Component } from 'react';
import { Tooltip, Grid } from '@material-ui/core';
import { HeloIcon } from '../icons/svgIcons'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Alarm from '@material-ui/icons/Alarm';
import LocalAirport from '@material-ui/icons/LocalAirport'
import NicerTooltip from './NicerTooltip';

class ProcessingParamStatusIcon extends Component {

    render() {
        const { isActive, expiryDate } = this.props;

        return (
            <div>
                {isActive === true && expiryDate === undefined &&
                    <NicerTooltip placement="right" content={<span>Valid and Current</span>}>
                        <CheckCircleOutline style={{ color: '#006600' }} />
                    </NicerTooltip>
                }
                {isActive === false && expiryDate !== undefined &&
                    <Grid>
                        <NicerTooltip placement="right" content={<span>Missing parameters and Expired</span>}>
                            <ErrorOutline style={{ color: '#FF0000', transform: 'scale(.75, .75)', marginBottom:-5 }} />
                        </NicerTooltip>
                        <NicerTooltip placement="right" content={<span>Missing parameters and Expired</span>}>
                            <ErrorOutline style={{ color: '#FF0000', transform: 'scale(.75, .75)', marginTop:-5  }} />
                        </NicerTooltip>
                    </Grid>
                }
                {isActive === false &&
                    <NicerTooltip placement="right" content={<span>Missing parameters<br/>Edit to complete</span>}>
                        <ErrorOutline style={{ color: '#FF0000' }} />
                    </NicerTooltip>
                }
                {isActive === true && expiryDate !== undefined &&
                    <NicerTooltip placement="right" content={<span>Valid and Expired</span>}>
                        <Alarm style={{ color: '#1a3a69' }} />
                    </NicerTooltip>
                }


            </div>
        );
    }
}

export default ProcessingParamStatusIcon;