import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import CancellationDialog from '../common/CancellationDialog';

class UploadResume extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelDialogOpen: false
        };
    }

    cancelConfirmationDisplay = () => {
        this.setState({cancelDialogOpen:true});
    }

    cancelConfirmation = (confirmation) => {
        this.setState({cancelDialogOpen:false});
        this.props.onCancel(confirmation);//same as onConfirm in this case
    }

    render() {
        return (
            <Grid container direction="column" alignItems="center" justify="center">
                <p>A prior incomplete {this.props.uploadType} upload has been detected, <span style={{fontWeight:'bold'}}>{this.props.completedFileSizePercent? this.props.completedFileSizePercent.toFixed(2) : 0}%</span> complete of <span style={{fontWeight:'bold'}}>{this.props.totalFileSize}</span>, from folder: <span style={{fontWeight:'bold'}}>{this.props.path}</span></p>
                <Grid container direction="row" alignItems="center" justify="center">
                    <input 
                        accept="*" id="resumeButton" 
                        webkitdirectory="true" directory="true" 
                        multiple type="file" style={{ display: 'none' }} 
                        onChange={(event) => this.props.onResume(event)} 
                        disabled={this.props.disabled || false}
                    />
                    <label htmlFor="resumeButton">
                        <Button 
                            variant="contained" component="span" color="primary" 
                            style={{ width: 120, height: 35, marginRight: 10 }}
                            disabled={this.props.disabled || false}
                        >
                            Resume
                        </Button>
                    </label>
                        {/* <Button variant="contained" component="span" color="primary" style={{ width: 100, height: 30, marginRight: 10 }} onClick={this.cancelConfirmationDisplay}>Cancel</Button> */}
                </Grid>
                <p>You will need to reselect the original source folder to resume the upload.</p>
                <p>Speak to your Team Leader or the LiDARnetics Administrator to reset the status of this Upload.</p>
                {/* <p>Cancellation will delete any partially uploaded files, and resume will no longer be available.</p> */}
                <CancellationDialog onCancel={this.cancelConfirmation} onConfirm={this.cancelConfirmation} open={this.state.cancelDialogOpen}/>
            </Grid>
        );
    }
}

export default UploadResume;