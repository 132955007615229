import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

class CancellationDialog extends Component {

    render() {
        return (
        <Dialog disableBackdropClick disableEscapeKeyDown open={this.props.open}>
            <DialogTitle>Upload Cancellation</DialogTitle>
            <DialogContent>
                <div style={{paddingBottom:5}}>Are you sure you want to cancel the upload?</div>
                <div>Anything uploaded to this point will be deleted.</div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={()=>{this.props.onCancel(false)}} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={()=>{this.props.onConfirm(true)}} style={{backgroundColor:'#ff0000', color:'#ffffff'}}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        );
    }
}

export default CancellationDialog;