export function instructionsListHasError(state = false, action) {
    switch (action.type) {
        case 'INSTRUCTION_LIST_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function instructionsListLoading(state = false, action) {
    switch (action.type) {
        case 'INSTRUCTION_LIST_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function insts(state = null, action) {
    switch (action.type) {
        case 'INSTRUCTION_LIST_SUCCESS':
            return action.insts;

        default:
            return state;
    }
}

export function instructionsQueryParams(state = {
    page: 1, pageSize: 10, total: -1//, filter: 'All'
}, action) {
    switch (action.type) {
        case 'INSTRUCTION_QUERY_PARAMS':
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

//update / put
export function instructionUpdateHasError(state = false, action) {
    switch (action.type) {
        case 'INSTRUCTION_UPDATE_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function instructionsUpdateLoading(state = false, action) {
    switch (action.type) {
        case 'INSTRUCTION_UPDATE_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function instructionUpdateSuccess(state = null, action) {
    switch (action.type) {
        case 'INSTRUCTION_UPDATE_SUCCESS':
            return action.insts;

        default:
            return state;
    }
}

// export function clearState(state = null, action) {
//     switch (action.type) {
//         case 'INSTRUCTION_CLEAR_ALL':
//             action.isLoading = false;
//             action.hasError = false;
//             action.insts = null;
//             return action;

//         default:
//             return state;
//     }
// }