import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom'

const styles = {
    bigIndicator: {
        height: 5,
    },
};

class PostTabs extends Component {

    constructor(props) {
        super(props)
        this.handleRoute(true);
    }

    state = {
        value: 0,
    };

    componentWillUpdate = () => {
        this.handleRoute(false);
    }

    handleRoute = (inConstructor) => {
        switch (this.props.history.location.pathname) {
            case String(this.props.history.location.pathname.match(/\/post(\/)?/g)):
                if (this.state.value !== 0)
                    inConstructor ? this.state.value = 0 : this.setState({ value: 0 });
                break;
            // case String(this.props.history.location.pathname.match(/\/post\/extract/g)):
            //     if (this.state.value !== 1)
            //         inConstructor ? this.state.value = 1 : this.setState({ value: 1 });
            //     break;
            case String(this.props.history.location.pathname.match(/\/post\/FL\d{6}/g)):
                if (this.state.value !== 1)
                    inConstructor ? this.state.value = 1 : this.setState({ value: 1 });
                break;
            case String(this.props.history.location.pathname.match(/\/post\/FL\d{6}\/uploads\/[0-9a-fA-F]{24}/g)):
                if (this.state.value !== 2)
                    inConstructor ? this.state.value = 2 : this.setState({ value: 2 });
                break;
            default:
                if (this.state.value !== 0)
                    inConstructor ? this.state.value = 0 : this.setState({ value: 0 });
        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        let flightID = null;
        flightID = this.props.history.location.pathname.match(/FL\d{6}/g) != null ? this.props.history.location.pathname.match(/FL\d{6}/g)[0] : '';
        return (
            <Tabs
                style={{ backgroundColor: '#CCCCCC', marginBottom: 10, width: '100vw', minWidth: 1425 }}
                classes={{ indicator: classes.bigIndicator }}
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="secondary"
                centered>

                <Tab label="DASHBOARD" component={Link} to="/post" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />

                {/* <Tab label="REVIEW &amp; EXTRACT" component={Link} to="/post/extract" style={{ color: '#1a3a69', fontWeight: 'bolder' }} /> */}

                {this.props.history.location.pathname.match(/\/post\/FL\d{6}/g) &&
                    <Tab label={"FLIGHT " + flightID} component={Link} to={"/post/" + flightID} style={{ color: '#1a3a69', fontWeight: 'bolder' }} />
                }

                {this.props.history.location.pathname.match(/\/post\/FL\d{6}\/uploads\/[0-9a-fA-F]{24}/g) &&
                    <Tab label={"SELECTED UPLOAD"} style={{ color: '#1a3a69', fontWeight: 'bolder' }} />
                }

            </Tabs>
        );
    }
}

PostTabs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(PostTabs));