import React, { useState, useEffect, createRef, useRef } from "react";
import { connect } from 'react-redux';
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Tooltip, Fab, IconButton } from "@material-ui/core";
import { Map, Marker, Popup, TileLayer, GeoJSON, LeafletProvider } from "react-leaflet";
import { KMZLayer } from 'leaflet-kmz'
import { latLngBounds, FeatureGroup, geoJSON } from 'leaflet'
// import L from 'leaflet'
import { authoriseDownload, resetDownloadAuthState } from './ProcessingActions'
// import useConstructor from '../common/useConstructor'
import { bearing, point } from '@turf/turf'
import * as L from 'leaflet';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CloseIcon from '@material-ui/icons/Close';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import MapIcon from '@material-ui/icons/Map';


function FlightLineMapDialog(props) {
  const { open, onClose, process } = props
  const [map, setMap] = useState(undefined);
  const [zoomLevel, setZoomLevel] = useState(4);
  const [osmEnabled, setOsmEnabled] = useState(true);
  const [authRequested, setAuthRequested] = useState(false);
  const [mapLayersLoaded, setMapLayersLoaded] = useState(false);

  useEffect(() => {
    if (props.open && props.downloadAuthLoading === false && props.downloadAuthSuccess === null && !authRequested) {
      props.authoriseDownload(props.process._id,props.process.SensorID,props.process.ProjectID,props.process.ProjectAOINo)
      setAuthRequested(true);
    }

    if (map && props.open) {
      let kmzLayer = new KMZLayer();
      var kmz = kmzLayer;//.addTo(map);

      if (props.downloadAuthSuccess) {
        kmz.load(props.downloadAuthSuccess);

        kmzLayer.on('load', function (e) {

          //hijacking features
          let features = []
          for (const [key, value] of Object.entries(e.layer._layers)) {
            // if (value.feature["geometry"]["type"] === "Polygon" || value.feature["geometry"]["type"] === "LineString")//FILTERING ALL BUT OLYGONS AND POLYLINES
            features.push(value.feature)
          }

          let gj = geoJSON(features, {
            onEachFeature: (feature, lyr) => {
              // lyr.on({
              //     click: highlightFeature
              //     // mouseover: function(e) {console.log("...mouseover")}
              // });

              let table = "<table>"
              for (var key in feature.properties) {
                if (['stroke-opacity', 'stroke', 'fill-opacity'].indexOf(key) !== -1)//stupid hack because style is conflated with properties
                  continue;
                table += "<tr><td style='width:150px;font-weight:bold'>" + key + "</td><td>" + feature.properties[key] + "</td></tr>";
              }
              table += "</table>";
              lyr.bindPopup(table);

              // let point1 = point(feature.geometry.coordinates[0]);
              //       let point2 = point(feature.geometry.coordinates[feature.geometry.coordinates.length - 1]);
              //       let bg = bearing(point1, point2);
              //       let translate = "";//false;
              //       if (bg < 0 && bg >= -90) {
              //           bg += 90
              //           translate = " translate(8px, -4px);"
              //       }
              //       else if (bg < 0 && bg < -90) {
              //           bg += 90
              //           translate = " translate(8px, -4px);"
              //       }
              //       else if (bg > 0 && bg <= 90) {
              //           bg -= 90
              //           // translate = true;
              //           translate = " translate(-120px, -4px);"
              //       }
              //       else if (bg > 90) {
              //           bg -= 90
              //           // translate = true;
              //           translate = " translate(-120px, -12px);"
              //       }
              //       let transform = "transform: rotate(" + bg.toFixed(0) + "deg)" + translate;

              //       var textMarker = L.marker(feature.geometry.coordinates[0].reverse(), {
              //           icon: L.divIcon({
              //               html: '<div style="width: 150px; font-size: 16px; font-weight: bold; text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white; transform-origin: top left; ' + transform + '">Strip: ' + feature.properties["STRIP"] + ', FL: ' + feature.properties["FL_LN"] + '</div>',
              //               className: '',
              //           })
              //       }).addTo(map);
            },
            style: (feature) => {
              var styles = {};
              if (feature["geometry"]["type"] === "LineString") {
                return { stroke: true, color: "#FF0000", weight: 5 };//, pane: 'polylinePane' };
              }

              return styles;
            }
          })//.addTo(map);
          map.addLayer(gj)

          let tempBounds = latLngBounds();
          map.eachLayer(function (layer) {
            // Check if layer is a featuregroup
            if (layer instanceof FeatureGroup) {
              // Extend bounds with group's bounds
              tempBounds.extend(layer.getBounds());
            }
          });

          map.fitBounds(tempBounds);

          setMapLayersLoaded(true);
        });

        // var kmz = L.kmzLayer().addTo(map);

        // kmz.on('load', function(e) {
        //   control.addOverlay(e.layer, e.name);
        //   // e.layer.addTo(map);
        // });

        // // Add remote KMZ files as layers (NB if they are 3rd-party servers, they MUST have CORS enabled)
        // kmz.load(props.downloadAuthSuccess);
        // var control = L.control.layers(null, null, { collapsed:false }).addTo(map);

      }
    }
  });

  const handleZoomDataExtents = () => {
    let tempBounds = latLngBounds();
    map.eachLayer(function (layer) {
      if (layer instanceof FeatureGroup) {
        tempBounds.extend(layer.getBounds());
      }
    });

    map.fitBounds(tempBounds);
  }

  // useConstructor(() => {
  //   // setAvailColors(contrastColors)
  //   // props.setQueryParams({ params: instructionFilterParams, page: instructionPage })
  //   // getInstructions();

  //   console.log("...in const")
  //   props.authoriseDownload()
  // });

  const handleDialogOnClose = () => {
    setAuthRequested(false)
    map.eachLayer(function (layer) {
      // if (layer instanceof FeatureGroup) {
      map.removeLayer(layer);
      // }
    });
    setMapLayersLoaded(false);
    props.resetDownloadAuthState();
    onClose()
  }

  // else 
  return (
    <Dialog
      // disableBackdropClick 
      // disableEscapeKeyDown 
      open={open}
      onClose={handleDialogOnClose}
      maxWidth={"600px"}>
      {/* <DialogTitle>Flight Lines</DialogTitle> */}
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="row" alignItems="center" justify="center"><span style={{ fontWeight: "bold", fontSize: 19 }}>{process.Aircraft} / {process.Sensor} / {process.ProjectID}: {process.ProjectName}</span></Grid>



        <Map
          //   style={{ marginLeft: 10, width: "calc(100vw - 300px)", zIndex: 1 }}
          style={{ width: "calc(100vw - 200px)", zIndex: 1, visibility: mapLayersLoaded ? 'visible' : 'hidden' }}
          // style={{ width: "calc(100vw - 200px)", zIndex: 1 }}
          // ref={mapRef}
          // ref={Map => map = Map.leafletElement}
          ref={(Map) => (Map ? setMap(Map.leafletElement) : null)}
          center={[-28.2744, 133.7751]}
          zoom={zoomLevel}
          zoomControl={false}
          attributionControl={false}
        >
          {/* {
            !mapLayersLoaded &&
            <Grid container direction="column" alignItems="center" justify="center" style={{ padding:10, position: 'absolute', top:100 }}>
              <CircularProgress />
              <div style={{ paddingTop: 5 }}>Loading Flight Lines Map...</div>
            </Grid>
          } */}
          <Grid container spacing={8} direction="column" alignItems="center" justify="flex-start" style={{ marginTop: 8, width: 60, zIndex: 9999, position: "absolute", right: 5 }}>
            <Grid item>
              <Tooltip title="Close Map" placement="left">
                <Fab color="primary" size="small" onClick={e => handleDialogOnClose()}>
                  <CloseIcon />
                </Fab>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Zoom In" placement="left">
                <Fab color="primary" size="small" onClick={e => map.setZoom(map._zoom + 1)}>
                  <ZoomInIcon />
                </Fab>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Zoom Out" placement="left">
                <Fab color="primary" size="small" onClick={e => map.setZoom(map._zoom - 1)}>
                  <ZoomOutIcon />
                </Fab>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Zoom to Data Extents" placement="left">
                <Fab color="primary" size="small"
                  // disabled={loadedGeometry.length === 0}
                  onClick={e => handleZoomDataExtents()}>
                  <SettingsOverscanIcon />
                </Fab>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Toggle Base Map" placement="left">
                <Fab color="primary" size="small"
                  // disabled={loadedGeometry.length === 0}
                  onClick={e => setOsmEnabled(!osmEnabled)}>
                  <MapIcon />
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>

          {osmEnabled &&
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          }
          {!osmEnabled &&
            <TileLayer visible={false} url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" />
          }
        </Map>

      </DialogContent>
      {/* <DialogActions>
                                <Button variant="contained" onClick={() => { this.props.onCancel(false) }} color="primary" >
                                    {this.props.cancelLabel ? this.props.cancelLabel : "Cancel"}
                                </Button>
                                <Button variant="contained" component="span" color="primary" style={{ backgroundColor: '#ff0000' }} onClick={this.props.onConfirm}>{this.props.confirmLabel}</Button>
                            </DialogActions> */}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    downloadAuthLoading: state.downloadAuthLoading,
    downloadAuthSuccess: state.downloadAuthSuccess,
    downloadAuthHasError: state.downloadAuthHasError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authoriseDownload: (guid,sensorId,projectId,aoiNum) => { dispatch(authoriseDownload(guid,sensorId,projectId,aoiNum)) },
    resetDownloadAuthState: () => { dispatch(resetDownloadAuthState()) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightLineMapDialog);
