import { Action,  AnyAction, ActionCreator, StateFromReducersMapObject } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import Config from '../app/Config.json'
import { FetchObjectListResponse, Pagination, ValidateArchiveResponse } from './ObjectExplorerTypes'

//////////////////////////////////
//           Constants          //
//////////////////////////////////
export const OBJECT_EXPLORER_ACTION_TYPE = ({
    UPDATE_OBJECT_PATH: 'UPDATE_OBJECT_PATH',
    UPDATE_TOTAL_KEY_COUNT: 'UPDATE_TOTAL_KEY_COUNT',
    UPDATE_PAGINATION: 'UPDATE_PAGINATION',
    FETCH_OBJECT_LIST: 'FETCH_OBJECT_LIST',
    FETCH_OBJECT_LIST_SUCCEED: 'FETCH_OBJECT_LIST_SUCCEED',
    FETCH_OBJECT_LIST_FAILED: 'FETCH_OBJECT_LIST_FAILED',
    VALIDATE_ARCHIVE: 'VALIDATE_ARCHIVE',
    VALIDATE_ARCHIVE_SUCCEED: 'VALIDATE_ARCHIVE_SUCCEED',
    VALIDATE_ARCHIVE_FAILED: 'VALIDATE_ARCHIVE_FAILED',
    CLEAR_ALL: 'CLEAR_ALL',
})

//////////////////////////////////
//        ActionCreators        //
//////////////////////////////////
export const updateObjectPath = (bucketName: string, prefix: string=''):AnyAction => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.UPDATE_OBJECT_PATH,
    bucketName,
    prefix
})

export const fetchObjectList = (): AnyAction => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.FETCH_OBJECT_LIST,
})

export const fetchObjectListSucceed = (fetchObjectListResponse: FetchObjectListResponse): AnyAction => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.FETCH_OBJECT_LIST_SUCCEED,
    nextContinuationToken: fetchObjectListResponse.nextContinuationToken,
    isTruncated: fetchObjectListResponse.isTruncated,
    objectList: fetchObjectListResponse.objects,
    totalKeyCount: fetchObjectListResponse.totalKeyCount,
    maxKeys: fetchObjectListResponse.maxKeys,
})

export const fetchObjectListFailed = (): Action<string> => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.FETCH_OBJECT_LIST_FAILED,
})

export const updatePagination = (pagination: Pagination): AnyAction => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.UPDATE_PAGINATION,
    pagination
})

export const updateTotalKeyCount = (totalKeyCount: number): AnyAction => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.UPDATE_TOTAL_KEY_COUNT,
    totalKeyCount
})

export const clearAll = (): Action<string> => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.CLEAR_ALL
})

export const validateArchive = (): Action<string> => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.VALIDATE_ARCHIVE
})

export const validateArchiveSucceed = (isArchivedSucceed: boolean): AnyAction => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.VALIDATE_ARCHIVE_SUCCEED,
    isArchivedSucceed
})

export const validateArchiveFailed = (): Action<string> => ({
    type: OBJECT_EXPLORER_ACTION_TYPE.VALIDATE_ARCHIVE_FAILED
})

//////////////////////////////////
//         Async Actions        //
//////////////////////////////////
const IP_URI = Config.IP_URI as string

const fetchObjectListPromise = (
        token: string, 
        bucketName: string,
        prefix: string,
        nextContinuationToken: string|null): Promise<FetchObjectListResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: IP_URI + `explorer`,
                params: { prefix, nextContinuationToken, bucket: bucketName },
                headers: { authorization: "Bearer " + token },
            })
            return resolve(response.data as FetchObjectListResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleFetchObjectList = (
        bucketName: string,
        prefix: string,
        nextContinuationToken: string|null =null): ThunkAction<Promise<FetchObjectListResponse | {}>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token } = getState()
        dispatch(fetchObjectList())
        try {
            const fetchObjectListResponse = await fetchObjectListPromise(token, bucketName, prefix, nextContinuationToken)
            // console.log('fetchObjectListResponse: ', fetchObjectListResponse)
            dispatch(fetchObjectListSucceed(fetchObjectListResponse));
            (!nextContinuationToken) && dispatch(updateTotalKeyCount(fetchObjectListResponse.totalKeyCount))
            return fetchObjectListResponse
        } catch(error) {
            console.log(error)
            dispatch(fetchObjectListFailed())
            return {}
        }
    }
}

const validateArchivePromise = (
        token: string,
        bucket: string,
        prefix: string): Promise<ValidateArchiveResponse> => {
    return new Promise (async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: IP_URI + `explorer/archive_validation`,
                params: { prefix, bucket },
                headers: { authorization: "Bearer " + token },
            })
            return resolve(response.data as ValidateArchiveResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleValidateArchive = (
        bucket: string,
        prefix: string): ThunkAction<Promise<ValidateArchiveResponse | {}>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token } = getState()
        dispatch(validateArchive())
        try {
            const validateArchiveResponse = await validateArchivePromise(token, bucket, prefix)
            dispatch(validateArchiveSucceed(validateArchiveResponse.isArchivedSucceed))
            return validateArchiveResponse
        } catch(error) {
            console.log(error)
            dispatch(validateArchiveFailed())
            return {}
        }
    }
}