import React, { Component } from 'react';
// import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Description from '@material-ui/icons/Description';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import NicerTooltip from './NicerTooltip';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { Archived } from '../icons/svgIcons'

class TableEllipsisIcon extends Component {

    render() {
        const { value, isArchived, isActive, isEoOnly } = this.props;
        //a temporary rule to lock powercore templates until dynamic template handling is completed
        const isLockedTemplate = value.indexOf("PowerICP") > -1 ? true : false;
        const archived = isArchived == false || isArchived == undefined ? false : isArchived;
        const active = isActive == undefined ? true : isActive;
        const eoOnly = isEoOnly == undefined ? false : isEoOnly;
        return (
            <div>
                {eoOnly && !archived &&
                    <NicerTooltip placement="right" content={<span>EO Processing Only<br />{value}</span>}>
                        <PhotoCameraIcon style={{ color: '#1a3a69' }} />
                    </NicerTooltip>
                }
                {!eoOnly && isLockedTemplate && //!archived &&
                    <NicerTooltip placement="right" content={<span>Locked template:<br />{value}</span>}>
                        <LockIcon style={{ color: '#ff0000' }} />
                    </NicerTooltip>
                }
                {!eoOnly && !isLockedTemplate && //!archived &&
                    <NicerTooltip placement="right" content={<span>{value}</span>}>
                        <Description style={{ color: '#1a3a69' }} />
                    </NicerTooltip>
                }
                {/* {archived && active &&
                    <NicerTooltip placement="right" content={<span>Processing data archived to lidarprocessing bucket<br />{value}</span>}>
                        <Archived style={{ color: '#1a3a69', marginTop:10, marginLeft:5 }} />
                    </NicerTooltip>
                } */}
                {archived && !active &&
                    <NicerTooltip placement="right" content={<span>Superseded processing deleted on Project closure<br />{value}</span>}>
                        <DeleteIcon style={{ color: '#FF0000' }} />
                    </NicerTooltip>
                }
            </div>
        );
    }
}

export default TableEllipsisIcon;