import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import {    
    Grid, CircularProgress, Typography, Table, TableHead, TableRow,
    TableBody, TableCell, TableFooter, TablePagination, Link,
    Button, Paper 
} from '@material-ui/core'
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import { 
    handleFetchObjectList, updatePagination, updateObjectPath, clearAll
} from './objectExplorerActions';
import { FetchObjectListResponse, ObjectMetadata, ObjectExplorerStore } from './ObjectExplorerTypes'
import { PAGE_SIZE } from './objectExplorerReducer';
import NicerTooltip from '../common/NicerTooltip';
import './explorer.css'

const ObjectList = (props: ObjectListFromRedux) => {
    // Props from Redux mapStateToProps
    const { objectExplorerStore } = props
    // Props from Redux mapDispatchToProps -- From InstructionList Reducer
    const { 
        handleFetchObjectList, updatePagination, updateObjectPath, clearAll
    } = props
    var history = useHistory() 

    const [currentPageContent, setCurrentPageContent] = useState<Array<ObjectMetadata>>([])

    useEffect(() => {
        (objectExplorerStore.bucketName && objectExplorerStore.prefix)
            && handleFetchObjectList(objectExplorerStore.bucketName, 
                                    objectExplorerStore.prefix, 
                                    objectExplorerStore.latestFetchObjectListResponseMeta.nextContinuationToken
            )
    }, [objectExplorerStore.bucketName, objectExplorerStore.prefix])

    useEffect(() => {
        let queryParams = new URLSearchParams(decodeURI(window.location.search))
        let bucket = queryParams.get('bucket') || ''
        let prefix = queryParams.get('prefix') || ''
        updateObjectPath(bucket, prefix)
        // history.replace({ search: '' })             // clean query params

        return () => {
            clearAll()
        }
    }, [])

    useEffect(() => {
        if (objectExplorerStore.bufferedPages.length) {
            let pageBufferDepth = objectExplorerStore.latestFetchObjectListResponseMeta.maxKeys / PAGE_SIZE
            if (Math.ceil(objectExplorerStore.pagination.page / pageBufferDepth) 
                    <= objectExplorerStore.bufferedPages.length) {
                setCurrentPageContent(extractCurrentPageContent())
            } else {
                handleFetchObjectList(objectExplorerStore.bucketName, 
                    objectExplorerStore.prefix, 
                    objectExplorerStore.latestFetchObjectListResponseMeta.nextContinuationToken
                )
            }
        }
    }, [objectExplorerStore.pagination.page, objectExplorerStore.bufferedPages])

    const sortObjects = (objects: Array<ObjectMetadata>): Array<ObjectMetadata> => {
        const isFolder = (object: ObjectMetadata): boolean => object.name.endsWith('/')
        let duplicatedObjects = [...objects]
        duplicatedObjects.sort((firstObject, secondObject) => {
            if (isFolder(firstObject) && !isFolder(secondObject)) return -1
            else if (!isFolder(firstObject) && isFolder(secondObject)) return 1

            if (firstObject.name.toLowerCase() > secondObject.name.toLowerCase()) return 1
            else if (firstObject.name.toLowerCase() < secondObject.name.toLowerCase()) return -1
            else return 0
        })
        return duplicatedObjects
    }

    const formatTimezoneOffset = (offset: number): string => {
        let symbol = (offset > 0) ? '-' : '+'
        let hours = Math.floor(Math.abs(offset) / 60) 
        let minutes = Math.round(Math.abs(offset) % 60)
        return 'UTC' + symbol + hours + ":" + minutes.toString().padStart(2, '0')
    }

    const extractCurrentPageContent = (): Array<ObjectMetadata> => {
        if (objectExplorerStore.latestFetchObjectListResponseMeta.maxKeys == 0) {
            return []
        }
        let page = objectExplorerStore.pagination.page
        let pageBufferDepth = objectExplorerStore.latestFetchObjectListResponseMeta.maxKeys / PAGE_SIZE 
        let bufferedPageIndex = Math.ceil(page / pageBufferDepth) - 1
        let startFrom = ((page -1) % pageBufferDepth) * PAGE_SIZE
        let pageContent = sortObjects(objectExplorerStore.bufferedPages[bufferedPageIndex])
                            .slice(startFrom, startFrom + PAGE_SIZE)
        return pageContent
    }

    const handleOnChangePage = (page: number) => {
        updatePagination({ page: page + 1, pageSize: objectExplorerStore.pagination.pageSize })
    }

    const handleObjectOnClick = (objectName: string) => {
        clearAll()
        updateObjectPath(objectExplorerStore.bucketName, (objectExplorerStore.prefix + objectName))
        let queryParams = new URLSearchParams({ 
            bucket: objectExplorerStore.bucketName, 
            prefix: (objectExplorerStore.prefix + objectName) 
        })
        history.replace({ search: queryParams.toString() })             // sync the query parameters
    }

    const handleRefreshBtnOnClick = () => {
        let bucketName = objectExplorerStore.bucketName
        let prefix = objectExplorerStore.prefix
        clearAll()
        updateObjectPath(bucketName, prefix)
        handleFetchObjectList(bucketName, prefix)
    }

    return (
        <>  
            <Grid container direction='row' justify='flex-start' alignItems='center' >
                <NicerTooltip placement="top-start" content="Refresh the page">
                    <Button 
                        variant='outlined' 
                        size='medium' 
                        onClick={handleRefreshBtnOnClick} 
                    >
                        <RefreshIcon />
                    </Button>
                </NicerTooltip>
            </Grid>
            <Paper  elevation={4} style={{ width: '100%', marginTop: 24 }} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="none" align='center' >
                                <div className='table__header table__column--large table__cell' >Name</div>
                            </TableCell>
                            <TableCell padding="none" align='center' >
                                <div className='table__header table__column--small table__cell' >Type</div>
                            </TableCell>
                            <TableCell padding="none" align='center' >
                                <div className='table__header table__column--medium table__cell'>Last Modified</div>
                            </TableCell>
                            <TableCell padding="none" align='center' >
                                <div className='table__header table__column--small table__cell'>Size</div>
                            </TableCell>
                            <TableCell padding="none" align='center' >
                                <div className='table__header table__column--storage-class table__cell'>Storage Class</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <div style={{ maxHeight: 520, overflow: 'auto', width: '100%' }} >
                    <Table>
                        <TableBody>
                            {
                                objectExplorerStore.isFetchingObjectList
                                    ? <TableRow>
                                        <TableCell padding="none" align='center' colSpan={5} >
                                            <Grid container direction="row" alignItems="center" justify="center">
                                                <Grid direction="row" alignItems="center" justify="center">
                                                    <CircularProgress size={25} />
                                                </Grid>
                                                <Grid direction="row" alignItems="center" justify="center">
                                                    <div className='pl-2 table__cell table__cell--small-font'>Loading objects...</div>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                    : (objectExplorerStore.fetchObjectListSucceed !== false)
                                        ? currentPageContent.length
                                            ? React.Children.toArray(currentPageContent.map((object, index) => {
                                                const isFolder: boolean = object.name.endsWith('/')
                                                
                                                return (
                                                    <TableRow key={index}  style={{ height: 40 }} >
                                                        <TableCell padding="none" align='left' >
                                                            <div className='table__column--large table__cell table__cell--small-font'>
                                                                {isFolder 
                                                                    ? <Grid container direction='row' justify='flex-start' alignItems='center'>
                                                                        <Grid container direction='row' justify='center' alignItems='center' xs={1}>
                                                                            <FolderOpenIcon />
                                                                        </Grid>
                                                                        <Grid container direction='row' justify='flex-start' alignItems='center' xs={11}>
                                                                            <div className='table_cell__box table_cell__box--folder_name' >
                                                                                <div className='table_cell--overflow  pl-1' >
                                                                                    <Link 
                                                                                        className='table__cell--link'
                                                                                        style={{ color: '#3366CC' }}
                                                                                        href='javascript:;' 
                                                                                        key={index} 
                                                                                        onClick={(event: any) => handleObjectOnClick(object.name)}
                                                                                    >
                                                                                        {object.name}
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                    : <Grid container direction='row' justify='flex-start' alignItems='center'>
                                                                        <Grid container direction='row' justify='center' alignItems='center' xs={1}>
                                                                            <DescriptionIcon />
                                                                        </Grid>
                                                                        <Grid container direction='row' justify='flex-start' alignItems='center' xs={11}>
                                                                            <div className='table_cell__box table_cell__box--file_name' >
                                                                                <div className='table_cell--overflow' >
                                                                                    {object.name}
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                
                                                            </div>
                                                        </TableCell>
                                                        <TableCell padding="none" align='center' >
                                                            <div className='table__column--small table__cell table__cell--small-font'>
                                                                {isFolder ? 'Folder' : 'File'}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell padding="none" align='center' >
                                                            <div className='table__column--medium table__cell table__cell--small-font'>
                                                                {object.lastModified 
                                                                    ? (new Date(object.lastModified)).toLocaleString('sv-SE') 
                                                                        + ` (${formatTimezoneOffset((new Date(object.lastModified)).getTimezoneOffset())})`
                                                                    : '-'
                                                                }
                                                            </div>
                                                        </TableCell>
                                                        <TableCell padding="none" align='center' >
                                                            <div className='table__column--small table__cell table__cell--small-font'>
                                                                {object.size || '-'}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell padding="none" align='center' >
                                                            <div className='table__column--storage-class table__cell table__cell--small-font'>
                                                                {object.storageClass || '-'}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }))
                                            : <TableRow>
                                                <TableCell padding="none" align='center' colSpan={5} >
                                                    <Grid direction="column" alignItems="center" justify="center">
                                                        <div className='table__cell table__header table__cell--grey table__cell--bold pt-2'>
                                                            No objects
                                                        </div>
                                                        <div className='table__cell table__cell--small-font table__cell--grey pt-2 pb-2'>
                                                            You don't have any objects in this folder.
                                                        </div>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        : <TableRow>
                                            <TableCell padding="none" align='center' colSpan={5} >
                                                <Grid direction="column" alignItems="center" justify="center">
                                                    <Grid container direction="row" alignItems="center" justify="center" className='pt-3'>
                                                        <Grid direction="row" alignItems="center" justify="center">
                                                            <CancelOutlined style={{ color: "red", fontSize: 30 }} />
                                                        </Grid>
                                                        <Grid direction="row" alignItems="center" justify="center">
                                                            <div className='table__cell table__header table__cell--grey table__cell--bold pl-1'>
                                                                Failed to load objects
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <div className='table__cell table__cell--small-font table__cell--grey pt-2 pb-2'>
                                                        Please refresh the page.
                                                    </div>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                            }
                            
                        </TableBody>
                    </Table>
                </div>
            </Paper>
            <Table>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPage={objectExplorerStore.pagination.pageSize}
                            rowsPerPageOptions={[objectExplorerStore.pagination.pageSize]}
                            count={objectExplorerStore.totalKeyCount}
                            page={objectExplorerStore.pagination.page-1}
                            onChangePage={(event, page) => handleOnChangePage(page)}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    )
}

const mapStateToProps = (rootState: any) => ({ 
    objectExplorerStore: rootState.objectExplorer as ObjectExplorerStore
 })

const mapDispatchToProps = { 
    handleFetchObjectList, updatePagination, updateObjectPath, clearAll
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ObjectListFromRedux = ConnectedProps<typeof connector>

const ConnectedObjectList = connector(ObjectList)

export default ConnectedObjectList