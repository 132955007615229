export function authenticationError(state = false, action) {
    switch (action.type) {
        case 'AUTHENTICATION_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function authenticationLoading(state = false, action) {
    switch (action.type) {
        case 'AUTHENTICATION_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function token(state = null, action) {
    switch (action.type) {
        case 'AUTHENTICATION_SUCCESS':
            return action.token;

        default:
            return state;
    }
}


export function appSettingsError(state = false, action) {
    switch (action.type) {
        case 'APP_SETTINGS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function appSettingsLoading(state = false, action) {
    switch (action.type) {
        case 'APP_SETTINGS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function appSettings(state = null, action) {
    switch (action.type) {
        case 'APP_SETTINGS_SUCCESS':
            return action.appSettings;

        default:
            return state;
    }
}