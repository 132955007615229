import React, { Component } from 'react';
import { 
    Table, TableBody, TableRow, TableHead, TableCell, LinearProgress, Tooltip 
} from '@material-ui/core';
import { VALID_ONGOING_TASK_STATUS } from './UploadPanel'

class UploadFilesTable extends Component {
    // upload specific functions below here
    formatBytes = (sizeInByte, toFixed) => { 
        if (0 === sizeInByte) return "0 Bytes"; 
        let kilo = 1024
        let unitList = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        let unitIndex = Math.floor(Math.log(sizeInByte) / Math.log(kilo)); 
        toFixed = toFixed || 2
        return parseFloat((sizeInByte / Math.pow(kilo, unitIndex)).toFixed(toFixed)) + " " + unitList[unitIndex] 
    }

    render() {
        const filenameColumnStyle = { };
        const filenameTextDivStyle = {textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap", width:365, maxWidth: 365};
        const partColumnStyle = { textAlign:"center", width:105};
        const sizeColumnStyle = { textAlign:"center", width:125};
        const compressionColumnStyle = { textAlign:"center", width:85};
        const progressColumnStyle = { textAlign:"center", width:125};
        const statusColumnStyle = { textAlign:"center", width:215, minWidth: 215, whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" };
        const linearProgressStyle = { marginLeft:5, marginRight:5};
        const headerDivTextStyle = {fontWeight:"bolder", color:"#000000"};

        return (
            <div style={{ maxHeight: 400, overflow: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow style={{ height: 30 }}>
                            <TableCell padding="none" style={filenameColumnStyle}><div style={headerDivTextStyle}>FILE</div></TableCell>
                            <TableCell padding="none" style={partColumnStyle}><div style={headerDivTextStyle}>PART</div></TableCell>
                            <TableCell padding="none" style={sizeColumnStyle}><div style={headerDivTextStyle}>SIZE</div></TableCell>
                            {/* <TableCell padding="none" style={compressionColumnStyle}><div style={headerDivTextStyle}>COMPRESSION</div></TableCell> */}
                            <TableCell padding="none" style={progressColumnStyle}><div style={headerDivTextStyle}>UPLOAD</div></TableCell>
                            <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}>STATUS</div></TableCell>
                            {/* <TableCell>Remaining</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.rows.length
                            ? this.props.rows.map(row => {
                                return (
                                    <TableRow key={row.fullPath} 
                                        style={
                                            row.status==='FAILED' || row.status==='RETRY @ END'
                                                ? {backgroundColor:'#FF9999', height:10} 
                                                : row.status=== VALID_ONGOING_TASK_STATUS.READING
                                                    ? {backgroundColor:'#ffffcc', height:10} 
                                                    : row.status==="COMPLETE" 
                                                        ? {backgroundColor:'#e6ffe6', height:10}
                                                        : [VALID_ONGOING_TASK_STATUS.COMPRESSING, VALID_ONGOING_TASK_STATUS.WAITING_FOR_UPLOAD, VALID_ONGOING_TASK_STATUS.UPLOADING].indexOf(row.status) > -1
                                                            ? {backgroundColor:'#e6f7ff', height:10} 
                                                            : {backgroundColor:'#FFFFFF', height:10}
                                        }
                                    >
                                        <TableCell padding="none" style={filenameColumnStyle}>
                                            <Tooltip title={row.fullPath} placement="right-start">
                                                <div style={filenameTextDivStyle}>{row.fileName}</div>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell padding="none" style={partColumnStyle}>
                                            {row.totalParts > 1 ? (row.chunkIndex + 1) + ' of ' + row.totalParts : 'NA'}
                                        </TableCell>
                                        <TableCell padding="none" style={sizeColumnStyle}>
                                            {this.formatBytes(row.chunkSize)}
                                        </TableCell>
                                        {/* <TableCell padding="none" style={compressionColumnStyle}> */}
                                            {/* {(row.status && row.status === VALID_ONGOING_TASK_STATUS.COMPRESSING)
                                                ? <LinearProgress style={linearProgressStyle} variant="determinate" value={parseFloat(row.percentCompleted)} /> 
                                                : 'NA'
                                            } */}
                                            {/* <LinearProgress style={linearProgressStyle} variant="determinate" 
                                                value={(row.status && row.status === VALID_ONGOING_TASK_STATUS.COMPRESSING) 
                                                            ? parseFloat(row.percentCompleted)
                                                            : (row.status && [VALID_ONGOING_TASK_STATUS.WAITING_FOR_UPLOAD, VALID_ONGOING_TASK_STATUS.UPLOADING, VALID_ONGOING_TASK_STATUS.COMPLETE].indexOf(row.status) >= 0)
                                                                ? 100
                                                                : 0
                                                        } 
                                            />
                                        </TableCell> */}
                                        <TableCell padding="none" style={progressColumnStyle}>
                                            {/* {(row.status && row.status === VALID_ONGOING_TASK_STATUS.UPLOADING)
                                                ? <LinearProgress style={linearProgressStyle} variant="determinate" value={parseFloat(row.percentCompleted)} /> 
                                                : 'NA'
                                            } */}
                                            <LinearProgress style={linearProgressStyle} variant="determinate" 
                                                value={(row.status && row.status === VALID_ONGOING_TASK_STATUS.UPLOADING)
                                                        ? parseFloat(row.percentCompleted)
                                                        : 0
                                                    } 
                                            />
                                        </TableCell>
                                        <TableCell padding="none" style={statusColumnStyle}>
                                            {row.status || 'QUEUED'}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            : <TableRow key={'n/a'}>
                                <TableCell padding="none" style={filenameColumnStyle}>
                                    <div style={filenameTextDivStyle}>N/A</div>
                                </TableCell>
                                <TableCell padding="none" style={partColumnStyle}>
                                </TableCell>
                                <TableCell padding="none" style={sizeColumnStyle}>
                                </TableCell>
                                {/* <TableCell padding="none" style={compressionColumnStyle}>
                                </TableCell> */}
                                <TableCell padding="none" style={progressColumnStyle}>
                                </TableCell>
                                <TableCell padding="none" style={statusColumnStyle}>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default UploadFilesTable;