import axios from 'axios';
import Config from '../app/Config.json'

const BASE_URI = Config.BASE_URI

export function flightsListHasError(bool) {
    return {
        type: 'FLIGHTS_LIST_HAS_ERROR',
        hasError: bool
    };
}

export function flightsListLoading(bool) {
    return {
        type: 'FLIGHTS_LIST_LOADING',
        isLoading: bool
    };
}

export function flightsListSuccess(flights) {
    return {
        type: 'FLIGHTS_LIST_SUCCESS',
        flights
    };
}
/**
 * 
 * @param {*} page, total, pageSize 
 */
export function flightsQueryParams(payload) {
    return {
        type: 'FLIGHTS_QUERY_PARAMS',
        payload
    }
}

/**
 * fetch flights, used by the 'POST' page
 */
export function flightsFetchList(token) {
    return (dispatch, getState) => {
        dispatch(flightsListLoading(true));
        const state = getState(),
            { page, pageSize, ...queryParams } = state.flightsQueryParams
        token = token || state.token

        // const server = BASE_URI + 'flights' + `?include=Sensors+sensor+SensorID+Sensors,Sensors.ProjectID+project+ProjectID+Projects&page=${page}&pageSize=${pageSize}&sort=Date+-1`,headers = { authorization: 'Bearer ' + token };
        const server = BASE_URI + 'flights' + `?page=${page}&pageSize=${pageSize}&sort=Date+-1`,headers = { authorization: 'Bearer ' + token };

        axios.get(server, { params: queryParams.params, headers }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(flightsListLoading(false));
            //console.log(response)
            const total = parseInt(response.headers['x-total-count'])
            dispatch(flightsQueryParams({ total }));
            return response;
        }).then((response) => dispatch(flightsListSuccess(response.data))).catch(() => {
            dispatch(flightsListLoading(false));
            dispatch(flightsListHasError(true))
        });
    };
}

export function flightItemHasError(bool) {
    return {
        type: 'FLIGHT_ITEM_HAS_ERROR',
        hasError: bool
    };
}

export function flightItemLoading(bool) {
    return {
        type: 'FLIGHT_ITEM_LOADING',
        isLoading: bool
    };
}

export function flightItemSuccess(flight) {
    return {
        type: 'FLIGHT_ITEM_SUCCESS',
        flight
    };
}

//?include=ProjectID+project+ProjectID+Project

/**
 * fetch flights, used by the 'POST' page
 */
//mis-using the flights (read many / list) to get a flight decorated with Sensor details
// export function flightsFetchDecoratedItem(token, flightID) {
//     return (dispatch) => {
//         dispatch(flightItemLoading(true));
        
//         const server = BASE_URI + 'flights' + `?include=Sensors+sensor+SensorID+Sensors&FlightID=${flightID}`,headers = { authorization: 'Bearer ' + token };

//         axios.get(server, { headers }).then((response) => {
//             if (response.status !== 200) {
//                 throw Error(response.statusText);
//             }
//             dispatch(flightItemLoading(false));
//             // const total = parseInt(response.headers['x-total-count'])
//             // dispatch(flightsQueryParams({ total }));
//             return response;
//         }).then((response) => dispatch(flightItemSuccess(response.data[0]))).catch(() => {
//             dispatch(flightItemLoading(false));
//             dispatch(flightItemHasError(true))
//         });
//     };
// }

export function flightFetchItem(token, id) {
    return (dispatch) => {
        dispatch(flightItemLoading(true));

        axios.get(
            BASE_URI + 'flights/' + id,
            {
                headers: { authorization: 'Bearer ' + token }
            }
        )
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(flightItemLoading(false));
                return response;
            })
            .then((response) => dispatch(flightItemSuccess(response.data)))
            .catch(() => {
                dispatch(flightItemLoading(false));
                dispatch(flightItemHasError(true));
            });
    };
}

export function flightItemUpdateHasError(bool) {
    return {
        type: 'FLIGHT_ITEM_UPDATE_HAS_ERROR',
        hasError: bool
    };
}

export function flightItemUpdateLoading(bool) {
    return {
        type: 'FLIGHT_ITEM_UPDATE_LOADING',
        isLoading: bool
    };
}

export function flightItemUpdateSuccess(flight) {
    return {
        type: 'FLIGHT_ITEM_UPDATE_SUCCESS',
        flight
    };
}

export function flightUpdateItem(token, flightID, uploadID,status, browserFingerprint = null, returnResult = false, jsonData = null) {//jsonData) {
    // console.log('...in flightUpdateItem, flightID, uploadID,status, browser fingerprint:',flightID, uploadID,status,browserFingerprint)
    // console.log(jsonData)
    return (dispatch) => {

        // dispatch(flightItemUpdateLoading(true));
        let paramString = returnResult ? '?returnResult=true' : '';

        let requestObject = {
                method: 'put',
                url: BASE_URI + 'flights/' + flightID + '/upload/' + uploadID + '/' + status + paramString,
                headers: {
                    "Authorization": 'Bearer ' + token
                },
                // data: jsonData
        };

        if(jsonData !== null){
            // jsonData['cacheBreaker'] = new Date().getTime();
            jsonData['browserFingerprint'] = browserFingerprint;
            requestObject.data = jsonData;
        }
        else{
            requestObject.data = {'browserFingerprint':browserFingerprint};
        }

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(flightItemLoading(false));
                return response;
            })
            .then((response) => {
                if (response.data.result)
                    dispatch(flightItemSuccess(response.data.result));
                // else
                //     dispatch(flightItemUpdateSuccess(response.data));
            })
            .catch(() => {
                // dispatch(flightItemUpdateLoading(false));
                // dispatch(flightItemUpdateHasError(true))
                dispatch(flightItemLoading(false));
                dispatch(flightItemHasError(true));
            });
    };
}

export function checkConcurrentUploads(token, browserFingerprint, flightID = null, uploadID = null) {
    return (dispatch) => {
        dispatch(concurrentUploadCheckLoading(true));

        axios.get(
            flightID != null && uploadID != null ? BASE_URI + 'uploads/byFingerprint/'+browserFingerprint+'/'+flightID+'/'+uploadID : BASE_URI + 'uploads/byFingerprint/'+browserFingerprint,
            // BASE_URI + 'uploads/byFingerprint/'+browserFingerprint,
            {
                headers: {authorization: 'Bearer ' + token}
            }
        )
        .then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(concurrentUploadCheckLoading(false));

            return response;
        })
        .then((response) => {
            dispatch(concurrentUploadCheckSuccess(response.data.concurrentUploads))
        })
        .catch(() => {
            dispatch(concurrentUploadCheckHasError(true))
        });
    };
}

export function concurrentUploadCheckHasError(bool) {
    return {
        type: 'CONCURRENT_UPLOAD_CHECK_HAS_ERROR',
        hasError: bool
    };
}

export function concurrentUploadCheckLoading(bool) {
    return {
        type: 'CONCURRENT_UPLOAD_CHECK_LOADING',
        isLoading: bool
    };
}

export function concurrentUploadCheckSuccess(concurrentUploads) {
    return {
        type: 'CONCURRENT_UPLOAD_CHECK_SUCCESS',
        concurrentUploads
    };
}

// export function flightUploadsFetchList(token) {
//     return (dispatch, getState) => {
//         dispatch(flightUploadsListLoading(true));
//         const state = getState(),
//             { page, pageSize, ...queryParams } = state.flightsQueryParams
//         token = token || state.token
//         const server = BASE_URI + 'flights' + `?include=Sensors+sensor+SensorID+Sensors,Sensors.ProjectID+project+ProjectID+Projects&page=${page}&pageSize=${pageSize}&sort=Date+-1`,headers = { authorization: 'Bearer ' + token };

//         axios.get(server, { params: queryParams.params, headers }).then((response) => {
//             if (response.status !== 200) {
//                 throw Error(response.statusText);
//             }
//             dispatch(flightsListLoading(false));
//             //console.log(response)
//             const total = parseInt(response.headers['x-total-count'])
//             dispatch(flightsQueryParams({ total }));
//             return response;
//         }).then((response) => dispatch(flightsListSuccess(response.data))).catch(() => {
//             dispatch(flightsListLoading(false));
//             dispatch(flightsListHasError(true))
//         });
//     };
// }

// export function flightItemHasError(bool) {
//     return {
//         type: 'FLIGHT_ITEM_HAS_ERROR',
//         hasError: bool
//     };
// }

// export function flightItemLoading(bool) {
//     return {
//         type: 'FLIGHT_ITEM_LOADING',
//         isLoading: bool
//     };
// }

// export function flightItemSuccess(flight) {
//     return {
//         type: 'FLIGHT_ITEM_SUCCESS',
//         flight
//     };
// }