import { combineReducers } from "redux";
import {
  flights,
  flightsListLoading,
  flightsQueryParams,
  flightsListHasError,
  flight,
  flightItemLoading,
  flightItemHasError,
  flightItemUpdateSuccess,
  flightItemUpdateLoading,
  flightItemUpdateHasError,
  concurrentUploadCheckHasError,
  concurrentUploadCheckLoading,
  concurrentUploadCheckSuccess,
} from "../flight/FlightReducer";
import {
  processing,
  processingListHasError,
  processingListLoading,
  processingQueryParams,
  params,
  paramsListHasError,
  paramsListLoading,
  paramsQueryParams,
  paramsUpdateSuccess,
  paramsUpdateHasError,
  paramsUpdateLoading,
  processingResubmitHasError,
  processingResubmitLoading,
  processingResubmitLoadingWithId,
  processingResubmitSuccess,
  processingLMSResubmitHasError,
  processingLMSResubmitLoading,
  processingLMSResubmitLoadingWithId,
  processingLMSResubmitSuccess,
  processingUpdateSuccess,
  processingUpdateHasError,
  processingUpdateLoading,
  processingClusterResubmitHasError,
  processingClusterResubmitLoading,
  processingClusterResubmitLoadingWithId,
  processingClusterResubmitSuccess,
  calibrationDownloadLoading,
  downloadAuthHasError,
  downloadAuthSuccess,
  downloadAuthLoading
} from "../processing/ProcessingReducer";
import {
  aircraft,
  aircraftListHasError,
  aircraftListLoading,
  aircraftQueryParams,
} from "../processing/AircraftReducer";
import {
  sensorEquipment,
  sensorEquipmentListHasError,
  sensorEquipmentListLoading,
  sensorEquipmentQueryParams,
} from "../processing/SensorEquipmentReducer";
import {
  token,
  authenticationLoading,
  authenticationError,
  appSettingsError,
  appSettingsLoading,
  appSettings,
} from "../auth/AuthenticationReducer";
import {
  addStatusValue,
  removeStatusValue,
  removeAllStatusValues,
  clearAllFilters,
  uniqueValuesFetchHasError,
  uniqueValuesFetchLoading,
  uniqueValuesFetchSuccess,
} from "../common/CompositeFilterReducer";
import {
  sensorsListHasError,
  sensorsListLoading,
  sensors,
  sensorsQueryParams,
  gpsListHasError,
  gpsListLoading,
  gps_list,
  submitCustomGPSProcessingHasError,
  submitCustomGPSProcessingSuccess,
  submitCustomGPSProcessingLoading,
} from "../processing/SensorReducer";
import { displaySystemSnackbar } from "../common/SystemSnackbarReducer";
import {
  createProjectSuccess,
  createProjectHasError,
  searchClient,
  searchClientHasError,
  searchProjectManager,
  searchProjectManagerHasError,
} from "./../manage/CreateProjectReducer";
import {
  getManageNotification,
  getManageNotificationFailure,
} from "./../notification/NotificationReducer";
import instructions from '../aviation/instructionsReducer'
import instructionList from '../aviation/instructionListReducer'
import productList from '../products/productListReducer'

import {instructionsListHasError,instructionsListLoading,insts,instructionsQueryParams, instructionUpdateHasError, instructionsUpdateLoading, instructionUpdateSuccess, } from '../aviation/InstructionReducer'
import uploader from '../upload/UploaderReducer'
import objectExplorer from "../explorer/objectExplorerReducer";

import { 
  TMProcessingReducer, 
  TMQAReducer,
  TMQAWithErrorsReducer, 
  TMIgnoreReducer,
  TMManuallyProcessedReducer,
  TMMarkSupersededReducer,
  TMResubmitReducer,
  TMCancelJobReducer,
  TMProcessingQueryParamsReducer
} from '../tmprocessing/TMProcessingReducer';

export default combineReducers({
  token,
  authenticationLoading,
  authenticationError,

  appSettingsError,
  appSettingsLoading,
  appSettings,

  flights,
  flightsListLoading,
  flightsListHasError,
  flightsQueryParams,

  flight,
  flightItemLoading,
  flightItemHasError,

  flightItemUpdateSuccess,
  flightItemUpdateLoading,
  flightItemUpdateHasError,

  flightItemUpdateSuccess,
  flightItemUpdateLoading,
  flightItemUpdateHasError,

  concurrentUploadCheckHasError,
  concurrentUploadCheckLoading,
  concurrentUploadCheckSuccess,

  processing,
  processingListHasError,
  processingListLoading,
  processingQueryParams,
  processingResubmitHasError,
  processingResubmitLoading,
  processingResubmitLoadingWithId,
  processingResubmitSuccess,
  processingLMSResubmitHasError,
  processingLMSResubmitLoading,
  processingLMSResubmitLoadingWithId,
  processingLMSResubmitSuccess,
  processingClusterResubmitHasError,
  processingClusterResubmitLoading,
  processingClusterResubmitLoadingWithId,
  processingClusterResubmitSuccess,
  calibrationDownloadLoading,

  aircraft,
  aircraftListHasError,
  aircraftListLoading,
  aircraftQueryParams,

  sensorEquipment,
  sensorEquipmentListHasError,
  sensorEquipmentListLoading,
  sensorEquipmentQueryParams,

  params,
  paramsListHasError,
  paramsListLoading,
  paramsQueryParams,

  paramsUpdateSuccess,
  paramsUpdateHasError,
  paramsUpdateLoading,

  processingUpdateSuccess,
  processingUpdateHasError,
  processingUpdateLoading,

  addStatusValue,
  removeStatusValue,
  removeAllStatusValues,
  clearAllFilters,
  uniqueValuesFetchHasError,
  uniqueValuesFetchLoading,
  uniqueValuesFetchSuccess,

  displaySystemSnackbar,

  sensorsListHasError,
  sensorsListLoading,
  sensors,
  // sensorsQueryParams

  gpsListHasError,
  gpsListLoading,
  gps_list,

  submitCustomGPSProcessingHasError,
  submitCustomGPSProcessingSuccess,
  submitCustomGPSProcessingLoading,

  createProjectSuccess,
  createProjectHasError,
  searchClient,
  searchClientHasError,
  searchProjectManager,
  searchProjectManagerHasError,
  getManageNotification,
  getManageNotificationFailure,

  downloadAuthHasError,
  downloadAuthSuccess,
  downloadAuthLoading,

  instructions,
  instructionsListHasError,
  instructionsListLoading,
  insts,
  instructionUpdateHasError,
  instructionsUpdateLoading,
  instructionUpdateSuccess,
  instructionsQueryParams,
  instructionList,

  productList,
  uploader,
  objectExplorer,

  TMProcessingReducer,
  TMQAReducer,
  TMQAWithErrorsReducer,
  TMIgnoreReducer,
  TMManuallyProcessedReducer,
  TMMarkSupersededReducer,
  TMResubmitReducer,
  TMCancelJobReducer,
  TMProcessingQueryParamsReducer
});
