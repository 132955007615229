import React, { Component } from 'react';
import { IconButton, MenuItem, ListItemIcon, Popper, Paper, ClickAwayListener, MenuList, Fade } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import { CircularProgress } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Restore from '@material-ui/icons/Restore';
import FileCopy from '@material-ui/icons/FileCopy'
import Block from '@material-ui/icons/Block';
import NotInterested from '@material-ui/icons/NotInterested'
import DeleteForever from '@material-ui/icons/DeleteForever'
import HourglassFullTwoTone from '@material-ui/icons/HourglassFullTwoTone'
import AssignmentReturnOutlined from '@material-ui/icons/AssignmentReturnOutlined'
import FindInPage from '@material-ui/icons/FindInPage';
import NicerTooltip from '../common/NicerTooltip'
import {
    ManualProcessingRequired, ManuallyProcessed, SupersededComplete,
    SupersededQueued, SupersededFailed, SupersededManuallyProcessed,
    SupersededIgnore, CompletedNoQA, FailedNoQA, Archived
} from '../icons/svgIcons'

class ProcessingStatusTableIcon extends Component {

    state = {
        anchorEl: null,
        open: false
    };

    // handleClick = event => {
    //     this.setState({ anchorEl: event.currentTarget });
    // };

    handleClose = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open,
        }));
    };

    handleClick = event => {
        // if (this.props.isArchived == true) {
        //     return
        // }
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open,
            tooltipOpen: false
        }));
    };

    // handleMenuClick = (event, func) => {
    //     const { currentTarget } = event;
    //     this.setState(state => ({
    //         anchorEl: currentTarget,
    //         open: !state.open
    //     }));

    //     func();
    // };

    handleMenuClick = (event, func, inputValue = undefined) => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open
        }));

        if (inputValue !== undefined)
            func(inputValue);
        else
            func();
    };

    handlePopoverOpen = event => {
        // this.setState({ anchorEl: event.currentTarget });
        this.setState({ tooltipOpen: true });

    };

    formatTimezoneOffset = (offset) => {
        let symbol = (offset > 0) ? '-' : '+'
        let hours = Math.floor(Math.abs(offset) / 60) 
        let minutes = Math.round(Math.abs(offset) % 60)
        return 'UTC' + symbol + hours + ":" + minutes.toString().padStart(2, '0')
    }

    render() {
        const { 
            status, attempts, template, qaUser, isGuestUser, GPSRuntime, GPSRunStopDate,
            isAnyRunningJob, GPSQAIssueDesc, GPSQAIssueRes, documentMajorVersion, isArchived,
            explorerOnClickCallback 
        } = this.props;
        const active = this.props.active !== undefined ? this.props.active : true;
        const deletedSuperseded = this.props.deletedSuperseded !== undefined ? this.props.deletedSuperseded : false
        //a temporary rule to lock powercore templates until dynamic template handling is completed
        const isLocked = template.indexOf("PowerICP") > -1 ? true : false;
        const isMigratedFromLegacy = (documentMajorVersion && documentMajorVersion <= 3.0)
        let unifiedStatus = (isArchived && isMigratedFromLegacy) ? 'ARCHIVED': status
        const isEoOnly = this.props.isEoOnly !== undefined ? this.props.isEoOnly : false;

        //TODO remove this tetrarays nonsense and replace with a REAL permissions model
        if (isGuestUser) {
            return (
                <div>
                    {{
                        ['NOT_COMMENCED']:
                            <NicerTooltip placement="right" content={<span>{active ? '' : <span>Superseded<br /></span>}Not yet commenced</span>}>
                                {/* <AccessTime style={{ color: '#a3a3a3' }} /> */}
                                {active ? <AccessTime style={{ color: '#a3a3a3', marginTop: 20 }} /> : <SupersededQueued style={{ marginTop: 20 }} />}
                            </NicerTooltip>,
                        ['QUEUED']:
                            <NicerTooltip placement="right" content={<span>Queued</span>}>
                                <AccessTime style={{ /*fontSize: 36,*/ color: '#1a3a69', marginTop: 20 }} />
                            </NicerTooltip>,
                        ['RUNNING']:
                            <NicerTooltip placement="right" content={<span>Running<br />Attempts: {this.props.attempts}</span>}>
                                <CircularProgress id="compress-progress-id" style={{ marginTop: 20 }} size={19} />
                            </NicerTooltip>,
                        ['MANUAL_PROCESSING_REQUIRED']:
                            <div>
                                <NicerTooltip placement="right" content={<span>{active ? '' : <span>Superseded<br /></span>}Manual Processing Required<br /><span style={{ fontStyle: 'italic' }}>Click for options</span></span>}>
                                    <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {active ? <ManualProcessingRequired id="compress-progress-id" style={{ marginTop: 2 }} size={19} /> : <SupersededQueued style={{ paddingBottom: 4 }} />}
                                    </IconButton>
                                </NicerTooltip>
                            </div>,
                        ['IGNORE']:
                            <div>
                                <NicerTooltip placement="right" content={<span>{active ? '' : <span>Superseded<br /></span>}Ignore Processing<br />
                                    {GPSQAIssueDesc &&
                                        <div>
                                            <span style={{ display: "block", width: 300 }}>QA Issue: {GPSQAIssueDesc}<br /></span>
                                            <span style={{ display: "block", width: 300 }}>QA Resolution: {GPSQAIssueRes}<br /></span>
                                        </div>
                                    }
                                    <span style={{ fontStyle: 'italic' }}>Click for options</span></span>}>
                                    <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {active ? <NotInterested id="compress-progress-id" style={{ color: '#ff6100' }} size={19} /> : <SupersededIgnore />}
                                    </IconButton>
                                </NicerTooltip>
                            </div>,
                        ['MANUALLY_PROCESSED']:
                            <div>
                                <NicerTooltip placement="right" content={<span>{active ? '' : <span>Superseded<br /></span>}Manually Processed<br />
                                    {GPSQAIssueDesc &&
                                        <div>
                                            <span style={{ display: "block", width: 300 }}>QA Issue: {GPSQAIssueDesc}<br /></span>
                                            <span style={{ display: "block", width: 300 }}>QA Resolution: {GPSQAIssueRes}<br /></span>
                                        </div>
                                    }
                                    <span style={{ fontStyle: 'italic' }}>Click for options</span></span>}>
                                    <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {/* <ManuallyProcessed style={{ fontSize: 23, color: '#FF0000', marginTop:-5 }} /> */}
                                        {active ? <ManuallyProcessed style={{ fontSize: 23, color: '#FF0000', marginTop: -5 }} /> : <SupersededManuallyProcessed />}
                                    </IconButton>
                                </NicerTooltip>
                            </div>,
                        ['QA_COMPLETED_WITH_ERRORS']:
                            <div>
                                <NicerTooltip
                                    placement="right"
                                    content={
                                        <span>
                                            {active ? '' : <span>Superseded<br /></span>}
                                            QA Completed (with errors)<br />Attempts: {this.props.attempts}<br />
                                            <span>QA by {qaUser}<br /></span>
                                            {GPSQAIssueDesc &&
                                                <div>
                                                    <span style={{ display: "block", width: 300 }}>QA Issue: {GPSQAIssueDesc}<br /></span>
                                                    <span style={{ display: "block", width: 300 }}>QA Resolution: {GPSQAIssueRes}<br /></span>
                                                </div>
                                            }
                                            <span style={{ fontStyle: 'italic' }}>Click for options</span></span>}>
                                    <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {active ? <CheckCircleOutline style={{ color: '#ff6100' }} /> : <SupersededComplete />}
                                    </IconButton>
                                </NicerTooltip>

                            </div>
                        ,
                        ['COMPLETED']:
                            <div>
                                <NicerTooltip
                                    placement="right"
                                    content={
                                        <span>
                                            {active ? '' : <span>Superseded<br /></span>}
                                            Completed<br />Attempts: {this.props.attempts}<br />
                                            {qaUser ? <span>QA by {qaUser}<br /></span> : <span style={{ fontWeight: 'bold' }}>QA Required<br /></span>}
                                            <span style={{ fontStyle: 'italic' }}>Click for options</span></span>}>
                                    <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {active ?
                                            qaUser ?
                                                <CheckCircleOutline style={{ color: '#006600' }} />
                                                : <CompletedNoQA />
                                            : <SupersededComplete />
                                        }

                                    </IconButton>
                                </NicerTooltip>

                            </div>
                        ,
                        ['FAILED']:
                            <div>

                                <NicerTooltip placement="right" content={
                                    this.props.attempts > 0 ?
                                        <span>{active ? '' : <span>Superseded<br /></span>}Failed<br />Attempts: {this.props.attempts}<br />Click for options</span> :
                                        this.props.attempts === -1 ?
                                            <span>Failed with data issues<br />Please check the source<br />data before reprocessing<br />Click for options</span> :
                                            <span>Failed<br />Attempts: {this.props.attempts}<br />Check the Calibration Parameters<br /><span style={{ fontStyle: 'italic' }}>{qaUser ? <span>QA by {qaUser}<br /></span> : <span style={{ fontWeight: 'bold' }}>QA Required<br /></span>}Click for options</span></span>
                                }>
                                    <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {/* <ErrorOutline style={{ color: active ? '#FF0000' : '#a3a3a3' }} /> */}
                                        {active ? qaUser ?
                                            <ErrorOutline style={{ color: '#FF0000' }} />
                                            : <FailedNoQA />
                                            : <SupersededFailed />}
                                    </IconButton>
                                </NicerTooltip>
                            </div>,
                        ['UNAVAILABLE']:
                            <div>
                                <NicerTooltip
                                    placement="right"
                                    content={
                                        <span>
                                            {active ? '' : <span>Superseded<br /></span>}
                                            Unavailable<br />
                                            <span style={{ fontStyle: 'italic' }}>Click for options</span></span>}
                                >
                                    <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}
                                    >
                                        {active
                                            ? <Block style={{ color: '#FF6100' }} />
                                            : <SupersededComplete />
                                        }
                                    </IconButton>
                                </NicerTooltip>
                            </div>,
                        ['ARCHIVED']:
                            <div>
                                <NicerTooltip
                                    placement="right"
                                    content={
                                        <span>
                                            {active ? '' : <span>Superseded<br /></span>}
                                            Archived<br />
                                            <span style={{ fontStyle: 'italic' }}>Click for options</span></span>}
                                >
                                    <IconButton
                                        style={{ marginTop: 18, marginLeft: 8, padding: 5 }}
                                        onClick={this.handleClick}
                                    >
                                        {active
                                            ? <Archived />
                                            : <SupersededComplete />
                                        }
                                    </IconButton>
                                </NicerTooltip>
                            </div>
                    }[status]}
                </div>
            )
        }

        return (
            <div>
                {{
                    ['NOT_COMMENCED']:
                        <NicerTooltip placement="right"
                            content={
                                <span>
                                    {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                    {active
                                        ? ''
                                        : deletedSuperseded
                                            ? <span>Superseded and Deleted<br /></span>
                                            : <span>Superseded<br /></span>
                                    }
                                    Not yet commenced
                                </span>
                            }
                        >
                            {
                                active
                                    ? <AccessTime style={{ color: '#a3a3a3', marginTop: 20 }} />
                                    : deletedSuperseded
                                        ? <DeleteForever style={{ marginTop: 20, color: '#FF0000' }} />
                                        : <SupersededQueued style={{ marginTop: 20 }} />
                            }
                        </NicerTooltip>,
                    ['QUEUED']:
                        <NicerTooltip placement="right" content={<span>Queued</span>}>
                            <AccessTime style={{ /*fontSize: 36,*/ color: '#1a3a69', marginTop: 20 }} />
                        </NicerTooltip>,
                    ['RUNNING']:
                        <NicerTooltip placement="right" content={<span>Running<br />Attempts: {this.props.attempts}</span>}>
                            <CircularProgress id="compress-progress-id" style={{ marginTop: 22 }} size={19} />
                        </NicerTooltip>,
                    ['MANUAL_PROCESSING_REQUIRED']:
                        <div>
                            <NicerTooltip placement="right"
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                        {active
                                            ? ''
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        Manual Processing Required<br />
                                        {GPSRuntime ? <span>Duration: {GPSRuntime}<br /></span> : null}
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop: 20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {
                                            active
                                                ? <ManualProcessingRequired id="compress-progress-id" style={{ marginTop: 2 }} size={19} />
                                                : <SupersededQueued style={{ paddingBottom: 4 }} />
                                        }
                                    </IconButton>
                                }
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                {!isMigratedFromLegacy
                                                    && <MenuList>
                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateManuallyProcessed)}>
                                                            <ListItemIcon>
                                                                <ManuallyProcessed style={{ fontSize: 19, color: '#1a3a69' }} />
                                                            </ListItemIcon>
                                                            <span style={{ fontSize: 12 }}>Mark as Manually Processed</span>
                                                        </MenuItem>
                                                        {active &&
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, false)}>
                                                                <ListItemIcon>
                                                                    <SupersededQueued style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Mark as Superseded</span>
                                                            </MenuItem>
                                                        }
                                                        {!active &&
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, true)}>
                                                                <ListItemIcon>
                                                                    <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Mark as Utilised</span>
                                                            </MenuItem>
                                                        }
                                                    </MenuList>
                                                }
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['IGNORE']:
                        <div>
                            <NicerTooltip placement="right"
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                        {active
                                            ? ''
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        Ignore Processing<br />
                                        {GPSRuntime ? <span>Duration: {GPSRuntime}<br /></span> : null}
                                        {GPSQAIssueDesc &&
                                            <div>
                                                <span style={{ display: "block", width: 300 }}>QA Issue: {GPSQAIssueDesc}<br /></span>
                                                <span style={{ display: "block", width: 300 }}>QA Resolution: {GPSQAIssueRes}<br /></span>
                                            </div>
                                        }
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop: 20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {active ? <NotInterested id="compress-progress-id" style={{ color: '#ff6100' }} size={19} /> : <SupersededIgnore />}
                                    </IconButton>

                                }
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.s3Link) })}>
                                                        <ListItemIcon>
                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                        </ListItemIcon>
                                                        <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                    </MenuItem>
                                                    {!isMigratedFromLegacy
                                                        && <>
                                                            {active &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, false)}>
                                                                    <ListItemIcon>
                                                                        <SupersededQueued style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Superseded</span>
                                                                </MenuItem>
                                                            }
                                                            {!active &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, true)}>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Utilised</span>
                                                                </MenuItem>
                                                            }
                                                            <MenuItem
                                                                disabled={isLocked || isAnyRunningJob}
                                                                onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                <ListItemIcon>
                                                                    <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                            </MenuItem>
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['MANUALLY_PROCESSED']:
                        <div>
                            <NicerTooltip placement="right"
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                        {active
                                            ? ''
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        Manually Processed<br />
                                        {GPSQAIssueDesc &&
                                            <div>
                                                <span style={{ display: "block", width: 300 }}>QA Issue: {GPSQAIssueDesc}<br /></span>
                                                <span style={{ display: "block", width: 300 }}>QA Resolution: {GPSQAIssueRes}<br /></span>
                                            </div>
                                        }
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop: 20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {/* <ManuallyProcessed style={{ fontSize: 23, color: '#FF0000', marginTop:-5 }} /> */}
                                        {active ? <ManuallyProcessed style={{ fontSize: 23, color: '#FF0000' }} /> : <SupersededManuallyProcessed />}
                                    </IconButton>

                                }
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.s3Link) })}>
                                                        <ListItemIcon>
                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                        </ListItemIcon>
                                                        <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                    </MenuItem>
                                                    {!isMigratedFromLegacy
                                                        && <>
                                                            {active &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, false)}>
                                                                    <ListItemIcon>
                                                                        <SupersededQueued style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Superseded</span>
                                                                </MenuItem>
                                                            }
                                                            {!active &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, true)}>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Utilised</span>
                                                                </MenuItem>
                                                            }
                                                            <MenuItem disabled={isLocked || isAnyRunningJob} onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                <ListItemIcon>
                                                                    <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                            </MenuItem>
                                                            {/* <MenuItem disabled={isLocked} onClick={(event) => this.handleMenuClick(event, this.props.submitLMS)}>
                                                                <ListItemIcon>
                                                                    <AssignmentReturnOutlined style={{ fontSize: 22, color: '#1a3a69', transform: 'scaleX(-1)' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Submit to LMS</span>
                                                            </MenuItem> */}
                                                            <MenuItem disabled={isLocked} onClick={(event) => this.handleMenuClick(event, this.props.submitLMS)}>
                                                                <ListItemIcon>
                                                                    <AssignmentReturnOutlined style={{ fontSize: 22, color: '#1a3a69', transform: 'scaleX(-1)' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Submit to EO & LMS Processing</span>
                                                            </MenuItem>
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['QA_COMPLETED_WITH_ERRORS']:
                        <div>
                            <NicerTooltip
                                placement="right"
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                        {active
                                            ? ''
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        QA Completed (with errors)<br />Attempts: {this.props.attempts}<br />
                                        <span>QA by {qaUser}<br /></span>
                                        {GPSQAIssueDesc &&
                                            <div>
                                                <span style={{ display: "block", width: 300 }}>QA Issue: {GPSQAIssueDesc}<br /></span>
                                                <span style={{ display: "block", width: 300 }}>QA Resolution: {GPSQAIssueRes}<br /></span>
                                            </div>
                                        }
                                        {GPSRunStopDate 
                                            ? <span>
                                                Completed Date: {(new Date(GPSRunStopDate+'.000+00:00')).toLocaleString('sv-SE') + ` (${this.formatTimezoneOffset((new Date(GPSRunStopDate+'.000+00:00')).getTimezoneOffset())})`}
                                                <br />
                                            </span> 
                                            : null
                                        }
                                        {GPSRuntime ? <span>Duration: {GPSRuntime}<br /></span> : null}
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop: 20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {active ? <CheckCircleOutline style={{ color: '#ff6100' }} /> : <SupersededComplete />}
                                    </IconButton>
                                }
                            </NicerTooltip>

                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.s3Link) })}>
                                                        <ListItemIcon>
                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                        </ListItemIcon>
                                                        <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                    </MenuItem>
                                                    {!isMigratedFromLegacy
                                                        && <>
                                                            <MenuItem
                                                                disabled={isLocked || isAnyRunningJob}
                                                                onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                <ListItemIcon>
                                                                    <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                            </MenuItem>
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateManuallyProcessed)}>
                                                                <ListItemIcon>
                                                                    <ManuallyProcessed style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Mark as Manually Processed</span>
                                                            </MenuItem>
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateIgnoreProcessing)}>
                                                                <ListItemIcon>
                                                                    <NotInterested style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Mark as Ignore Processing</span>
                                                            </MenuItem>
                                                            {active &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, false)}>
                                                                    <ListItemIcon>
                                                                        <SupersededQueued style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Superseded</span>
                                                                </MenuItem>
                                                            }
                                                            {!active &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, true)}>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Utilised</span>
                                                                </MenuItem>
                                                            }
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>
                    ,
                    ['COMPLETED']:
                        <div>
                            <NicerTooltip
                                placement="right"
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                        {active
                                            ? ''
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        Completed<br />Attempts: {this.props.attempts}<br />
                                        {GPSRunStopDate 
                                            ? <span>
                                                Completed Date: {(new Date(GPSRunStopDate+'.000+00:00')).toLocaleString('sv-SE') + ` (${this.formatTimezoneOffset((new Date(GPSRunStopDate+'.000+00:00')).getTimezoneOffset())})`}
                                                <br />
                                            </span> 
                                            : null
                                        }
                                        {GPSRuntime ? <span>Duration: {GPSRuntime}<br /></span> : null}
                                        {qaUser ? <span>QA by {qaUser}<br /></span> :  !isEoOnly ? <span style={{ fontWeight: 'bold' }}>QA Required<br /></span> : <span></span>}
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }>
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop: 20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {active
                                            ? qaUser||isEoOnly ?
                                                <CheckCircleOutline style={{ color: '#006600' }} />
                                                : <CompletedNoQA />
                                            : <SupersededComplete />
                                        }
                                    </IconButton>
                                }
                            </NicerTooltip>

                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.s3Link) })}>
                                                        <ListItemIcon>
                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                        </ListItemIcon>
                                                        <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                    </MenuItem>
                                                    {!isMigratedFromLegacy
                                                        && <>
                                                            <MenuItem
                                                                disabled={isLocked || isAnyRunningJob}
                                                                onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                <ListItemIcon>
                                                                    <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                            </MenuItem>
                                                            {!qaUser && !isEoOnly &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQA)}>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as QA Completed</span>
                                                                </MenuItem>
                                                            }
                                                            {!qaUser && !isEoOnly &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors)}>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as QA Completed (with errors)</span>
                                                                </MenuItem>
                                                            }
                                                            { !isEoOnly &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateManuallyProcessed)}>
                                                                    <ListItemIcon>
                                                                        <ManuallyProcessed style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Manually Processed</span>
                                                                </MenuItem>
                                                            }
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateIgnoreProcessing)}>
                                                                <ListItemIcon>
                                                                    <NotInterested style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Mark as Ignore Processing</span>
                                                            </MenuItem>
                                                            {active && 
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, false)}>
                                                                    <ListItemIcon>
                                                                        <SupersededQueued style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Superseded</span>
                                                                </MenuItem>
                                                            }
                                                            {!active && 
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, true)}>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Utilised</span>
                                                                </MenuItem>
                                                            }
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>
                    ,
                    ['FAILED']:
                        <div>
                            <NicerTooltip placement="right"
                                content={
                                    this.props.attempts > 0
                                        ? <span>
                                            {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                            {active
                                                ? ''
                                                : deletedSuperseded
                                                    ? <span>Superseded and Deleted<br /></span>
                                                    : <span>Superseded<br /></span>
                                            }
                                            Failed<br />Attempts: {this.props.attempts}<br />
                                            {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                        </span>
                                        : this.props.attempts === -1
                                            ? <span>Failed with data issues<br />Please check the source<br />data before reprocessing<br />Click for options</span>
                                            : <span>
                                                Failed<br />Attempts: {this.props.attempts}<br />Check the Calibration Parameters<br />
                                                <span style={{ fontStyle: 'italic' }}>
                                                    {qaUser
                                                        ? <span>QA by {qaUser}<br /></span>
                                                        : <span style={{ fontWeight: 'bold' }}>
                                                            QA Required<br />
                                                            {GPSRuntime
                                                                ? <span>Duration: {GPSRuntime}<br /></span>
                                                                : null
                                                            }
                                                        </span>
                                                    }
                                                    {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                                </span>
                                            </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop: 20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {/* <ErrorOutline style={{ color: active ? '#FF0000' : '#a3a3a3' }} /> */}
                                        {active ? qaUser ?
                                            <ErrorOutline style={{ color: '#FF0000' }} />
                                            : <FailedNoQA />
                                            : <SupersededFailed />}
                                    </IconButton>

                                }
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {!isMigratedFromLegacy
                                                        && <MenuItem
                                                            disabled={this.props.attempts < 1}
                                                            onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.s3Link) })}>
                                                            <ListItemIcon>
                                                                <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                            </ListItemIcon>
                                                            <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                        </MenuItem>
                                                    }
                                                    {!isMigratedFromLegacy
                                                        && <>
                                                            <MenuItem disabled={isLocked} onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                <ListItemIcon>
                                                                    <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                            </MenuItem>
                                                            {!qaUser &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQA)}>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as QA Completed</span>
                                                                </MenuItem>
                                                            }
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateManuallyProcessed)}>
                                                                <ListItemIcon>
                                                                    <ManuallyProcessed style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Mark as Manually Processed</span>
                                                            </MenuItem>
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateIgnoreProcessing)}>
                                                                <ListItemIcon>
                                                                    <NotInterested style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Mark as Ignore Processing</span>
                                                            </MenuItem>
                                                            {active &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, false)}>
                                                                    <ListItemIcon>
                                                                        <SupersededQueued style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Superseded</span>
                                                                </MenuItem>
                                                            }
                                                            {!active &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateSuperseded, true)}>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Mark as Utilised</span>
                                                                </MenuItem>
                                                            }
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['UNAVAILABLE']:
                        <div>
                            <NicerTooltip
                                placement="right"
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                        {active ? '' : <span>Superseded<br /></span>}
                                        Unavailable<br />
                                        <span style={{ fontStyle: 'italic' }}>Click for options</span></span>}
                            >
                                <IconButton
                                    style={{ marginTop: 15, padding: 5 }}
                                    onClick={this.handleClick}
                                >
                                    {active
                                        ? <Block style={{ color: '#FF6100' }} />
                                        : <SupersededComplete />
                                    }
                                </IconButton>
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {!isMigratedFromLegacy
                                                        && <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.s3Link) })}>
                                                            <ListItemIcon>
                                                                <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                            </ListItemIcon>
                                                            <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                        </MenuItem>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['ARCHIVED']:
                        <div>
                            <NicerTooltip
                                placement="right"
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                        {active ? '' : <span>Superseded<br /></span>}
                                        Archived<br />
                                        <span style={{ fontStyle: 'italic' }}>Click for options</span></span>}
                            >
                                <IconButton
                                    style={{ marginTop: 18, marginLeft: 8, padding: 5 }}
                                    onClick={this.handleClick}
                                >
                                    {active
                                        ? <Archived />
                                        : <SupersededComplete />
                                    }
                                </IconButton>
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {!isMigratedFromLegacy
                                                        && [
                                                            <MenuItem key={this.props.archivedS3Link+'GPSlink'} onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.archivedS3Link || '') })}>
                                                                <ListItemIcon>
                                                                    <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                            </MenuItem>,
                                                            <MenuItem key={this.props.archivedS3Link+'GPSexplorer'} onClick={(event) => this.handleMenuClick(event, () => explorerOnClickCallback() )}>
                                                                <ListItemIcon>
                                                                    <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                            </MenuItem>
                                                        ]
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['WAITING']:
                        <NicerTooltip
                            placement="right"
                            content={<span>Waiting after resubmitting</span>}
                        >
                            <HourglassFullTwoTone style={{ marginTop: 20 }} />
                        </NicerTooltip>
                }[unifiedStatus]}
            </div>
        );
    }
}

export default ProcessingStatusTableIcon;