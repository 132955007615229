import React, { Component } from 'react';
// import { LiDARIcon } from '../icons/svgIcons'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Wifi from '@material-ui/icons/Wifi'
import NicerTooltip from './NicerTooltip';
import { Grid } from '@material-ui/core';
import { LiDARandImagery } from '../icons/svgIcons'

class TableSensorTypeIcon extends Component {

    render() {
        const { sensorType } = this.props;
        const small = sensorType.length > 1 ? true : false;
        // let labels = JSON.parse(JSON.stringify(sensorType));
        // let finalValue = labels.pop();
        // labels = sensorType.length > 1 ? labels.join(',') + ' & ' + finalValue : finalValue;

        return (
            <div>
                {sensorType.map((value, index) => {
                    return (
                        // <NicerTooltip placement="right" content={<span>{labels}</span>}>
                        <Grid item>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <SensorTypeIcon sensorType={value} shrink={small} index={index} />
                            </Grid>
                        </Grid>
                        // </NicerTooltip>
                    )
                })}
            </div>
        );
    }
}

function SensorTypeIcon(props) {
    const { sensorType, shrink, index } = props;
    return (
        <div>
            {{
                ['LiDAR']:
                    <NicerTooltip placement="right" content={<span>{sensorType}</span>}>
                        <Wifi style={{ color: '#1a3a69', transform: shrink ? 'rotate(180deg) scale(.75, .75)' : 'rotate(180deg)', marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
                ['MediumFormatImagery']:
                    <NicerTooltip placement="right" content={<span>Medium Format Imagery</span>}>
                        <PhotoCamera style={{ color: '#1a3a69', transform: shrink ? 'scale(.75, .75)' : '', marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
                ['LargeFormatImagery']:
                    <NicerTooltip placement="right" content={<span>Large Format Imagery</span>}>
                        <PhotoCamera style={{ color: '#1a3a69', transform: shrink ? 'scale(.75, .75)' : '', marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
                ['LiDARandImagery']:
                    <NicerTooltip placement="right" content={<span>LiDAR &amp; Imagery<br /><span style={{ fontSize: 9 }}>(simultaneous imagery)</span></span>}>
                        <LiDARandImagery style={{ color: '#1a3a69', width: 50, height: 25, transform: shrink ? 'scale(.75, .75)' : '', marginLeft: -10, marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
            }[sensorType]}
        </div>
    )
}

export default TableSensorTypeIcon;