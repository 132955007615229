import { Reducer, AnyAction, Store, Action } from 'redux'
// import { PAGE_SIZE } from './InstructionList'
import { INSTRUCTION_LIST_ACTION_TYPE } from './instructionListActions'
import { 
    InstructionListDashboard,
    UpdateQueryParamsAction,
    UpdatePaginationAction,
    UpdateTotalItemCountAction,
    StoreInstructionListAction
} from './InstructionListTypes'

//////////////////////////////////
//            Reduces           //
//////////////////////////////////
export const PAGE_SIZE = 10
export const initPaginationParams = {
    page: 1,
    pageSize: PAGE_SIZE
}
export const initQueryParams = {
    sort: 'instruction.Updated -1',
}

const initState: InstructionListDashboard = {
    pagination: initPaginationParams,
    queryParams: initQueryParams,
    // queryParams: {},
    aggregatedInstructionList: [],
    isFetching: false,
    isPollingEnabled: false,
    totalItemCount: 0,
}

const instructionList: Reducer<InstructionListDashboard> = (prevState = initState, action: AnyAction) => {
    switch(action.type) {
        case INSTRUCTION_LIST_ACTION_TYPE.UPDATE_QUERY_PARAMS:
            // console.log(`From Reducer instructionList: queryParams: ${JSON.stringify(action.queryParams)}`)
            const { queryParams } = action as UpdateQueryParamsAction
            const prevQueryParams = prevState.queryParams
            var newQueryParams = Object.assign({}, prevQueryParams)
            if(Object.keys(queryParams).length !== 1) {
                throw new Error('Only one query param is expected')
            }
            if(queryParams[Object.keys(queryParams)[0]] === '') {
                delete newQueryParams[Object.keys(queryParams)[0]]
            } else {
                Object.assign(newQueryParams, queryParams)
            }
            return {
                ...prevState,
                queryParams: newQueryParams
            }
        case INSTRUCTION_LIST_ACTION_TYPE.CLEAR_ALL_QUERY_PARAMS:
            return {
                ...prevState,
                queryParams: {}
            }
        case INSTRUCTION_LIST_ACTION_TYPE.UPDATE_TOTAL_ITEM_COUNT:
            const { totalItemCount } = action
            return {
                ...prevState,
                totalItemCount
            }
        case INSTRUCTION_LIST_ACTION_TYPE.UPDATE_PAGINATION:
            const { pagination } = action as UpdatePaginationAction
            return {
                ...prevState,
                pagination
            }
        case INSTRUCTION_LIST_ACTION_TYPE.FETCH_INSTRUCTION_LIST:
            return {
                ...prevState,
                isFetching: true
            }
        case INSTRUCTION_LIST_ACTION_TYPE.STORE_INSTRUCTION_LIST:
            const { fetchedInstructionList } = action as StoreInstructionListAction
            return {
                ...prevState,
                aggregatedInstructionList: fetchedInstructionList
            }
        case INSTRUCTION_LIST_ACTION_TYPE.FETCH_INSTRUCTION_LIST_SUCCEED:
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: true
            }
        case INSTRUCTION_LIST_ACTION_TYPE.FETCH_INSTRUCTION_LIST_FAILED:
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: false
            }
        case INSTRUCTION_LIST_ACTION_TYPE.ENABLE_POLLING:
            return {
                ...prevState,
                isPollingEnabled: true
            }
            case INSTRUCTION_LIST_ACTION_TYPE.DISABLE_POLLING:
                return {
                    ...prevState,
                    isPollingEnabled: false
                }
        case INSTRUCTION_LIST_ACTION_TYPE.CLEAR_ALL:
            return ({ 
                ...Object.assign(initState)
            })
        default:
            return prevState
    }
}

export default instructionList
