export const UploadStatus = {
    READY_FOR_UPLOAD:"READY_FOR_UPLOAD",
    VALIDATING_SOURCE:"VALIDATING_SOURCE",
    VALIDATING_SOURCE_FAILED:"VALIDATING_SOURCE_FAILED",
    UPLOAD_QUEUED:"UPLOAD_QUEUED",
    UPLOADING:"UPLOADING",
    UPLOAD_FAILED:"UPLOAD_FAILED",
    UPLOAD_CANCELLED:"UPLOAD_CANCELLED",
    UPLOAD_PAUSED:"UPLOAD_PAUSED",
    UPLOAD_COMPLETE:"UPLOAD_COMPLETE",
    VALIDATING_UPLOAD:"VALIDATING_UPLOAD",
    VALIDATING_UPLOAD_FAILED:"VALIDATING_UPLOAD_FAILED",
    DATA_READY:"DATA_READY",
    LEGACY_SYSTEM:"LEGACY_SYSTEM",
    UPLOAD_STALLED:"UPLOAD_STALLED",
    ARCHIVED:"ARCHIVED"
}

export const UploadStatusLabels = {
    "READY_FOR_UPLOAD":"Ready for Upload",
    "VALIDATING_SOURCE":"Validating Source",
    "VALIDATING_SOURCE_FAILED":"Validating Source Failed",
    "UPLOAD_QUEUED":"Upload Queued",
    "UPLOADING":"Uploading",
    "UPLOAD_FAILED":"Upload Failed",
    "UPLOAD_CANCELLED":"Upload Cancelled",
    "UPLOAD_PAUSED":"Upload Paused",
    "UPLOAD_COMPLETE":"Upload Complete",
    "VALIDATING_UPLOAD":"Validating Upload",
    "VALIDATING_UPLOAD_FAILED":"Validating Upload Failed",
    "DATA_READY":"Data Ready",
    "LEGACY_SYSTEM":"Legacy System",
    "UPLOAD_STALLED":"Upload Stalled",
    "ARCHIVED":"Upload Archived"
}