import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Switch } from 'react-router-dom'
import PrivateRoute from '../common/PrivateRoute'
import CustomProcessingTabs from './CustomProcessingTabs';
import CustomProcessingSensor from './CustomProcessingSensor';

class CustomProcessingIndex extends Component {

    render() {
        return (
            <div>

                <Grid container direction="column" alignItems="center">
                    <CustomProcessingTabs />
                    <Switch>
                        <PrivateRoute exact path='/ip/custom_processing/sensor' component={CustomProcessingSensor} />
                        {/* <PrivateRoute exact path='/ip/params' component={ProcessingParamsList} />
                        <PrivateRoute exact path='/ip/params/:CompositeID' component={ProcessingParams} />
                        <PrivateRoute exact path='/ip/params/:CompositeID/edit' component={ProcessingParams} />
                        <PrivateRoute exact path='/ip/custom_processing' component={CustomProcessingIndex} /> */}
                    </Switch>
                </Grid>
            </div>
        );
    }
}

export default CustomProcessingIndex;
