import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Checkbox, IconButton, FormControlLabel, Grid, Tooltip } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
class LMSValidationDialog extends Component {

    render() {
        const errors = this.props.errors ? this.props.errors : [];

        return (
            <Dialog disableBackdropClick disableEscapeKeyDown onEnter={this.reset} open={this.props.open} fullWidth={true} maxWidth={'lg'}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    {
                        errors.length > 0 &&
                        <List dense={true} >
                            {errors.map((error) => (
                                
                                <ListItem key={error} divider style={{ backgroundColor: '#FF9999', fontSize: 14, paddingTop: 2, paddingBottom: 2 }}>
                                    {error}
                                 </ListItem>
                            ))}
                        </List>
                    }

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.props.onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default LMSValidationDialog;