import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Switch } from 'react-router-dom'
import PrivateRoute from '../common/PrivateRoute'
import ProcessingList from '../processing/ProcessingList';
import ProcessingParams from '../processing/ProcessingParams';
import ProcessingTabs from '../processing/ProcessingTabs';
// import { GearsIcon } from '../icons/svgIcons'
import ProcessingParamsList from './ProcessingParamsList';
import CustomProcessingIndex from './CustomProcessingIndex';
import DownloadAuthorisation from './DownloadAuthorisation';
import ImageryProcessingParams from '../processing/ImageryProcessingParams';
import ImageryProcessingParamsList from './ImageryProcessingParamsList';

class ProcessingIndex extends Component {

    render() {
        return (
            <div>

                <Grid container direction="column" alignItems="center">
                
                    {/* <Grid container direction="row" alignItems="center" justify="center" style={{ paddingTop: 10, paddingBottom: 10, background: "#CCCCCC", width:'100vw' }}>
                        <div style={{ fontSize: 28, fontWeight: "bolder", color: "#1a3a69", marginRight: 8 }}>
                            <GearsIcon style={{ marginBottom: -6, fontSize: 32, paddingRight: 5 }} />INITIAL PROCESSING</div>
                    </Grid> */}
                    <ProcessingTabs />
                    <Switch>
                        <PrivateRoute exact path='/ip' component={ProcessingList} />
                        {/* <PrivateRoute exact path='/ip/params' component={ProcessingParams} /> */}
                        <PrivateRoute exact path='/ip/params' component={ProcessingParamsList} />
                        <PrivateRoute exact path='/ip/params/:CompositeID' component={ProcessingParams} />
                        <PrivateRoute exact path='/ip/params/:CompositeID/edit' component={ProcessingParams} />
                        <PrivateRoute exact path='/ip/custom_processing/*' component={CustomProcessingIndex} />
                        <PrivateRoute exact path='/ip/download_auth/*' component={DownloadAuthorisation} />

                        <PrivateRoute exact path='/ip/imagery-params' component={ImageryProcessingParamsList} />
                        <PrivateRoute exact path='/ip/imagery-params/:CompositeID' component={ImageryProcessingParams} />
                        <PrivateRoute exact path='/ip/imagery-params/:CompositeID/edit' component={ImageryProcessingParams} />

                    </Switch>
                </Grid>
            </div>
        );
    }
}

export default ProcessingIndex;
