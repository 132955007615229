export function displaySystemSnackbar(state = false, action) {
    switch (action.type) {
        case 'DISPLAY_SYSTEM_SNACKBAR':
            return {
                display: action.display,
                type: action.snackbarType,
                content: action.content,
                autoHideDuration: action.autoHideDuration
            };

        default:
            return state;
    }
}