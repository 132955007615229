import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { 
    Grid, Divider, Input, Chip, Button, Badge, IconButton
} from '@material-ui/core';
import { withStyles, WithStyles, StyledComponentProps, createStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel'
// import Divider from '@material-ui/core/Divider';
import { SearchBarProps } from './SearchbarTypes'
import NicerTooltip from '../common/NicerTooltip';

const styles = ({ palette, spacing }: Theme) => createStyles({
    badge: {
      top: '50%',
      right: -16,
    },
  });

interface SearchBarPropsWithStyles extends WithStyles<typeof styles>, SearchBarProps {}

const SearchBar = (props: SearchBarPropsWithStyles) => {
    const { classes } = props;
    const { 
        sortedFilterList, width, filtersBtnOnClick, clearAllFiltersCallBack
    } = props

    const handleFilterBtnOnClick = (index: number, filterName: string) => {
        let filterNameList: Array<string> = []
        sortedFilterList.forEach(filter => filterNameList.push(filter.name))
        let originalIndex = filterNameList.indexOf(filterName)
        filtersBtnOnClick(originalIndex)
    }

    return (
        <>
            <Grid container direction="row" alignItems="center" justify="center" xs={12}
            >
                <Grid container direction="row" alignItems="center" justify="flex-end" className='mb-1' style={{ width: width }}>
                    {
                        React.Children.toArray(
                            sortedFilterList.filter(filter => (filter.filterList && filter.filterList.length))
                                .map((filter, index) => {
                                    const filterCounter = filter.filterList 
                                                            ? filter.name == 'Survey Date'
                                                                ? 1
                                                                : filter.filterList.length 
                                                            : 0
                                    return (
                                        <NicerTooltip placement="bottom-start"
                                            content={
                                                <>
                                                    {
                                                        React.Children.toArray(
                                                            filter.filterList.map((filterElement, index) => {
                                                                return (
                                                                    <>
                                                                        <Chip 
                                                                            key={index}
                                                                            label={
                                                                                <>
                                                                                    <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '130px' }} >{filterElement}</p>
                                                                                </>
                                                                            }
                                                                            style={{ maxWidth: '180px', margin: 8 }}
                                                                        /><br />
                                                                    
                                                                    </>
                                                                )
                                                            })
                                                        )
                                                    }
                                                </>
                                            }
                                        >
                                            <Button
                                                key={index}
                                                variant='outlined'
                                                style={{ marginRight: 12 }}
                                                onClick={() => handleFilterBtnOnClick(index, filter.name)}
                                            >
                                                {filter.name}
                                                {
                                                    <Badge 
                                                        badgeContent={filterCounter} 
                                                        color="primary" 
                                                        classes={{ badge: classes.badge }}
                                                        max={9}
                                                        style={{ marginRight: 18 }}
                                                    >
                                                    </Badge>
                                                }
                                            </Button>
                                        </NicerTooltip>
                                    )
                                })
                        )
                    }
                    <NicerTooltip placement="right"
                        content={
                            <span>Clear all filters</span>
                        }
                    >
                        <IconButton 
                            // className='iconbutton__button--medium'
                            onClick={() => clearAllFiltersCallBack()}
                            style={{ marginLeft: 16, height: 40, width: 40 }}
                        >
                            <CancelIcon color='primary' />
                        </IconButton>
                    </NicerTooltip>
                </Grid>
            </Grid>
            <Divider style={{ width: '100%' }}  />
        </>
    )
}

export default withStyles(styles)(SearchBar);