import { Action, ActionCreator, StateFromReducersMapObject } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import { IP_URI } from "../app/Config.json"
import {
    Pagination,
    QueryParams,
    UpdateTotalItemCountAction,
    UpdatePaginationAction,
    UpdateQueryParamsAction,
    AggregatedInstructionList,
    StoreInstructionListAction,
    InstructionListDashboard
} from './InstructionListTypes'

//////////////////////////////////
//           Constants          //
//////////////////////////////////
export const INSTRUCTION_LIST_ACTION_TYPE = ({
    UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',
    CLEAR_ALL_QUERY_PARAMS: 'CLEAR_ALL_QUERY_PARAMS',
    UPDATE_TOTAL_ITEM_COUNT: 'UPDATE_TOTAL_ITEM_COUNT',
    UPDATE_PAGINATION: 'UPDATE_PAGINATION',
    FETCH_INSTRUCTION_LIST: 'FETCH_INSTRUCTION_LIST',
    STORE_INSTRUCTION_LIST: 'STORE_INSTRUCTION_LIST',
    FETCH_INSTRUCTION_LIST_SUCCEED: 'FETCH_INSTRUCTION_LIST_SUCCEED',
    FETCH_INSTRUCTION_LIST_FAILED: 'FETCH_INSTRUCTION_LIST_FAILED',
    ENABLE_POLLING: 'ENABLE_POLLING',
    DISABLE_POLLING: 'DISABLE_POLLING',
    CLEAR_ALL: 'CLEAR_ALL',
})

//////////////////////////////////
//        ActionCreators        //
//////////////////////////////////
export const updateQueryParams = (queryParams: QueryParams): UpdateQueryParamsAction => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.UPDATE_QUERY_PARAMS,
    queryParams
})

export const clearAllQueryParams = (): Action<string> => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.CLEAR_ALL_QUERY_PARAMS
})

export const updateTotalItemCount = (totalItemCount: number): UpdateTotalItemCountAction => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.UPDATE_TOTAL_ITEM_COUNT,
    totalItemCount
})

export const updatePagination = (pagination: Pagination): UpdatePaginationAction => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.UPDATE_PAGINATION,
    pagination
})

export const fetchInstructionList = (): Action<string> => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.FETCH_INSTRUCTION_LIST
})

export const storeInstructionList = 
        (fetchedInstructionList: AggregatedInstructionList): StoreInstructionListAction => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.STORE_INSTRUCTION_LIST,
    fetchedInstructionList: fetchedInstructionList
})

export const fetchInstructionListSucceed = (): Action<string> => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.FETCH_INSTRUCTION_LIST_SUCCEED
})

export const fetchInstructionListFailed = (): Action<string> => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.FETCH_INSTRUCTION_LIST_FAILED
})

export const enablePolling = (): Action<string> => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.ENABLE_POLLING
})

export const disablePolling = (): Action<string> => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.DISABLE_POLLING
})

export const clearAll = (): Action<string> => ({
    type: INSTRUCTION_LIST_ACTION_TYPE.CLEAR_ALL
})


//////////////////////////////////
//         Async Actions        //
//////////////////////////////////
export const handleFetchInstructionList = (): ThunkAction<Promise<any>, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { token, instructionList } = getState()
        dispatch(fetchInstructionList())
        return (
            axios({
                method: 'get',
                url: IP_URI + 'planning',
                // params: Object.assign({}, (instructionList as InstructionListDashboard).pagination),
                params: Object.assign({}, 
                            (instructionList as InstructionListDashboard).pagination,
                            (instructionList as InstructionListDashboard).queryParams),
                headers: { authorization: "Bearer " + token }
            })
            .then((response) => {
                // console.log('Fetch Succeed')
                // console.log(`Fetch Succeed: ${JSON.stringify(response.data, null, 4)}`)
                // Have to invoke getState() again because it is a closure to access the instructionList in outer scope.
                const currentState = getState()
                // State will be re-initialized after unmounting. 
                // Should not run the below block of code any more after unmounting.
                if(currentState.instructionList.isFetching) {
                    // console.log(`Processing after fetch data`)
                    // If queryParams is updated while fetching.
                    // It will abort the current response and wait for the next response which is triggered by filter update.
                    if(JSON.stringify(instructionList.queryParams) !== JSON.stringify(currentState.instructionList.queryParams)) {
                        // console.log(`queryParams has been changed during the period of fetching data: 
                        //                 Previous: ${JSON.stringify(instructionList.queryParams, null, 4)},
                        //                 Now: ${JSON.stringify(currentState.instructionList.queryParams, null, 4)}`)
                        return 
                    }
                    // If pagination is updated while fetching.
                    // It will abort the current response and wait for the next response which is triggered by filter update.
                    if(JSON.stringify(instructionList.pagination) !== JSON.stringify(currentState.instructionList.pagination)) {
                        // console.log(`pagination has been changed during the period of fetching data: 
                        //                 Previous: ${JSON.stringify(instructionList.pagination, null, 4)},
                        //                 Now: ${JSON.stringify(currentState.instructionList.pagination, null, 4)}`)
                        return 
                    }
                    const totalItemCount = parseInt(response.headers['x-total-count'])
                    dispatch(updateTotalItemCount(totalItemCount))
                    dispatch(storeInstructionList(response.data))
                    dispatch(enablePolling())                       // Enable polling after first fetching
                    return dispatch(fetchInstructionListSucceed())
                }
            })
            .catch((error) => {
                // console.log(`Fetch Failed: ${JSON.stringify(error.response, null, 4)}`)
                return dispatch(fetchInstructionListFailed())
            })
        )
    }
}
