import React, { Component, useState, useEffect, useRef  } from 'react';
import { Route, Redirect, withRouter, RouteComponentProps } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux';
import { useMsalAuthentication, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionType, InteractionStatus  } from "@azure/msal-browser";
// import qs from 'qs';
import { authenticationSuccess, renewToken } from '../auth/AuthenticationActions'
import { loginRequest, requiredGroups, guestGroups } from '../auth/authConfig'
import { handleAccessCurrentReduxAuthState } from '../upload/UploaderActions'

// An access token's default lifetime is assigned a random value 
// ranging between 60-90 minutes (75 minutes on average)
const TOKEN_CHECK_INTERVAL = 10 * 60 * 1000      // 10 mins
const TOKEN_CHECK_RETRY_INTERVAL = 10 * 1000 // 10s
// Have to put it here otherwise it end up multiple timers
var tokenExpirationCheckTimerId: number|null = null

export const isMemberOfGuestGroups = (accessToken: string) => {
    if(accessToken) {
        let payload = JSON.parse(window.atob(accessToken.split('.')[1]))
        let groups: Array<string> = payload['groups']
        return guestGroups.every(group => groups.includes(group))
    } else {
        return false
    }
}

const PrivateRoute = (props: PrivateRouteFromRedux) => {
    // from Redux store
    const { token, updateToken, getCurrentToken } = props
    const { location } = props
    
    const { instance, inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [isHasSufficientPrivileges, setIsHasSufficientPrivileges] = useState<boolean>(false)

    // const { login, result, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

    const isTokenShouldRenew = (jwtToken: string) => {
        if(!jwtToken) return false
        let jwtInfo = JSON.parse(atob(jwtToken.split(".")[1]));
        // console.log(`Remaining time: ${jwtInfo.exp - ((Date.now() + TOKEN_CHECK_INTERVAL) / 1000)}`)
        if(jwtInfo.exp && jwtInfo.exp > ((Date.now() + TOKEN_CHECK_INTERVAL) / 1000) + 60 ){
            return false;
        }
        console.log(`The token will expire in less than ${(TOKEN_CHECK_INTERVAL/1000)+60}s. Need to renew the token.`)
        return true;
    }
    
    const setTokenExpirationCheckTimer = (interval: number) => {
        let intervalId = window.setInterval(() => {
            try {
                    instance
                        .acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            let currentToken = getCurrentToken()
                            if(currentToken != response.accessToken) {
                                updateToken(response.accessToken)
                            }
                            return isTokenShouldRenew(response.accessToken)
                        }) 
                        .then((response) => {
                            if(response) {
                                return instance.acquireTokenSilent({
                                            ...loginRequest,
                                            account: accounts[0],
                                            forceRefresh: true
                                        })
                            } else {
                                return null
                            }
                        })
                        .then((response) => {
                            if(response) {
                                // console.log(`Renewal token fetched successfully: ${response.accessToken}`)
                                updateToken(response.accessToken)
                                console.log(`Token renewal succeed.`)
                            }
                        })
                        .catch(error => {
                            console.log(`Failed to renew token: ${error}`)
                            tokenRenewRetry(TOKEN_CHECK_RETRY_INTERVAL)
                        })
                
            } catch(error) {
                console.log(`Failed to check token: ${error}`)
            }
        }, interval)
        return intervalId 
    }

    const tokenRenewRetry = (interval: number, retry: number =1) => {
        if(retry < 3) {
            setTimeout(() => {
                instance
                    .acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0],
                        forceRefresh: true
                    })
                    .then((response) => {
                        updateToken(response.accessToken)
                        tokenExpirationCheckTimerId = setTokenExpirationCheckTimer(TOKEN_CHECK_INTERVAL)
                    })
                    .catch(error => {
                        console.log(`Failed to renew token: ${error}, retry: ${retry}`)
                        tokenRenewRetry(interval, retry+1)
                    })
            }, interval)
        } else {
            console.log(`Failed to renew token ${retry} times. Redirect to login page...`)
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            })
        }
    }

    const clearTokenExpirationCheckTimer = (intervalId: number) => {
        // console.log(`timer cleaned up`)
        window.clearTimeout(intervalId)
    }

    // Check if the user has membership in at least one permitted group
    const validatePrivilege = (accessToken: string) => {
        let payload = JSON.parse(window.atob(accessToken.split('.')[1]))
        let groups: Array<string> = payload['groups']
        return requiredGroups.every(group => groups.includes(group))
    }

    useEffect(() => {
        if(isAuthenticated) {
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                    // forceRefresh: true
                })
                .then((response) => {
                    // console.log(`idToken: ${response.idToken}`)
                    // console.log(`accessToken: ${response.accessToken}`)
                    let accessToken = response.accessToken
                    updateToken(accessToken)
                    if(validatePrivilege(accessToken)) {
                        setIsHasSufficientPrivileges(true)
                        // updateToken(accessToken)
                        if(tokenExpirationCheckTimerId == null) {
                            tokenExpirationCheckTimerId = setTokenExpirationCheckTimer(TOKEN_CHECK_INTERVAL)
                        }
                    } else {
                        setIsHasSufficientPrivileges(false)
                    }
                })
                .catch(error => {
                    console.log(`Failed to acquire token: ${error}`)
                    instance.loginRedirect(loginRequest).catch(e => {
                        console.log(e);
                    })
                })
        } else {
            tokenExpirationCheckTimerId && clearTokenExpirationCheckTimer(tokenExpirationCheckTimerId)
            tokenExpirationCheckTimerId = null
        }
        return () => {
            tokenExpirationCheckTimerId && clearTokenExpirationCheckTimer(tokenExpirationCheckTimerId)
            tokenExpirationCheckTimerId = null
        }
    }, [isAuthenticated])

    return (
        <>
            {
                (inProgress == InteractionStatus.None)
                    ? isAuthenticated
                        ? (token !== null)
                            ? isHasSufficientPrivileges
                                ? <Route {...props} />
                                : <>
                                    <div style={{ textAlign: 'center' }} className='mt-5 '>
                                        <h3>Insufficient privileges to access.</h3>
                                        <h3>Please contact your Team Leader to assign required privileges.</h3>
                                    </div>
                                </>
                            : <h3 style={{ textAlign: 'center' }}>
                                Authenticating...
                            </h3>
                        : <Redirect to={{
                            pathname: '/login',
                            state: { from: location }
                        }} />
                    : <h3 style={{ textAlign: 'center' }}>
                        Authenticating...
                    </h3>
            }
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        token: state.token
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateToken: (token: string) => dispatch(authenticationSuccess(token)),
        getCurrentToken: () => dispatch(handleAccessCurrentReduxAuthState())
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps)

type PrivateRouteFromRedux = ConnectedProps<typeof connector> & RouteComponentProps

const ConnectedPrivateRoute = connector(withRouter(PrivateRoute))

export default ConnectedPrivateRoute