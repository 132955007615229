import { Reducer, AnyAction } from 'redux'
import { PRODUCT_LIST_ACTION_TYPE } from './productListActions'
import { 
    ProductListDashboard, 
    UpdateQueryParamsAction,
    UpdateTotalItemCountAction, 
    UpdatePaginationAction,
    StoreProductListAction,
 } from './ProductListTypes'

//////////////////////////////////
//            Reduces           //
//////////////////////////////////
export const PAGE_SIZE = 10
export const initPaginationParams = {
    page: 1,
    pageSize: PAGE_SIZE
}
export const initQueryParams = {
    sort: 'instruction.Updated -1',
}

const initState: ProductListDashboard = {
    pagination: initPaginationParams,
    queryParams: initQueryParams,
    aggregatedProductList: [],
    isFetching: false,
    isPollingEnabled: false,
    totalItemCount: 0,
}

const productList: Reducer<ProductListDashboard> = (prevState = initState, action: AnyAction) => {
    switch(action.type) {
        case PRODUCT_LIST_ACTION_TYPE.UPDATE_PRODUCT_LIST_QUERY_PARAMS:
            const { queryParams } = action as UpdateQueryParamsAction
            const prevQueryParams = prevState.queryParams
            var newQueryParams = Object.assign({}, prevQueryParams)
            if(Object.keys(queryParams).length !== 1) {
                throw new Error('Only one query param is expected')
            }
            if(queryParams[Object.keys(queryParams)[0]] === '') {
                delete newQueryParams[Object.keys(queryParams)[0]]
            } else {
                Object.assign(newQueryParams, queryParams)
            }
            return {
                ...prevState,
                queryParams: newQueryParams
            }
        case PRODUCT_LIST_ACTION_TYPE.CLEAR_ALL_PRODUCT_LIST_QUERY_PARAMS:
            return {
                ...prevState,
                queryParams: {}
            }
        case PRODUCT_LIST_ACTION_TYPE.UPDATE_PRODUCT_LIST_TOTAL_ITEM_COUNT:
            const { totalItemCount } = action as UpdateTotalItemCountAction
            return {
                ...prevState,
                totalItemCount
            }
        case PRODUCT_LIST_ACTION_TYPE.UPDATE_PRODUCT_LIST_PAGINATION:
            const { pagination } = action as UpdatePaginationAction
            return {
                ...prevState,
                pagination
            }
        case PRODUCT_LIST_ACTION_TYPE.FETCH_PRODUCT_LIST:
            return {
                ...prevState,
                isFetching: true,
                isFetchSuccess: undefined,
            }
        case PRODUCT_LIST_ACTION_TYPE.FETCH_PRODUCT_LIST_SUCCEED:
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: true,
            }
        case PRODUCT_LIST_ACTION_TYPE.FETCH_PRODUCT_LIST_FAILED:
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: false,
            }
        case PRODUCT_LIST_ACTION_TYPE.STORE_PRODUCT_LIST:
            const { fetchedProductList } = action as StoreProductListAction
            return {
                ...prevState,
                aggregatedProductList: fetchedProductList
            }
        case PRODUCT_LIST_ACTION_TYPE.ENABLE_PRODUCT_LIST_POLLING:
            return {
                ...prevState,
                isPollingEnabled: true
            }
        case PRODUCT_LIST_ACTION_TYPE.DISABLE_PRODUCT_LIST_POLLING:
            return {
                ...prevState,
                isPollingEnabled: false
            }
        case PRODUCT_LIST_ACTION_TYPE.CLEAR_ALL_PRODUCT_LIST:
            return ({ 
                ...Object.assign(initState)
            })
        default:
            return prevState
    }
}

export default productList