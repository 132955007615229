import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Switch } from 'react-router-dom'
import PrivateRoute from '../common/PrivateRoute'
import FlightList from './FlightList';
import PostTabs from './PostTabs'
import FlightUploadList from './FlightUploadList';
import ReviewExtractRangeMap from '../upload/ReviewExtractRangeMap';
import Uploader from '../upload/Uploader'

class PostIndex extends Component {

    render() {
        return (
            <div>
                <Grid container direction="column" alignItems="center">
                    <PostTabs />
                    <Switch>
                        <PrivateRoute exact path='/post/extract' component={ReviewExtractRangeMap} />
                        <PrivateRoute exact path='/post/:flightId/uploads/:uploadId' component={Uploader} />
                        <PrivateRoute exact path='/post/:FlightID' component={FlightUploadList} />
                        <PrivateRoute exact path='/post' component={FlightList} />
                    </Switch>
                </Grid>
            </div>
        );
    }
}

export default PostIndex;
