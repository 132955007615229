import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom'

const styles = {
    bigIndicator: {
        height: 5,
    },
};

class CustomProcessingTabs extends Component {

    constructor(props) {
        super(props)
        this.handleRoute(true);
    }

    state = {
        value: 0,
    };

    componentWillUpdate = () => {
        this.handleRoute(false);
    }

    handleRoute = (inConstructor) => {
        switch (this.props.history.location.pathname) {
            case String(this.props.history.location.pathname.match(/\/custom_processing/g)):
                if (this.state.value !== 0)
                    inConstructor ? this.state.value = 0 : this.setState({ value: 0 });
                break;
            // case String(this.props.history.location.pathname.match(/\/ip\/params(\/.*)?/g)):
            // case String(this.props.history.location.pathname.match(/\/ip\/params(\/)?/g)):
            //     if (this.state.value !== 1)
            //         inConstructor ? this.state.value = 1 : this.setState({ value: 1 });
            //     break;
            // case String(this.props.history.location.pathname.match(/\/ip\/params\/[a-f\d]{24}/g)):
            // case String(this.props.history.location.pathname.match(/\/ip\/params\/[a-f\d]{24}\/edit/g)):
            //     if (this.state.value !== 2)
            //         inConstructor ? this.state.value = 2 : this.setState({ value: 2 });
            //     break;
            // case String(this.props.history.location.pathname.match(/\/ip\/params\/new/g)):
            //     if (this.state.value !== 2)
            //         inConstructor ? this.state.value = 2 : this.setState({ value: 2 });
            //     break;
            default:
                if (this.state.value !== 0)
                    inConstructor ? this.state.value = 0 : this.setState({ value: 0 });
                break;
        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        return (
            <Tabs
                style={{ backgroundColor: '#1a3a69', color: '#FFFFFF', width: '100%', marginBottom: 10, marginTop:-10, width: '100vw' }}
                classes={{ indicator: classes.bigIndicator }}
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="secondary"
                centered>

                <Tab label="BY SENSOR LOG" component={Link} to="/ip/custom_processing/sensor" style={{ fontWeight: 'bolder' }} />

                {/* <div>
                    <Tab label="BY PROJECT" component={Link} to="/ip/custom_processing" style={{ fontWeight: 'bolder' }} />
                    <img src="/coming_soon.png" width="120px" height="53px" alt="coming soon" style={{ position: 'absolute', marginLeft: -140, marginTop:-5, marginBottom:-5 }} />
                </div> */}

            </Tabs>
        );
    }
}

CustomProcessingTabs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(CustomProcessingTabs));