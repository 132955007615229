import React, { Component } from 'react';
import Block from '@material-ui/icons/Block';
import { Link } from 'react-router-dom'
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import CloudUpload from '@material-ui/icons/CloudUpload';
import LocalShipping from '@material-ui/icons/LocalShipping';
import NicerTooltip from '../common/NicerTooltip';
import { Grid, Fade, Paper, Popper, ClickAwayListener, MenuList, MenuItem, ListItemIcon } from '@material-ui/core';
import { UploadStatus, UploadStatusLabels } from '../upload/UploadStatusEnum';
import AccessTime from '@material-ui/icons/AccessTime';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CloudOff from '@material-ui/icons/CloudOff';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ContactSupport from '@material-ui/icons/ContactSupport';
import FindInPage from '@material-ui/icons/FindInPage';
import { QueuedUpload, Uploading, UploadStalled, Archived, LAZ, GPS } from '../icons/svgIcons'

import Config from '../app/Config.json'

class FlightDataDispatchTableIcon extends Component {

    render() {
        const { status, archiveS3Path, documentMajorVersion, explorerOnClick } = this.props;
        // console.log(this.props)
        return (
            <div>
                {status.map((value) => {
                    // console.log("blah:",value)
                    return (
                        <Grid container direction="column" alignItems="center" justify="center">
                            <UploadStatusIcon
                                // type={type}
                                type={value.Type}
                                status={value.Dispatch}
                                uploadUser={value.UploadUser}
                                uploadState={value.UploadStatus ? value.UploadStatus : ""}
                                flightID={value.FlightID} 
                                uploadID={value.UploadID}
                                archiveS3Path={archiveS3Path}
                                documentMajorVersion = {documentMajorVersion}
                                explorerOnClick={explorerOnClick}
                            />
                        </Grid>
                    )
                })}
            </div>
        );
    }
}


//YUK! So much repitition here, it would be nice to break it down into several components.
class UploadStatusIcon extends Component {

    state = {
        anchorEl: null,
        open: false
    };

    handleClose = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open,
        }));
    };

    handleClick = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open,
            tooltipOpen: false
        }));
    };

    handleMenuClick = (event, func = undefined, inputValue = undefined) => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open
        }));

        if (func !== undefined) {
            if (inputValue !== undefined)
                func(inputValue);
            else
                func();
        }
    };

    handlePopoverOpen = event => {
        // this.setState({ anchorEl: event.currentTarget });
        this.setState({ tooltipOpen: true });

    };

    render() {
    //    console.log("props", this.props);
        const { 
            status, uploadState, flightID, uploadID, uploadUser, type, archiveS3Path, 
            documentMajorVersion, explorerOnClick
        } = this.props
        const isMigratedFromLegacy = (documentMajorVersion && documentMajorVersion <= 3.0)
        const isFromLegacyProd = (documentMajorVersion && documentMajorVersion == 1.4)
        const s3Path = isFromLegacyProd
                            ? (type === "LiDAR" ? Config.LEGACY_UPLOAD_LIDAR_BUCKET : Config.LEGACY_UPLOAD_IMAGERY_BUCKET) + "/" + uploadID
                            : (type === "LiDAR" 
                                ? Config.UPLOAD_LIDAR_BUCKET 
                                : (['TerrainMapperLAZ', 'TerrainMapperGPS'].includes(type) ? Config.UPLOAD_TM_LIDAR_BUCKET : Config.UPLOAD_IMAGERY_BUCKET))
                                + "/" + uploadID
        // const isDisplayArchiveContextMenu = !(documentMajorVersion && documentMajorVersion === 1.4)

        return (
            <div>
                {
                    uploadState &&
                    <div>
                        {{
                            ['Not Applicable']:
                                <NicerTooltip placement="right" content={<span>{status}</span>}>
                                    <Block style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />
                                </NicerTooltip>,
                            ['TBA']:
                                <NicerTooltip placement="right" content={<span>{status}</span>}>
                                    <ContactSupport style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />
                                </NicerTooltip>,
                            ['Courier to Office']:
                                <NicerTooltip placement="right"
                                    content={
                                        <span>
                                            {
                                            type.toLowerCase().indexOf("terrainmapper") !== -1 && //show additional tooltip for terrain mapper
                                            <div>
                                                <span style={{ fontWeight: 'bold' }}>Upload Type: </span>{type}<br />
                                            </div>
                                            }
                                            {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                            <span style={{ fontWeight: 'bold' }}>Dispatch: </span>{status}<br />
                                            <span style={{ fontWeight: 'bold' }}>Status: </span>{UploadStatusLabels[uploadState]}<br />
                                            {uploadUser !== '' &&
                                                <span><span style={{ fontWeight: 'bold' }}>User: </span>{uploadUser}<br /></span>
                                            }
                                            {uploadState !== UploadStatus.LEGACY_SYSTEM &&
                                                uploadState !== UploadStatus.UPLOAD_QUEUED &&
                                                uploadState !== UploadStatus.UPLOADING &&
                                                <span style={{ fontStyle: 'italic' }}>Click for Upload details</span>
                                            }
                                        </span>
                                    }>
                                    <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                        {{
                                            // [UploadStatus.READY_FOR_UPLOAD]:
                                            //     <LocalShipping style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_SOURCE]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.UPLOAD_QUEUED]:
                                            //     <QueuedUpload style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOADING]:
                                                <Uploading style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_UPLOAD]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_COMPLETE]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.DATA_READY]:
                                            //     <CheckCircleOutline style={{ color: '#006600', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_PAUSED]:
                                                <PauseCircleOutline style={{ color: '#ff6100', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_CANCELLED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_SOURCE_FAILED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_FAILED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_UPLOAD_FAILED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_STALLED]:
                                                <UploadStalled style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.LEGACY_SYSTEM]:
                                            //     <CloudOff style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                        }[uploadState]}
                                    </Link>
                                    {
                                        uploadState === UploadStatus.READY_FOR_UPLOAD &&
                                        type === "TerrainMapperLAZ" &&
                                        <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                            <LAZ onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                        </Link>
                                    }
                                    {
                                        uploadState === UploadStatus.READY_FOR_UPLOAD &&
                                        type === "TerrainMapperGPS" &&
                                        <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                            <GPS style={{ cursor:"Hand"}} onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                        </Link>
                                    }
                                    {   // ensure the shipping icon still appears for non-terrain mapper uploads
                                        uploadState === UploadStatus.READY_FOR_UPLOAD &&
                                        type.toLowerCase().indexOf("terrainmapper") == -1 &&
                                        <LocalShipping style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5, cursor:"Hand" }} onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                        
                                    }
                                    {
                                        uploadState === UploadStatus.LEGACY_SYSTEM &&
                                        <CloudOff style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />
                                    }
                                    {
                                        uploadState === UploadStatus.UPLOAD_QUEUED &&
                                        <QueuedUpload style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />
                                    }
                                    {
                                        uploadState === UploadStatus.DATA_READY &&
                                        <div>
                                            <CheckCircleOutline
                                                onClick={this.handleClick}
                                                style={{ color: '#006600', marginTop: 0, marginBottom: -5 }} />
                                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                                <MenuList>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Path) })}>
                                                                        <ListItemIcon>
                                                                            <FileCopyIcon style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                                    </MenuItem>
                                                                    {!isMigratedFromLegacy
                                                                        && <MenuItem
                                                                            // component={Link} to={"/post/" + flightID + "/uploads/" + uploadID}
                                                                            onClick={(event) => this.handleMenuClick(event, () => window.open("/post/" + flightID + "/uploads/" + uploadID))}>
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutline style={{ color: '#006600' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Upload Details</span>
                                                                        </MenuItem>
                                                                    }
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </div>
                                    }
                                    {
                                        uploadState === UploadStatus.ARCHIVED 
                                        && <>
                                            <Archived
                                                onClick={this.handleClick}
                                                style={{ color: '#1a3a69', marginTop:10, marginLeft:5 }} 
                                            />
                                            {!isMigratedFromLegacy 
                                                && <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                                <MenuList>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(archiveS3Path || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopyIcon style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, explorerOnClick, { type, uploadID, archiveS3Path })} >
                                                                        <ListItemIcon>
                                                                            <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                                    </MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Fade>
                                                )}
                                            </Popper>}
                                        </>
                                    }
                                    {/* {
                                uploadState === UploadStatus.UPLOADING &&
                                <Uploading style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />
                            } */}
                                </NicerTooltip>,
                            ['Upload']:
                                <NicerTooltip placement="right"
                                    content={
                                        <span>
                                            {
                                            type.toLowerCase().indexOf("terrainmapper") !== -1 && //show additional tooltip for terrain mapper
                                            <div>
                                                <span style={{ fontWeight: 'bold' }}>Upload Type: </span>{type}<br />
                                            </div>
                                            }
                                            {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                            <span style={{ fontWeight: 'bold' }}>Dispatch: </span>{status}<br />
                                            <span style={{ fontWeight: 'bold' }}>Status: </span>{UploadStatusLabels[uploadState]}<br />
                                            {uploadUser !== '' &&
                                                <span><span style={{ fontWeight: 'bold' }}>User: </span>{uploadUser}<br /></span>
                                            }
                                            {uploadState !== UploadStatus.LEGACY_SYSTEM &&
                                                uploadState !== UploadStatus.UPLOAD_QUEUED &&
                                                uploadState !== UploadStatus.UPLOADING &&
                                                <span style={{ fontStyle: 'italic' }}>Click for Upload details</span>
                                            }
                                        </span>
                                    }>
                                    <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                        {{
                                            // [UploadStatus.READY_FOR_UPLOAD]:
                                            //     <CloudUpload style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_SOURCE]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.UPLOAD_QUEUED]:
                                            //     <QueuedUpload style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOADING]:
                                                <Uploading style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_UPLOAD]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_COMPLETE]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.DATA_READY]:
                                            //     <CheckCircleOutline style={{ color: '#006600', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_PAUSED]:
                                                <PauseCircleOutline style={{ color: '#ff6100', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_CANCELLED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_SOURCE_FAILED]:
                                                <ErrorOutline style={{ color: '#FF00 00', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_FAILED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_UPLOAD_FAILED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_STALLED]:
                                                <UploadStalled style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.LEGACY_SYSTEM]:
                                            //     <CloudOff style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                        }[uploadState]}
                                    </Link>
                                     {
                                        uploadState === UploadStatus.READY_FOR_UPLOAD &&
                                        type === "TerrainMapperLAZ" &&
                                        <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                            <LAZ onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                        </Link>
                                    }
                                    {
                                        uploadState === UploadStatus.READY_FOR_UPLOAD &&
                                        type === "TerrainMapperGPS" &&
                                        <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                            <GPS style={{ cursor:"Hand"}} onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                        </Link>
                                    }
                                    { // standard icon for when not terrain mapper
                                        uploadState === UploadStatus.READY_FOR_UPLOAD && 
                                        type.toLowerCase().indexOf("terrainmapper") == -1 &&
                                        <CloudUpload style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5, cursor:"Hand" }} onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                    }
                                    {
                                        uploadState === UploadStatus.LEGACY_SYSTEM &&
                                        <CloudOff style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />
                                    }
                                    {
                                        uploadState === UploadStatus.UPLOAD_QUEUED &&
                                        <QueuedUpload style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />
                                    }
                                    {
                                        uploadState === UploadStatus.DATA_READY &&
                                        <div>
                                            <CheckCircleOutline
                                                onClick={this.handleClick}
                                                style={{ color: '#006600', marginTop: 0, marginBottom: -5 }} />
                                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                                <MenuList>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Path) })}>
                                                                        <ListItemIcon>
                                                                            <FileCopyIcon style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                                    </MenuItem>
                                                                    {!isMigratedFromLegacy
                                                                        && <MenuItem
                                                                            // component={Link} to={"/post/" + flightID + "/uploads/" + uploadID}
                                                                            onClick={(event) => this.handleMenuClick(event, () => window.open("/post/" + flightID + "/uploads/" + uploadID))}>
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutline style={{ color: '#006600' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Upload Details</span>
                                                                        </MenuItem>
                                                                    }
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </div>
                                    }
                                    {
                                        uploadState === UploadStatus.ARCHIVED 
                                        && <>
                                            <Archived
                                                onClick={this.handleClick}
                                                style={{ color: '#1a3a69', marginTop:10, marginLeft:5 }} 
                                            />
                                            {!isMigratedFromLegacy
                                                && <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                                <MenuList>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(archiveS3Path || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopyIcon style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, explorerOnClick, { type, uploadID, archiveS3Path })} >
                                                                        <ListItemIcon>
                                                                            <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                                    </MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Fade>
                                                )}
                                            </Popper>}
                                        </>
                                    }
                                    {/* {
                                uploadState === UploadStatus.UPLOADING &&
                                <Uploading style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />
                            } */}
                                </NicerTooltip>,
                            ['Hand Deliver']:
                                <NicerTooltip placement="right"
                                    content={
                                        <span>
                                            {
                                            type.toLowerCase().indexOf("terrainmapper") !== -1 && //show additional tooltip for terrain mapper
                                            <div>
                                                <span style={{ fontWeight: 'bold' }}>Upload Type: </span>{type}<br />
                                            </div>
                                            }
                                            {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{documentMajorVersion.toFixed(1)}<br /></>}
                                            <span style={{ fontWeight: 'bold' }}>Dispatch: </span>{status}<br />
                                            <span style={{ fontWeight: 'bold' }}>Status: </span>{UploadStatusLabels[uploadState]}<br />
                                            {uploadUser !== '' &&
                                                <span><span style={{ fontWeight: 'bold' }}>User: </span>{uploadUser}<br /></span>
                                            }
                                            {uploadState !== UploadStatus.LEGACY_SYSTEM &&
                                                uploadState !== UploadStatus.UPLOAD_QUEUED &&
                                                uploadState !== UploadStatus.UPLOADING &&
                                                <span style={{ fontStyle: 'italic' }}>Click for Upload details</span>
                                            }
                                        </span>
                                    }>
                                    <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                        {{
                                            // [UploadStatus.READY_FOR_UPLOAD]:
                                            //     <DirectionsWalk style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_SOURCE]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.UPLOAD_QUEUED]:
                                            //     <QueuedUpload style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOADING]:
                                                <Uploading style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_UPLOAD]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_COMPLETE]:
                                                <AccessTime style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.DATA_READY]:
                                            //     <CheckCircleOutline style={{ color: '#006600', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_PAUSED]:
                                                <PauseCircleOutline style={{ color: '#ff6100', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_CANCELLED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_SOURCE_FAILED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_FAILED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.VALIDATING_UPLOAD_FAILED]:
                                                <ErrorOutline style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            [UploadStatus.UPLOAD_STALLED]:
                                                <UploadStalled style={{ color: '#FF0000', marginTop: 0, marginBottom: -5 }} />,
                                            // [UploadStatus.LEGACY_SYSTEM]:
                                            //     <CloudOff style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />,
                                        }[uploadState]}
                                    </Link>
                                    {
                                        uploadState === UploadStatus.READY_FOR_UPLOAD &&
                                        type === "TerrainMapperLAZ" &&
                                        <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                            <LAZ onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                        </Link>
                                    }
                                    {
                                        uploadState === UploadStatus.READY_FOR_UPLOAD &&
                                        type === "TerrainMapperGPS" &&
                                        <Link key={uploadID} to={"/post/" + flightID + "/uploads/" + uploadID}>
                                            <GPS style={{ cursor:"Hand"}} onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                        </Link>
                                    }
                                    { // ensure the shand deliver icon still appears for non-terrain mapper uploads
                                        uploadState === UploadStatus.READY_FOR_UPLOAD &&
                                        type.toLowerCase().indexOf("terrainmapper") == -1 &&
                                        <DirectionsWalk style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5, cursor:"Hand" }} onClick={() => window.open("/post/" + flightID + "/uploads/" + uploadID)}/>
                                    }
                                    {
                                        uploadState === UploadStatus.LEGACY_SYSTEM &&
                                        <CloudOff style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />
                                    }
                                    {
                                        uploadState === UploadStatus.UPLOAD_QUEUED &&
                                        <QueuedUpload style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />
                                    }
                                    {
                                        uploadState === UploadStatus.DATA_READY &&
                                        <div>
                                            <CheckCircleOutline
                                                onClick={this.handleClick}
                                                style={{ color: '#006600', marginTop: 0, marginBottom: -5 }} />
                                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                                <MenuList>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Path) })}>
                                                                        <ListItemIcon>
                                                                            <FileCopyIcon style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                                    </MenuItem>
                                                                    {!isMigratedFromLegacy
                                                                        && <MenuItem
                                                                            // component={Link} to={"/post/" + flightID + "/uploads/" + uploadID}
                                                                            onClick={(event) => this.handleMenuClick(event, () => window.open("/post/" + flightID + "/uploads/" + uploadID))}>
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutline style={{ color: '#006600' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Upload Details</span>
                                                                        </MenuItem>
                                                                    }
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </div>
                                    }
                                    {
                                        uploadState === UploadStatus.ARCHIVED 
                                        && <>
                                            <Archived
                                                onClick={this.handleClick}
                                                style={{ color: '#1a3a69', marginTop:10, marginLeft:5 }} 
                                            />
                                            {!isMigratedFromLegacy
                                                && <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                                <MenuList>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(archiveS3Path || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopyIcon style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, explorerOnClick, { type, uploadID, archiveS3Path })} >
                                                                        <ListItemIcon>
                                                                            <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                                    </MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Fade>
                                                )}
                                            </Popper>}
                                        </>
                                    }
                                    {/* {
                                uploadState === UploadStatus.UPLOADING &&
                                <Uploading style={{ color: '#ff6100', width: 23, marginLeft: -4, marginTop: 0, marginBottom: -5 }} />
                            } */}
                                </NicerTooltip>,
                        }[status]}
                    </div>
                }
                {!uploadState &&
                    <NicerTooltip placement="right" content={<span>Not Applicable</span>}>
                        <Block style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />
                    </NicerTooltip>
                }
            </div>
        )
    }
    // else {
    //     return (
    //         <NicerTooltip placement="right" content={<span>Not Applicable</span>}>
    //             <Block style={{ color: '#1a3a69', marginTop: 0, marginBottom: -5 }} />
    //         </NicerTooltip>
    //     )
    // }
}

export default FlightDataDispatchTableIcon;