import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import { 
    Grid, CircularProgress
} from '@material-ui/core'
import FolderNavigation from './FolderNavigation'
import ObjectList from './ObjectList';


const ObjectExplorer = () => {
    return (
        <>
            <Grid container direction='column' justify='center' alignItems='center' xs={9} className='mt-2' style={{ minWidth: 1200 }} >
                <Grid container direction='column' justify='center' alignItems='flex-start' className='mt-4'>
                    <FolderNavigation />
                </Grid>
                <Grid container direction='column' justify='center' alignItems='center' className='mt-2'>
                    <ObjectList />
                </Grid>
            </Grid>
        </>
    )
}

export default ObjectExplorer