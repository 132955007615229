import React, { Component } from 'react';
import { 
    Grid, AppBar, Toolbar, IconButton, Menu, MenuItem, Tooltip, Divider, MenuList,
    Popper, Fade, Paper, ClickAwayListener 
} from '@material-ui/core';
import { connect } from 'react-redux';
import { authenticationSuccess } from '../auth/AuthenticationActions'
import Home from '@material-ui/icons/Home'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Error from '@material-ui/icons/Error';
import Config from './Config.json';
import ITSupport from '../support/ITSupport';
import NicerTooltip from '../common/NicerTooltip';
import { displaySystemSnackbar } from '../common/SystemSnackbarActions'
import { withRouter } from 'react-router-dom'
import { withMsal } from "@azure/msal-react";


class MainHeader extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        menuOpen: false,
        anchorEl: null,
        itSupportOpen: false,
        nameMappping: { "prod_redux": "", "test_redux": "test_redux", "develop": "dev" }
    }

    handleMenu = event => {
        this.setState({
            anchorEl: event.currentTarget,
            menuOpen: true
        });
    };

    handleItSupport = () => {
        this.setState({ itSupportOpen: true });
        // console.log("...",this.props)
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null, menuOpen: false });
    };

    logout = (msalInstance) => {
        this.handleMenuClose();

        // localStorage.removeItem('LiDARnetics.token');
        this.props.removeToken();
        msalInstance.logoutRedirect({ })
    }

    handleITSupportClose = () => {
        this.setState({ itSupportOpen: false });
    }

    render() {
        const msalInstance = this.props.msalContext.instance;
        return (
            <AppBar position="static" style={{ backgroundColor: '#CCCCCC', width: '100vw', minWidth: 1425 }}>
                <Toolbar>
                    <img src="/Woolpert_logo_grey.svg" height="126px" alt="Woolpert" style={{marginLeft: -18, marginRight: -18, marginTop: -31, marginBottom: -31}}
                        // onClick={() => { this.props.history.push('/post') }}
                    />
                    <Grid justify="center" direction="row" container>
                        <div style={{ color: '#1a3a69', fontSize: 50, fontWeight: 'bolder', textShadow: "-1px 0 #1a3a69, 0 1px #1a3a69, 1px 0 #1a3a69, 0 -1px #1a3a69" }}>
                            LiDAR
                        </div>
                        <div style={{ color: '#1a3a69', fontSize: 50, fontWeight: 'normal' }}>netics</div>
                        <div style={{ color: '#1a3a69', fontSize: 50, fontWeight: 'bold' }} >&nbsp;{this.state.nameMappping[Config.BUILD_TARGET].toUpperCase()}</div>
                    </Grid>
                    <div ref="menuDiv" style={{ display: 'contents', right: 0 }}>
                        <ITSupport
                            open={this.state.itSupportOpen}
                            user={this.props.email}
                            token={this.props.token}
                            snackbar={this.props.displaySystemSnackbar}
                            onClose={this.handleITSupportClose}
                        />
                        < NicerTooltip placement="bottom" content="Log a Support Issue">
                            <IconButton onClick={this.handleItSupport} color="inherit" aria-label="Menu">
                                <Error color="primary" style={{ fontSize: 40 }} />
                            </IconButton>
                        </NicerTooltip>
                        <IconButton onClick={this.handleMenu} color="inherit" aria-label="Menu">
                            <AccountCircle color="primary" style={{ fontSize: 40 }} />
                        </IconButton>
                        <Popper 
                            open={this.state.menuOpen} 
                            anchorEl={this.state.anchorEl} 
                            transition 
                            // disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Fade { ...TransitionProps } timeout={350} >
                                    <Paper>
                                        <ClickAwayListener onClickAway={this.handleMenuClose}>
                                            <MenuList>
                                                <div style={{ fontWeight: 'bold', textAlign: 'center', margin: 8 }}>
                                                    {this.props.user}
                                                </div>
                                                <Divider />
                                                <MenuItem 
                                                    onClick={() => this.logout(msalInstance)} 
                                                >
                                                    Logout
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                        </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </div>
                    <div style={{ position: 'absolute', fontSize: 10, right: 1, top: 1, color: '#828282' }}>v {Config.RELEASE_NO}</div>
                    {["develop", "dev_legacy", "test", "test_redux"].indexOf(Config.BUILD_TARGET) > -1 &&
                        <Tooltip title={<span>Environment: {Config.BUILD_TARGET}<br />GIT Revision: {Config.GIT_REVISION}<br />Built at: {Config.BUILD_DATE_TIME}</span>} placement="left">
                            <div style={{ position: 'absolute', fontSize: 10, right: 1, top: 50, color: '#828282' }}>b {Config.BUILD_NO}</div>
                        </Tooltip>
                    }
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let tokenPayload = state.token ? JSON.parse(atob(state.token.split(".")[1])) : ''
    let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : (tokenPayload.name || 'Unknown')
    let email = tokenPayload.upn ? tokenPayload.upn : tokenPayload.email//TODO: add in check here 
    return {
        user,
        token: state.token,
        email
    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        removeToken: () => dispatch(authenticationSuccess(null)),
        displaySystemSnackbar: (display, type, content, autoHideDuration) => dispatch(displaySystemSnackbar(display, type, content, autoHideDuration))
    };
};

// export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withMsal(MainHeader)));