import React, { useRef, useState, useEffect } from 'react';
import { Route, Switch, Redirect  } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { 
    Grid, CircularProgress, List, ListItem, Button, IconButton, Paper
} from '@material-ui/core';
import { loginRequest } from './authConfig'
import { MicrosoftLogo } from '../icons/svgIcons'

const Login = (props: any) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const from = (props.location && props.location.state && props.location.state.from.pathname) || '/'

    const handleLogin = () => {
        window.onbeforeunload = null
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        })
    }

    return (
        <>
            {
                isAuthenticated
                    ? <Redirect to={from.replace('login', '')} />
                    : <>
                        <Grid container direction="column" alignItems="center" justify="center" style={{ width: "100%", height: "100vh", backgroundColor: "#143C65" }}>
                            <Paper style={{ width: 360, height: 225 }}>
                                <Grid container direction="column" alignItems="center" justify="flex-start" style={{ height: "100%" }} >
                                    <Grid justify="center" direction="row" container className='mb-5 mt-4' >
                                        <div style={{ color: '#1a3a69', fontSize: 30, fontWeight: 'bolder', textShadow: "-0.5px 0 #1a3a69, 0 0.5px #1a3a69, 0.5px 0 #1a3a69, 0 -0.5px #1a3a69" }}>
                                            LiDAR
                                        </div>
                                        <div style={{ color: '#1a3a69', fontSize: 30, fontWeight: 'normal' }}>netics</div>
                                    </Grid>
                                    <Button 
                                        variant="contained"
                                        onClick={handleLogin}
                                        color="primary"
                                        size='large'
                                        style={{ width: 290, marginTop: 16 }}
                                    >
                                        <MicrosoftLogo width={23} height={23} />
                                        <div className='ml-2'>Sign in with Microsoft</div>
                                    </Button>
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
            }
        </>
    )
}

export default Login