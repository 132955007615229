import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TableCell, MenuItem, Select, Input, Popover, Button, ClickAwayListener, Grid, InputLabel, FormControl, Checkbox, Icon } from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import NicerTooltip from './NicerTooltip';
import { addStatusValue, removeStatusValue, removeAllStatusValues } from './CompositeFilterActions'
import { UploadStatusLabels } from '../upload/UploadStatusEnum';

class PopoverDateFilter extends Component {
    constructor(props) {
        super(props);
        this.startInputRef = null;
        this.endInputRef = null;
    }

    state = {
        anchorEl: null,
        popoverOpen: false,
        startDate: null,
        endDate: null
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27 && this.state.popoverOpen) {
            this.handleClose(event);
        }
    }

    handlePopoverOpen = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            popoverOpen: true
        }));
    };

    handleClose = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            popoverOpen: !state.popoverOpen,
        }));
    };

    handleClickAwayClose = event => {
        if(this.state.startDate === null && this.state.endDate === null) this.handleClose(event)
    };

    handleCancel = event => {
        event.persist();
        this.handleClose(event);
        if(this.state.startDate != null && this.state.endDate != null){
            this.setState({ startDate: null, endDate: null });
            this.props.onUpdate(this.props.name, "");
        }
    }

    handleOK = event => {
        event.persist();
        this.handleClose(event);
        this.props.onUpdate(this.props.name, this.state.startDate+"+"+this.state.endDate);
    }

    onDateUpdate = event => {
        let updateObj = {};
        if (this.startInputRef.value && this.startInputRef.value !== this.state.startDate)
            updateObj["startDate"] = this.startInputRef.value;
        if (this.endInputRef.value && this.endInputRef.value !== this.state.endDate)
            updateObj["endDate"] = this.endInputRef.value;

        this.setState(updateObj);
    }

    render() {
        const componentStyle = { width: this.props.width };
        return (
            <TableCell padding="none" style={{ position: 'relative', paddingLeft: 10, paddingRight: 1, top: -10, width: componentStyle.width }}>
                <Grid container direction="row" alignItems="center" justify="center" style={{ width: componentStyle.width, marginTop: 10, marginLeft: -5 }}>
                    {
                        (this.state.startDate && this.state.endDate) &&
                        <NicerTooltip placement="bottom"
                            content={
                                <span>
                                    <span style={{ fontWeight: 'bold' }}>Start Date: </span><span>{this.state.startDate}</span><br /><br/>
                                    <span style={{ fontWeight: 'bold' }}>End Date: </span><span>{this.state.endDate}</span>
                                </span>
                            }>
                            <Icon onClick={this.handlePopoverOpen}><CheckBox style={{ color: '#1a3a69', fontSize: 20 }} /></Icon>
                        </NicerTooltip>
                    }
                    {
                        (!this.state.startDate || !this.state.endDate) &&
                        <Icon onClick={this.handlePopoverOpen}><CheckBoxOutlineBlank style={{ color: '#757575', fontSize: 20 }} /></Icon>
                    }
                </Grid>
                <Popover
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClickAwayClose}
                    open={this.state.popoverOpen} style={{ width: '100%' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
                    {/* <ClickAwayListener onClickAway={this.handleClose}> */}
                    <Grid container direction="column" alignItems="center" justify="center" style={{ width: '200px', padding: '10px' }}>
                        <span style={{ fontSize: 12, fontWeight: 'bold' }}>{this.props.title}</span>
                        <Grid container direction="row" alignItems="center" justify="space-evenly">
                            <InputLabel htmlFor="startDate" style={{ fontSize: 12 }}>Start</InputLabel>
                            <Input
                                style={{ fontSize: 12, width: 100 }}
                                name="startDate"
                                inputRef={input => (this.startInputRef = input)}
                                onChange={this.onDateUpdate}
                                type="date"
                                value={this.state.startDate}
                            />
                        </Grid>
                        <Grid container direction="row" alignItems="center" justify="space-evenly">
                            <InputLabel htmlFor="endDate" style={{ fontSize: 12 }}>End</InputLabel>
                            <Input
                                style={{ fontSize: 12, width: 100 }}
                                name="endDate"
                                inputRef={input => (this.endInputRef = input)}
                                onChange={this.onDateUpdate}
                                type="date"
                                value={this.state.endDate}
                            />
                        </Grid>
                        <Grid container direction="row" alignItems="center" justify="space-around" style={{ marginTop: '5px', marginBottom: "-5px" }}>
                            <Button onClick={this.handleOK} disabled={!this.state.startDate || !this.state.endDate}>OK</Button>
                            <Button onClick={this.handleCancel}>Clear</Button>
                        </Grid>
                    </Grid>
                    {/* </ClickAwayListener> */}
                </Popover>
            </TableCell>
        );
    }
}
export default PopoverDateFilter;