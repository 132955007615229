import React, { Component } from 'react';
import { TableCell, MenuItem, Select, Input, Popover, Button, ClickAwayListener, Grid, InputLabel, FormControl, Checkbox, Icon, Chip } from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import NicerTooltip from './NicerTooltip';
import { clearAllFilters } from './CompositeFilterActions';
import { connect } from 'react-redux';

export const EXIST_OPTIONS = {
    EXIST: 'Exist',
    NOT_EXIST: 'Not_Exist'
}

export const BOOLEAN_OPTIONS = {
    BOOLEAN_TRUE: 'Boolean_True',
    BOOLEAN_FALSE: 'Boolean_False'
}

class PopoverSelectFilter extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        // selectedValue: ''
        anchorEl: null,
        popoverOpen: false,
        valueSelections: []
    }

    componentDidUpdate(){
        if(this.props.clearAllFilters){
            this.setState({ valueSelections: [] });
            this.props.filterCleared();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27 && this.state.popoverOpen) {
            this.handleClose(event);
        }
    }

    handlePopoverOpen = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            popoverOpen: true
        }));
    };

    handleClose = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            popoverOpen: !state.popoverOpen,
        }));
    };

    handleClickAwayClose = event => {
        if(this.state.valueSelections.length == 0) this.handleClose(event)
    };

    handleCancel = event => {
        event.persist();
        this.handleClose(event);
        if(this.state.valueSelections.length > 0)  {
            this.setState({ valueSelections: [] });
            let fieldName = this.props.name.replace('Status','QAUser')
            this.props.onUpdate(this.props.name, "", [fieldName,""]);
        }
    }

    handleOK = event => {
        event.persist();
        this.handleClose(event);
        if(this.props.isExist == true) {
            // console.log(`PopoverSelectFilter: valueSelections: ${this.state.valueSelections}`)
            if(this.state.valueSelections.length === 1) {
                let option = this.state.valueSelections[0]
                let operator = (option === EXIST_OPTIONS.EXIST) ? '$exists' : '$notExists'
                this.props.onUpdate(this.props.name, operator)
            } else {
                let operator = '$custom'
                let expression = {'$or': [{[this.props.name]: {'$exists': true}}, {[this.props.name]: {'$exists': false}}]}
                let queryString = operator + JSON.stringify(expression)
                this.props.onUpdate(this.props.name, queryString)
            }
        } else {
            let operator = this.props.isAnyOr == true ? "$anyOr" : "$any";
    
            let selectionsCopy = Array.from(this.state.valueSelections);
    
            var qaCompletedIndex = this.state.valueSelections.indexOf("QA_COMPLETED");
            if (qaCompletedIndex !== -1) selectionsCopy.splice(qaCompletedIndex, 1);
            var qaPendingIndex = this.state.valueSelections.indexOf("QA_PENDING");
            if (qaPendingIndex !== -1) selectionsCopy.splice(qaPendingIndex, 1);
    
            let queryString = "";
            if(selectionsCopy.length > 0){
                if(this.props.isBooleanValues) {
                    if(selectionsCopy.length === 1 && selectionsCopy[0] === BOOLEAN_OPTIONS.BOOLEAN_TRUE) {
                        queryString = operator + selectionsCopy.join(",");
                    } else if (selectionsCopy.length === 1 && selectionsCopy[0] === BOOLEAN_OPTIONS.BOOLEAN_FALSE) {
                        operator = '$custom'
                        let expression = { '$or': [ {[this.props.name]: {'$in': [false]}}, {[this.props.name]: {'$exists': false}} ] }
                        queryString = operator + JSON.stringify(expression)
                    } else {
                        operator = '$custom'
                        let expression = { '$or': [ {[this.props.name]: {'$in': [true, false]}}, {[this.props.name]: {'$exists': false}} ] }
                        queryString = operator + JSON.stringify(expression)
                    }
                } else {
                    queryString = operator + selectionsCopy.join(",");
                }
            }
    
            //a monkey hack to support the QA'd/Not QA'd toggle... its not a proper Status, instead a seperate field
            let compositeQueryArray = [];
            if(qaCompletedIndex !== -1 || qaPendingIndex !== -1){
                let fieldName = this.props.name.replace('Status','QAUser')
                if (qaCompletedIndex !== -1)
                    compositeQueryArray.push([fieldName,"$exists"])
                    
                if (qaPendingIndex !== -1)
                    compositeQueryArray.push([fieldName,"$notExists"])
            }
            else{
                let fieldName = this.props.name.replace('Status','QAUser')
                compositeQueryArray.push([fieldName,""])
            }
            this.props.onUpdate(this.props.name, queryString,compositeQueryArray);
        }
    }

    // handleSelectChange = event => {
    //     let selectedValues = this.state.valueSelections;
    //     let index = this.state.valueSelections.indexOf(event.target.value[0]);
    //     if (index === -1) {
    //         selectedValues.push(event.target.value[0]);
    //     }
    //     else {
    //         selectedValues.splice(index, 1);
    //     }
    //     this.setState({ valueSelections: selectedValues });
    // };
    handleSelectChange = event => {
        this.setState({ valueSelections: event.target.value });
      };

    getLabels = () => {
        let labels = [];
        this.state.valueSelections.forEach(item => {
            const index = this.props.values.map(e => e.value).indexOf(item);
            labels.push(this.props.values[index].label)
        });
        return labels;
    }

    render() {
        const componentStyle = { width: this.props.width };
        const toolTipContent = this.state.valueSelections.length > 0 ? this.getLabels() : ["NA"];
        return (
            <TableCell padding="none" style={{ position: 'relative', paddingLeft: 10, paddingRight: 1, top: -10, width: componentStyle.width }}>
                <Grid container direction="row" alignItems="center" justify="center" style={{ width: componentStyle.width, marginTop: 10, marginLeft: -5 }}>
                    {
                        this.state.valueSelections.length > 0 &&
                        < NicerTooltip placement="bottom"
                            content={
                                <span><span style={{ fontWeight: 'bold' }}>{this.props.title}:</span><br />
                                    {toolTipContent.map(item => (<span>{item}<br /></span>))}
                                </span>
                            }>
                            <Icon onClick={this.handlePopoverOpen}><CheckBox style={{ color: '#1a3a69', fontSize: 20 }} /></Icon>
                        </NicerTooltip>
                    }
                    {
                        this.state.valueSelections.length === 0 &&
                        <Icon onClick={this.handlePopoverOpen}><CheckBoxOutlineBlank style={{ color: '#757575', fontSize: 20 }} /></Icon>
                    }
                </Grid>
                <Popover
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClickAwayClose}
                    open={this.state.popoverOpen} 
                    style={{ width: '100%' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
                    {/* <ClickAwayListener onClickAway={this.handleClose}> */}
                    <Grid container direction="column" alignItems="center" justify="center" style={{ width: '200px', padding: '10px' }}>
                        <span style={{ fontSize: 12, fontWeight: 'bold' }}>{this.props.title}</span>
                        <Grid container direction="row" alignItems="center" justify="space-evenly">
                            {/* <InputLabel style={{ fontSize: 12, marginRight: -60 }}>Dispatch</InputLabel> */}
                            <Select
                                multiple
                                onChange={this.handleSelectChange}
                                // value={[]}
                                value={this.state.valueSelections}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={() => `${this.state.valueSelections.length} selected`}
                                style={{ width: "125px" }}
                                >
                                {this.props.values.map(item => (
                                    <MenuItem name={item.value} key={item.value} value={item.value} style={{ fontSize: 12 }}>
                                        <Checkbox checked={this.state.valueSelections.indexOf(item.value) > -1} />
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid container direction="row" justify="space-evenly" alignItems="center" style={{ marginTop: 8 }}>
                            {/* {this.state.valueSelections.map(item => { */}
                            {this.getLabels().map(item => {
                                return (
                                    <Chip
                                        key={item}
                                        label={
                                            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '130px' }} >{item}</p>
                                        }
                                        style={{ margin: 2, maxWidth: '180px' }}
                                    />
                                )
                            })
                            }
                        </Grid>
                        <Grid container direction="row" alignItems="center" justify="space-around" style={{ marginTop: '5px', marginBottom: "-5px" }}>
                            <Button onClick={this.handleOK} disabled={this.state.valueSelections.length === 0}>OK</Button>
                            <Button onClick={this.handleCancel}>Clear</Button>
                        </Grid>
                    </Grid>
                    {/* </ClickAwayListener> */}
                </Popover>
            </TableCell >
        );
    }
}
// export default PopoverSelectFilter;

const mapStateToProps = (state) => {
    return {
        clearAllFilters: state.clearAllFilters
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterCleared: () => { dispatch(clearAllFilters(false)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopoverSelectFilter);