import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { processingUpdate } from './ProcessingActions';
import Block from '@material-ui/icons/Block';
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import CloudUpload from '@material-ui/icons/CloudUpload';
import LocalShipping from '@material-ui/icons/LocalShipping';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { Tooltip, IconButton, Menu, MenuItem, ListItemIcon, Popper, Paper, ClickAwayListener, Grow, MenuList, Fade } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import BlockIcon from '@material-ui/icons/Block';
import { CircularProgress } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Restore from '@material-ui/icons/Restore';
import FileCopy from '@material-ui/icons/FileCopy'
import NicerTooltip from '../common/NicerTooltip'
import Search from '@material-ui/icons/Search';
import NotInterested from '@material-ui/icons/NotInterested'
import DeleteForever from '@material-ui/icons/DeleteForever'
import HourglassFullTwoTone from '@material-ui/icons/HourglassFullTwoTone'
import FindInPage from '@material-ui/icons/FindInPage'
import { 
    ManuallyProcessed, SupersededComplete, SupersededFailed, SupersededManuallyProcessed, 
    SupersededQueued, SupersededIgnore, CompletedNoQA, CompletedIssuesNoQA, FailedNoQA, Archived 
} from '../icons/svgIcons'
import humanizeDuration from 'humanize-duration';
import { handleValidateArchive } from '../explorer/objectExplorerActions'
import { isMemberOfGuestGroups } from '../common/PrivateRoute'
import Config from '../app/Config.json'

class ClusterProcessingStatusTableIcon extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        anchorEl: null,
        open: false,
    };

    // handleClick = event => {
    //     this.setState({ anchorEl: event.currentTarget });
    // };

    handleClose = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open,
        }));
    };

    handleClick = event => {
        // if (this.props.process.isArchived == true) {
        //     return
        // }
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open,
            tooltipOpen: false
        }));
    };



//currently getting passed:
//this.updateClusterValues(this.props.process._id, clusterName, { "QAUser": this.props.user, "Status": "QA_COMPLETED_WITH_ERRORS" })
//acually needs to wrap  updateClusterValues in the below handlign first

    handleMenuClick = (event, func, inputValue = undefined) => {

        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open
        }));

        if (inputValue !== undefined)
            func(inputValue);
        else
            func();


    };

    handlePopoverOpen = event => {
        // this.setState({ anchorEl: event.currentTarget });
        this.setState({ tooltipOpen: true });

    };

    getCounts = (clusterProcessingArray, clusterName) => {
        if (!clusterProcessingArray)
            return ['NA', 'NA']

        //urgh... more specifics, will replace this when generic cluster handling is implemented
        let clusterResults = [];
        // if (clusterName === 'ICP_MineIP1') {
        //     clusterResults = clusterProcessingArray.filter(cluster => cluster.Name === 'ICP1_MineIP1' || cluster.Name === 'ICP2_MineIP1')
        // }
        // else
        clusterResults = clusterProcessingArray.filter(cluster => cluster.Name === clusterName);

        let successCount = 0;
        let failureCount = 0;
        clusterResults.forEach((result) => {
            successCount += result.SucceededJobCount;
            failureCount += result.FailedJobCount;
        })

        return [successCount, failureCount];
    }

    getRuntime = (process, clusterName) => {
        for (var i = 0; i < process.ClusterProcessing.length; i++) {
            let item = process.ClusterProcessing[i];
            if (item.Name === clusterName && item.RunStartDate && item.RunEndDate) {
                return humanizeDuration(Math.abs(Date.parse(item.RunEndDate) - Date.parse(item.RunStartDate)))
            }
        }
        return null;
    }

    formatTimezoneOffset = (offset) => {
        let symbol = (offset > 0) ? '-' : '+'
        let hours = Math.floor(Math.abs(offset) / 60) 
        let minutes = Math.round(Math.abs(offset) % 60)
        return 'UTC' + symbol + hours + ":" + minutes.toString().padStart(2, '0')
    }

    getCompletedDatetime = (process, clusterName) => {
        for (var i = 0; i < process.ClusterProcessing.length; i++) {
            let item = process.ClusterProcessing[i];
            if (item.Name === clusterName && item.RunStartDate && item.RunEndDate) {
                let formatedDatetime = (new Date(item.RunEndDate+'.000+00:00')).toLocaleString('sv-SE')
                return formatedDatetime + ` (${this.formatTimezoneOffset((new Date(item.RunEndDate+'.000+00:00')).getTimezoneOffset())})`
            }
        }
        return null;
    }

    getClusterValue = (processDoc, clusterName, value) => {
        // if(processDoc === undefined || processDoc.ClusterProcessing === undefined)
        //     return
        for (var i = 0; i < processDoc.ClusterProcessing.length; i++) {
            // console.log("..."processDoc)            
            if (clusterName === processDoc.ClusterProcessing[i].Name)
                // console.log("... processDoc.ClusterProcessing[i][value]",processDoc.ClusterProcessing[i][value])            
                return processDoc.ClusterProcessing[i][value]
        }
    }

    updateClusterValue = (id, clusterName, fieldName, value) => {
        let jsonDoc = {};
        jsonDoc[fieldName] = value;

        this.props.updateProcessing(this.props.token, id, jsonDoc, clusterName);
    }

    updateClusterValues = (id, clusterName, jsonDoc) => {
        this.props.updateProcessing(this.props.token, id, jsonDoc, clusterName);
    }

    render() {
        if (this.props.process === undefined || this.props.process.ClusterProcessing.length === 0)
            return (<div></div>)

        const { 
            attempts, removeIgnore, removeQa, disableResubmit, removeMarkCompleted, 
            clusterName, isWaitingForPollingResponse, process, explorerOnClickCallback
        } = this.props;
        let isGPSResubmitted = (this.props.process._id in this.props.processingResubmitLoadingWithId) ? true : false
        let isLMSResubmitted = (this.props.process._id in this.props.processingLMSResubmitLoadingWithId) ? true : false
        let isClusterResubmitted = (this.props.process._id in this.props.processingClusterResubmitLoadingWithId) ? true : false
        let isAnyResubmitted = isGPSResubmitted || isLMSResubmitted || isClusterResubmitted
        // let status = this.getClusterValue(this.props.process, clusterName, "Status");
        let status = (isAnyResubmitted || isWaitingForPollingResponse) ? 'WAITING' : this.getClusterValue(this.props.process, clusterName, "Status");
        let qaIssueDesc = this.getClusterValue(this.props.process, clusterName, "QAIssueDesc");
        let qaIssueRes = this.getClusterValue(this.props.process, clusterName, "QAIssueRes");
        let qaUser = this.getClusterValue(this.props.process, clusterName, "QAUser");
        let s3Link = this.getClusterValue(this.props.process, clusterName, "OutputS3Path");
        let runtime = this.getRuntime(this.props.process, clusterName);
        let completedDatetime = this.getCompletedDatetime(this.props.process, clusterName);
        let template = this.props.process.ProcessingTemplate;
        let active = this.props.process.Active !== undefined ? this.props.process.Active : true;
        let deletedSuperseded = this.props.process.deletedSuperseded !== undefined ? this.props.process.deletedSuperseded : false
        let isArchived = this.props.process.isArchived == undefined ? false : this.props.process.isArchived
        let isLocked = template.indexOf("PowerICP") > -1 ? true : false;

        let counts = this.getCounts(this.props.process.ClusterProcessing, this.props.clusterName);

        let succeededTaskCount = counts[0];
        let failedTaskCount = counts[1];

        // A bool value to indicate if there is GPS, LMS or dynamic cluster is on RUNNING or QUEUED state
        // GPS is the first cluster. So there is no downstream icon that have the context menu.
        const isRunningStatus = (status) => ['RUNNING', 'QUEUED'].indexOf(status) > -1
        // const isGPSRunning = isRunningStatus(process.GPSProcStatus)
        const isLMSRunning = isRunningStatus(this.props.process.LMSProcStatus)
        const isClusterRunning = this.props.process.ClusterProcessing
                                                            // .filter(cluster => cluster.Category=='IP')
                                                            .map((cluster) => cluster.Status)
                                                            .some(isRunningStatus)
        const isAnyRunningJob = isLMSRunning || isClusterRunning           // LMS and dynamic clusters
        const isFromLegacyProd = (process.DocumentMajorVersion && process.DocumentMajorVersion == 1.4)
        const isMigratedFromLegacy = (process.DocumentMajorVersion && process.DocumentMajorVersion <= 3.0)

        //TODO remove this tetrarays nonsense and replace with a REAL permissions model
        if (isMemberOfGuestGroups(this.props.token)) {
            return (
                <div>
                    {{
                        ['NOT_APPLICABLE']:
                            <NicerTooltip placement="right" content={<span>Not Applicable</span>}>
                                {active ? <BlockIcon style={{ color: '#a3a3a3' }} /> : <SupersededQueued />}
                            </NicerTooltip>,
                        ['NOT_COMMENCED']:
                            <NicerTooltip placement="right" content={<span>{active ? '' : <span>Superseded<br /></span>}Not yet commenced</span>}>
                                {active ? <AccessTime style={{ color: '#a3a3a3', marginTop:20 }} /> : <SupersededQueued />}
                            </NicerTooltip>,
                        ['QUEUED']:
                            <NicerTooltip placement="right" content={<span>Queued</span>}>
                                <AccessTime style={{ color: '#1a3a69' }} />
                            </NicerTooltip>,
                        ['QUEUING']:
                            <NicerTooltip placement="right" content={<span>Queued</span>}>
                                <AccessTime style={{ color: '#1a3a69' }} />
                            </NicerTooltip>,
                        ['RUNNING']:
                            <NicerTooltip placement="right" content={<span>Running</span>}>
                                <CircularProgress id="compress-progress-id" style={{ marginTop: 2 }} size={19} />
                            </NicerTooltip>,
                        ['ON_HOLD']:
                            <NicerTooltip placement="right" content={<span>On Hold</span>}>
                                <PauseCircleOutlineIcon style={{ color: '#a3a3a3',marginTop:20 }} />
                            </NicerTooltip>,
                        ['IGNORE']:
                            <div>
                                <NicerTooltip placement="right" content={<span>{active ? '' : <span>Superseded<br /></span>}Ignore Processing<br />Click for options</span>}>
                                    <IconButton onClick={this.handleClick} style={{ marginTop: 15, padding: 5 }}>
                                        {active ? <NotInterested id="compress-progress-id" style={{ color: '#ff6100' }} size={19} /> : <SupersededIgnore />}
                                    </IconButton>
                                </NicerTooltip>
                            </div>,
                        ['MANUALLY_PROCESSED']:
                            <div>
                                <NicerTooltip placement="right" 
                                    content={
                                        <span>
                                            {isArchived ? <span>Archived<br /></span> : ''}
                                            {active ? '' : <span>Superseded<br /></span>}
                                            Manually Processed<br />Click for options
                                        </span>
                                    }
                                >
                                    <IconButton onClick={this.handleClick} style={{ marginTop: 15, padding: 5 }}>
                                        {/* <ManuallyProcessed style={{ fontSize: 23, color: '#FF0000', marginTop: -5 }} /> */}
                                        {isArchived
                                            ? <Archived style={{ marginTop: 6, marginLeft: 5 }} />
                                            : active 
                                                ? <ManuallyProcessed style={{ fontSize: 23, color: '#FF0000' }} /> 
                                                : <SupersededManuallyProcessed />
                                        }
                                    </IconButton>
                                </NicerTooltip>
                                {
                                    (clusterName === 'PI1_MineIP1' || clusterName === 'MiningAI3') 
                                        && <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                            {({ TransitionProps }) => (
                                                <Fade {...TransitionProps} timeout={350}>
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={this.handleClose}>
                                                            <MenuList>
                                                                {isArchived 
                                                                    ? <>
                                                                        {/* <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.process.Flights[0].archiveS3PathLiDAR || '') })}>
                                                                            <ListItemIcon>
                                                                                <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                        </MenuItem> */}
                                                                    </>
                                                                    : <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Link) })}>
                                                                        <ListItemIcon>
                                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                                    </MenuItem>
                                                                }
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Fade>
                                            )}
                                        </Popper>
                                        
                                }
                            </div>,
                        ['COMPLETED']:
                            <div>
                                <NicerTooltip placement="right" content={
                                    <span>
                                        {isArchived ? <span>Archived<br /></span> : ''}
                                        {active ? '' : <span>Superseded<br /></span>}
                                        {failedTaskCount > 0 
                                            ? <span>Completed with failures</span> 
                                            : <span>Completed</span>}<br />
                                                Successful tasks: {succeededTaskCount}<br />
                                                Failed tasks: {failedTaskCount}<br />
                                                {removeQa === true 
                                                    ? null 
                                                    : qaUser 
                                                        ? <span>QA by {qaUser}<br /></span> 
                                                        : <span style={{ fontWeight: 'bold' }}>QA Required<br /></span>
                                                }
                                                <span style={{ fontStyle: 'italic' }}>Click for options</span>
                                    </span>
                                }>
                                    <IconButton onClick={this.handleClick} style={{ marginTop: 15, padding: 5 }} >
                                        {/* <CheckCircleOutline style={{ color: active ?  failedTaskCount > 0 ? '#ff6100' : '#006600' : '#a3a3a3' }} /> */}
                                        {isArchived
                                            ? <Archived style={{ marginTop: 6, marginLeft: 5 }} />
                                            : active 
                                                ? (qaUser || removeQa) 
                                                    ? <CheckCircleOutline style={{ color: failedTaskCount > 0 ? '#ff6100' : '#006600' }} />
                                                    : failedTaskCount > 0 
                                                        ? <CompletedIssuesNoQA />
                                                        : <CompletedNoQA />
                                                : <SupersededComplete />
                                        }
                                    </IconButton>
                                </NicerTooltip>

                                {
                                    (clusterName === 'PI1_MineIP1' || clusterName === 'MiningAI3') &&
                                    <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper>
                                                    <ClickAwayListener onClickAway={this.handleClose}>
                                                        <MenuList>
                                                            {isArchived 
                                                                ? <>
                                                                    {/* <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.process.Flights[0].archiveS3PathLiDAR || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem> */}
                                                                </>
                                                                : <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Link) })}>
                                                                    <ListItemIcon>
                                                                        <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                                </MenuItem>
                                                            }
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                }
                            </div>
                        ,
                        ['QA_COMPLETED_WITH_ERRORS']:
                            <div>
                                <NicerTooltip placement="right" content={
                                    <span>
                                        {isArchived ? <span>Archived<br /></span> : ''}
                                        {active ? '' : <span>Superseded<br /></span>} 
                                        {failedTaskCount > 0 ? <span>Completed with failures</span> : <span>QA Completed (with errors)</span>}<br />Successful tasks: {succeededTaskCount}<br />Failed tasks: {failedTaskCount}<br />{removeQa === true ? null : <span>QA by {qaUser}<br /></span>}<span style={{ fontStyle: 'italic' }}>Click for options</span>
                                    </span>
                                }>
                                    <IconButton onClick={this.handleClick} style={{ marginTop: 15, padding: 5 }} >
                                        {isArchived
                                            ? <Archived style={{ marginTop: 6, marginLeft: 5 }} />
                                            : (active || removeQa) 
                                                ? <CheckCircleOutline style={{ color: '#ff6100' }} /> 
                                                : <SupersededComplete />
                                        }
                                    </IconButton>
                                </NicerTooltip>

                                {
                                    (clusterName === 'PI1_MineIP1' || clusterName === 'MiningAI3') &&
                                    <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper>
                                                    <ClickAwayListener onClickAway={this.handleClose}>
                                                        <MenuList>
                                                            {isArchived 
                                                                ? <>
                                                                    {/* <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.process.Flights[0].archiveS3PathLiDAR || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem> */}
                                                                </>
                                                                : <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Link) })}>
                                                                    <ListItemIcon>
                                                                        <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                                </MenuItem>
                                                            }
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                }
                            </div>
                        ,
                        ['FAILED']:
                            <div>

                                <NicerTooltip placement="right" content={
                                    <span>{active ? '' : <span>Superseded<br /></span>}Failed<br />{removeQa === true ? null : qaUser ? <span>QA by {qaUser}<br /></span> : <span style={{ fontWeight: 'bold' }}>QA Required<br /></span>}<span style={{ fontStyle: 'italic' }}>Click for options</span></span>
                                }>
                                    <IconButton onClick={this.handleClick} style={{ marginTop: 15, padding: 5 }} >
                                        {/* <ErrorOutline style={{ color: active ? '#FF0000' : '#a3a3a3' }} /> */}
                                        {active ?
                                            (qaUser || removeQa) ?
                                                <ErrorOutline style={{ color: '#FF0000' }} />
                                                : <FailedNoQA />
                                            : <SupersededFailed />}
                                    </IconButton>
                                </NicerTooltip>
                            </div>,
                    }[status]}
                </div>
            );
        }

        return (
            <div>
                {{
                    ['NOT_APPLICABLE']:
                        <NicerTooltip placement="right" content={<span>Not Applicable</span>}>
                            {active ? <BlockIcon style={{ color: '#a3a3a3', marginTop:20 }} /> : <SupersededQueued style={{marginTop:20}}/>}
                        </NicerTooltip>,
                    ['NOT_COMMENCED']:
                        <NicerTooltip placement="right" 
                            content={
                                <span>
                                    {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{process.DocumentMajorVersion.toFixed(1)}<br /></>}
                                    {active 
                                        ? '' 
                                        : deletedSuperseded
                                            ? <span>Superseded and Deleted<br /></span>
                                            : <span>Superseded<br /></span>
                                    }
                                    Not yet commenced
                                </span>
                            }
                        >
                            {active 
                                ? <AccessTime style={{ color: '#a3a3a3', marginTop:20 }} /> 
                                : deletedSuperseded
                                    ? <DeleteForever style={{ marginTop:20, color: '#FF0000' }} />
                                    : <SupersededQueued style={{marginTop:20}}/>
                            }
                        </NicerTooltip>,
                    ['QUEUED']:
                        <NicerTooltip placement="right" content={<span>Queued</span>}>
                            <AccessTime style={{ color: '#1a3a69',marginTop:20 }} />
                        </NicerTooltip>,
                    ['QUEUING']:
                        <NicerTooltip placement="right" content={<span>Queued</span>}>
                            <AccessTime style={{ color: '#1a3a69',marginTop:20 }} />
                        </NicerTooltip>,
                    ['RUNNING']:
                        <NicerTooltip placement="right" content={<span>Running</span>}>
                            {/* <CircularProgress id="compress-progress-id" style={{ marginTop: 18 }} size={19} /> */}
                            <CircularProgress id="compress-progress-id" style={{ marginTop: 23 }} size={19} />
                        </NicerTooltip>,
                    ['ON_HOLD']:
                        <NicerTooltip placement="right" content={<span>On Hold</span>}>
                            <PauseCircleOutlineIcon style={{ color: '#a3a3a3',marginTop:20 }} />
                        </NicerTooltip>,
                    ['IGNORE']:
                        <div>
                            <NicerTooltip placement="right" 
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{process.DocumentMajorVersion.toFixed(1)}<br /></>}
                                        {isArchived ? <span>Archived<br /></span> : ''}
                                        {active 
                                            ? '' 
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        Ignore Processing<br />
                                        {runtime ? <span>Duration: {runtime}<br /></span> : null}
                                        {qaIssueDesc &&
                                            <div>
                                                <span style={{ display: "block", width: 300 }}>QA Issue: {qaIssueDesc}<br /></span>
                                                <span style={{ display: "block", width: 300 }}>QA Resolution: {qaIssueRes}<br /></span>
                                            </div>
                                        }
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop:20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}
                                    >
                                        {isArchived
                                            ? <Archived style={{ marginTop: 6, marginLeft: 5 }} />
                                            : <>
                                                {active 
                                                    ? <NotInterested id="compress-progress-id" style={{ color: '#ff6100' }} size={19} /> 
                                                    : <SupersededIgnore />}
                                            </>
                                        }
                                    </IconButton>
                                }
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {isArchived
                                                        ? <>
                                                            {!isFromLegacyProd
                                                                && <>
                                                                        <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Link`} onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.process.archiveS3PathLiDAR || '') })}>
                                                                            <ListItemIcon>
                                                                                <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                        </MenuItem>
                                                                        <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Explorer`} onClick={(event) => this.handleMenuClick(event, () => explorerOnClickCallback() )}>
                                                                            <ListItemIcon>
                                                                                <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                                        </MenuItem>
                                                                </>
                                                            }
                                                        </>
                                                        : <>
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Link) })}>
                                                                <ListItemIcon>
                                                                    <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                            </MenuItem>
                                                            {!isMigratedFromLegacy
                                                                && <>
                                                                    <MenuItem disabled={isLocked || disableResubmit || isAnyRunningJob} onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                        <ListItemIcon>
                                                                            <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                                    </MenuItem>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['MANUALLY_PROCESSED']:
                        <div>
                            <NicerTooltip placement="right" 
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{process.DocumentMajorVersion.toFixed(1)}<br /></>}
                                        {isArchived ? <span>Archived<br /></span> : ''}
                                        {active 
                                            ? '' 
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        Manually Processed<br />
                                        {qaIssueDesc &&
                                            <div>
                                                <span style={{ display: "block", width: 300 }}>QA Issue: {qaIssueDesc}<br /></span>
                                                <span style={{ display: "block", width: 300 }}>QA Resolution: {qaIssueRes}<br /></span>
                                            </div>
                                        }
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop:20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}
                                    >
                                        {/* <ManuallyProcessed style={{ fontSize: 23, color: '#FF0000', marginTop: -5 }} /> */}
                                        {isArchived
                                            ? <Archived style={{ marginTop: 6, marginLeft: 5 }} />
                                            : active 
                                                ? <ManuallyProcessed style={{ fontSize: 23, color: '#FF0000' }} /> 
                                                : <SupersededManuallyProcessed />
                                        }
                                    </IconButton>
                                }
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {isArchived
                                                        ? <>
                                                            {!isFromLegacyProd
                                                                && [
                                                                    <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Link`} onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.process.archiveS3PathLiDAR || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem>,
                                                                    <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Explorer`} onClick={(event) => this.handleMenuClick(event, () => explorerOnClickCallback() )}>
                                                                        <ListItemIcon>
                                                                            <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                                    </MenuItem>
                                                                ]
                                                            }
                                                        </>
                                                        : <>
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Link) })}>
                                                                <ListItemIcon>
                                                                    <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                            </MenuItem>
                                                            {!isMigratedFromLegacy
                                                                && <>
                                                                    <MenuItem disabled={isLocked || disableResubmit || isAnyRunningJob} onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                        <ListItemIcon>
                                                                            <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                                    </MenuItem>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['COMPLETED']:
                        <div>
                            <NicerTooltip placement="right" 
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{process.DocumentMajorVersion.toFixed(1)}<br /></>}
                                        {isArchived ? <span>Archived<br /></span> : ''}
                                        {active 
                                            ? '' 
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        {failedTaskCount > 0 ? <span>Completed with failures</span> : <span>Completed</span>}<br />Successful tasks: {succeededTaskCount}<br />Failed tasks: {failedTaskCount}<br />{removeQa === true ? null : qaUser ? <span>QA by {qaUser}<br /></span> : <span style={{ fontWeight: 'bold' }}>QA Required<br /></span>}
                                        {completedDatetime ? <span>Completed Date: {completedDatetime}<br /></span> : null}
                                        {runtime ? <span>Duration: {runtime}<br /></span> : null}
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop:20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}
                                    >
                                        {/* <CheckCircleOutline style={{ color: active ?  failedTaskCount > 0 ? '#ff6100' : '#006600' : '#a3a3a3' }} /> */}
                                        {isArchived
                                            ? <Archived style={{ marginTop: 6, marginLeft: 5 }} />
                                            : active ?
                                                (qaUser || removeQa) ?
                                                    <CheckCircleOutline style={{ color: failedTaskCount > 0 ? '#ff6100' : '#006600' }} />
                                                    : failedTaskCount > 0 ?
                                                        <CompletedIssuesNoQA />
                                                        : <CompletedNoQA />
                                                : <SupersededComplete />
                                        }
                                    </IconButton>
                                }
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {isArchived
                                                        ? <>
                                                            {/* {!isMigratedFromLegacy */}
                                                            {!isFromLegacyProd
                                                                && [
                                                                    <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Link`} onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.process.archiveS3PathLiDAR || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem>,
                                                                    <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Explorer`} onClick={(event) => this.handleMenuClick(event, () => explorerOnClickCallback() )}>
                                                                        <ListItemIcon>
                                                                            <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                                    </MenuItem>
                                                                ]
                                                            }
                                                        </>
                                                        : <>
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Link) })}>
                                                                <ListItemIcon>
                                                                    <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                            </MenuItem>
                                                            {!isMigratedFromLegacy
                                                                && <>
                                                                    {failedTaskCount > 0 &&
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.showResults)}>
                                                                            <ListItemIcon>
                                                                                <Search style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>View failure details</span>
                                                                        </MenuItem>
                                                                    }
                                                                    <MenuItem disabled={isLocked || disableResubmit || isAnyRunningJob} onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                        <ListItemIcon>
                                                                            <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        {/* TODO... this needs to be greyed out or missing if any downstream jobs running */}
                                                                        <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                                    </MenuItem>
                                                                    {!qaUser && removeQa !== true &&
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, () => this.updateClusterValue(this.props.process._id, clusterName, "QAUser", this.props.user))}>
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Mark as QA Completed</span>
                                                                        </MenuItem>
                                                                    }
                                                                    {!qaUser && removeQa !== true &&
                                                                        // <MenuItem onClick={(event) => this.handleMenuClick(event, this.updateClusterValues(this.props.process._id, clusterName, { "QAUser": this.props.user, "Status": "QA_COMPLETED_WITH_ERRORS" }))}>
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors, "QA_COMPLETED_WITH_ERRORS")}>
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Mark as QA Completed (with errors)</span>
                                                                        </MenuItem>
                                                                    }
                                                                    {/* <MenuItem onClick={(event) => this.handleMenuClick(event, this.updateClusterValue(process._id, clusterName, "Status", "MANUALLY_PROCESSED"))}> */}
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors, "MANUALLY_PROCESSED")}>
                                                                        <ListItemIcon>
                                                                            <ManuallyProcessed style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Mark as Manually Processed</span>
                                                                    </MenuItem>
                                                                    {removeIgnore !== true &&
                                                                        // <MenuItem onClick={(event) => this.handleMenuClick(event, this.updateClusterValue(process._id, clusterName, "Status", "IGNORE"))}>
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors, "IGNORE")}>
                                                                            <ListItemIcon>
                                                                                <NotInterested style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Mark as Ignore Processing</span>
                                                                        </MenuItem>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>
                    ,
                    ['QA_COMPLETED_WITH_ERRORS']:
                        <div>
                            <NicerTooltip placement="right" 
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{process.DocumentMajorVersion.toFixed(1)}<br /></>}
                                        {isArchived ? <span>Archived<br /></span> : ''}
                                        {active 
                                            ? '' 
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        {failedTaskCount > 0 
                                            ? <span>Completed with failures</span> 
                                            : <span>QA Completed (with errors)</span>}<br />
                                        Successful tasks: {succeededTaskCount}<br />
                                        Failed tasks: {failedTaskCount}<br />
                                        {removeQa === true ? null : <span>QA by {qaUser}<br /></span>}
                                        {completedDatetime ? <span>Completed Date: {completedDatetime}<br /></span> : null}
                                        {runtime ? <span>Duration: {runtime}<br /></span> : null}
                                        {qaIssueDesc &&
                                            <div>
                                                <span style={{ display: "block", width: 300 }}>QA Issue: {qaIssueDesc}<br /></span>
                                                <span style={{ display: "block", width: 300 }}>QA Resolution: {qaIssueRes}<br /></span>
                                            </div>
                                        }
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop:20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}
                                    >
                                        {isArchived
                                            ? <Archived style={{ marginTop: 6, marginLeft: 5 }} />
                                            : (active || removeQa) 
                                                ? <CheckCircleOutline style={{ color: '#ff6100' }} /> 
                                                : <SupersededComplete />
                                        }
                                    </IconButton>
                                }
                            </NicerTooltip>

                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {isArchived
                                                        ? <>
                                                            {!isFromLegacyProd
                                                                && [
                                                                    <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Link`} onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.process.archiveS3PathLiDAR || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem>,
                                                                    <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Explorer`} onClick={(event) => this.handleMenuClick(event, () => explorerOnClickCallback() )}>
                                                                        <ListItemIcon>
                                                                            <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                                    </MenuItem>
                                                                ]
                                                            }
                                                        </>
                                                        : <>
                                                            {failedTaskCount > 0 &&
                                                                <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.showResults)}>
                                                                    <ListItemIcon>
                                                                        <Search style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                    </ListItemIcon>
                                                                    <span style={{ fontSize: 12 }}>View failure details</span>
                                                                </MenuItem>
                                                            }
                                                            <MenuItem onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Link) })}>
                                                                <ListItemIcon>
                                                                    <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                            </MenuItem>
                                                            {!isMigratedFromLegacy
                                                                && <>
                                                                    <MenuItem disabled={isLocked || disableResubmit || isAnyRunningJob} onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                        <ListItemIcon>
                                                                            <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        {/* TODO... this needs to be greyed out or missing if any downstream jobs running */}
                                                                        <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                                    </MenuItem>
                                                                    {!qaUser && removeQa !== true &&
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.updateClusterValue(this.props.process._id, clusterName, "QAUser", this.props.user))}>
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Mark as QA Completed</span>
                                                                        </MenuItem>
                                                                    }
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors, "MANUALLY_PROCESSED")}>
                                                                    
                                                                        <ListItemIcon>
                                                                            <ManuallyProcessed style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Mark as Manually Processed</span>
                                                                    </MenuItem>
                                                                    {removeIgnore !== true &&
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors, "IGNORE")}>
                                                                            <ListItemIcon>
                                                                                <NotInterested style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Mark as Ignore Processing</span>
                                                                        </MenuItem>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>
                    ,
                    ['FAILED']:
                        <div>
                            <NicerTooltip placement="right" 
                                content={
                                    <span>
                                        {isMigratedFromLegacy && <><span style={{ fontWeight: 'bold' }}>Major Version: </span>{process.DocumentMajorVersion.toFixed(1)}<br /></>}
                                        {isArchived ? <span>Archived<br /></span> : ''}
                                        {active 
                                            ? '' 
                                            : deletedSuperseded
                                                ? <span>Superseded and Deleted<br /></span>
                                                : <span>Superseded<br /></span>
                                        }
                                        Failed<br />
                                        {removeQa === true ? null : qaUser ? <span>QA by {qaUser}<br /></span> : <span style={{ fontWeight: 'bold' }}>QA Required<br /></span>}{runtime ? <span>Duration: {runtime}<br /></span> : null}
                                        {!deletedSuperseded && <span style={{ fontStyle: 'italic' }}>Click for options</span>}
                                    </span>
                                }
                            >
                                {deletedSuperseded
                                    ? <DeleteForever style={{ marginTop:20, color: '#FF0000' }} />
                                    : <IconButton
                                        style={{ marginTop: 15, padding: 5 }}
                                        onClick={this.handleClick}>
                                        {/* <ErrorOutline style={{ color: active ? '#FF0000' : '#a3a3a3' }} /> */}
                                        {isArchived
                                            ? <Archived style={{ marginTop: 6, marginLeft: 5 }} />
                                            : active 
                                                ? (qaUser || removeQa) 
                                                    ? <ErrorOutline style={{ color: '#FF0000' }} />
                                                    : <FailedNoQA />
                                                : <SupersededFailed />
                                        }
                                    </IconButton>
                                }
                            </NicerTooltip>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement='left' transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {isArchived 
                                                        ? <>
                                                            {!isFromLegacyProd
                                                                && [
                                                                    <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Link`} onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(this.props.process.archiveS3PathLiDAR || '') })}>
                                                                        <ListItemIcon>
                                                                            <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Copy S3 archive link</span>
                                                                    </MenuItem>,
                                                                    <MenuItem key={this.props.process.archiveS3PathLiDAR+`${clusterName}Explorer`} onClick={(event) => this.handleMenuClick(event, () => explorerOnClickCallback() )}>
                                                                        <ListItemIcon>
                                                                            <FindInPage style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Browse archived files</span>
                                                                    </MenuItem>
                                                                ]
                                                            }
                                                        </>
                                                        : <>
                                                            <MenuItem
                                                                disabled={this.props.attempts < 1}
                                                                onClick={(event) => this.handleMenuClick(event, () => { navigator.clipboard.writeText(s3Link) })}>
                                                                <ListItemIcon>
                                                                    <FileCopy style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: 12 }}>Copy S3 link</span>
                                                            </MenuItem>
                                                            {!isMigratedFromLegacy
                                                                && <>
                                                                    <MenuItem disabled={isLocked || disableResubmit} onClick={(event) => this.handleMenuClick(event, this.props.resubmit)}>
                                                                        <ListItemIcon>
                                                                            <Restore style={{ fontSize: 22, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Resubmit Job</span>
                                                                    </MenuItem>
                                                                    {!qaUser && removeQa !== true &&
                                                                        // <MenuItem onClick={(event) => this.handleMenuClick(event, this.updateClusterValue(this.props.process._id, clusterName, "QAUser", this.props.user))}>
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors)}>
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutline style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Mark as QA Completed</span>
                                                                        </MenuItem>
                                                                    }
                                                                    {!removeMarkCompleted &&
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.updateClusterValue(process._id, clusterName, "Status", "COMPLETED"))}>
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutline style={{ fontSize: 20, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Mark as Completed</span>
                                                                        </MenuItem>
                                                                    }
                                                                    <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors, "MANUALLY_PROCESSED")}>
                                                                        <ListItemIcon>
                                                                            <ManuallyProcessed style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                        </ListItemIcon>
                                                                        <span style={{ fontSize: 12 }}>Mark as Manually Processed</span>
                                                                    </MenuItem>
                                                                    {removeIgnore !== true &&
                                                                        <MenuItem onClick={(event) => this.handleMenuClick(event, this.props.updateQAWithErrors, "IGNORE")}>
                                                                            <ListItemIcon>
                                                                                <NotInterested style={{ fontSize: 19, color: '#1a3a69' }} />
                                                                            </ListItemIcon>
                                                                            <span style={{ fontSize: 12 }}>Mark as Ignore Processing</span>
                                                                        </MenuItem>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>,
                    ['WAITING']:
                        <NicerTooltip
                            placement="right"
                            content={<span>Waiting after resubmitting</span>}
                        >
                            <HourglassFullTwoTone style={{ marginTop: 20 }}/>
                        </NicerTooltip>
                }[status]}
            </div>
        );
    }
}

// export default ClusterProcessingStatusTableIcon;

const mapStateToProps = (state, ownProps) => {
    let tokenPayload = state.token ? JSON.parse(atob(state.token.split(".")[1])) : ''
    let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''
    return {
        token: state.token,
        user,
        processingResubmitLoadingWithId: state.processingResubmitLoadingWithId,
        processingLMSResubmitLoadingWithId: state.processingLMSResubmitLoadingWithId,
        processingClusterResubmitLoadingWithId: state.processingClusterResubmitLoadingWithId,
        explorerStore: state.objectExplorer
    };
};

// //this is needed to match thre returned result to properties
const mapDispatchToProps = (dispatch) => {
    return {
        updateProcessing: (token, id, jsonData, clusterName) => { dispatch(processingUpdate(token, id, jsonData, clusterName)) },
        handleValidateArchive: (bucket, prefix) => { dispatch(handleValidateArchive(bucket, prefix)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClusterProcessingStatusTableIcon);