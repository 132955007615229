import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Grid, List, ListItem, ListItemIcon, ListItemText, TextField, Button, InputAdornment, CircularProgress, Table, TableRow, TableCell, TableBody, TableHead, Paper, Checkbox, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, IconButton, Select, MenuItem, Radio } from '@material-ui/core';
import { sensorsFetchList, clearSensorsList, sensorsListLoading, gpsFetchList, clearGpsList, gpsListLoading, submitCustomGPSProcessing, submitCustomGPSProcessingHasError, submitCustomGPSProcessingLoading, submitCustomGPSProcessingSuccess } from './SensorActions';
import TableSensorTypeIcon from '../common/TableSensorTypeIcon';
import NicerTooltip from '../common/NicerTooltip';
import FlightTypeTableIcon from '../flight/FlightTypeTableIcon';
import TableEllipsisIcon from '../common/TableEllipsisIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CancelIcon from '@material-ui/icons/Cancel';
import { clearAllFilters, uniqueValuesFetch } from '../common/CompositeFilterActions'

class CustomProcessingSensor extends Component {

    constructor(props) {
        super(props);
        // this.testing('FL013530');
    }

    state = {
        flightId: undefined,
        checked: [],
        selectAll: false,
        isContiguousSelection: true,
        startHelperText: '',
        endHelperText: '',
        startInError: false,
        endInError: false,
        startTime: '',
        endTime: '',
        // lowestSensor: undefined,
        baseFolder: undefined,
        gpsInError: false,
        gpsDirty: false,

        isSelected: '',
        selectedSensor: undefined,
        selectedTemplate: undefined,

        //range
        rangeChecked: [],
        lmsInError: false,
        lmsDirty: false,
        //accordian props
        expanded: null,
        customType: 'lidar',
        lmsDisabled: false
    };
    // state = {
    //     isSelected: '',
    //     selectedSensor: undefined
    // }


    componentDidMount() {
        this.getProcessingTemplates();
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("...checkContiguousFiles", this.checkContiguousFiles());


        let isContiguous = this.checkContiguousFiles();
        if (prevState.isContiguousSelection !== isContiguous)
            this.setState({ isContiguousSelection: isContiguous });

        //greg wanted the lowest sensor code disabled... custom submits werent batchin the default submit SensorID (which grabs the last)
        // if (this.props.sensors && this.props.sensors.length > 0 && this.state.lowestSensor === undefined) {
        //     let lowestSensor = '';
        //     let lowestSensorInt = 0;
        //     this.props.sensors.forEach(sensor => {
        //         let currentSensorInt = sensor.SensorID.substring(2, 8)
        //         if (currentSensorInt > lowestSensorInt) {
        //             lowestSensor = sensor;
        //             lowestSensorInt = currentSensorInt;
        //         }
        //     })
        //     this.setState({ lowestSensor: lowestSensor });
        // }
        //instead grabbing the last sensor in the list (to match the crappy way the default submit must be working)
        // if (this.props.sensors && this.props.sensors.length > 0 && this.state.lowestSensor === undefined) {
        //     this.setState({ lowestSensor: this.props.sensors[this.props.sensors.length - 1] });
        // }

        if (this.props.submitSuccess !== null && prevProps.submitSuccess === null) {
            //on successful submit clearing the form state and redirecting to the ip dashboard
            this.props.clearSensors();
            this.props.clearGPS();
            this.props.clearSubmit();
            this.setState({
                flightId: undefined,
                checked: [],
                selectAll: false,
                isContiguousSelection: true,
                startHelperText: '',
                endHelperText: '',
                startInError: false,
                endInError: false,
                startTime: '',
                endTime: '',
                // lowestSensor: undefined,
                baseFolder: undefined,
                //range
                rangeChecked: [],
                lmsInError: false,
                lmsDirty: false,
                isSelected: '',
                selectedSensor: undefined,
                selectedTemplate: undefined

            });
            this.props.history.push('/ip');
        }

        //checking dirty and error state of gps
        if (this.state.startTime !== '' || this.state.endTime !== '' || this.state.checked.length > 0) {
            if (prevState.gpsDirty === false)
                this.setState({ gpsDirty: true });
        }
        else if (prevState.gpsDirty === true)
            this.setState({ gpsDirty: false });

        if (this.state.startInError || this.state.endInError || (!this.state.isContiguousSelection && this.state.checked.length > 0)) {
            if (prevState.gpsInError !== true)
                this.setState({ gpsInError: true });
        }
        else if (prevState.gpsInError !== false)
            this.setState({ gpsInError: false });

        // if only one range, select it

        //checking dirty and error state of lms
        // console.log("...",this.props.rangeList.length,this.state.rangeChecked.length,this.refs["range_checkbox_" + 0].checked)
        if (this.props.rangeList.length === 1 && this.state.rangeChecked.length === 0 /*&& this.refs["range_checkbox_" + 0].checked === false*/) {
            this.refs["range_checkbox_" + 0].checked = true;
            this.setState({ rangeChecked: [this.props.rangeList[0]] });
        }
        else if (this.state.lmsDirty === true && this.props.rangeList.length === 0) {
            this.setState({ lmsDirty: false });
        }


        if (this.props.rangeList.length > 1 && this.state.rangeChecked.length === 0) {
            if (prevState.lmsInError !== true)
                this.setState({ lmsInError: true });
        }
        else if (this.state.lmsInError) {
            this.setState({ lmsInError: false });
        }

        if (this.state.rangeChecked.length > 0) {
            if (prevState.lmsDirty !== true)
                this.setState({ lmsDirty: true });
        }
        else if (prevState.lmsDirty !== false)
            this.setState({ lmsDirty: false });

    }

    componentWillUnmount() {
        this.props.clearSensors();
        this.props.clearGPS();
        this.props.clearSubmit();
        this.setState({
            flightId: undefined,
            checked: [],
            selectAll: false,
            isContiguousSelection: true,
            startHelperText: '',
            endHelperText: '',
            startInError: false,
            endInError: false,
            startTime: '',
            endTime: '',
            // lowestSensor: undefined,
            baseFolder: undefined,
            //range
            rangeChecked: [],
            lmsInError: false,
            lmsDirty: false,
            isSelected: '',
            selectedSensor: undefined,
            selectedTemplate: undefined,
        });
    }

    resetGPS = () => {
        this.setState({ startTime: '', endTime: '', checked: [], startInError: false, endInError: false })
    }

    resetLMS = () => {
        this.setState({ rangeChecked: [], lmsInError: false, lmsDirty: false })
    }

    onChange = (event) => {
        if (event.currentTarget.value.length < 6 && this.props.sensors && this.props.sensors.length > 0) {
            this.props.clearSensors();
            this.props.clearGPS();
            this.setState({
                // lowestSensor: undefined,
                flightId: undefined,
                isSelected: '',
                selectedSensor: undefined,
                selectedTemplate : undefined,
                checked: [],
                rangeChecked: []
            });
            // this.setState({ isSelected: '', selectedSensor:undefined });
        }
    }

    testInput = (event) => {
        let results = (event.target.value + event.key).match(/\d{1,6}/g);
        if (results === null || results.length != 1) {
            event.preventDefault();
        }
        else if (results[0].length === 6) {
            this.props.fetchSensors(this.state.token, results[0])
            this.props.fetchGPS(this.state.token, results[0])
            this.setState({ flightId: results[0] });
        }
    }

    getProcessingTemplates = () => {
        this.props.fetchUniqueValues(this.props.token, "project", "ProcessingTemplate");
    }

    testing = (flightId) => {
        this.setState({ flightId: flightId })
        this.props.fetchSensors(this.state.token, flightId)
        this.props.fetchGPS(this.state.token, flightId)
    }

    testNumberInput = (event) => {
        if (event.target.value + event.key < 0 || event.target.value + event.key > 604800)
            event.preventDefault();
    }

    validateGpsTime = (event, startOrEnd) => {
        if (startOrEnd === "start") {
            if (this.state.endTime !== '' && parseInt(event.currentTarget.value) >= this.state.endTime)
                this.setState({ startInError: true, startHelperText: "Start time must be < End time", endInError: true, endHelperText: "End time must be > Start time" })
            else
                this.setState({ startInError: false, startHelperText: "", endInError: false, endHelperText: "" })

            this.setState({ startTime: parseInt(event.currentTarget.value) })
        }
        else if (startOrEnd === "end") {
            if (this.state.startTime !== '' && parseInt(event.currentTarget.value) <= this.state.startTime)
                this.setState({ startInError: true, startHelperText: "Start time must be < End time", endInError: true, endHelperText: "End time must be > Start time" })
            else
                this.setState({ startInError: false, startHelperText: "", endInError: false, endHelperText: "" })

            this.setState({ endTime: parseInt(event.currentTarget.value) })
        }
    }

    getSensors = () => {
        // if (this.props.sensorEquipment.length === 0 && !this.props.sensorEquipmentListLoading)
        this.props.fetchSensors(this.props.token);
    }

    tableRowOnClick = (event, sensor) => {
        this.setState({ isSelected: sensor.SensorID, selectedSensor: sensor, selectedTemplate: sensor.Projects[0].ProcessingTemplate });
    }

    resetGPSList = () => {
        this.setState({
            checked: []
            // selectAll: false
        });
        // if (!this.props.isUpdate)
        //     this.setAllChecked();
        // this.getUniqueFileTypes();
    }

    handleToggle = (gpsFile) => {
        const newChecked = [...this.state.checked];
        const currentIndex = this.state.checked.indexOf(gpsFile);
        // let files = [...this.props.files]
        // let selectAll = false;
        if (currentIndex === -1) {
            newChecked.push(gpsFile);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        newChecked.sort((a, b) => (parseInt(a.slice(-3)) > parseInt(b.slice(-3))) ? 1 : ((parseInt(b.slice(-3)) > parseInt(a.slice(-3))) ? -1 : 0));

        // if (files.length === newChecked.length) {
        //     selectAll = true;
        // }

        this.setState({
            checked: newChecked,
            // selectAll: selectAll
        });
        //}, this.checkContiguousFiles());
    };

    checkContiguousFiles = () => {
        // let fileNameArray = this.state.checked.map(x => x.split(".").pop())
        let fileNameArray = this.state.checked.map(x => parseInt(x.slice(-3)));
        // console.log("...fileNameArray",fileNameArray)
        if (fileNameArray.length === 0)
            return true;
        let isContiguous = fileNameArray.every(function (item, index, array) {
            if (index > 0) {
                let currentIntValue = item;
                let prevIntValue = array[index - 1];
                return currentIntValue === prevIntValue + 1;
            }
            return true;
        });
        return isContiguous;
    }

    // setAllChecked = () => {
    //     let files = [...this.props.files];
    //     let newArray = [];
    //     files.forEach((file, index) => {
    //         newArray.push(file);
    //         this.refs["checkbox_" + index].checked = true;
    //     });

    //     this.setState({
    //         checked: newArray,
    //         selectAll: true,
    //         dirty: true
    //     });
    // }

    handleToggleAll = (event) => {
        let newArray = [];
        let gpsList = this.props.gpsList;
        for (var i = 0; i < gpsList.length; i++) {
            newArray.push(gpsList[i])
            // console.log("...gpsList[i]",gpsList[i])
            this.refs["checkbox_" + i].checked = true;
        }

        this.setState({
            checked: newArray,
            // selectAll: event.target.checked
        });
        // }, this.checkContiguousFiles());
    }

    getGPSTimeOfWeek = (date) => {
        //this is relative, so not sure if daylight savings would bugger this up... possibly should use Date.UTC()
        let inputDate = new Date(Date.parse(date));
        let sunday = new Date(inputDate.getTime() - inputDate.getDay() * 24 * 3600 * 1000);
        let newDate = new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate())
        return (inputDate.getTime() - newDate.getTime()) / 1000
    }


    handleAccordianChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleCustomTypeChange = (value) => {

        this.setState({
            customType: value,
            lmsDisabled : value !== 'lidar'
        });
    };

    handleRangeToggle = (rangeFile) => {
        //prevent deselect if only one range file
        if (this.props.rangeList.length == 1)
            return

        const newChecked = [...this.state.rangeChecked];
        const currentIndex = this.state.rangeChecked.indexOf(rangeFile);

        if (currentIndex === -1) {
            newChecked.push(rangeFile);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        newChecked.sort((a, b) => (parseInt(a.slice(-3)) > parseInt(b.slice(-3))) ? 1 : ((parseInt(b.slice(-3)) > parseInt(a.slice(-3))) ? -1 : 0));

        this.setState({
            rangeChecked: newChecked,
            // selectAll: selectAll
        });
        //}, this.checkContiguousFiles());
    };

    handleTemplateChange = event => {
        if (event.target && event.target.value)
            this.setState({ selectedTemplate: event.target.value })
    };

    handleSubmit = () => {

        let gpsData = this.state.gpsDirty ? {
            gpsFiles: this.state.checked,
            gpsStartTime: this.state.startTime,
            gpsEndTime: this.state.endTime,
            sensorId: this.state.isSelected,
            EOOnly: this.state.customType === 'eo' ? true : false
            // sensorId: this.state.lowestSensor.SensorID,
            // sensorName: this.state.lowestSensor.Sensor
        } : null;

        // if (gpsData == null && this.state.customType === 'eo'){
        //     gpsData = {
        //         gpsFiles: this.state.checked,
        //         gpsStartTime: this.state.startTime,
        //         gpsEndTime: this.state.endTime,
        //         sensorId: this.state.isSelected,
        //         EOOnly:  true
        //     }
        // }

        let lmsData = this.state.lmsDirty ? this.state.rangeChecked : null;

        if(this.state.customType === 'eo'){
            // console.log('...',this.state.token, this.state.flightId, this.state.selectedSensor.SensorID, this.state.selectedSensor.Sensor, "RTXEO", gpsData)
            // ... undefined 019474 SL021949 Optech Galaxy Prime - 424 RTXGDA94-LMSRefCal-Mining3 null ['FL019474.range']
            this.props.submitGPSProcessing(this.state.token, this.state.flightId, this.state.selectedSensor.SensorID, this.state.selectedSensor.Sensor, this.state.selectedTemplate.split("-")[0], gpsData, undefined, true)
        }
        else
            this.props.submitGPSProcessing(this.state.token, this.state.flightId, this.state.selectedSensor.SensorID, this.state.selectedSensor.Sensor, this.state.selectedTemplate, gpsData, lmsData, false)
    }

    render() {

        let customType = this.state.customType;

        let { sensorEquipment, aircraft } = this.props;
        sensorEquipment = sensorEquipment || [];
        aircraft = aircraft || [];

        const flightIdColumnStyle = { textAlign: "center", width: 75 };
        const uploadStatusColumnStyle = { textAlign: "center", width: 50 };
        const sensorIdColumnStyle = { textAlign: "center", width: 75 };
        const dateColumnStyle = { textAlign: "center", width: 80 };
        const sensorNameColumnStyle = { textAlign: "center", width: 175 };
        const aircraftColumnStyle = { textAlign: "center", width: 70 };
        const dispatchColumnStyle = { textAlign: "center", width: 50 };
        const headerDivTextStyle = { fontWeight: "bolder", color: "#000000" };

        if (this.props.submitLoading)
            return (
                <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                    <CircularProgress />
                    <div style={{ paddingTop: 5 }}>Submitting Custom Sensor Processing...</div>
                </Grid>
            )

        if (this.props.paramsUpdateLoading)
            return (
                <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                    <CircularProgress />
                    <div style={{ paddingTop: 5 }}>Loading Custom Sensor Processing...</div>
                </Grid>
            )

        return (

            <Grid container direction="column" alignItems="center" justify="center" style={{ width: '850px' }}>

                <Grid container direction="row" alignItems="center" justify="space-between">
                    <div>
                        <span style={{ fontWeight: "bold", fontSize: 14 }}>Enter Flight ID:</span>
                        <TextField
                            autoFocus={true}
                            onKeyPress={event => this.testInput(event)}
                            onChange={event => this.onChange(event)}
                            margin="dense"
                            type="search"
                            InputProps={{
                                style: { fontSize: 12 },
                                startAdornment: <InputAdornment position="start" style={{ marginRight: 0 }}><span style={{ fontSize: 12, marginBottom: 4 }}>FL</span></InputAdornment>
                            }}
                            style={{ paddingLeft: 5, width: 80, marginTop: -1 }}
                        />
                    </div>
                    {/* <Button
                        variant="contained" component="span" color="primary"
                        disabled={true}
                        style={{ width: 135, height: 30, marginBottom: 5 }}>REVIEW LOGS</Button> */}


                </Grid>

                {/* <TextField
                    label="Search field"
                    type="search"
                    // className={classes.textField}
                    margin="normal"
                    variant="filled"
                /> */}
                <Grid style={{ width: '850px', height: '200px', borderStyle: "solid", borderWidth: 1, borderColor: "#CCCCCC", marginBottom: 5 }} >
                    {
                        this.state.flightId
                            ? (this.props.sensorListLoading || this.props.gpsListLoading)
                                ? <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10, height: "100%" }}>
                                    <CircularProgress />
                                    <div style={{ paddingTop: 5 }}>Loading Sensor details...</div>
                                </Grid>
                                : ((this.props.sensors && this.props.sensors.length > 0) && (this.props.gpsList && this.props.gpsList.length > 0))
                                    ? <Paper style={{ height: 200, overflow: "auto" }}>
                                        <Table dense={true} >
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Flight ID</div></TableCell> */}
                                                    <TableCell padding="none" style={uploadStatusColumnStyle}><div style={headerDivTextStyle}>Flight Type</div></TableCell>
                                                    <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Sensor ID</div></TableCell>
                                                    <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Project ID</div></TableCell>
                                                    <TableCell padding="none" style={dateColumnStyle}><div style={headerDivTextStyle}>Survey Date<br />(UTC)</div></TableCell>
                                                    <TableCell padding="none" style={dateColumnStyle}><div style={headerDivTextStyle}>Survey Start<br />Survey End<br />(UTC)</div></TableCell>
                                                    <TableCell padding="none" style={dateColumnStyle}><div style={headerDivTextStyle}>GPS Start<br />GPS End<br />(sec's of week)</div></TableCell>
                                                    <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>Sensor(s)</div></TableCell>
                                                    <TableCell padding="none" style={uploadStatusColumnStyle}><div style={headerDivTextStyle}>Sensor Type(s)</div></TableCell>
                                                    <TableCell padding="none" style={aircraftColumnStyle}><div style={headerDivTextStyle}>Aircraft</div></TableCell>
                                                    <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Processing<br />Template</div></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.props.sensors &&
                                                    this.props.sensors.map(sensor =>
                                                        <TableRow
                                                            hover
                                                            selected={this.state.isSelected === sensor.SensorID}
                                                            onClick={event => this.tableRowOnClick(event, sensor)}
                                                        >
            
                                                            <TableCell padding="none" style={uploadStatusColumnStyle}>
                                                                <FlightTypeTableIcon type={[sensor.Status]} />
                                                            </TableCell>
            
                                                            <TableCell padding="none" style={sensorIdColumnStyle}>
                                                                {sensor.SensorID}
                                                            </TableCell>
            
                                                            <TableCell padding="none" style={sensorIdColumnStyle}>
                                                                <NicerTooltip placement="right"
                                                                    content={
                                                                        <span>
                                                                            <strong>Project Name:</strong>&nbsp;{sensor.Projects[0].ProjectName}<br />
                                                                            <strong>Project Status:</strong>&nbsp;{sensor.Projects[0].ProjectStatus}
                                                                        </span>
                                                                    }>
                                                                    {sensor.ProjectID}
                                                                </NicerTooltip>
                                                            </TableCell>
            
                                                            <TableCell padding="none" style={dateColumnStyle}>
                                                                <span>{sensor.SurveyStart.split("T")[0]}<br /></span>
                                                            </TableCell>
            
                                                            <TableCell padding="none" style={dateColumnStyle}>
                                                                <span>
                                                                    {sensor.SurveyStart.split("T")[1]}<br />
                                                                    {sensor.SurveyEnd.split("T")[1]}
                                                                </span>
                                                            </TableCell>
            
                                                            <TableCell padding="none" style={dateColumnStyle}>
                                                                <span>
                                                                    {this.getGPSTimeOfWeek(sensor.SurveyStart)}<br />
                                                                    {this.getGPSTimeOfWeek(sensor.SurveyEnd)}
                                                                </span>
                                                            </TableCell>
            
                                                            <TableCell padding="none" style={sensorNameColumnStyle}>
                                                                <span>{sensor.Sensor}
                                                                    {
                                                                        sensor.SecondarySensor !== "" &&
                                                                        sensor.SecondarySensor !== "NA" &&
                                                                        sensor.SecondarySensor !== [] &&
                                                                        <span style={{ fontSize: 9, color: "#757575" }}>
                                                                            <br />
                                                                            {sensor.SecondarySensor}
                                                                        </span>
                                                                    }
                                                                </span>
                                                            </TableCell>
            
                                                            <TableCell padding="none" style={uploadStatusColumnStyle}>
                                                                <TableSensorTypeIcon sensorType={[sensor.Type]} />
                                                            </TableCell>
            
                                                            <TableCell padding="none" style={aircraftColumnStyle}>{sensor.Flights[0].Aircraft}</TableCell>
            
                                                            <TableCell padding="none" style={flightIdColumnStyle}><TableEllipsisIcon value={sensor.Projects[0].ProcessingTemplate} /></TableCell>
                                                        </TableRow>
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                    : <Grid container direction="column" style={{ width: "100%", height: "100%", color: "#A6A9A7" }} alignItems="center" justify="center">No valid data available</Grid>
                            : <Grid container direction="column" style={{ width: "100%", height: "100%", color: "#A6A9A7" }} alignItems="center" justify="center">Please enter a Flight ID above</Grid>
                    }
                </Grid>

                {/* <Paper style={{ height: 50 }}> */}
                {/* { this.state.isSelected !== '' &&
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <div><span style={{fontWeight:"bold", fontSize: 14}}>SensorID:</span>{this.state.isSelected}</div>
                        <div><span style={{fontWeight:"bold", fontSize: 14}}>ProjectID:</span>{this.state.selectedSensor.Projects[0].ProjectID}</div>
                        <div><span style={{fontWeight:"bold", fontSize: 14}}>Project Name:</span>{this.state.selectedSensor.Projects[0].ProjectName}</div>
                        <div><span style={{fontWeight:"bold", fontSize: 14}}>Processing Template:</span>{this.state.selectedSensor.Projects[0].ProcessingTemplate}</div>
                    </Grid>
                } */}
                {/* </Paper> */}

                <Grid container direction="row" alignItems="center" justify="center" style={{ width: '850px', height: '150px', borderStyle: "solid", borderWidth: 1, borderColor: "#CCCCCC", marginBottom: 6 }} >
                    {this.state.isSelected === '' && this.state.flightId === undefined &&
                        <span style={{ color: "#A6A9A7" }}>Please enter a Flight ID above and make the relevant Sensor/Project selection</span>
                    }
                    {this.state.isSelected === '' && this.state.flightId !== undefined &&
                        <span style={{ color: "#A6A9A7" }}>Please make the relevant Sensor/Project selection</span>
                    }
                    {this.state.isSelected !== '' &&
                        <Paper style={{ height: 150, width: "100%" }}>
                            <span style={{ fontSize: 14, fontWeight: "bold", textAlign: "center", width: "100%", display: "block", marginBottom: -5, marginTop: 10 }}>Selected Sensor/Project</span>
                            <Table dense={true} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Sensor ID</div></TableCell>
                                        <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Project ID</div></TableCell>
                                        <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>Project Name</div></TableCell>
                                        <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>Sensor(s)</div></TableCell>
                                        <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Processing Template</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.selectedSensor &&
                                        // this.props.sensors.map(sensor =>
                                        <TableRow
                                            hover
                                            selected={this.state.isSelected === this.state.selectedSensor.SensorID}
                                        >

                                            <TableCell padding="none" style={sensorIdColumnStyle}>
                                                {this.state.selectedSensor.SensorID}
                                            </TableCell>

                                            <TableCell padding="none" style={sensorIdColumnStyle}>
                                                <NicerTooltip placement="right"
                                                    content={
                                                        <span>
                                                            <strong>Project Name:</strong>&nbsp;{this.state.selectedSensor.Projects[0].ProjectName}<br />
                                                            <strong>Project Status:</strong>&nbsp;{this.state.selectedSensor.Projects[0].ProjectStatus}
                                                        </span>
                                                    }>
                                                    {this.state.selectedSensor.ProjectID}
                                                </NicerTooltip>
                                            </TableCell>

                                            <TableCell padding="none" style={sensorNameColumnStyle}>
                                                <span>{this.state.selectedSensor.Projects[0].ProjectName}</span>
                                            </TableCell>

                                            <TableCell padding="none" style={sensorNameColumnStyle}>
                                                <span>{this.state.selectedSensor.Sensor}
                                                    {
                                                        this.state.selectedSensor.SecondarySensor !== "" &&
                                                        this.state.selectedSensor.SecondarySensor !== "NA" &&
                                                        this.state.selectedSensor.SecondarySensor !== [] &&
                                                        <span style={{ fontSize: 9, color: "#757575" }}>
                                                            <br />
                                                            {this.state.selectedSensor.SecondarySensor}
                                                        </span>
                                                    }
                                                </span>
                                            </TableCell>

                                            {/* <TableCell padding="none" style={flightIdColumnStyle}><TableEllipsisIcon value={this.state.selectedSensor.Projects[0].ProcessingTemplate} /></TableCell> */}
                                            <TableCell padding="none" style={sensorNameColumnStyle}>
                                                <Select style={{ fontSize: 12 }}
                                                    value={this.state.selectedTemplate}
                                                    onChange={this.handleTemplateChange}
                                                // displayEmpty
                                                // name="age"
                                                // className={classes.selectEmpty}
                                                >
                                                    {
                                                        this.props.processingTemplates !== undefined &&
                                                        this.props.processingTemplates.map((procTemplate) =>
                                                            // procTemplate &&
                                                            <MenuItem value={procTemplate.value}>{procTemplate.label}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                        // )
                                    }
                                </TableBody>
                            </Table>
                            <span style={{ fontSize: 14, textAlign: "center", width: "100%", display: "block", marginTop: 5 }}><strong>NOTE:</strong> The Project's Processing Template is selected, only update if required</span>
                        </Paper>
                    }
                </Grid>


                            <Grid container direction="row" alignItems="center" justify="space-evenly" >
                                {/* <div style={{ marginTop: -9, marginLeft: -15 }}> */}
                                <div>
                                    <Radio
                                        checked={customType === 'lidar'}
                                        value='lidar'
                                        onChange={e => this.handleCustomTypeChange(e.target.value)}
                                    />
                                    <span>LiDAR Processing</span>
                                </div>
                                {/* <div style={{ marginTop: -9, marginLeft: -15 }}> */}
                                <div>
                                    <Radio
                                        checked={customType === 'eo'}
                                        value='eo'
                                        onChange={e => this.handleCustomTypeChange(e.target.value)}
                                    />
                                    <span>Imagery EO Processing Only</span>
                                </div>
                            </Grid>


                <ExpansionPanel expanded={this.state.expanded === 'panel1'} onChange={this.handleAccordianChange('panel1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: 14, fontWeight: 'bold' }}>
                        {/* <SimpleBadge label={"!"} style={{ position: 'absolute', left: 5 }}/> */}
                        <Grid container alignItems="center" justify="center">
                            {
                                this.state.gpsInError &&
                                <PriorityHighIcon style={{ position: 'absolute', left: 15, color: "#FF0000", fontSize: 16 }} />
                            }
                            {
                                !this.state.gpsInError &&
                                this.state.gpsDirty &&
                                <CheckBoxIcon style={{ position: 'absolute', left: 15, color: "#1a3a69" }} />
                            }
                            Custom GPS
                            {
                                this.state.gpsDirty &&
                                <IconButton color="primary"
                                    // disabled={this.isEquivalent(this.state.queryObject, {})}
                                    style={{ position: 'absolute', right: 40 }}
                                    onClick={(event) => { event.stopPropagation(); this.resetGPS(); }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            }
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container direction="column">

                            <Grid container direction="column" alignItems="flex-start" justify="flex-start" style={{ marginTop: 5, marginBottom: 5 }} >

                                <span style={{ fontWeight: "bold", fontSize: 14, marginBottom: 5 }}>Select GPS files for processing:</span>
                                <span style={{ fontWeight: "bold", fontSize: 10, marginBottom: 5 }}>* Required</span>

                                <Grid container direction="row" alignItems="center" justify="center">
                                    <Button
                                        variant="contained" component="span" color="primary"
                                        onClick={event => this.handleToggleAll(event)}
                                        // disabled={!this.props.sensorListLoading && (!this.props.sensors || this.props.sensors.length == 0)}
                                        disabled={this.props.gpsListLoading || (!this.props.gpsList || this.props.gpsList.length == 0)}
                                        style={{ width: 165, height: 30, marginBottom: 5, marginRight: 3 }}>SELECT ALL</Button>
                                    <Button
                                        variant="contained" component="span" color="primary"
                                        onClick={event => this.resetGPSList()}
                                        disabled={!this.props.sensorListLoading && (!this.props.sensors || this.props.sensors.length == 0) || this.state.checked.length === 0}
                                        style={{ width: 165, height: 30, marginBottom: 5, marginLeft: 3 }}>CLEAR</Button>
                                </Grid>


                                <List style={{ width: '800px', height: '200px', overflow: 'auto', borderStyle: "solid", borderWidth: 1, borderColor: "#CCCCCC", marginBottom: 5 }} dense={true}>
                                    {
                                        this.props.gpsListLoading &&
                                        <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10, height: "100%" }}>
                                            <CircularProgress />
                                            <div style={{ paddingTop: 5 }}>Loading GPS files...</div>
                                        </Grid>
                                    }
                                    {
                                        !this.props.gpsListLoading &&
                                        // !this.props.sensorListLoading &&
                                        // (this.props.sensors && this.props.sensors.length > 0) &&
                                        this.props.gpsList &&
                                        this.props.gpsList.map((gpsFile, index) =>
                                            gpsFile &&
                                            // <ListItem>{gpsFile}</ListItem>
                                            <ListItem style={{ height: 30 }}
                                                key={gpsFile}>
                                                <Checkbox
                                                    ref={"checkbox_" + index}
                                                    onChange={(event) => this.handleToggle(gpsFile)}
                                                    checked={this.state.checked.indexOf(gpsFile) !== -1}
                                                />
                                                <ListItemText>
                                                    {/* <Tooltip title={file.webkitRelativePath} placement="right"> */}
                                                    {/* <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", direction: 'rtl' }}> */}
                                                    {gpsFile}
                                                    {/* </div> */}
                                                    {/* </Tooltip> */}
                                                </ListItemText>
                                            </ListItem>
                                        )
                                    }
                                    {
                                        !this.state.flightId && !this.props.sensorListLoading &&
                                        (!this.props.sensors || this.props.sensors.length == 0) &&
                                        <Grid container direction="column" style={{ width: "100%", height: "100%", color: "#A6A9A7" }} alignItems="center" justify="center">Please enter a Flight ID above</Grid>
                                    }
                                    {
                                        this.state.flightId && !this.props.gpsListLoading
                                            && (!this.props.gpsList || this.props.gpsList.length == 0)
                                            && <Grid container direction="column" style={{ width: "100%", height: "100%", color: "#A6A9A7" }} alignItems="center" justify="center">
                                                No GPS data available
                                            </Grid>
                                    }
                                </List>

                                <Grid container direction="row" justify="center" alignItems="center">
                                    <span style={{ color: "#FF0000", fontWeight: "bold", fontSize: 14, display: this.state.isContiguousSelection ? 'none' : 'inline' }}>#NOTE: Some non-contiguous files are selected, there must be no gaps in the selected files</span>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" alignItems="center" justify="flex-start">
                                <Grid conatiner direction="column" alignItems="flex-end" style={{ marginBottom: -17, paddingRight: 30 }}>
                                    <span style={{ fontWeight: "bold", fontSize: 14 }}>Enter GPS Time Range:</span><br />
                                    <span style={{ fontWeight: "bold", fontSize: 10, marginBottom: 5 }}>* Optional</span>
                                </Grid>
                                <Grid>
                                    <TextField
                                        value={this.state.startTime}
                                        label="Start time (seconds of week)"
                                        margin="dense"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 0, max: 604800 },
                                        }}
                                        error={this.state.startInError}
                                        helperText={this.state.startHelperText}
                                        onKeyPress={event => this.testNumberInput(event)}
                                        onChange={event => this.validateGpsTime(event, "start")}
                                        style={{ width: 205, marginTop: -4, marginRight: 30 }}
                                    />
                                    <TextField
                                        value={this.state.endTime}
                                        label="End time (seconds of week)"
                                        margin="dense"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 0, max: 604800 },
                                        }}
                                        error={this.state.endInError}
                                        helperText={this.state.endHelperText}
                                        onKeyPress={event => this.testNumberInput(event)}
                                        onChange={event => this.validateGpsTime(event, "end")}
                                        style={{ width: 200, marginTop: -4 }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel disabled={this.state.lmsDisabled} expanded={this.state.expanded === 'panel2'} onChange={this.handleAccordianChange('panel2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: 14, fontWeight: 'bold' }}>
                        <Grid container alignItems="center" justify="center">
                            {
                                this.state.lmsInError &&
                                <PriorityHighIcon style={{ position: 'absolute', left: 15, color: "#FF0000", fontSize: 16 }} />
                            }
                            {
                                !this.state.lmsInError &&
                                this.state.lmsDirty &&
                                <CheckBoxIcon
                                    onClick={(event) => event.stopPropagation()}
                                    style={{ position: 'absolute', left: 15, color: "#1a3a69" }} />
                            }
                            Custom LMS
                            {
                                this.state.lmsDirty &&
                                <IconButton color="primary"
                                    style={{ position: 'absolute', right: 40 }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (this.props.rangeList.length !== 1) {
                                            this.resetLMS();
                                        }
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            }
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container direction="column" alignItems="flex-start" justify="flex-start" style={{ marginTop: 5, marginBottom: 5 }} >

                            <span style={{ fontWeight: "bold", fontSize: 14, marginBottom: 5 }}>Select LMS Range files for processing:</span>
                            <span style={{ fontWeight: "bold", fontSize: 10, marginBottom: 5 }}>* Required</span>

                            <List style={{ width: '800px', height: '200px', overflow: 'auto', borderStyle: "solid", borderWidth: 1, borderColor: "#CCCCCC", marginBottom: 5 }} dense={true}>
                                {
                                    this.props.gpsListLoading &&
                                    <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10, height: "100%" }}>
                                        <CircularProgress />
                                        <div style={{ paddingTop: 5 }}>Loading Range files...</div>
                                    </Grid>
                                }
                                {
                                    !this.props.gpsListLoading &&
                                    this.props.rangeList &&
                                    this.props.rangeList.map((rangeFile, index) =>
                                        rangeFile &&
                                        <ListItem style={{ height: 30 }}
                                            key={rangeFile}>
                                            <Checkbox
                                                ref={"range_checkbox_" + index}
                                                onChange={(event) => this.handleRangeToggle(rangeFile)}
                                                checked={this.state.rangeChecked.indexOf(rangeFile) !== -1}
                                            />
                                            <ListItemText>
                                                {rangeFile}
                                            </ListItemText>
                                        </ListItem>
                                    )
                                }
                                {
                                    !this.state.flightId && !this.props.sensorListLoading &&
                                    (!this.props.sensors || this.props.sensors.length == 0) &&
                                    <Grid container direction="column" style={{ width: "100%", height: "100%", color: "#A6A9A7" }} alignItems="center" justify="center">Please enter a Flight ID above</Grid>
                                }
                                {
                                    this.state.flightId && !this.props.gpsListLoading
                                        && (!this.props.rangeList || this.props.rangeList.length == 0) 
                                        && <Grid container direction="column" style={{ width: "100%", height: "100%", color: "#A6A9A7" }} alignItems="center" justify="center">
                                            No LMS data available
                                        </Grid>
                                }
                            </List>

                            {/* <Grid container direction="row" justify="center" alignItems="center">
                                    <span style={{ color: "#FF0000", fontWeight: "bold", fontSize: 14, display: this.state.isContiguousSelection ? 'none' : 'inline' }}>#NOTE: Some non-contiguous files are selected, there must be no gaps in the selected files</span>
                                </Grid> */}

                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <Grid container alignItems="flex-end" justify="flex-end">
                    <Button
                        variant="contained" component="span" color="primary"
                        disabled={
                            // !(
                            // !this.state.startInError //&& this.state.startTime !== undefined
                            // && !this.state.endInError //&& this.state.endTime !== undefined
                            // && this.state.isContiguousSelection && this.state.checked.length > 0
                            // )
                            // this.state.gpsInError || this.state.lmsInError || this.state.gpsDirty && this.state.lmsDirty
                            (!this.state.gpsDirty && !this.state.lmsDirty)
                            || (this.state.gpsDirty && this.state.checked.length === 0)
                            || (this.state.gpsInError && this.state.gpsDirty)
                            || this.state.isSelected === ''

                        }
                        onClick={event => {
                            this.handleSubmit()
                        }}
                        style={{ width: 75, height: 30, marginTop: 10 }}>SUBMIT</Button>
                </Grid>

            </Grid >

        );
    }
}


const mapStateToProps = (state) => {
    if (state.sensors) {
        state.sensors.sort((a, b) => (a.SurveyStart > b.SurveyStart) ? 1 : ((b.SurveyStart > a.SurveyStart) ? -1 : 0));
    }

    let gpsList = [];
    let rangeList = [];
    if (state.gps_list !== null)
        state.gps_list.forEach(item => {
            if (RegExp(/.+\.\d{3}/, "g").test(item))
                gpsList.push(item);
            else if (RegExp(/.+\.range/, "g").test(item))
                rangeList.push(item);
        });

    let processingTemplates = [];
    if (state.uniqueValuesFetchSuccess && state.uniqueValuesFetchSuccess["project.ProcessingTemplate"]){
        for(var i=0;i<state.uniqueValuesFetchSuccess["project.ProcessingTemplate"].length;i++){
            let item = state.uniqueValuesFetchSuccess["project.ProcessingTemplate"][i];
            if( item !== "")
                processingTemplates.push({ "label": item, "value": item })
        }
    }

    return {
        token: state.token,
        // processingTemplates: state.uniqueValuesFetchSuccess && state.uniqueValuesFetchSuccess["project.ProcessingTemplate"] ?
            // state.uniqueValuesFetchSuccess["project.ProcessingTemplate"].map(item => { return { "label": item, "value": item } }) : [],
        processingTemplates: processingTemplates,
        sensorListLoading: state.sensorsListLoading,
        sensors: state.sensors,
        gpsListLoading: state.gpsListLoading,
        gpsList: gpsList,//state.gps_list,
        rangeList: rangeList,
        submitSuccess: state.submitCustomGPSProcessingSuccess,
        submitLoading : state.submitCustomGPSProcessingLoading
    };
};

// //this is needed to match thre returned result to properties
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSensors: (token, flightID) => dispatch(sensorsFetchList(token, flightID)),
        clearSensors: () => dispatch(clearSensorsList()),
        fetchGPS: (token, flightID) => dispatch(gpsFetchList(token, flightID)),
        clearGPS: () => dispatch(clearGpsList()),
        clearSubmit: () => dispatch(submitCustomGPSProcessingSuccess(null)),
        fetchUniqueValues: (token, collection, field) => { dispatch(uniqueValuesFetch(token, collection, field)) },
        submitGPSProcessing: (token, flightId, sensorId, sensorName, processingTemplate, gpsData, lmsData, eoOnly) => dispatch(submitCustomGPSProcessing(token, flightId, sensorId, sensorName, processingTemplate, gpsData, lmsData, eoOnly))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomProcessingSensor);