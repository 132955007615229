import { Reducer, AnyAction, Store, Action } from 'redux'
// import { RasterSurfaceClassInfo, RasterSurfaceClassSelection } from './InstructionsPanel'
import { INSTRUCTIONS_ACTION_TYPE } from './instructionsActions'
import { 
    InstructionsHeaderAction,
    AddLidarConfigurationAction,
    AddRasterConfigurationAction,
    AddVectorConfigurationAction,
    Instruction,
    InstructionsHeader,
    LidarConfiguration,
    RasterConfiguration,
    VectorConfiguration
 } from './InstructionsTypes';

//////////////////////////////////
//            Reduces           //
//////////////////////////////////
export const initHeaderState: InstructionsHeader = ({
    projectId: '',
    siteName: '',
    control: false,
    targetHorizontalDatum: '',
    targetVerticalDatum: '',
    tileExtents: '',
    tileNaming: '',
})

export const initState: Instruction = ({
    ...initHeaderState,
    products: [],
    isSubmitting: false,
    isFetching: false,
})

const instructions: Reducer<Instruction> = (prevState = initState, action: AnyAction) => {
    switch(action.type) {
        case INSTRUCTIONS_ACTION_TYPE.ADD_POINTCLOUD_CONFIGURATION: {
            const { type, ...rest } = action as AddLidarConfigurationAction
            return ({
                ...prevState,
                products: [
                    ...prevState.products,
                    rest
                ]
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.ADD_RASTER_CONFIGURATION: {
            const { type, ...rest } = action as AddRasterConfigurationAction
            return ({
                ...prevState,
                products: [
                    ...prevState.products,
                    rest
                ]
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.ADD_VECTOR_CONFIGURATION: {
            const { type, ...rest } = action as AddVectorConfigurationAction
            return ({
                ...prevState,
                products: [
                    ...prevState.products,
                    rest
                ]
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.DELETE_PRODUCT: {
            const { productIndex } = action
            return ({
                ...prevState,
                products: prevState.products.filter((_, index) => index !== productIndex)
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.UPDATE_POINTCLOUD_CONFIGURATION: {
            const { type, productIndex, ...rest } = action
            const newProducts = Object.assign([], prevState.products)
            newProducts.splice(productIndex, 1, rest as LidarConfiguration)
            return ({
                ...prevState,
                products: newProducts
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.UPDATE_RASTER_CONFIGURATION: {
            const { type, productIndex, ...rest } = action
            const newProducts = Object.assign([], prevState.products)
            newProducts.splice(productIndex, 1, rest as RasterConfiguration)
            return ({
                ...prevState,
                products: newProducts
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.UPDATE_VECTOR_CONFIGURATION: {
            const { type, productIndex, ...rest } = action
            const newProducts = Object.assign([], prevState.products)
            newProducts.splice(productIndex, 1, rest as VectorConfiguration)
            return ({
                ...prevState,
                products: newProducts
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.ADD_INSTRUCTIONS_HEADER: {
            const { type, ...rest } = action as InstructionsHeaderAction
            return ({
                ...prevState,
                ...rest
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.CREATE_INSTRUCTION: {
            return ({
                ...prevState,
                isSubmitting: true,
                isSubmitSuccess: undefined
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.CREATE_INSTRUCTION_SUCCEED: {
            return ({
                ...prevState,
                isSubmitting: false,
                isSubmitSuccess: true
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.CREATE_INSTRUCTION_FAILED: {
            return ({
                ...prevState,
                isSubmitting: false,
                isSubmitSuccess: false
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTION:
            return {
                ...prevState,
                isFetching: true,
                isFetchSuccess: undefined
            }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTION_SUCCEED:
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: true
            }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTION_FAILED:
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: false
            }
        case INSTRUCTIONS_ACTION_TYPE.STORE_FETCHED_INSTRUCTION_TO_STORE:
            const { fetchedInstruction } = action
            return {
                // Because handleFetchInstruction support exclude parameters. 
                // Have to initialize all required fields here in case some required fields are excluded when fetching.
                ...initState,
                ...fetchedInstruction,
                controlFileName: fetchedInstruction.control == true 
                                    ? `control_${fetchedInstruction['projectId']}_${fetchedInstruction['siteName']}.txt`
                                    : undefined,
            }
        case INSTRUCTIONS_ACTION_TYPE.UPDATE_INSTRUCTION: {
            return ({
                ...prevState,
                isSubmitting: true,
                isSubmitSuccess: undefined
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.UPDATE_INSTRUCTION_SUCCEED: {
            return ({
                ...prevState,
                isSubmitting: false,
                isSubmitSuccess: true
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.UPDATE_INSTRUCTION_FAILED: {
            return ({
                ...prevState,
                isSubmitting: false,
                isSubmitSuccess: false
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.CLEAR_ALL: {
            return ({ 
                ...Object.assign(initState)
            })
        }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_SITENAME: {
            return {
                ...prevState,
                isFetching: true
            }
        }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_SITENAME_SUCCEED: {
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: true
            }
        }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_SITENAME_FAILED: {
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: false
            }
        }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTIONS_BY_PROJECT_ID: {
            return {
                ...prevState,
                isFetching: true
            }
        }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTIONS_BY_PROJECT_ID_SUCCEED: {
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: true
            }
        }
        case INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTIONS_BY_PROJECT_ID_FAILED: {
            return {
                ...prevState,
                isFetching: false,
                isFetchSuccess: false
            }
        }
        default:
            return prevState
    }
}

export default instructions