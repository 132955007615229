export function aircraftListHasError(state = false, action) {
    switch (action.type) {
        case 'AIRCRAFT_LIST_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function aircraftListLoading(state = false, action) {
    switch (action.type) {
        case 'AIRCRAFT_LIST_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function aircraft(state = null, action) {
    switch (action.type) {
        case 'AIRCRAFT_LIST_SUCCESS':
            return action.aircraft;

        default:
            return state;
    }
}

export function aircraftQueryParams(state = {
    page: 1, pageSize: 10, total: -1//, filter: 'All'
}, action) {
    switch (action.type) {
        case 'AIRCRAFT_QUERY_PARAMS':
            return { ...state, ...action.payload };

        default:
            return state;
    }
}