import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { 
    Grid, LinearProgress,
} from '@material-ui/core'
import NetworkCheck from '@material-ui/icons/NetworkCheck';
import Timelapse from '@material-ui/icons/Timelapse';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import UploadFilesTable from './UploadFilesTable';
import { UploaderStore, UploadTask, OngoingTask } from './UploaderTypes'
import './commonStyles.css'
import './uploaderStyles.css'

//////////////////////////////////
//           Constants          //
//////////////////////////////////


//////////////////////////////////
//           Component          //
//////////////////////////////////
// interface UploadProgressProps {
//     flightId: string;
//     uploadId: string;
//     uploadType: string;         // LiDAR | MediumFormatImagery | LargeFormatImagery
// }

// upload specific functions below here
export const formatBytes = (sizeInByte: number, toFixed: number) => { 
    if (0 === sizeInByte) return "0 Bytes"; 
    let kilo = 1024
    let unitList = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    let unitIndex = Math.floor(Math.log(sizeInByte) / Math.log(kilo)); 
    toFixed = toFixed || 2
    return parseFloat((sizeInByte / Math.pow(kilo, unitIndex)).toFixed(toFixed)) + " " + unitList[unitIndex] 
}

const UploadProgress = (props: UploadProgressFromRedux) => {
    // Props from Redux mapStateToProps
    const { uploaderStore } = props
    // Props from Redux mapDispatchToProps
    // const { } = props
    // Props from parent component
    // const { flightId, uploadId, uploadType } = props

    const extractAllIncompleteUploadTaskList = (): Array<UploadTask | OngoingTask> => {
        let ongoingTaskList: Array<UploadTask> = []
        Object.keys(uploaderStore.ongoingTaskLookup).forEach(ongoingTaskKey => {
            const ongoingTask: OngoingTask = uploaderStore.ongoingTaskLookup[ongoingTaskKey]
            // const { status, percentCompleted, ...uploadTask } = ongoingTask
            ongoingTaskList.push(ongoingTask)
        })
        // Sorted as the original order in uploadTaskList
        ongoingTaskList.sort((firstUploadTask, secondUploadTask) => {
            // Assuming it is less likely to happen. But leave it here to make the logic robust.
            if(firstUploadTask.fileMetadataIndex === secondUploadTask.fileMetadataIndex 
                    && firstUploadTask.chunkIndex === secondUploadTask.chunkIndex) {
                return 0
            }
            if(firstUploadTask.fileMetadataIndex < secondUploadTask.fileMetadataIndex 
                    || firstUploadTask.chunkIndex < secondUploadTask.chunkIndex) {
                return -1
            } else {
                return 1
            }
        })
        return ([
            ...ongoingTaskList,
            ...uploaderStore.uploadTaskList
        ])
    }

    return (
        <>
            {((uploaderStore.status === "UPLOADING"
                    || uploaderStore.status === "UPLOAD_PAUSED") 
                        && uploaderStore.fileMetadataList.length)     // check if it is going to resume from a different tab
                ? <div>
                    <Grid container direction="row" alignItems="center" justify="space-around" 
                        className='frame mt-3 mb-1'
                    >
                        <div className='div--gauge'>
                            <NetworkCheck style={{ color: '#143C65', paddingRight: 8 }} />
                            {formatBytes(uploaderStore.globalUploadProgress.globalSpeed, 2) + "/s"}
                        </div>

                        <div className='div--gauge'>
                            <Timelapse style={{ color: '#143C65', paddingRight: 8 }} />
                            {formatBytes(uploaderStore.globalUploadProgress.completedFileSize, 2)} of {formatBytes(uploaderStore.globalUploadProgress.totalFileSize, 2)}
                        </div>
                        <div className='div--gauge'>
                            <InsertDriveFile style={{ color: '#143C65', paddingRight: 8 }} />
                            {uploaderStore.globalUploadProgress.completedFileCount} of {uploaderStore.globalUploadProgress.totalFileCount} files
                        </div>
                    </Grid>
                    <Grid container direction="row" alignItems="center" justify="center">
                        {Number((uploaderStore.globalUploadProgress.completedFileSize / uploaderStore.globalUploadProgress.totalFileSize * 100).toFixed(2)) || 'N/A'}%
                    </Grid>
                    <LinearProgress
                        variant="determinate"
                        style={{ marginBottom: 8, marginTop: -19, height: 18, zIndex: -999 }}
                        value={Number((uploaderStore.globalUploadProgress.completedFileSize / uploaderStore.globalUploadProgress.totalFileSize * 100).toFixed(2)) || 0}
                        color="secondary" 
                    />
                    <Grid container direction="row" alignItems="center" justify="space-around" 
                        className='frame mt-1 pt-1'
                    >
                        <UploadFilesTable rows={extractAllIncompleteUploadTaskList()} />
                    </Grid>
                </div>
                : null
            }
        </>
    )
}

const mapStateToProps = (rootState: any) => ({
    // authToken: rootState.token,
    uploaderStore: rootState.uploader as UploaderStore
})

const mapDispatchToProps = { }

const connector = connect(mapStateToProps, mapDispatchToProps)

type UploadProgressFromRedux = ConnectedProps<typeof connector>

const ConnectedUploadProgress = connector(UploadProgress)

export default ConnectedUploadProgress