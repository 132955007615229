import axios from 'axios';
import Config from '../app/Config.json'

const BASE_URI = Config.BASE_URI

export function addStatusValue(value) {
    return {
        type: 'ADD_COMPOSITE_FILTER_STATUS',
        value
    }
}

export function removeStatusValue(value) {
    return {
        type: 'REMOVE_COMPOSITE_FILTER_STATUS',
        value
    }
}

export function removeAllStatusValues(value) {
    return {
        type: 'REMOVE_ALL_COMPOSITE_FILTER_STATUS',
        value
    }
}

export function clearAllFilters(value) {
    return {
        type: 'CLEAR_ALL_FILTERS',
        value
    }
}

export function uniqueValuesFetch(token,collection,field) {
    return (dispatch) => {
        dispatch(uniqueValuesFetchLoading(true));

        axios.get(
            BASE_URI + 'unique/'+collection+'/'+field,
            {
                headers: { authorization: 'Bearer ' + token }
            }
        )
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(uniqueValuesFetchLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(uniqueValuesFetchSuccess(response.data))
            })
            .catch(() => {
                dispatch(uniqueValuesFetchLoading(false));
                dispatch(uniqueValuesFetchHasError(true));
            });
    };
}


export function uniqueValuesFetchHasError(bool) {
    return {
        type: 'UNIQUE_VALUES_FETCH_HAS_ERROR',
        hasError: bool
    };
}

export function uniqueValuesFetchLoading(bool) {
    return {
        type: 'UNIQUE_VALUES_FETCH_LOADING',
        isLoading: bool
    };
}

export function uniqueValuesFetchSuccess(uniqueValues) {
    return {
        type: 'UNIQUE_VALUES_FETCH_SUCCESS',
        uniqueValues
    };
}