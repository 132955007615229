import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
// import { createStore } from 'redux';
// import configureStore from './app/ConfigureStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import Logger from './LoggerService';
// import UploadStatusReducer from './reducers/UploadStatusReducer'
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from "./auth/authConfig";

// const store = createStore(UploadStatusReducer);
// Logger.init();

// const store = configureStore();
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './app/Reducers';

const store = configureStore({ reducer: rootReducer })
export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            </BrowserRouter>
        </Provider>, 
    document.getElementById('root')
);