import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import AccessTime from '@material-ui/icons/AccessTime';
// Import other icons you need
import ArchiveIcon from '@material-ui/icons/Archive';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import NicerTooltip from '../common/NicerTooltip'
import { Tooltip, IconButton, Menu, MenuItem, ListItemIcon, Popper, Paper, ClickAwayListener, Grow, MenuList, Fade } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import NotInterested from '@material-ui/icons/NotInterested'
import BlockIcon from '@material-ui/icons/Block';
import Restore from '@material-ui/icons/Restore';
import FileCopy from '@material-ui/icons/FileCopy'
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import HourglassFullTwoTone from '@material-ui/icons/HourglassFullTwoTone'
import FindInPage from '@material-ui/icons/FindInPage'
import Config from '../app/Config.json'
import ConfirmationDialog from '../common/ConfirmationDialog'
import QALogDialog from '../common/QALogDialog';
import { connect } from 'react-redux';
import { loginRequest, requiredGroups, guestGroups, managerGroup } from '../auth/authConfig'
import {
  updateQaWithErrorsData,
  updateQaData,
  updateIgnoreData,
  updateManuallyProcessedData,
  updateMarkSuperseded,
  updateResubmit,
  updateCancelJob
} from "./TMProcessingActions";
import humanizeDuration from 'humanize-duration';

import {
  ManuallyProcessed, SupersededComplete, SupersededFailed, SupersededManuallyProcessed,
  SupersededQueued, SupersededIgnore, CompletedNoQA, CompletedIssuesNoQA, FailedNoQA, Archived
} from '../icons/svgIcons'
// import { stringify } from "querystring";
// import color from "@material-ui/core/colors/amber";

const actionForCancelJob = (props) => {
  console.log('actionForCancelJob status triggered')
  let jsonDoc = {};
  const requestData = {
    ...jsonDoc,
    process_id: props.process.guid,
    cluster_output: props.cluster.output,
  };

  props.updateCancelJob(requestData);
  props.blockRow();
}

const actionForS3CopyLink = (process, cluster) => {
  console.log('actionForS3CopyLink for COMPLETED status triggered');

  // Construct the S3 link
  const s3Link = `${Config.TM_BUCKET}/${process.guid}/${cluster.output}`;

  navigator.clipboard.writeText(s3Link).then(function () {
  }).catch(function (err) {
    console.error('Failed to copy S3 Link: ', err);
  });
};

const actionForS3CopyArchiveLink = (process) => {
  // Construct the S3 archive link
  const s3ArchiveLink = process.archiveS3PathLiDAR || '';

  navigator.clipboard.writeText(s3ArchiveLink).then(function () {
  }).catch(function (err) {
    console.error('Failed to copy S3 archive link: ', err);
  });
};

const actionForS3rBrowseArchiveFiles = (process) => {
  // Construct the S3 archive link
  const s3ArchiveLink = process.archiveS3PathLiDAR || '';

  let archiveS3PathSplited = s3ArchiveLink.split('/')
  let archiveBucket = archiveS3PathSplited[0]
  let archivePrefix = archiveS3PathSplited.slice(1).join('/') + (s3ArchiveLink.endsWith('/') ? '' : '/')
  let uri = encodeURI(`/explorer?bucket=${archiveBucket}&prefix=${archivePrefix}`)
  window.open(uri)
};

const actionForResubmit = (setShowDialog, props) => {
  console.log('Resubmit status triggered');
  setShowDialog(true);
  props.blockRow();
};

const disableByGroup = (props) => {
  let tokenPayload = props.token ? JSON.parse(atob(props.token.split(".")[1])) : ''
  let groups = tokenPayload.groups;

  let isPresent = groups.some(element => managerGroup.includes(element));

  return !isPresent;
}

const actionForManuallyProcessed = (props) => {
  console.log('Manually Processed status triggered');
  // Add your logic for this action
  let jsonDoc = {};
  jsonDoc["ManuallyProcessed"] = true;
  const requestData = {
    ...jsonDoc,
    process_id: props.process.guid,
    cluster_name: props.cluster.output,
  };

  props.updateManuallyProcessedData(requestData);
  props.blockRow();
};

const actionForIgnoreProcessing = (props) => {
  console.log('Ignore Processing status triggered');
  // Add your logic for this action
  let jsonDoc = {};
  jsonDoc["Ignore"] = true;
  const requestData = {
    ...jsonDoc,
    process_id: props.process.guid,
    cluster_name: props.cluster.output,
  };

  props.updateIgnoreData(requestData);
  props.blockRow();
};
const actionForMarkQA = (props) => {
  console.log('Mark QA Completed status triggered');
  let jsonDoc = {};
  let tokenPayload = props.token ? JSON.parse(atob(props.token.split(".")[1])) : ''
  let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''
  const requestData = {
    ...jsonDoc,
    process_id: props.process.guid,
    cluster_name: props.cluster.output,
    user: user
  };
  props.updateQaData(requestData);
  props.blockRow();
};

const actionForMarkSuperseded = (props) => {
  console.log('Mark Superseded status triggered');
  let jsonDoc = {};
  jsonDoc["Active"] = false;
  const requestData = {
    ...jsonDoc,
    process_id: props.process.guid,
    cluster_name: props.cluster.output,
  };
  props.updateMarkSuperseded(requestData);
  props.blockRow();
};

const actionForMarkUtilised = (props) => {
  console.log('Mark Utilised status triggered');
  let jsonDoc = {};
  jsonDoc["Active"] = true;
  const requestData = {
    ...jsonDoc,
    process_id: props.process.guid,
    cluster_name: props.cluster.output,
  };
  props.updateMarkSuperseded(requestData);
  props.blockRow();
};

const actionForMarkQACompletedWithErrors = (setShowDialog, props) => {
  console.log('Mark QA Completed (with Errors) status triggered');
  setShowDialog(true);
  props.blockRow();
};


const ReusableMenuItem = ({ handleClose, label, IconComponent, action, disabled=false }) => {
  let disabledValue = true;
  if (typeof disabled === 'function') {
    // If myProp is a function, call it and use its return value
    disabledValue = disabled();
  } else {
    // If myProp is not a function, use it directly (assuming it's a boolean)
    disabledValue = disabled;
  }
  return(
  <MenuItem
    onClick={() => {
      action();
      handleClose();
    }}
    disabled={disabledValue}
  >
    <ListItemIcon >
      <IconComponent style={{ fontSize: 19, color: '#1a3a69' }} />
    </ListItemIcon>
    <span style={{ fontSize: 12 }}>{label}</span>
  </MenuItem>
  )
};

const formatTimezoneOffset = (offset) => {
  let symbol = (offset > 0) ? '-' : '+'
  let hours = Math.floor(Math.abs(offset) / 60)
  let minutes = Math.round(Math.abs(offset) % 60)
  return 'UTC' + symbol + hours + ":" + minutes.toString().padStart(2, '0')
}

const getCompletedDatetime = (cluster) => {
  if (typeof cluster.Completed === 'undefined') {
    return null;
  }
  let formatedDatetime = (new Date(cluster.Completed.$date)).toLocaleString('sv-SE')
  return formatedDatetime + ` (${formatTimezoneOffset((new Date(cluster.Completed.$date)).getTimezoneOffset())})`
}

const getStatusConfig = (props, setShowQALogDialog, setShowConfirmDialog) => {

  let qaRequiredCompletedOverride = null;
  if (props.cluster.allowRequireQA === true && typeof props.cluster.QAUser === 'undefined') {

    qaRequiredCompletedOverride = CompletedNoQA; // Override with the specific icon you want
  }

  let qaRequiredFailedOverride = null;
  if (props.cluster.allowRequireQA === true && typeof props.cluster.QAUser === 'undefined') {
    qaRequiredFailedOverride = FailedNoQA; // Override with the specific icon you want
  }

  let qaRequiredCompletedFailuresOverride = null;
  if (props.cluster.allowRequireQA === true && typeof props.cluster.QAUser === 'undefined') {
    qaRequiredCompletedFailuresOverride = CompletedIssuesNoQA; // Override with the specific icon you want
  }
  let baseConfig = {
    QUEUED: {
      Icon: AccessTime,
      style: { color: '#1a3a69' },
      tooltip: 'Queued',
      menuItems: () => null,
    },
    SUPERSEDED: {
      Icon: AccessTime,
      style: { color: '#1a3a69' },
      tooltip: 'Superseded',
      menuItems: () => null,
    },
    QUEUING: {
      Icon: AccessTime,
      style: { color: '#1a3a69' },
      tooltip: 'Queuing',
      menuItems: () => null,
    },
    PREQUEUE: {
      Icon: AccessTime,
      style: { color: '#1a3a69' },
      tooltip: 'Pre Queue',
      menuItems: () => null,
    },
    ARCHIVED: {
      Icon: Archived,
      style: { color: '#1a3a69' },
      tooltip: 'Archived',
      menuItems: () => null,
    },
    RUNNING: {
      Icon: CircularProgress,
      style: { color: '#1a3a69', marginTop: '2px' },
      extraProps: { size: 19,  },
      tooltip: (props) => {
        let duration = null;
        if (typeof props.cluster.Submitted !== 'undefined') {
          duration = humanizeDuration(Math.abs(Date.now() - Date.parse(props.cluster.Submitted.$date)))
        }
        return (
          <span>
            <span>Running</span><br />
            {duration ? <><span>Duration: {duration}</span><br /></> : null}
          </span>
        )
      },
      menuItems: (handleClose) => {
        const itemsConfig = [
          { label: 'Cancel Job', IconComponent: CancelOutlined, action: () => actionForCancelJob(props) },
        ];


        return itemsConfig.map((item, index) => (
          <ReusableMenuItem
            key={index}
            handleClose={handleClose}
            label={item.label}
            IconComponent={item.IconComponent}
            action={item.action}
          />
        ));
      }
    },
    FAILED: {
      Icon: qaRequiredFailedOverride || ErrorOutline,
      style: { color: "#FF0000" },
      tooltip: (props) => {
        const completed_time = getCompletedDatetime(props.cluster);

        const total_successes = props.cluster.total_successes;
        const total_failures = props.cluster.total_failures;
        const total_numbers = props.cluster.total_numbers;

        let duration = null;
        if ((typeof props.cluster.Submitted !== 'undefined') && (typeof props.cluster.Completed !== 'undefined')) {
          duration = humanizeDuration(Math.abs(Date.parse(props.cluster.Completed.$date) - Date.parse(props.cluster.Submitted.$date)))
        }
        return (
          <span>
            {isArchived ? <span>Archived<br /></span> : ''}
            <span>Failed</span><br />
            {qaRequiredFailedOverride !== null && (
              <>
                <span><b>QA Required</b></span><br />
              </>
            )}
            {duration ? <><span>Duration: {duration}</span><br /></> : null}
            {completed_time ? <><span>Completed Date: {completed_time}</span><br /></> : null}

            {total_successes ? <><span>Total Successful: {total_successes}</span><br /></> : null}
            {total_failures ? <><span>Total Failed: {total_failures}</span><br /></> : null}
            {total_numbers ? <><span>Total Number: {total_numbers}</span><br /></> : null}

            <span style={{ fontStyle: 'italic' }}>Click for options</span>
          </span>
        );
      },
      menuItems: (handleClose) => {
        const itemsConfig = [
          { label: 'S3 Copy Link', IconComponent: FileCopy, action: () => actionForS3CopyLink(props.process, props.cluster) },
          { label: 'Resubmit Job', IconComponent: Restore, action: () => actionForResubmit(setShowConfirmDialog, props), disabled: () => disableByGroup(props)},
        ];

        if (typeof props.cluster.allowRequireQA !== 'undefined') {
          if (props.cluster.allowRequireQA === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as QA Completed', IconComponent: CheckCircleOutline, action: () => actionForMarkQA(props) },
              { label: 'Mark as QA Completed (with Errors)', IconComponent: CheckCircleOutline, action: () => actionForMarkQACompletedWithErrors(setShowQALogDialog, props) }
            );
          }
        }

        if (typeof props.cluster.allowManualProcessed !== 'undefined') {
          if (props.cluster.allowManualProcessed === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Manually Processed', IconComponent: ManuallyProcessed, action: () => actionForManuallyProcessed(props) },
            );
          }
        }

        if (typeof props.cluster.allowIgnoreProcessing !== 'undefined') {
          if (props.cluster.allowIgnoreProcessing === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Ignore Processing', IconComponent: NotInterested, action: () => actionForIgnoreProcessing(props) },
            );
          }
        }

        if ((typeof props.cluster.allowSuperseded !== 'undefined') && (typeof props.process.Active !== 'undefined')) {
          if (props.cluster.allowSuperseded && props.process.Active) {
            itemsConfig.push(
              { label: 'Mark as Superseded', IconComponent: SupersededQueued, action: () => actionForMarkSuperseded(props) }
            );
          }
        }

        return itemsConfig.map((item, index) => (
          <ReusableMenuItem
            key={index}
            handleClose={handleClose}
            label={item.label}
            IconComponent={item.IconComponent}
            action={item.action}
            disabled={item.disabled ? item.disabled : false}
          />
        ));
      }
    },
    CANCELLED: {
      Icon: qaRequiredFailedOverride || CancelOutlined,
      style: { color: "#FF0000" },
      tooltip: (props) => {
        const completed_time = getCompletedDatetime(props.cluster);

        const total_successes = props.cluster.total_successes;
        const total_failures = props.cluster.total_failures;
        const total_numbers = props.cluster.total_numbers;

        let duration = null;
        if ((typeof props.cluster.Submitted !== 'undefined') && (typeof props.cluster.Completed !== 'undefined')) {
          duration = humanizeDuration(Math.abs(Date.parse(props.cluster.Completed.$date) - Date.parse(props.cluster.Submitted.$date)))
        }
        return (
          <span>
            {isArchived ? <span>Archived<br /></span> : ''}
            <span>Cancelled</span><br />
            {qaRequiredFailedOverride !== null && (
              <>
                <span><b>QA Required</b></span><br />
              </>
            )}
            {duration ? <><span>Duration: {duration}</span><br /></> : null}
            {completed_time ? <><span>Completed Date: {completed_time}</span><br /></> : null}

            {total_successes ? <><span>Total Successful: {total_successes}</span><br /></> : null}
            {total_failures ? <><span>Total Failed: {total_failures}</span><br /></> : null}
            {total_numbers ? <><span>Total Number: {total_numbers}</span><br /></> : null}

            <span style={{ fontStyle: 'italic' }}>Click for options</span>
          </span>
        );
      },
      menuItems: (handleClose) => {
        const itemsConfig = [
          { label: 'S3 Copy Link', IconComponent: FileCopy, action: () => actionForS3CopyLink(props.process, props.cluster) },
          { label: 'Resubmit Job', IconComponent: Restore, action: () => actionForResubmit(setShowConfirmDialog, props), disabled: () => disableByGroup(props)},
        ];

        if (typeof props.cluster.allowRequireQA !== 'undefined') {
          if (props.cluster.allowRequireQA === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as QA Completed', IconComponent: CheckCircleOutline, action: () => actionForMarkQA(props) },
              { label: 'Mark as QA Completed (with Errors)', IconComponent: CheckCircleOutline, action: () => actionForMarkQACompletedWithErrors(setShowQALogDialog, props) }
            );
          }
        }

        if (typeof props.cluster.allowManualProcessed !== 'undefined') {
          if (props.cluster.allowManualProcessed === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Manually Processed', IconComponent: ManuallyProcessed, action: () => actionForManuallyProcessed(props) },
            );
          }
        }

        if (typeof props.cluster.allowIgnoreProcessing !== 'undefined') {
          if (props.cluster.allowIgnoreProcessing === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Ignore Processing', IconComponent: NotInterested, action: () => actionForIgnoreProcessing(props) },
            );
          }
        }

        if ((typeof props.cluster.allowSuperseded !== 'undefined') && (typeof props.process.Active !== 'undefined')) {
          if (props.cluster.allowSuperseded && props.process.Active) {
            itemsConfig.push(
              { label: 'Mark as Superseded', IconComponent: SupersededQueued, action: () => actionForMarkSuperseded(props) }
            );
          }
        }

        return itemsConfig.map((item, index) => (
          <ReusableMenuItem
            key={index}
            handleClose={handleClose}
            label={item.label}
            IconComponent={item.IconComponent}
            action={item.action}
            disabled={item.disabled ? item.disabled : false}
          />
        ));
      }
    },
    COMPLETED: {
      Icon: qaRequiredCompletedOverride || CheckCircleOutline,
      style: { color: '#006600' },
      tooltip: (props) => {
        const completed_time = getCompletedDatetime(props.cluster);

        const total_successes = props.cluster.total_successes;
        const total_failures = props.cluster.total_failures;
        const total_numbers = props.cluster.total_numbers;

        let duration = null;
        if ((typeof props.cluster.Submitted !== 'undefined') && (typeof props.cluster.Completed !== 'undefined')) {
          duration = humanizeDuration(Math.abs(Date.parse(props.cluster.Completed.$date) - Date.parse(props.cluster.Submitted.$date)))
        }
        return (
          <span>
            {isArchived ? <span>Archived<br /></span> : ''}
            <span>Completed</span><br />
            {qaRequiredCompletedOverride ? (
              <>
                <span><b>QA Required</b></span><br />
              </>
            ) : null}
            {duration ? <><span>Duration: {duration}</span><br /></> : null}
            {completed_time ? <><span>Completed Date: {completed_time}</span><br /></> : null}

            {total_successes ? <><span>Total Successful: {total_successes}</span><br /></> : null}
            {total_failures ? <><span>Total Failed: {total_failures}</span><br /></> : null}
            {total_numbers ? <><span>Total Number: {total_numbers}</span><br /></> : null}
            
            <span style={{ fontStyle: 'italic' }}>Click for options</span>
          </span>
        );
      },
      menuItems: (handleClose) => {
        const itemsConfig = [
          { label: 'S3 Copy Link', IconComponent: FileCopy, action: () => actionForS3CopyLink(props.process, props.cluster) },
          { label: 'Resubmit Job', IconComponent: Restore, action: () => actionForResubmit(setShowConfirmDialog, props), disabled: () => disableByGroup(props) },
        ];

        // Check if the variable exists and its value
        if (typeof props.cluster.allowRequireQA !== 'undefined') {
          if (props.cluster.allowRequireQA === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as QA Completed', IconComponent: CheckCircleOutline, action: () => actionForMarkQA(props) },
              { label: 'Mark as QA Completed (with Errors)', IconComponent: CheckCircleOutline, action: () => actionForMarkQACompletedWithErrors(setShowQALogDialog, props) }
            );
          }
        }

        if (typeof props.cluster.allowManualProcessed !== 'undefined') {
          if (props.cluster.allowManualProcessed === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Manually Processed', IconComponent: ManuallyProcessed, action: () => actionForManuallyProcessed(props) },
            );
          }
        }

        if (typeof props.cluster.allowIgnoreProcessing !== 'undefined') {
          if (props.cluster.allowIgnoreProcessing === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Ignore Processing', IconComponent: NotInterested, action: () => actionForIgnoreProcessing(props) },
            );
          }
        }

        if ((typeof props.cluster.allowSuperseded !== 'undefined') && (typeof props.process.Active !== 'undefined')) {
          if (props.cluster.allowSuperseded && props.process.Active) {
            itemsConfig.push(
              {
                label: 'Mark as Superseded', IconComponent: SupersededQueued, action: () => actionForMarkSuperseded(props)
              })
          }
        }

        return itemsConfig.map((item, index) => (
          <ReusableMenuItem
            key={index}
            handleClose={handleClose}
            label={item.label}
            IconComponent={item.IconComponent}
            action={item.action}
            disabled={item.disabled ? item.disabled : false}
          />
        ));
      }
    },
    COMPLETED_WITH_ERRORS: {
      Icon: qaRequiredCompletedFailuresOverride || CheckCircleOutline,
      style: { color: '#ff6100' },
      tooltip: (props) => {
        const completed_time = getCompletedDatetime(props.cluster);

        const total_successes = props.cluster.total_successes;
        const total_failures = props.cluster.total_failures;
        const total_numbers = props.cluster.total_numbers;

        let duration = null;
        if ((typeof props.cluster.Submitted !== 'undefined') && (typeof props.cluster.Completed !== 'undefined')) {
          duration = humanizeDuration(Math.abs(Date.parse(props.cluster.Completed.$date) - Date.parse(props.cluster.Submitted.$date)))
        }

        return (
          <span>
            {isArchived ? <span>Archived<br /></span> : ''}
            <span>Completed with failures</span><br />
            {qaRequiredCompletedFailuresOverride !== null && (
              <>
                <span><b>QA Required</b></span><br />
              </>
            )}
            {duration ? <><span>Duration: {duration}</span><br /></> : null}
            {completed_time ? <><span>Completed Date: {completed_time}</span><br /></> : null}

            {total_successes ? <><span>Total Successful: {total_successes}</span><br /></> : null}
            {total_failures ? <><span>Total Failed: {total_failures}</span><br /></> : null}
            {total_numbers ? <><span>Total Number: {total_numbers}</span><br /></> : null}

            <span style={{ fontStyle: 'italic' }}>Click for options</span>
          </span>
        );
      },
      menuItems: (handleClose) => {

        const itemsConfig = [
          { label: 'S3 Copy Link', IconComponent: FileCopy, action: () => actionForS3CopyLink(props.process, props.cluster) },
          { label: 'Resubmit Job', IconComponent: Restore, action: () => actionForResubmit(setShowConfirmDialog, props), disabled: () => disableByGroup(props) },
        ];

        if (typeof props.cluster.allowRequireQA !== 'undefined') {
          if (props.cluster.allowRequireQA === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as QA Completed', IconComponent: CheckCircleOutline, action: () => actionForMarkQA(props) },
              { label: 'Mark as QA Completed (with Errors)', IconComponent: CheckCircleOutline, action: () => actionForMarkQACompletedWithErrors(setShowQALogDialog, props) }
            );
          }
        }

        if (typeof props.cluster.allowManualProcessed !== 'undefined') {
          if (props.cluster.allowManualProcessed === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Manually Processed', IconComponent: ManuallyProcessed, action: () => actionForManuallyProcessed(props) },
            );
          }
        }

        if (typeof props.cluster.allowIgnoreProcessing !== 'undefined') {
          if (props.cluster.allowIgnoreProcessing === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Ignore Processing', IconComponent: NotInterested, action: () => actionForIgnoreProcessing(props) },
            );
          }
        }

        if ((typeof props.cluster.allowSuperseded !== 'undefined') && (typeof props.process.Active !== 'undefined')) {
          if (props.cluster.allowSuperseded && props.process.Active) {
            itemsConfig.push(
              {
                label: 'Mark as Superseded', IconComponent: SupersededQueued, action: () => actionForMarkSuperseded(props)
              })
          }
        }

        return itemsConfig.map((item, index) => (
          <ReusableMenuItem
            key={index}
            handleClose={handleClose}
            label={item.label}
            IconComponent={item.IconComponent}
            action={item.action}
            disabled={item.disabled ? item.disabled : false}
          />
        ));
      }
    },
    ON_HOLD: {
      Icon: PauseCircleOutlineIcon,
      style: { color: '#a3a3a3' },
      tooltip: 'On Hold',
      menuItems: () => null,
    },
    NOT_COMMENCED: {
      Icon: AccessTime,
      style: { color: '#a3a3a3' },
      tooltip: 'Not Commenced',
      menuItems: () => null,
    },
    NOT_APPLICABLE: {
      Icon: BlockIcon,
      style: { color: '#a3a3a3' },
      tooltip: "Not Applicable",
      menuItems: () => null,
    },
    QA_COMPLETED_WITH_ERRORS:
    {
      Icon: qaRequiredCompletedFailuresOverride || CheckCircleOutline,
      style: { color: '#ff6100' },
      tooltip: (props) => {
        const completed_time = getCompletedDatetime(props.cluster);

        const total_successes = props.cluster.total_successes;
        const total_failures = props.cluster.total_failures;
        const total_numbers = props.cluster.total_numbers;

        const qa_user_text = 'QA by ' + (props.cluster.QAUser || 'Unknown user');
        const qa_issue_text = "QA Issue: " + (props.cluster.QAIssueDesc || '');
        const qa_resolution_text = "QA Resolution: " + (props.cluster.QAIssueRes || '')

        let duration = null;
        if ((typeof props.cluster.Submitted !== 'undefined') && (typeof props.cluster.Completed !== 'undefined')) {
          duration = humanizeDuration(Math.abs(Date.parse(props.cluster.Completed.$date) - Date.parse(props.cluster.Submitted.$date)))
        }

        return <span>
          {isArchived ? <span>Archived<br /></span> : ''}
          <span>QA Completed (with errors)</span><br />
          <span>{qa_user_text}</span><br />
          <span>{qa_issue_text}</span><br />
          <span>{qa_resolution_text}</span><br />
          {duration ? <><span>Duration: {duration}</span><br /></> : null}
          {completed_time ? <><span>Completed Date: {completed_time}</span><br /></> : null}

          {total_successes ? <><span>Total Successful: {total_successes}</span><br /></> : null}
          {total_failures ? <><span>Total Failed: {total_failures}</span><br /></> : null}
          {total_numbers ? <><span>Total Number: {total_numbers}</span><br /></> : null}

          <span style={{ fontStyle: 'italic' }}>Click for options</span>
        </span>
      },

      menuItems: (handleClose) => {

        const itemsConfig = [
          { label: 'S3 Copy Link', IconComponent: FileCopy, action: () => actionForS3CopyLink(props.process, props.cluster) },
          { label: 'Resubmit Job', IconComponent: Restore, action: () => actionForResubmit(setShowConfirmDialog, props), disabled: () => disableByGroup(props) },
        ];

        if (typeof props.cluster.allowManualProcessed !== 'undefined') {
          if (props.cluster.allowManualProcessed === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Manually Processed', IconComponent: ManuallyProcessed, action: () => actionForManuallyProcessed(props) },
            );
          }
        }

        if (typeof props.cluster.allowIgnoreProcessing !== 'undefined') {
          if (props.cluster.allowIgnoreProcessing === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Ignore Processing', IconComponent: NotInterested, action: () => actionForIgnoreProcessing(props) },
            );
          }
        }

        if ((typeof props.cluster.allowSuperseded !== 'undefined') && (typeof props.process.Active !== 'undefined')) {
          if (props.cluster.allowSuperseded && props.process.Active) {
            itemsConfig.push(
              {
                label: 'Mark as Superseded', IconComponent: SupersededQueued, action: () => actionForMarkSuperseded(props)
              })
          }
        }

        return itemsConfig.map((item, index) => (
          <ReusableMenuItem
            key={index}
            handleClose={handleClose}
            label={item.label}
            IconComponent={item.IconComponent}
            action={item.action}
            disabled={item.disabled ? item.disabled : false}
          />
        ));
      }
    },
    QA_COMPLETED:
    {
      Icon: qaRequiredCompletedOverride || CheckCircleOutline,
      style: { color: '#006600' },
      tooltip: (props) => {
        const completed_time = getCompletedDatetime(props.cluster);

        const total_successes = props.cluster.total_successes;
        const total_failures = props.cluster.total_failures;
        const total_numbers = props.cluster.total_numbers;

        const qa_user_text = 'QA by ' + (props.cluster.QAUser || 'Unknown user');
        let duration = null;

        if ((typeof props.cluster.Submitted !== 'undefined') && (typeof props.cluster.Completed !== 'undefined')) {
          duration = humanizeDuration(Math.abs(Date.parse(props.cluster.Completed.$date) - Date.parse(props.cluster.Submitted.$date)))
        }
        return <span>
          {isArchived ? <span>Archived<br /></span> : ''}
          <span>QA Completed</span><br />
          <span>{qa_user_text}</span><br />
          {duration ? <><span>Duration: {duration}</span><br /></> : null}
          {completed_time ? <><span>Completed Date: {completed_time}</span><br /></> : null}

          {total_successes ? <><span>Total Successful: {total_successes}</span><br /></> : null}
          {total_failures ? <><span>Total Failed: {total_failures}</span><br /></> : null}
          {total_numbers ? <><span>Total Number: {total_numbers}</span><br /></> : null}

          <span style={{ fontStyle: 'italic' }}>Click for options</span>
        </span>
      },

      menuItems: (handleClose) => {

        const itemsConfig = [
          { label: 'S3 Copy Link', IconComponent: FileCopy, action: () => actionForS3CopyLink(props.process, props.cluster) },
          { label: 'Resubmit Job', IconComponent: Restore, action: () => actionForResubmit(setShowConfirmDialog, props), disabled: () => disableByGroup(props) },
        ];

        if (typeof props.cluster.allowManualProcessed !== 'undefined') {
          if (props.cluster.allowManualProcessed === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Manually Processed', IconComponent: ManuallyProcessed, action: () => actionForManuallyProcessed(props) },
            );
          }
        }

        if (typeof props.cluster.allowIgnoreProcessing !== 'undefined') {
          if (props.cluster.allowIgnoreProcessing === true) {  // specifically checking for true
            itemsConfig.push(
              { label: 'Mark as Ignore Processing', IconComponent: NotInterested, action: () => actionForIgnoreProcessing(props) },
            );
          }
        }

        if ((typeof props.cluster.allowSuperseded !== 'undefined') && (typeof props.process.Active !== 'undefined')) {
          if (props.cluster.allowSuperseded && props.process.Active) {
            itemsConfig.push(
              {
                label: 'Mark as Superseded', IconComponent: SupersededQueued, action: () => actionForMarkSuperseded(props)
              })
          }
        }

        return itemsConfig.map((item, index) => (
          <ReusableMenuItem
            key={index}
            handleClose={handleClose}
            label={item.label}
            IconComponent={item.IconComponent}
            action={item.action}
            disabled={item.disabled ? item.disabled : false}
          />
        ));
      }
    }
  }

  const status = props.cluster.status;
  const isArchived = props.isArchived

  if (!props.isActive && (typeof props.cluster.allowSuperseded === 'undefined' || !props.cluster.allowSuperseded)) {
    // Gray out all icons and disable menu actions if isActive is false
    Object.keys(baseConfig).forEach(status => {
      baseConfig[status].Icon = SupersededQueued;
      baseConfig[status].style = { ...baseConfig[status].style, color: '#a3a3a3' };
      baseConfig[status].tooltip = isArchived
        ? (props) => (
          <span>
            <span>Archived</span><br />
            <span>Superseded</span>
          </span>
        )
        : 'Superseded'
      baseConfig[status].menuItems = () => [];
    });

  } else if (!props.isActive && props.cluster.allowSuperseded) {
    baseConfig[status].Icon = SupersededQueued;
    baseConfig[status].style = { ...baseConfig[status].style, color: '#a3a3a3' };
    baseConfig[status].tooltip = isArchived
        ? (props) => (
          <span>
            <span>Archived</span><br />
            <span>Superseded</span>
          </span>
        )
        : 'Superseded'
    baseConfig[status].menuItems = (handleClose) => {
      const itemsConfig = [
        {
          label: 'Mark as Utilised',
          IconComponent: CheckCircleOutline, // Replace with your actual component
          action: () => actionForMarkUtilised(props)
        }
      ];

      return itemsConfig.map((item, index) => (
        <ReusableMenuItem
          key={index}
          handleClose={handleClose}
          label={item.label}
          IconComponent={item.IconComponent}
          action={item.action}
        />
      ));
    }
  }

  if (isArchived) {
    Object.keys(baseConfig).forEach(status => {
      if (status !== 'ARCHIVED') {
        baseConfig[status].Icon = Archived;
        baseConfig[status].style = { color: '#1a3a69' };
        baseConfig[status].menuItems = (handleClose) => {
          const itemsConfig = [
            { label: 'Copy S3 Archive Link', IconComponent: FileCopy, action: () => actionForS3CopyArchiveLink(props.process) },
            { label: 'Browse Archived Files', IconComponent: FindInPage, action: () => actionForS3rBrowseArchiveFiles(props.process) },
          ];
  
          return itemsConfig.map((item, index) => (
            <ReusableMenuItem
              key={index}
              handleClose={handleClose}
              label={item.label}
              IconComponent={item.IconComponent}
              action={item.action}
              disabled={item.disabled ? item.disabled : false}
            />
          ));
        }
      }
    });
  }

  return baseConfig;
};

const getClusterValue = (processDoc, clusterName, value) => {
  for (var i = 0; i < processDoc.ClusterProcessing.length; i++) {
    if (clusterName === processDoc.ClusterProcessing[i].Name)
      return processDoc.ClusterProcessing[i][value]
  }
};


function ClusterStatusIcon(props) {
  const {
    process,
    cluster,
    isWaitingForPollingResponse,
    blockRow,
    isBlocked,
    isActive,
    allowSuperseded
  } = props;
  const ClusterName = cluster.output;
  const status = cluster.status;
  const [open, setOpen] = useState(false);
  const [showQALogDialog, setShowQALogDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const anchorRef = useRef(null);
  let tokenPayload = props.token ? JSON.parse(atob(props.token.split(".")[1])) : ''
  let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''

  const statusConfig = getStatusConfig(props, setShowQALogDialog, setShowConfirmDialog)

  let tooltipContent;
  const tooltipStrFunc = statusConfig[status].tooltip;

  if (typeof tooltipStrFunc === "function") {
    tooltipContent = tooltipStrFunc(props);
  } else {
    tooltipContent = tooltipStrFunc; // It's a string.
  }

  const { Icon, style, tooltip, extraProps } = statusConfig[status] || {};

  const defaultHandleToggle = (setOpen) => {
    // Default behavior for toggling
    setOpen(prev => !prev);
  };

  const defaultHandleClose = () => {
    // Default behavior for closing
    setOpen(false);
  };

  const handleToggle = () => {
    if (isBlocked) {
      // If the icon is blocked, do not proceed with the toggle
      return;
    }
    if (statusConfig[status] && typeof statusConfig[status].handleToggle === 'function') {
      statusConfig[status].handleToggle(setOpen);
    } else {
      defaultHandleToggle(setOpen); // Fall back to default if no handleToggle is defined for this status
    }
  };

  const handleClose = () => {
    if (statusConfig[status] && typeof statusConfig[status].handleClose === 'function') {
      statusConfig[status].handleClose();
    } else {
      defaultHandleClose(); // Fall back to default if no handleClose is defined for this status
    }
  };

  const handleQALogConfirm = (issueDesc, issueRes) => {
    let jsonDoc = {};
    jsonDoc["QAIssueDesc"] = issueDesc;
    jsonDoc["QAIssueRes"] = issueRes;

    const requestData = {
      ...jsonDoc,
      process_id: process.guid,
      cluster_name: ClusterName,
      user: user
    };

    props.updateQaWithErrorsData(requestData);
    setShowQALogDialog(false);
  };

  const handleConfirmDialog = () => {
    console.log('Handle Confirm Dialog triggered');
    let tokenPayload = props.token ? JSON.parse(atob(props.token.split(".")[1])) : ''
    let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''
    let jsonDoc = {};
    const requestData = {
      ...jsonDoc,
      process_id: props.process.guid,
      cluster_output: props.cluster.output,
      user: user,
      token: props.token
    };
    props.updateResubmit(requestData)
    props.blockRow();
    setShowConfirmDialog(false);
  }

  const handleIgnoreConfrim = () => {
    let jsonDoc = {};
    jsonDoc["Ignore"] = true;

    const requestData = {
      ...jsonDoc,
      process_id: process.guid,
      cluster_name: ClusterName,
      user: user
    };

    props.updateIgnoreData(requestData);
  };

  if (!Icon) {
    console.error(`Invalid status: ${status}`);
    return null;
  }

  return (
    <div ref={anchorRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', height: "27px"}}>
      <span onClick={handleToggle} style={{ display: 'inline-block', zIndex: 1 }}>
        <NicerTooltip placement="right" content={tooltipContent}>
          {isBlocked ? (
            // Render this when the icon is blocked
            <HourglassFullTwoTone style={{ color: '#a3a3a3' }} />
          ) : (
            // Render the usual icon when not blocked
            <Icon style={style} {...extraProps} />
          )}
        </NicerTooltip>
      </span>
      <Popper
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement='left'
        container={document.body} // Render directly within the body
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {statusConfig[status] && statusConfig[status].menuItems(handleClose)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <QALogDialog
        dialogOpen={showQALogDialog}
        onConfirm={handleQALogConfirm}
        onCancel={() => setShowQALogDialog(false)}
      />

      <ConfirmationDialog
        title="Resubmit Job"
        confirmLabel="PROCEED"
        onCancel={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirmDialog}
        open={showConfirmDialog}>
        <p>Are you sure you want reprocess this data?</p>
        <p>The existing data (including downstream processing results) will be <span style={{ fontWeight: 'bold' }}>OVERWRITTEN</span></p>
      </ConfirmationDialog>
    </div>
  );

}

const mapStateToProps = state => ({
  token: state.token // or wherever the token is located in your state
});

const mapDispatchToProps = dispatch => ({
  updateQaWithErrorsData: (data) => dispatch(updateQaWithErrorsData(data)),
  updateIgnoreData: (data) => dispatch(updateIgnoreData(data)),
  updateManuallyProcessedData: (data) => dispatch(updateManuallyProcessedData(data)),
  updateQaData: (data) => dispatch(updateQaData(data)),
  updateMarkSuperseded: (data) => dispatch(updateMarkSuperseded(data)),
  updateResubmit: (data) => dispatch(updateResubmit(data)),
  updateCancelJob: (data) => dispatch(updateCancelJob(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(ClusterStatusIcon);
