export default () => {
    /* eslint no-restricted-globals: 1 */
    console.log(`WebWorker is running`)
    self.importScripts('https://dev.lidarnetics.com/zstdCodec.min.js')
    let ZstdCodec = self.ZstdCodec.ZstdCodec
    
    let retries = 0
    self.addEventListener("message", event => {
        if (!event) return;

        let msg = event.data[0]
        let fileContent = event.data[1];
        let fileProperties = event.data[2];
        let originalChunkSize = event.data[3];
        // let destination = event.data[4];
        // let disableCompression = event.data[4];

        const compressFileChunk = (fileContent) => {
            ZstdCodec.run((zstd) => {
                const streaming = new zstd.Streaming();
                const COMPRESSION_CHUNK = 8 * 1024 * 1024
                const level = 3;
                // Have to stream and chunked the data. Otherwise the memory would be overflowed.
                const partCount = Math.ceil(fileContent.byteLength / COMPRESSION_CHUNK)
                let chunks = []
                let metadata = {fullPath: fileProperties.webkitRelativePath, percent: 0.0}
                for(let i=0; i < partCount; i++) {
                    let offset = i * COMPRESSION_CHUNK
                    chunks[i] = new Uint8Array(fileContent.slice(offset, offset+COMPRESSION_CHUNK))
                    metadata.percent = ((i+1) / partCount * 100).toFixed(2)
                    // TODO: It is not the real compression progress. There is a delay between the compression done and upload start.
                    postMessage({
                        'message': 'progress',
                        'metadata': metadata
                    })
                }
                let compressed = streaming.compressChunks(chunks, fileContent.byteLength, level)
                fileProperties.name = fileProperties.name + ".bin";
                let messageData = {
                    'message': 'completed',
                    'arrayBuffer': compressed,
                    'fileProperties': fileProperties,
                    'uncompressedChunkSize': originalChunkSize,
                    'metadata': metadata,
                    // 'destination': destination
                };
                // postMessage(messageData, [messageData.arrayBuffer]);
                postMessage(messageData)
            })
        }

        switch (msg) {
            case 'pause':
                self.close();
                return;
            case 'terminate':
                self.close();
                return;
            case 'start':
                try {
                    compressFileChunk(fileContent)
                } catch(error) {
                    console.log(`Compress file chunk failed, retry no ${retries}`)
                    retries += 1
                    if(retries < 3) {
                        compressFileChunk(fileContent)
                    } else {
                        console.log(`Compression web worker has been terminated because errors are occurring continuously.`)
                        self.close();
                        return;
                    }
                }
                break
            default:
                console.log(`Unsupported message in ZSTD compression web worker: ${msg} `)
                self.close();
                return;
        }


        // let msg = event.data[0]
        // let uploadTask = event.data[1]
        // let file = event.data[2];
        // // let fileProperties = event.data[2];
        // // let originalSize = event.data[3];
        // // let destination = event.data[4];
        // let disableCompression = event.data[3];

        // const compressFileChunk = (fileContent) => {
        //     ZstdCodec.run((zstd) => {
        //         const streaming = new zstd.Streaming();
        //         const COMPRESSION_CHUNK = 8 * 1024 * 1024
        //         const level = 3;
        //         // Have to stream and chunked the data. Otherwise the memory would be overflowed.
        //         const partCount = Math.ceil(fileContent.byteLength / COMPRESSION_CHUNK)
        //         let chunks = []
        //         let metadata = {currentFile: fileProperties.webkitRelativePath, percent: 0.0}
        //         for(let i=0; i < partCount; i++) {
        //             let offset = i * COMPRESSION_CHUNK
        //             chunks[i] = new Uint8Array(fileContent.slice(offset, offset+COMPRESSION_CHUNK))
        //             metadata.percent = ((i+1) / partCount * 100).toFixed(2)
        //             // TODO: It is not the real compression progress. There is a delay between the compression done and upload start.
        //             postMessage({
        //                 'message': 'progress',
        //                 'metadata': metadata
        //             })
        //         }
        //         let compressed = streaming.compressChunks(chunks, fileContent.byteLength, level)
        //         fileProperties.name = fileProperties.name + ".bin";
        //         let messageData = {
        //             'message': 'completed',
        //             'arrayBuffer': compressed,
        //             'fileProperties': fileProperties,
        //             'uncompressedFileSize': originalSize,
        //             'metadata': metadata,
        //             // 'destination': destination
        //         };
        //         // postMessage(messageData, [messageData.arrayBuffer]);
        //         postMessage(messageData)
        //     })
        // }

        // switch (msg) {
        //     case 'pause':
        //         self.close();
        //         return;
        //     case 'terminate':
        //         self.close();
        //         return;
        //     case 'start':
        //         console.log(`Compression web worker is running a new upload task: ${uploadTask.fullPath}`)
        //         let reader = new FileReader()
        //         reader.onerror = function (evt) {
        //             console.error("Error occured when reading a chunk: " + evt.error)
        //             retries += 1
        //             if(retries < 3) {
        //                 reader.readAsArrayBuffer(blob)
        //             } else {
        //                 console.log(`Compression web worker has been terminated because error is occurring continuously.`)
        //                 // TODO: Should post message to parent and rerun this uploadTask
        //                 self.close();
        //                 return;
        //             }
        //         }

        //         let offset = uploadTask.chunkIndex * 200 * 1024 * 1024      // 200MB
        //         let blob = file.slice(offset, offset + uploadTask.chunkSize);
        //         reader.onload = function (evt) {
        //             console.log('Read Blob Done...')
        //             // let fileProperties = {
        //             //     name: uploadTask.targetFullPath,
        //             //     webkitRelativePath: uploadTask.fullPath,
        //             //     lastModifiedDate: file.lastModifiedDate
        //             // }
        //             if(uploadTask.isZipped) {
        //                 // TODO: post message to the main thread to update the task status 
        //                 // Use uploadTask.fullPath as the key of ongoingUploadTaskLoopup. workerId use the same value.
        //                 // updateUploadOngoingTaskStatus(uploadTask.fullPath, VALID_ONGOING_TASK_STATUS.COMPRESSING)

        //                 // // let worker = new WebWorker(CompressionWorker) as Worker
        //                 // let worker = new WebWorker(ZstdCompressionWorker) as Worker
        //                 // let workerId = uploadTask.fullPath
        //                 // concurrentWebWorkerLookup[workerId] = worker
            
        //                 // worker.onerror = function (event) {
        //                 //     console.log("...worker error 1", event.message, event.filename, event.lineno, event);
        //                 // };
        
        //                 // const handleMessageEvent = (event: any ) => {
        //                 //     if (event.data.message === 'progress' || event.data.message === 'completed') {
        //                 //         let percentCompleted = event.data.metadata ? Math.floor(event.data.metadata.percent) : 0
        //                 //         console.log(`WebWorker compression progress: fileName: ${event.data.metadata.currentFile}, percent: ${percentCompleted}%`)
        //                 //         updateUploadOngoingTaskProgress(workerId, percentCompleted)
        //                 //     }
        //                 //     if (event.data.message === 'completed') {
        //                 //         worker.postMessage(['terminate'])
        //                 //         worker.removeEventListener('message', handleMessageEvent)
        //                 //         let newBlob = new Blob([event.data.arrayBuffer], { type: 'application/octet-stream' });//{ type: 'application/zip' });
        
        //                 //         console.log('fullPath: ', uploadTask.fullPath, 'Blob length after compression: ', event.data.arrayBuffer.byteLength)
        //                 //         // printLog(`Compression Completed: ${uploadTask.fullPath}`)
        //                 //         let blobToUpload: BlobToUpload = {
        //                 //             blob: newBlob,
        //                 //             fullPath: uploadTask.fullPath,
        //                 //             originalChunkSize: uploadTask.chunkSize
        //                 //         };
        //                 //         enqueueBlobToUploadQueue(blobToUpload)
        //                 //         updateUploadOngoingTaskStatus(workerId, VALID_ONGOING_TASK_STATUS.WAITING_FOR_UPLOAD)
        //                 //         removeWebWorkerFromLookup(workerId)
        //                 //     }
        //                 // }
        //                 // worker.addEventListener('message', event => handleMessageEvent(event))
        //                 // console.log('fullPath: ', uploadTask.fullPath, 'Blob length before compression: ', ((evt.target as FileReader).result as ArrayBuffer).byteLength)
        //                 // worker.postMessage([ 'start', (evt.target as FileReader).result, fileProperties, file.size, isGlobalDisableCompression], 
        //                 //                         [((evt.target as FileReader).result as ArrayBuffer)]
        //                 //                     );
        //                 let fileContent = evt.target.result
        //                 try {
        //                     compressFileChunk(fileContent)
        //                 } catch(error) {
        //                     console.log(`Compress file chunk failed, retry no ${retries}`)
        //                     retries += 1
        //                     if(retries < 3) {
        //                         compressFileChunk(fileContent)
        //                     } else {
        //                         console.log(`Compression web worker has been terminated because error is occurring continuously.`)
        //                         // TODO: Should post message to parent and rerun this uploadTask
        //                         self.close();
        //                         return;
        //                     }
        //                 }

                        
        //             } else {
        //                 console.log(`File is not compressed: ${uploadTask.fullPath}, Blob length: ${uploadTask.totalOriginalSize}`)
        //                 let newBlob = new Blob([((evt.target as FileReader).result as ArrayBuffer)], { type: 'application/octet-stream' })
        //                 let blobToUpload: BlobToUpload = {
        //                     blob: newBlob,
        //                     fullPath: uploadTask.fullPath,
        //                     originalChunkSize: uploadTask.chunkSize
        //                 };
        //                 enqueueBlobToUploadQueue(blobToUpload)
        //                 updateUploadOngoingTaskStatus(uploadTask.fullPath, VALID_ONGOING_TASK_STATUS.WAITING_FOR_UPLOAD)
        //             }
        //         };
        //         reader.readAsArrayBuffer(blob)

        //         break
        //     default:
        //         console.log(`Unsupported message in ZSTD compression web worker: ${msg} `)
        //         self.close();
        //         return;
        // }

    })
}