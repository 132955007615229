import React, { 
    useState, useEffect, BaseSyntheticEvent, forwardRef, useImperativeHandle
} from 'react'
import { 
    Grid, Chip, MenuItem, Select, NativeSelect, IconButton, FormControl
} from '@material-ui/core';
import ControlPoint from '@material-ui/icons/ControlPoint'
import { SelectOption } from '../processing/FilterDialogTypes'

interface GenericSelectFilterProps {
    label: string;
    selectOptions: Array<SelectOption>;
    defaultFilterList?: Array<string>;
    Icon: JSX.Element;
    width?: number;
    autoFocus?: boolean;
    handleFilterListOnChange?: (filterList: Array<string>) => any;
}

export interface GenericSelectFilterRef {
    filterList: Array<string>;
    cleanFilter(): void;
}

const GenericSelectFilter = forwardRef<GenericSelectFilterRef, GenericSelectFilterProps>((props, ref) => {
    const { 
        label, selectOptions, defaultFilterList, Icon, width, handleFilterListOnChange, autoFocus 
    } = props
    const [filterList, setFilterList] = useState<Array<string>>([])

    useEffect(() => {
        defaultFilterList && setFilterList(defaultFilterList)
    }, [])

    useEffect(() => {
        handleFilterListOnChange && handleFilterListOnChange(filterList)
    }, [filterList])

    const handleItemChipOnDelete = (item: string, index: number) => {
        setFilterList(prevState => {
            var newState = [...prevState]
            newState.splice(index, 1)
            return newState
        })
    }

    const handleSelectOnChange = (event: BaseSyntheticEvent) => {
        let itemSelected = event.target.value
        if(itemSelected && filterList.indexOf(itemSelected) == -1) {
            setFilterList(prevState => ([...prevState, itemSelected]))
        }
    }

    useImperativeHandle(ref, () => ({
        filterList,
        cleanFilter() {
            setFilterList([])
        },
    }));

    return (
        <>
            <Grid container direction="column" justify="center" alignItems="flex-start" style={{ paddingTop: 8 }}>
                <div><strong>{label}:</strong></div>
                <div>
                    <Grid container direction='row' justify='flex-start' alignContent='center' xs={12} 
                        className='mt-1 mb-1'
                    >
                        {Icon}
                        <Select
                            className='select--large ml-2'
                            value={''}
                            onChange={(event: BaseSyntheticEvent) => handleSelectOnChange(event)}
                            style={{ width: width || 260 }}
                            displayEmpty
                            autoFocus={autoFocus || false}
                        >
                            <MenuItem value='' disabled><em>Please select</em></MenuItem>
                            {
                                React.Children.toArray(
                                    selectOptions.map((option, index) => 
                                        <MenuItem key={option.label} value={option.value} >{option.label}</MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </Grid>
                </div>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <div className='pl-4 pb-2'>
                        {
                            React.Children.toArray(
                                filterList.map((item, index) => {
                                    return (
                                        <Chip
                                        key={index}
                                        label={
                                            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '230px' }} >{item}</p>
                                        }
                                        onDelete={() => handleItemChipOnDelete(item, index)}
                                        style={{ margin: 2, maxWidth: '260px' }}
                                        />
                                    )
                                })
                                )
                            }
                    </div>
                </Grid>
            </Grid>
        </>
    )
})

export default GenericSelectFilter