import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

class ConfirmationDialog extends Component {

    render() {
        return (
            <Dialog disableBackdropClick disableEscapeKeyDown open={this.props.open}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => { this.props.onCancel(false) }} color="primary" >
                        {this.props.cancelLabel ? this.props.cancelLabel : "Cancel"}
                    </Button>
                    <Button variant="contained" component="span" color="primary" style={{ backgroundColor: '#ff0000' }} onClick={this.props.onConfirm}>{this.props.confirmLabel}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmationDialog;