import React, { Component } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Tune from '@material-ui/icons/Tune';
import { Link } from 'react-router-dom'
import NicerTooltip from '../common/NicerTooltip';
import { ParamsError } from '../icons/svgIcons'

class CalibrationStatusTableIcon extends Component {

    render() {
        const { calParamsId, lmsVersionsMatch, isGuestUser } = this.props
        return (
            <div>
                {
                    calParamsId && lmsVersionsMatch &&
                    <NicerTooltip placement="right" content={<span>Open Parameters</span>}>
                        <IconButton style={{ width: 50, height: 50 }}>
                            {
                                !isGuestUser &&
                                <Link to={"/ip/params/" + calParamsId}>
                                    <Tune style={{ color: '#1a3a69' }} />
                                </Link>
                            }
                            {
                                isGuestUser &&
                                <Tune style={{ color: '#1a3a69' }} />
                            }
                        </IconButton>
                    </NicerTooltip>
                }
                {
                    calParamsId && !lmsVersionsMatch &&
                    <NicerTooltip placement="right" content={<span>Unsupported LMS Version<br />LMS processing will not proceed</span>}>
                        <IconButton style={{ width: 50, height: 50 }}>
                            {
                                !isGuestUser &&
                                <Link to={"/ip/params/" + calParamsId}>
                                    <ParamsError />
                                </Link>
                            }
                            {
                                isGuestUser &&
                                <ParamsError />
                            }
                        </IconButton>
                    </NicerTooltip>
                }
                {
                    !calParamsId &&
                    <NicerTooltip placement="right" content={<span>No matching parameters</span>}>
                        <ErrorOutline style={{ color: '#FF0000' }} />
                    </NicerTooltip>
                }
            </div>
        );
    }
}

export default CalibrationStatusTableIcon;