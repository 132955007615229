import { CircularProgress, Grid, Button, Table, TableHead, TableRow, TableCell, TableBody, ListItem, List, Fab, InputAdornment, TextField, Tooltip, Checkbox, RadioGroup, FormControlLabel, Radio, TablePagination } from '@material-ui/core';
import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import useInterval from '../common/useInterval'
import useConstructor from '../common/useConstructor'
import Config from '../app/Config.json'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CancelIcon from '@material-ui/icons/Cancel';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ClearIcon from '@material-ui/icons/Clear';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import InfoIcon from '@material-ui/icons/Info';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import SaveIcon from '@material-ui/icons/Save';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { HighlightAltIcon, AlignVerticalCenter } from "../icons/svgIcons";
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { Map, Marker, Popup, TileLayer, GeoJSON, LeafletProvider } from "react-leaflet";
import { latLngBounds, FeatureGroup, geoJSON } from 'leaflet'
import { KMZLayer } from 'leaflet-kmz'
import NicerTooltip from '../common/NicerTooltip';
import ConfirmationDialog from '../common/ConfirmationDialog';
import IconButton from '@material-ui/core/IconButton';
import { bearing, point } from '@turf/turf'
import 'leaflet-lasso';
import * as L from 'leaflet';
// import { instructionFetchList, instructionQueryParams } from './InstructionActions'

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

function ReviewExtractRangeMap(props) {

    const contrastColors = ['#ff7f00', '#6a3d9a', '#61f00e', '#33a02c', '#b15928', '#f0f00e', '#fb9a99', '#fdbf6f', '#cab2d6', '#0ef0e1'];//only 10 contrasting colours :-(
    // let polygonStyle = { stroke: true, color: "#FF0000", weight: 5 };
    // const polylineStyle = { stroke: true, color: "#0000FF", weight: 5 };
    const selectedStyle = { stroke: true, color: "#ce0ef0", weight: 5 };

    //state
    const [isLoading, setIsLoading] = useState(false);
    const [bounds, setBounds] = useState(undefined);
    const [selected, setSelected] = useState(undefined);
    // const [listItems, setListItems] = useState([]);
    const [instructionPage, setInstructionPage] = useState(1);
    const [instructionFilterParams, setInstructionFilterParams] = useState({});
    const [zoomLevel, setZoomLevel] = useState(4);
    const [loadedFileNames, setLoadedFileNames] = useState([]);
    const [map, setMap] = useState(undefined);
    const [displayReplaceDialog, setDisplayReplaceDialog] = useState(false);
    const [loadedFiles, setLoadedFiles] = useState([]);
    const [loadedGeometry, setLoadedGeometry] = useState([]);
    const [addSelected, setAddSelected] = useState(false);
    const [removeSelected, setRemoveSelected] = useState(false);
    const [selectionEnabled, setSelectionEnabled] = useState(false);
    const [assignmentDictionary, setAssignmentDictionary] = useState({});
    const [availColors, setAvailColors] = useState(undefined);
    const [currentColor, setCurrentColor] = useState(undefined);
    const [geomTypeFilter, setGeomTypeFilter] = useState('All');
    const [polygonStyle, setPolygonStyle] = useState({ stroke: true, color: "#FF0000", weight: 5 });
    const [polylineStyle, setPolylineStyle] = useState({ stroke: true, color: "#0000FF", weight: 5 });
    const [polygonPane, setPolygonPane] = useState(null);
    const [polylinePane, setPolylinePane] = useState(null);


    // const [queryObject, setQueryObject] = useState({});
    // const [over, setOver] = useState(false);

    // let polygonPane = null;
    // let polylinePane = null;
    useEffect(() => {
        // if (props.processing !== null && isLoading == true) {
        //     setIsLoading(false);
        // }
        console.log("...in effect")
        console.log("...assignmentDictionary", assignmentDictionary)
        // console.log("...instructions",props.instructions)

        if (map) {
            if (polygonPane === null) {
                let pgPane = map.createPane('polygonPane')
                setPolygonPane(pgPane);
                setPolygonStyle({ stroke: true, color: "#FF0000", weight: 5, pane: 'polygonPane' });
            }
            if (polylinePane === null) {
                let plPane = map.createPane('polylinePane')
                setPolylinePane(plPane);
                setPolylineStyle({ stroke: true, color: "#0000FF", weight: 5, pane: 'polylinePane' });
            }
        }
    });

    const forceUpdate = useForceUpdate();

    useConstructor(() => {
        setAvailColors(contrastColors)
    });

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const getWierdoDiskExtractLocalDate = (missionDateUtcString, gpsTimeString, leapSecondOffset) => {

        let gpsEpoch = new Date(Date.UTC(1980, 0, 6, 0, 0, 0));
        let gpsTimeLocal = new Date(gpsEpoch.getTime() + (parseFloat(gpsTimeString) * 1000) + (leapSecondOffset * 1000))//a local time
        let missionDateParts = missionDateUtcString.split("-");
        let localDate = new Date(parseInt(missionDateParts[0]), parseInt(missionDateParts[1]) - 1, parseInt(missionDateParts[2]), gpsTimeLocal.getHours(), gpsTimeLocal.getMinutes(), gpsTimeLocal.getSeconds());
        return localDate;
        // console.log("...localDate",localDate);
    }

    const loadFmsLog = (fmsLogFile, _loadedGeometry) => {
        // gpsTimeToUtc("2021-01-29","523986.03",0);
        // return
        console.log("...fmsTest, file name", fmsLogFile.name)
        //TODO: maybe improve to read in chunks into arrayBuffer.... depends on size of log
        var fr = new FileReader();
        fr.onload = function () {
            let tempBounds = latLngBounds();
            // console.log("...found bro:",RegExp(/\$MISSION/, "g").test("$MISSION"))
            let emiDict = {};
            let pasDict = {};
            let stripDict = {}
            let missionDate = "";
            let allStartDates = [];
            let lines = fr.result.split('\n');
            let fl = "";
            let strip = "";
            for (var line = 0; line < lines.length; line++) {
                if (RegExp(/\$MISSION/, "g").test(lines[line])) {
                    missionDate = lines[line].split(",")[7];
                    // console.log("...MISSION:", missionDate);
                }
                else if (RegExp(/.+SA_\d\d_FL_\d\d_Strip_\d\d.+\.kml/, "g").test(lines[line])) {
                    let kmlFilename = lines[line].split(",")[6];
                    // console.log("...kmlFilename:", kmlFilename);
                    // let fileNameParts = RegExp(/SA_\d\d_FL_\d\d_Strip_\d\d.+\.kml/, "g").match(kmlFilename)[0].split('_');
                    let fileNameParts = kmlFilename.match(RegExp(/SA_\d\d_FL_\d\d_Strip_\d\d.+\.kml/, "g"))[0].split('_');
                    // console.log("...fileNameParts:", fileNameParts);
                    fl = fileNameParts[3];
                    strip = fileNameParts[5];
                    stripDict[parseInt(strip)] = fl;
                }
                else if (RegExp(/\$EMI/, "g").test(lines[line])) {
                    let parts = lines[line].split(",");
                    let emiList = emiDict[parts[6]] || [];
                    emiList.push({
                        'GPS Time': parts[1],
                        'Strip No': parts[6],
                        // 'log fl no': fl,
                        // 'log strip no': strip,
                        'PRF Hz': parts[8],
                        'Scan Frequency Hz': parts[9],
                        'Scan Horizontal Degrees': parts[10],
                        'Orientation': parts[12] === "0" ? 'backwards' : 'forwards',
                        'PIA': parts[14],
                        'NOHD': parts[15],
                        // 'Flight Line': parts.length > 18 ? parts[18] : '',
                        "Mission Date (UTC)": missionDate,
                        // "Disk Extract Date (local)": getWierdoDiskExtractLocalDate(missionDate, parts[1], 0)//deliberately set leapsecond offset to 0
                    })
                    emiDict[parts[6]] = emiList;

                    let deDate = getWierdoDiskExtractLocalDate(missionDate, parts[1], 0);
                    let gpsEpoch = new Date(1980, 0, 6, 0, 0, 0);
                    let bodgeGps = (deDate.getTime() - gpsEpoch.getTime()) / 1000.0
                    allStartDates.push(bodgeGps);

                    //2021-01-27,16:35:11
                    // allStartDates.push(`${deDate.getFullYear()}-${(deDate.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}-${deDate.getDate()},${deDate.getHours()}:${deDate.getMinutes()}:${(deDate.getSeconds()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`);
                }
                else if (RegExp(/\$PAS/, "g").test(lines[line])) {
                    let parts = lines[line].split(",");
                    let pointList = pasDict[parts[5]] || [];
                    pointList.push([parseFloat(parts[3]), parseFloat(parts[2])]);
                    pasDict[parts[5]] = pointList;
                }
            }
            // console.log("...allStartDates", allStartDates.join(','));
            let now = Date.now();
            // let gpsEpoch = new Date(Date.UTC(1980, 0, 6, 0, 0, 0));
            // console.log("...gpstimenow:", (Date.now() - gpsEpoch.getTime()) / 1000);
            // console.log("...emiList:", emiDict);
            // console.log("...pasDict:", pasDict);
            let features = [];
            for (var key in pasDict) {
                // console.log("...key,vlaues", key, emiDict[key]);
                let props = emiDict[key][0];
                let gpsStart = emiDict[key][0]['GPS Time'];
                let gpsEnd = emiDict[key][1]['GPS Time'];
                delete props['GPS Time'];
                props['GPS Start Time'] = gpsStart;
                props['GPS End Time'] = gpsEnd;
                props["FL"] = stripDict[key];
                let feature = {
                    "type": "Feature",
                    "geometry": {
                        "type": "LineString",
                        "coordinates": pasDict[key]
                    },
                    "properties": props
                }
                features.push(feature)
            }
            // let featureCollection =  {
            //     "type": "FeatureCollection",
            //     "features": features
            // }
            // console.log("...featureCollection",featureCollection)

            let gj = geoJSON(features, {
                onEachFeature: (feature, lyr) => {
                    // lyr.on({
                    //     click: highlightFeature
                    //     // mouseover: function(e) {console.log("...mouseover")}
                    // });
                    // console.log("...feature",feature)

                    let table = "<table>"
                    for (var key in feature.properties) {
                        table += "<tr><td style='width:150px;font-weight:bold'>" + key + "</td><td>" + feature.properties[key] + "</td></tr>";
                    }
                    table += "</table>";
                    // var popupContent = "<p></p>";

                    // if (feature.properties){//} && feature.properties.popupContent) {
                    //     popupContent += feature.properties;//.popupContent;
                    // }

                    lyr.bindPopup(table);

                    // // var marker = new L.marker([39.5, -77.3], { opacity: 0.01 }); //opacity may be set to zero
                    // var marker = new L.marker(feature.geometry.coordinates[0].reverse(), { opacity: 0.01 }); //opacity may be set to zero
                    // marker.bindTooltip(feature.properties["Strip No"], {permanent: true, offset: [0, 0] });
                    // marker.addTo(map);
                    // console.log("...feature",feature)
                    // var text = new L.marker(feature.geometry.coordinates[0].reverse(), {opacity: 0.0001});
                    // text.bindLabel("TEXT");//, styleProperties);
                    // text.addTo(myLayer);
                    // var marker = L.marker(feature.geometry.coordinates[0].reverse()).bindTooltip(feature.properties["Strip No"], {permanent: true, direction : 'bottom'}).addTo(map);
                    let point1 = point(feature.geometry.coordinates[0]);
                    let point2 = point(feature.geometry.coordinates[feature.geometry.coordinates.length - 1]);
                    let bg = bearing(point1, point2);
                    console.log("...fl,bg", feature.properties["FL"], bg)
                    let translate = "";//false;
                    if (bg < 0 && bg >= -90) {
                        bg += 90
                        translate = " translate(8px, -4px);"
                    }
                    else if (bg < 0 && bg < -90) {
                        bg += 90
                        translate = " translate(8px, -4px);"
                    }
                    else if (bg > 0 && bg <= 90) {
                        bg -= 90
                        // translate = true;
                        translate = " translate(-120px, -4px);"
                    }
                    else if (bg > 90) {
                        bg -= 90
                        // translate = true;
                        translate = " translate(-120px, -12px);"
                    }
                    // bg*=-1
                    // console.log("...bearing",bg)
                    // let transform = "transform: translate(0px, -5px);"// rotate("+bg.toFixed(0)+"deg);"
                    let transform = "transform: rotate(" + bg.toFixed(0) + "deg)" + translate;

                    // if (translate)
                    //     transform +=" translate(-120px, -4px);"; //actually the translation needs to be along the bearing of the line

                    //TODO: dont do translate above if on RHS


                    var textMarker = L.marker(feature.geometry.coordinates[0].reverse(), {
                        icon: L.divIcon({
                            html: '<div style="width: 150px; font-size: 16px; font-weight: bold; text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white; transform-origin: top left; ' + transform + '">Strip: ' + feature.properties["Strip No"] + ', FL: ' + feature.properties["FL"] + '</div>',
                            // html: '<div style="font-size: 15px; font-weight: bold; text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;">Strip: '+feature.properties["Strip No"] + '</div>',
                            // bgPos: [0, 0],
                            className: '',
                        })
                    }).addTo(map);

                },
                style: (feature) => {
                    var styles = {};
                    if (feature["geometry"]["type"] === "Polygon") {
                        return polygonStyle;
                    }
                    else if (feature["geometry"]["type"] === "LineString") {
                        return polylineStyle;
                    }
                    return styles;
                }
            })

            map.addLayer(gj)

            _loadedGeometry.push({
                name: fmsLogFile.name,
                layer: gj,
                visible: true
            })

            //NOT WORKING with multiple files... only zooming to last
            map.eachLayer(function (layer) {
                // Check if layer is a featuregroup
                if (layer instanceof FeatureGroup) {
                    // Extend bounds with group's bounds
                    tempBounds.extend(layer.getBounds());
                }
            });

            map.fitBounds(tempBounds);
            console.log("..._loadedGeometry3", _loadedGeometry)
            setLoadedGeometry(_loadedGeometry);
            forceUpdate();
        }

        fr.readAsText(fmsLogFile);

    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let files = [];
        if (e.dataTransfer.items.length > 0) {

            for (var i = 0; i < e.dataTransfer.items.length; i++) {

                //start temporary test of fmslog
                // let testFile = e.dataTransfer.items[i].getAsFile();
                // console.log("...dropped file", testFile)
                // console.log("...dropped file", testFile.name.substr(-3))
                // if (testFile.name.substr(-3) === "log")
                //     return fmsLogTest(testFile);
                //end temporary test of fmslog

                files.push(e.dataTransfer.items[i].getAsFile())
            }
            setLoadedFiles(files)

            if (loadedGeometry.length > 0) {
                setDisplayReplaceDialog(true);
            }
            else {
                addGeometry(false, files)
            }

        }
    }

    const handleBrowseLoad = (event) => {
        // console.log("...files", event.target.files)
        let files = [];
        if (event.target.files.length > 0) {

            for (var i = 0; i < event.target.files.length; i++) {
                files.push(event.target.files[i])
                // console.log("...file",event.target.files[i])
            }
            setLoadedFiles(files)

            if (loadedGeometry.length > 0) {
                setDisplayReplaceDialog(true);
            }
            else {
                addGeometry(false, files)
            }
        }
    }

    const copyArray = (inArray) => {
        //not a true deep copy
        let newArray = [];
        inArray.forEach(item => {
            newArray.push(item);
        });
        return newArray;
    }

    const addGeometry = (replace = true, overrideFiles = undefined) => {

        let _loadedGeometry = replace ? [] : copyArray(loadedGeometry);
        if (replace) {
            loadedGeometry.forEach((item, index) => {
                handleLayerRemove(index)
            });
        }


        let filesToAdd = overrideFiles != undefined ? overrideFiles : loadedFiles;
        if (filesToAdd.length > 0) {
            filesToAdd.forEach(file => {
                //TODO also check is a fms log
                loadFmsLog(file, _loadedGeometry);
            });
        }
    }


    const handleEnableSelect = () => {
        setAddSelected(true);
        const lasso = L.lasso(map, { intersect: true });
        lasso.enable();

        map.addOneTimeEventListener('lasso.finished', event => {
            let features = []
            event.layers.forEach(value => {
                if (geomTypeFilter === 'AOI' && value.feature["geometry"]["type"] !== "LineString") {
                    features.push(value)
                }
                else if (geomTypeFilter === 'FlightLines' && value.feature["geometry"]["type"] !== "Polygon") {
                    features.push(value)
                }
                else if (geomTypeFilter === 'All') {
                    features.push(value)
                }
            })
            setSelectedLayers(features);
        });
    }

    const handleEnableRemove = () => {
        setRemoveSelected(true);
        const lasso = L.lasso(map, { intersect: true });
        lasso.enable();

        map.addOneTimeEventListener('lasso.finished', event => {
            removeSelectedLayers(event.layers);
        });
    }

    function resetSelectedState() {

        map.eachLayer(layer => {
            if (layer instanceof L.Polyline && layer.feature !== undefined) {
                if (layer.feature.geometry.type == "LineString") {
                    layer.setStyle(polylineStyle);
                }
                else if (layer.feature.geometry.type == "Polygon") {
                    layer.setStyle(polygonStyle);
                }
            }
        });

        // let _assignmentDictionary = copyDict(assignmentDictionary);
        Object.keys(assignmentDictionary).map(function (key) {
            resetColor(key);
        });
        setAssignmentDictionary({});
        setSelected(undefined);

    }

    const assignToAll = (item) => {
        let _layers = [];
        let _assignmentDictionary = {};
        _assignmentDictionary[item.ProcInstID] = item;

        map.eachLayer(function (layer) {
            if (layer instanceof FeatureGroup) {
                Object.entries(layer._layers).forEach(entry => _layers.push(entry[1]))
            }
        });

        setSelectedLayers(_layers, item);
        setAssignmentDictionary(_assignmentDictionary);
    }

    function setSelectedLayers(layers, selectedOverride = null) {

        let item = selectedOverride ? selectedOverride : selected;
        let _currentColor;
        let _layers = [];

        // console.log("...item (selected)", item)
        // console.log("...assignmentDictionary",assignmentDictionary)

        if (assignmentDictionary[item.ProcInstID] === undefined) {
            // console.log("...NOT assigned bro")
            let _availColors = [...availColors];
            _currentColor = _availColors.shift()
            setAvailColors(_availColors);
        }
        else {
            // console.log("...assigned bro")
            _currentColor = assignmentDictionary[item.ProcInstID].color;
            _layers = assignmentDictionary[item.ProcInstID].layers;
        }

        item['color'] = _currentColor;
        setCurrentColor(_currentColor);
        // console.log("...currentColor", _currentColor)
        //TODO: need to check that a selection is actually made!

        setAddSelected(false);
        layers.forEach(layer => {
            if (layer instanceof L.Path) {
                selectedStyle.color = _currentColor;
                layer.setStyle(selectedStyle);
            }
        });

        // let _assignmentDictionary = JSON.parse(JSON.stringify(assignmentDictionary));//deep copy
        let _assignmentDictionary = {};
        Object.keys(assignmentDictionary).map(function (key) {
            const item = assignmentDictionary[key];
            _assignmentDictionary[key] = item;
        })

        _assignmentDictionary[item.ProcInstID] = item;

        // _layers.length > 0 ? assignmentDictionary[item.ProcInstID].layers : []
        let combinedLayers = _layers.concat(layers);
        combinedLayers = [...new Set([..._layers, ...layers])]
        item.layers = combinedLayers;
        _assignmentDictionary[item.ProcInstID] = item;

        setAssignmentDictionary(_assignmentDictionary);

    }

    const copyDict = (dictIn) => {
        let newDict = {};
        Object.keys(dictIn).map(function (key) {
            const item = dictIn[key];
            newDict[key] = item;
        })
        return newDict;
    }

    function removeSelectedLayers(layers) {
        // let item = selected;
        let _assignmentDictionary = {};

        Object.keys(assignmentDictionary).map(function (key) {
            const item = assignmentDictionary[key];
            let storedLayers = assignmentDictionary[key].layers;
            let newLayers = [];

            console.log("...storedLayers", storedLayers)
            for (var i = 0; i < storedLayers.length; i++) {
                let found = false;
                for (var j = 0; j < layers.length; j++) {
                    if (layers[j] === storedLayers[i]) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    console.log("...not found")
                    newLayers.push(storedLayers[i])
                }
            }
            console.log("...newLayers", newLayers);
            item.layers = newLayers;
            _assignmentDictionary[key] = item;
            if (_assignmentDictionary[key].layers.length == 0) {
                resetColor(key);
                delete _assignmentDictionary[key];
            }
        });


        setAssignmentDictionary(_assignmentDictionary);

        setRemoveSelected(false);
        layers.forEach(layer => {
            if (layer instanceof L.Polyline && layer.feature !== undefined) {
                if (layer.feature.geometry.type == "LineString") {
                    layer.setStyle(polylineStyle);
                }
                else if (layer.feature.geometry.type == "Polygon") {
                    layer.setStyle(polygonStyle);
                }
            }
        });
    }

    const getSelectionByColor = (color) => {
        console.log("...yo")
        map.eachLayer(function (layer) {
            if (layer instanceof FeatureGroup) {
                // map.removeLayer(layer);
                console.log("...layer", layer)
            }
        });
    }

    const handleLayerToggle = (index) => {
        let newArray = [...loadedGeometry];
        newArray[index].visible = !newArray[index].visible
        newArray[index].visible ? map.addLayer(newArray[index].layer) : map.removeLayer(newArray[index].layer);

        setLoadedGeometry(newArray)
    }

    const handleLayerRemove = (index) => {
        let _layers = []
        Object.entries(loadedGeometry[index].layer._layers).forEach(entry => _layers.push(entry[1]))
        removeSelectedLayers(_layers);

        map.removeLayer(loadedGeometry[index].layer);
        let newArray = [...loadedGeometry];
        newArray.splice(index, 1)
        setLoadedGeometry(newArray)
    }

    const handleClearAll = () => {
        map.eachLayer(function (layer) {
            if (layer instanceof FeatureGroup) {
                map.removeLayer(layer);
            }
        });
        setLoadedGeometry([]);
        setSelectionEnabled(false);
        resetSelectedState();
    }

    const handleFilterGeom = (geomType) => {

        if (geomType === 'FlightLines') {
            polygonPane.style.display = 'none';
            polylinePane.style.display = 'block';
        }
        else if (geomType === 'AOI') {
            polylinePane.style.display = 'none';
            polygonPane.style.display = 'block';
        }
        else {
            polylinePane.style.display = 'block';
            polygonPane.style.display = 'block';
        }

        setGeomTypeFilter(geomType)
        // let features = [];
        // map.eachLayer(function (layer) {
        //     if (layer instanceof FeatureGroup) {
        //         // console.log("...layer",layer)
        //         // map.removeLayer(layer)

        //         for (const [key, value] of Object.entries(layer._layers)) {
        //             if (geomType === 'AOI' && value.feature["geometry"]["type"] !== "LineString"){//FILTERING ALL BUT OLYGONS AND POLYLINES
        //                 // features.push(value.feature)
        //             }
        //             else if(geomType === 'FlightLines' && value.feature["geometry"]["type"] !== "Polygon"){
        //                 // features.push(value.feature)
        //                 // console.log("..._layer",value)
        //                 // value.options.color="#00FF00"
        //             }
        //         }
        //     }
        // });

        // let gj = geoJSON(features, {
        //      style: (feature) => {
        //         var styles = {};
        //         if (feature["geometry"]["type"] === "Polygon") {
        //             return polygonStyle;
        //         }
        //         else if (feature["geometry"]["type"] === "LineString") {
        //             return polylineStyle;
        //         }
        //         return styles;
        //     }
        // })



        // map.addLayer(gj)

        // _loadedGeometry.push({
        //     name: file.name,
        //     layer: gj,
        //     visible: true
        // })

        //NOT WORKING with multiple files... only zooming to last
        // let tempBounds = latLngBounds();
        // map.eachLayer(function (layer) {
        //     // Check if layer is a featuregroup
        //     if (layer instanceof FeatureGroup) {
        //         // Extend bounds with group's bounds
        //         tempBounds.extend(layer.getBounds());
        //     }
        // });

        // map.fitBounds(tempBounds);
        // // console.log("..._loadedGeometry3", _loadedGeometry)
        // // setLoadedGeometry(_loadedGeometry);
        // forceUpdate();

        // setLoadedGeometry([]);
        // setSelectionEnabled(false);
        // resetSelectedState();
    }

    const handleZoomDataExtents = () => {
        let tempBounds = latLngBounds();
        map.eachLayer(function (layer) {
            if (layer instanceof FeatureGroup) {
                tempBounds.extend(layer.getBounds());
            }
        });

        map.fitBounds(tempBounds);
    }

    const handleListSelection = (item) => {
        setSelected(item);
    }

    const resetColor = (key) => {
        setCurrentColor(undefined);
        let _availColors = [...availColors];
        let item = assignmentDictionary[key];
        _availColors.push(item.color);
        setAvailColors(_availColors)
    }

    const removeAssignment = (key) => {

        let item = assignmentDictionary[key];
        resetColor(key);
        removeSelectedLayers(item.layers);

        // let _assignmentDictionary = JSON.parse(JSON.stringify(assignmentDictionary));//deep copy
        let _assignmentDictionary = copyDict(assignmentDictionary);
        delete _assignmentDictionary[key];
        setAssignmentDictionary(_assignmentDictionary);

        setSelected(undefined);
    }

    const testProjectId = (event) => {
        let results = (event.target.value + event.key).match(/PRJ\d{1,6}/g);
        // if (results === null || results.length != 1) {
        //     event.preventDefault();
        // }
        // else if (results[0].length === 9) {
        setInstructionFilterParams({ projectId: results[0] })
        // this.props.fetchSensors(this.state.token, results[0])
        // this.props.fetchGPS(this.state.token, results[0])
        // this.setState({ flightId: results[0] });
        // }
    }

    const onChangeProjectId = (event) => {
        if (event.currentTarget.value.length < 9) {
            // this.setState({
            //     // lowestSensor: undefined,
            //     isSelected: '',
            //     selectedSensor: undefined,
            //     selectedTemplate : undefined
            // });
            // this.setState({ isSelected: '', selectedSensor:undefined });
        }
    }

    if (isLoading) {
        return (
            <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                <CircularProgress />
                <div style={{ paddingTop: 5 }}>Loading Flight Planning Map...</div>
            </Grid>
        )

    }
    if (!isLoading) {

        let queryParams = props.queryParams;
        let instructions = props.instructions || [];
        return (
           

            <Grid container direction="row" alignItems="flex-start" justify="flex-start" style={{ width: "100%" }}
                onDragEnter={e => handleDragEnter(e)}
                onDragLeave={e => handleDragLeave(e)}
                onDragOver={e => handleDragOver(e)}
                onDrop={e => handleDrop(e)}
            >
                <Map

                    style={{ marginLeft: 10, width: "calc(100vw - 300px)", zIndex: 1 }}
                    // ref={mapRef}
                    // ref={Map => map = Map.leafletElement}
                    ref={Map => Map ? setMap(Map.leafletElement) : null}
                    center={[-28.2744, 133.7751]} zoom={zoomLevel}
                    zoomControl={false}
                    attributionControl={false}>

                    {
                        loadedGeometry.length === 0 &&
                        <Grid container align="center" direction="row" justify="center" style={{ marginTop: 8, width: 'calc(100% - 80px)', zIndex: 9999, position: "absolute", left: 8, backgroundColor: "#000000", color: "#FFFFFF", opacity: 0.65, padding: 5, borderRadius: 5 }}>
                            <InfoIcon style={{ position: 'absolute', left: 5 }} />
                            <span style={{ paddingTop: 3, paddingLeft: 5, paddingRight: 3, fontSize: 16 }}>
                                Load a file with AOI or Flight Line geometry. Click
                            </span>
                            <FolderOpenIcon />
                            <span style={{ paddingTop: 3, paddingLeft: 3, fontSize: 16 }}>
                                or drag and drop one or more files (kmz or shp) onto the map.
                            </span>
                        </Grid>
                    }
                    {
                        loadedGeometry.length > 0 && selected === undefined &&
                        <Grid container align="center" direction="row" justify="center" style={{ marginTop: 8, width: 'calc(100% - 80px)', zIndex: 9999, position: "absolute", left: 8, backgroundColor: "#000000", color: "#FFFFFF", opacity: 0.65, padding: 5, borderRadius: 5 }}>
                            <InfoIcon style={{ position: 'absolute', left: 5 }} />
                            <span style={{ paddingTop: 3, paddingLeft: 5, fontSize: 16 }}>
                                Select a Project / LiDAR Instruction from the list at the top right
                            </span>
                        </Grid>
                    }
                    {
                        loadedGeometry.length > 0 && selected !== undefined && Object.entries(assignmentDictionary).length === 0 &&
                        <Grid container align="center" direction="row" justify="center" style={{ marginTop: 8, width: 'calc(100% - 80px)', zIndex: 9999, position: "absolute", left: 8, backgroundColor: "#000000", color: "#FFFFFF", opacity: 0.65, padding: 5, borderRadius: 5 }}>
                            <InfoIcon style={{ position: 'absolute', left: 5 }} />
                            <span style={{ paddingTop: 3, paddingLeft: 5, paddingRight: 3, fontSize: 16 }}>
                                Use the selection tool on the right
                            </span>
                            <HighlightAltIcon />
                            <span style={{ paddingTop: 3, paddingLeft: 3, fontSize: 16 }}>
                                to associate the selected instruction with an AOI or Flight Lines
                            </span>
                        </Grid>
                    }
                    {
                        loadedGeometry.length > 0 && selected != undefined && Object.entries(assignmentDictionary).length > 0 &&
                        <Grid container align="center" direction="row" justify="center" style={{ marginTop: 8, width: 'calc(100% - 80px)', zIndex: 9999, position: "absolute", left: 8, backgroundColor: "#000000", color: "#FFFFFF", opacity: 0.65, padding: 5, borderRadius: 5 }}>
                            <InfoIcon style={{ position: 'absolute', left: 5 }} />
                            <span style={{ paddingTop: 3, paddingLeft: 5, paddingRight: 3, fontSize: 16 }}>
                                When happy all relevant AOI's or Flight Lines have been assigned a Project / LiDAR Instruction, click save
                            </span>
                            <SaveIcon />

                        </Grid>
                    }


                    <Grid container spacing={8} direction="column" alignItems="center" justify="flex-start" style={{ marginTop: 8, width: 60, zIndex: 9999, position: "absolute", right: 5 }}>
                        <Grid item>
                            <Tooltip title="Zoom In" placement="left">
                                <Fab color="primary" size="small" onClick={e => map.setZoom(map._zoom + 1)}>
                                    <ZoomInIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Zoom Out" placement="left">
                                <Fab color="primary" size="small" onClick={e => map.setZoom(map._zoom - 1)}>
                                    <ZoomOutIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Zoom to Data Extents" placement="left">
                                <Fab color="primary" size="small" disabled={loadedGeometry.length === 0} onClick={e => handleZoomDataExtents()}>
                                    <SettingsOverscanIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid container spacing={8} direction="column" alignItems="center" justify="center" style={{ width: 60, height: "100%", zIndex: 9998, position: "absolute", right: 15 }}>

                        <Grid item>
                            {/* <Tooltip title="Load Geometry" placement="left">
                                <Fab color="primary" size="small" onClick={e => getSelectionByColor('blah')}>
                                    <FolderOpenIcon />
                                </Fab>
                            </Tooltip> */}

                            <input
                                // disabled={this.state.selectDisabled}
                                accept=".kmz"
                                id="uploadButton"
                                // webkitdirectory="true"
                                // directory="true"
                                multiple type="file"
                                style={{ display: 'none' }}
                                onChange={(event) => handleBrowseLoad(event)}
                            // ref="selectButton" 
                            />

                            <label htmlFor="uploadButton">
                                <Tooltip title="Load Geometry" placement="left">
                                    <Button
                                        variant="contained" component="span" color="primary"
                                        style={{ width: 80, height: 80, borderRadius: '50%', transform: 'scale(0.5)', marginBottom: -48 }}
                                    // onClick={e => console.log("...clicked2")}
                                    >
                                        {/* <FolderOpenIcon style={{position:'absolute' }}/> */}
                                    </Button>
                                </Tooltip>
                            </label>
                            <FolderOpenIcon style={{ position: 'relative', left: 28, top: -4, color: '#FFFFFF', pointerEvents: 'none' }} />
                        </Grid>

                        <Grid item direction="row" style={{ width: 200, marginLeft: -120 }}>

                            <Tooltip title="Add to Selection" placement="top">
                                <Fab color="primary" size="small"
                                    style={{ backgroundColor: loadedGeometry.length === 0 || addSelected === false ? null : selectionEnabled ? '#fdbc2f' : '#1a3a69', marginRight: 5, visibility: selectionEnabled ? 'visible' : 'hidden' }}

                                    onClick={e => handleEnableSelect()}
                                    disabled={loadedGeometry.length === 0 || selected === undefined}
                                >
                                    <AddIcon />
                                </Fab>
                            </Tooltip>

                            <Tooltip title="Remove from Selection" placement="top">
                                <Fab color="primary" size="small"
                                    style={{ backgroundColor: loadedGeometry.length === 0 || removeSelected === false ? null : selectionEnabled ? '#fdbc2f' : '#1a3a69', marginRight: 5, visibility: selectionEnabled ? 'visible' : 'hidden' }}
                                    onClick={e => handleEnableRemove()}
                                    disabled={loadedGeometry.length === 0 || selected === undefined}
                                >
                                    <RemoveIcon />
                                </Fab>
                            </Tooltip>

                            <Tooltip title="Clear All Selections" placement="top">
                                <Fab color="primary" size="small"
                                    style={{ marginRight: 5, visibility: selectionEnabled ? 'visible' : 'hidden' }}
                                    onClick={e => resetSelectedState()}
                                    disabled={loadedGeometry.length === 0 || selected === undefined}
                                >
                                    <ClearIcon />
                                </Fab>
                            </Tooltip>

                            <Tooltip title="Associate Selected Project with Geometry" placement="right">
                                <Fab color="primary" size="small"
                                    style={{ backgroundColor: loadedGeometry.length === 0 || selected === undefined ? null : selectionEnabled ? '#fdbc2f' : '#1a3a69' }}
                                    onClick={e => setSelectionEnabled(!selectionEnabled)}
                                    disabled={loadedGeometry.length === 0 || selected === undefined}
                                >
                                    <HighlightAltIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title="Auto Cross-Strip Determination" placement="left">
                                <Fab color="primary" size="small" disabled={loadedGeometry.length === 0}>
                                    <AlignVerticalCenter />
                                </Fab>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Clear All Loaded Files" placement="left">
                                <Fab color="primary" size="small" disabled={loadedGeometry.length === 0} onClick={e => handleClearAll()}>
                                    <ClearIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid container spacing={8} direction="column" alignItems="center" justify="flex-end" style={{ marginTop: 8, width: 60, zIndex: 9999, position: "absolute", right: 5, bottom: 8 }}>
                        <Grid item>
                            <Tooltip title="Save" placement="left">
                                <Fab color="primary" size="small" disabled={true}>
                                    <SaveIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    {/* <Grid container alignItems="center" justify="center" spacing={8} style={{ width: "100%", zIndex: 9999, position: "absolute", bottom: 5 }}>
                        <Grid item>
                            <Button style={{ width: 130 }} variant="contained" color="primary">ALL</Button>
                        </Grid>
                        <Grid item>
                            <Button style={{ width: 130 }} variant="contained" color="primary">AOI</Button>
                        </Grid>
                        <Grid item>
                            <Button style={{ width: 130 }} variant="contained" color="primary">Flight Lines</Button>
                        </Grid>
                    </Grid> */}



                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    {/* {logGeoJSON &&
                        <GeoJSON key={geojsonKey} data={logGeoJSON} style={{ color: "#ff0000" }} />
                    } */}

                    <List alignItems="center" style={{ position: "absolute", bottom: 33, left: 0, zIndex: 9999 }} dense={true}>
                        {
                            loadedGeometry.map((item, index) => {
                                return (
                                    <ListItem
                                    // button
                                    // selected={selected === item.ProcInstID}
                                    // onClick={event => this.setState({ SensorName: sensor.Name })}
                                    >
                                        <div style={{ backgroundColor: "#000000", color: "#FFFFFF", opacity: 0.65, padding: 2, borderRadius: 5, marginLeft: -8, marginTop: -8 }}>
                                            <Grid container alignItems="center" justify="flex-start">
                                                <Checkbox
                                                    style={{ margin: -8 }}
                                                    checked={item.visible}
                                                    onChange={(event) => handleLayerToggle(index)}
                                                />
                                                <span>{item.name}</span>
                                                <IconButton style={{ padding: 3, color: "#FFFFFF" }} onClick={e => handleLayerRemove(index)}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </Grid>
                                        </div>
                                    </ListItem>
                                )
                            })
                        }
                    </List>

                    {loadedGeometry.length > 0 &&
                        <Grid container alignItems="center" justify="center" style={{ width: "100%", zIndex: 9999, position: "absolute", bottom: 12 }}>
                            <Grid container direction="row" alignItems="center" justify="space-around" spacing={8} style={{ width: 275, height: 30, backgroundColor: "#000000", color: "#FFFFFF", opacity: 0.65, padding: 0, borderRadius: 5, fontSize: 16 }}>
                                <div style={{ marginTop: -9, marginLeft: -15 }}>
                                    <Radio
                                        style={{ color: "#FFFFFF" }}
                                        checked={geomTypeFilter === 'All'}
                                        value='All'
                                        // onChange={e => setGeomTypeFilter(e.target.value)}
                                        onChange={e => handleFilterGeom(e.target.value)}
                                    />
                                    <span>All</span>
                                </div>
                                <div style={{ marginTop: -9, marginLeft: -15 }}>
                                    <Radio
                                        style={{ color: "#FFFFFF" }}
                                        checked={geomTypeFilter === 'AOI'}
                                        value='AOI'
                                        // onChange={e => setGeomTypeFilter(e.target.value)}
                                        onChange={e => handleFilterGeom(e.target.value)}
                                    />
                                    <span>AOI's</span>
                                </div>
                                <div style={{ marginTop: -9, marginLeft: -15 }}>
                                    <Radio
                                        style={{ color: "#FFFFFF" }}
                                        checked={geomTypeFilter === 'FlightLines'}
                                        value='FlightLines'
                                        onChange={e => handleFilterGeom(e.target.value)}
                                    />
                                    <span>Flight Lines</span>
                                </div>

                                {/* </RadioGroup> */}
                            </Grid>
                        </Grid>
                    }

                    {/* <Button style={{position:"absolute", bottom:5, zIndex:9999, transform:"rotate(-90deg)"}} color="primary">foo</Button> */}

                </Map>

                <ConfirmationDialog
                    title="Add/Replace Geometry"
                    cancelLabel="Add"
                    confirmLabel="Replace"
                    onCancel={() => {
                        addGeometry(false);
                        setDisplayReplaceDialog(false);
                    }}
                    onConfirm={() => {
                        // setLoadedGeometry([]);
                        addGeometry(true);
                        setDisplayReplaceDialog(false);
                    }}
                    open={displayReplaceDialog}>
                    <p>Do you want to replace all existing geometry or add this data to the current selection?</p>
                </ConfirmationDialog>

                <Grid direction="column" justify="center" alignItems="center" style={{ width: 268,  marginLeft: 10 }}>
                    {/* <Grid direction="column" justify="center" alignItems="center" style={{ width: 268, height: "calc(50vh - 138px)", marginLeft: 10 }}> */}
                    <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>FLIGHT LINES FOR<br/>RANGE EXTRACTION</div>
                    {/* <TextField
                        margin="dense"
                        placeholder="Filter by ProjectID"
                        style={{ width: "100%" }}
                    /> */}


                    {!props.instructionsListLoading &&
                        <div>
                            <List alignItems="center" style={{ height: "calc(100vh - 252px)", borderStyle: "solid", borderWidth: 1, borderColor: "#CCCCCC" }} dense={true}>
                                {
                                    instructions.map(item => {
                                        return (

                                            <ListItem
                                                style={{ height: 50 }}
                                                onClick={() => handleListSelection(item)}
                                                // onClick={() => console.log("...blah")}
                                                button
                                                selected={selected === item}
                                                // onClick={event => this.setState({ SensorName: sensor.Name })}

                                                divider style={{ width: "100%" }}>
                                                <Grid container alignItems="center" justify="space-between" style={{ width: "100%", fontSize:15 }}>
                                                    <span>{item.projectId}<br/>{item.siteName}</span>
                                                    <NicerTooltip placement="top" content="Assign to All">
                                                        <IconButton color="primary"
                                                            style={{ padding: 3 }}
                                                            onClick={event => { assignToAll(item) }}
                                                            disabled={loadedGeometry.length === 0}
                                                        >
                                                            <SelectAllIcon />
                                                        </IconButton>
                                                    </NicerTooltip>
                                                </Grid>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    }
                {/* {
                    props.instructionsListLoading &&
                    <Grid container direction="column" alignItems="center" justify="center" style={{ height: 572, padding: 10 }}>
                        <CircularProgress />
                        <div style={{ paddingTop: 5 }}>Loading Instructions...</div>
                    </Grid>
                } */}
            </Grid>
            </Grid >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        // instructions: state.insts,
        // queryParams: state.instructionsQueryParams,
        // instructionsListLoading: state.instructionsListLoading
        // instructionsListHasError: false
        // processingSubmitLoading: state.processingResubmitLoading,
        // processingSubmitSuccessful: state.processingResubmitSuccess && !state.processingResubmitLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    // return {
    //     fetchData: (token) => { dispatch(instructionFetchList(token)) },
    //     setQueryParams: payload => {
    //         dispatch(instructionQueryParams(payload))
    //         dispatch(instructionFetchList())
    //     },

    //     // clearAllFilters: () => { dispatch(clearAllFilters(true)) },
    // };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewExtractRangeMap);