import axios from 'axios';
import Config from '../app/Config.json'

const BASE_URI = Config.BASE_URI
const BASE_URI_IP = Config.IP_URI


export function sensorsListHasError(bool) {
    return {
        type: 'SENSORS_LIST_HAS_ERROR',
        hasError: bool
    };
}

export function sensorsListLoading(bool) {
    return {
        type: 'SENSORS_LIST_LOADING',
        isLoading: bool
    };
}

export function sensorsListSuccess(sensors) {
    return {
        type: 'SENSORS_LIST_SUCCESS',
        sensors
    };
}
/**
 * 
 * @param {*} page, total, pageSize 
 */
// export function sensorsQueryParams(payload) {
//     return {
//         type: 'SENSORS_QUERY_PARAMS',
//         payload
//     }
// }

export function clearSensorsList(){
    return (dispatch, getState) => { dispatch(sensorsListSuccess([])) };
}
/**
 * fetch sensors, used by the 'custom sensor processing' page
 */
export function sensorsFetchList(token, flightID) {
    return (dispatch, getState) => {
        dispatch(sensorsListLoading(true));
        const state = getState(),
            // { page, pageSize, ...queryParams } = state.sensorsQueryParams 
        token = token || state.token

        const server = BASE_URI + 'sensors' + `?FlightID=${flightID}&include=ProjectID+project+ProjectID+Projects,FlightID+flight+FlightID+Flights`,headers = { authorization: 'Bearer ' + token };

        axios.get(server, { /*params: queryParams.params,*/ headers }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(sensorsListLoading(false));
            return response;
        }).then((response) => dispatch(sensorsListSuccess(response.data))).catch(() => {
            dispatch(sensorsListLoading(false));
            dispatch(sensorsListHasError(true))
        });
    };
}


export function gpsListHasError(bool) {
    return {
        type: 'GPS_LIST_HAS_ERROR',
        hasError: bool
    };
}

export function gpsListLoading(bool) {
    return {
        type: 'GPS_LIST_LOADING',
        isLoading: bool
    };
}

export function gpsListSuccess(gps_list) {
    return {
        type: 'GPS_LIST_SUCCESS',
        gps_list
    };
}

export function clearGpsList(){
    return (dispatch, getState) => { dispatch(gpsListSuccess([])) };
}

export function gpsFetchList(token, flightID) {
    return (dispatch, getState) => {
        dispatch(gpsListLoading(true));
        const state = getState(),
        token = token || state.token

        const server = BASE_URI_IP + `flights/FL${flightID}/list_gps`,headers = { authorization: 'Bearer ' + token };

        axios.get(server, { headers }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(gpsListLoading(false));
            return response;
        }).then((response) => dispatch(gpsListSuccess(response.data))).catch(() => {
            dispatch(gpsListLoading(false));
            dispatch(gpsListHasError(true))
        });
    };
}

export function submitCustomGPSProcessingHasError(bool) {
    return {
        type: 'SUBMIT_CUSTOM_GPS_PROCESSING_HAS_ERROR',
        hasError: bool
    };
}

export function submitCustomGPSProcessingLoading(bool) {
    return {
        type: 'SUBMIT_CUSTOM_GPS_PROCESSING_LOADING',
        isLoading: bool
    };
}

export function submitCustomGPSProcessingSuccess(result) {
    return {
        type: 'SUBMIT_CUSTOM_GPS_PROCESSING_SUCCESS',
        result
    };
}

export function submitCustomGPSProcessing(token, flightID, sensorId, sensorName, processingTemplate, gpsData, lmsData, eoOnly) {
    return (dispatch, getState) => {
        dispatch(submitCustomGPSProcessingLoading(true));
        const state = getState(),
        token = token || state.token

        const server = BASE_URI_IP + `flights/FL${flightID}/submit_custom`;
        const headers = { authorization: 'Bearer ' + token };

        let dataObj = {
            sensorId:sensorId,
            sensorName:sensorName,
            processingTemplate:processingTemplate
        };
        if(gpsData !== undefined)
            dataObj["gps"] = gpsData
        if(lmsData !== undefined)
            dataObj["lms"] = lmsData
        if(eoOnly !== false)
            dataObj["EOOnly"] = eoOnly

        axios.put(server, dataObj, { headers })
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(submitCustomGPSProcessingLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(submitCustomGPSProcessingSuccess(response))
            })
            .catch(() => {
                dispatch(submitCustomGPSProcessingLoading(false));
                dispatch(submitCustomGPSProcessingHasError(true))
            });
    };
}

