import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, TableBody, TableRow, TableCell, Table, TableHead, TableFooter, TablePagination, MenuItem, Button, CircularProgress, Tooltip, Select } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { paramsFetchList, paramsQueryParams } from './ProcessingActions'
// import FilterTextField from '../common/FilterTextField';
// import FilterComboField from '../common/FilterComboField';
// import ProcessingStatusTableIcon from './ProcessingStatusTableIcon';
import TableAircraftTypeIcon from '../common/TableAircraftTypeIcon';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit'
import Search from '@material-ui/icons/Search'
import DeleteForever from '@material-ui/icons/DeleteForever'
import NicerTooltip from '../common/NicerTooltip';
import Config from '../app/Config.json'
import ProcessingParamStatusIcon from '../common/ProcessingParamStatusIcon';
import FilterDateField from '../common/FilterDateField';
import PopoverDateFilter from '../common/PopoverDateFilter';
import PopoverTextFilter from '../common/PopoverTextFilter';
import PopoverSelectFilter from '../common/PopoverSelectFilter';
import { clearAllFilters, uniqueValuesFetch } from '../common/CompositeFilterActions'
import CancelIcon from '@material-ui/icons/Cancel';
import { aircraftFetchList } from '../processing/AircraftActions';
class ProcessingParamsList extends Component {

    constructor(props) {
        super(props);
        // this.getProcessing();
        // this.state.processing = this.props.processing || [];
    }

    state = {
        queryObject: {},
        params: [],
        polling: false
        // rowsPerPage: 10
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log("nextProps.processing",nextProps.params)
        if (nextProps.params !== null && nextProps.params.length > 0)
            return {
                params: nextProps.params.splice(0, nextProps.params.length)
            }
        // return {
        //     // params: []
        // }
    }

    componentDidMount() {
        this.getParams();
        this.getSensorNames();
        this.getAircraft();
        this.clearAllFilters();
    }

    componentDidUpdate(prevProps, prevState) {

        if (!this.props.paramsListLoading && prevProps.paramsListLoading) {
            if (this.state.pollingTimeoutId) {
                clearTimeout(this.state.pollingTimeoutId);
                this.state.pollingTimeoutId = null
                this.state.polling = false;
            }

            this.state.pollingTimeoutId = setTimeout(() => {
                this.state.polling = true;
                this.getParams();
            }, Config.SLOW_POLLING);

        }

    }

    componentWillUnmount() {
        if (this.state.pollingTimeoutId) {
            clearTimeout(this.state.pollingTimeoutId);
            this.state.pollingTimeoutId = null
            this.state.polling = false;
        }
    }

    clearAllFilters = () => {
        this.setState({ queryObject: {}, selectedValue:"" }, this.props.setQueryParams({ params: {}, page: 1 }));//not maintaining filters between page loads
        this.props.clearAllFilters();
    }

    getSensorNames = () => {
        if (this.props.sensorNames.length === 0 && !this.props.sensorNamesListLoading)
            this.props.fetchUniqueValues(this.props.token, "calibration_parameters", "SensorName");
    }

    getAircraft = () => {
        if (this.props.aircraft.length === 0 && !this.props.aircraftListLoading)
            this.props.fetchAircraft(this.props.token);
    }

    getParams = () => {
        this.props.fetchData(this.props.token);
    }

    changePage = (evt, page) => {
        page = page + 1
        this.props.setQueryParams({ page })
    }

    onFilterChange = (name, value) => {
        console.log(name)
        console.log(value)
        // let name = event.target.name;
        // let value = event.target.value;
        let queryParam = { [name]: value };

        if (value === '')
            delete this.state.queryObject[name]
        else
            Object.assign(this.state.queryObject, queryParam);

        if (this.timerInterval)
            clearTimeout(this.timerInterval);

        this.timerInterval = setTimeout(() => {
            this.props.setQueryParams({ params: this.state.queryObject, page: 1 })
        }, 1000);

    }

    statusFilterChange = (value) => {
        delete this.state.queryObject['IsActive']
        delete this.state.queryObject['ExpiryDate']
        if (value === 'VALID_AND_CURRENT') {
            // let today = new Date();
            // let offsetInHours = today.getTimezoneOffset() / 60;
            // let todayUTC = new Date();
            // todayUTC.setHours(todayUTC.getHours() + offsetInHours);
            // console.log('...',todayUTC)

            // let formatted_date = todayUTC.getFullYear() + "-" + (todayUTC.getMonth() + 1) + "-" + todayUTC.getDate() + " " + todayUTC.getHours() + ":" + todayUTC.getMinutes() + ":" + todayUTC.getSeconds() 
            // console.log('...',formatted_date)
            // Object.assign(this.state.queryObject, { ['IsActive']: true, ['ScheduledExpiryDate']: '$gtDate'+formatted_date });
            Object.assign(this.state.queryObject, { ['IsActive']: true, ['ExpiryDate']: '$notExists' });
            this.setState({ selectedStatusFilter: "Current" })
        }
        else if (value === 'VALID_AND_EXPIRED') {
            Object.assign(this.state.queryObject, { ['IsActive']: true, ['ExpiryDate']: '$exists' });
            this.setState({ selectedStatusFilter: "Expired" })
        }
        else if (value === 'INVALID') {
            Object.assign(this.state.queryObject, { ['IsActive']: false });
            this.setState({ selectedStatusFilter: "Invalid" })
        }
        else if (value === '') {
            this.setState({ selectedStatusFilter: "" })
        }
        console.log('...', this.state.queryObject)
        this.props.setQueryParams({ params: this.state.queryObject, page: 1 });
    }

    isEquivalent = (a, b) => {
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);

        if (aProps.length != bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];

            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        return true;
    }

    render() {

        // let processing = this.props.processing || [];
        let params = this.state.params;

        let queryParams = this.props.queryParams;
        console.log("queryParams", queryParams)

        const flightIdColumnStyle = { textAlign: "center", width: 70 };
        const timeColumnStyle = { textAlign: "center", width: 60 };
        const statusColumnStyle = { textAlign: "center", width: 40 };
        const sensorNameColumnStyle = { textAlign: "center", width: 150 };
        const headerDivTextStyle = { fontWeight: "bolder", color: "#000000" };

        return (
            <div>
                {
                    this.props.paramsListLoading && this.state.polling === false &&
                    <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                        <CircularProgress />
                        <div style={{ paddingTop: 5 }}>Loading Parameters...</div>
                    </Grid>
                }

                <Grid container direction="column" alignItems="center" justify="center" style={{ width: 995, display: this.props.paramsListLoading && !this.state.polling ? 'none' : 'flex' }}>

                    <Button
                        variant="contained" component="span" color="primary"
                        style={{ width: 300, height: 30 }}>
                        Add New Calibration Parameters
                        <Link to={"/ip/params/new"} style={{ position: "absolute", width: "100%", height: "100%" }}></Link>
                    </Button>

                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>ID</div></TableCell> */}
                                <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Aircraft</div></TableCell>
                                <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}>Aircraft<br />Type</div></TableCell>
                                <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>Sensor</div></TableCell>
                                <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Survey Date<br />Wheels Up<br />(UTC)</div></TableCell>
                                <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Expiry<br />(UTC)</div></TableCell>
                                <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Calibration<br />Due<br />(UTC)</div></TableCell>
                                <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>LMS<br />Version</div></TableCell>
                                <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Updated<br />(UTC)</div></TableCell>
                                <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}>Status</div></TableCell>
                                <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}>View</div></TableCell>
                                <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}>Edit</div></TableCell>
                                <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}>Delete</div></TableCell>
                            </TableRow>
                            <TableRow>
                                {/* <FilterTextField name="Aircraft" width={flightIdColumnStyle.width} onUpdate={this.onFilterChange} /> */}
                                {/* <PopoverTextFilter name="Aircraft" title="Aircraft" width={flightIdColumnStyle.width} onUpdate={this.onFilterChange} /> */}

                                <PopoverSelectFilter name="Aircraft" title="Aircraft" width={flightIdColumnStyle.width} onUpdate={this.onFilterChange} values={this.props.aircraft} />

                                <PopoverSelectFilter name="AircraftType" title="Aircraft Type" width={statusColumnStyle.width} onUpdate={this.onFilterChange} values={[
                                    { value: "FixedWing", label: "Fixed-Wing" },
                                    { value: "Rotary", label: "Rotary" }
                                ]} />
                                {/* <FilterTextField name="SensorName" width={sensorNameColumnStyle.width} onUpdate={this.onFilterChange} /> */}
                                <PopoverSelectFilter name="SensorName" title="Sensor" width={sensorNameColumnStyle.width} onUpdate={this.onFilterChange} values={this.props.sensorNames} />
                                {/* <FilterDateField name="SurveyDate" width={flightIdColumnStyle.width} onUpdate={this.onFilterChange} /> */}
                                <PopoverDateFilter name="SurveyDate" title="Date Range" width={flightIdColumnStyle.width} onUpdate={this.onFilterChange} />
                                <TableCell style={{ position: 'relative', top: -10, width: '100%' }} />
                                <TableCell style={{ position: 'relative', top: -10, width: '100%' }} />
                                <TableCell style={{ position: 'relative', top: -10, width: '100%' }} />
                                <TableCell style={{ position: 'relative', top: -10, width: '100%' }} />
                                {
                                    //a filter combo that is based on two fields
                                }
                                <TableCell padding="none" style={{ position: 'relative', paddingLeft: 10, paddingRight: 1, top: -10, width: statusColumnStyle.width }}>
                                    <Select
                                        style={{ fontSize: 12 }}
                                        value={this.state.selectedValue}
                                        onChange={(event) => {
                                            this.statusFilterChange(event.target.value)
                                            this.setState({ selectedValue: event.target.value });
                                        }}>
                                        <MenuItem value="" style={{ fontSize: 12 }}>None</MenuItem>
                                        <MenuItem value="VALID_AND_CURRENT" style={{ fontSize: 12 }}>Current</MenuItem>
                                        <MenuItem value="VALID_AND_EXPIRED" style={{ fontSize: 12 }}>Expired</MenuItem>
                                        <MenuItem value="INVALID" style={{ fontSize: 12 }}>Invalid</MenuItem>
                                    </Select>
                                </TableCell>

                                <TableCell style={{ position: 'relative', top: -10, width: '100%' }} />
                                <TableCell style={{ position: 'relative', top: -10, width: '100%' }} />
                                <TableCell style={{ position: 'relative', top: -10, width: '100%' }} />
                                {
                                    !this.isEquivalent(this.state.queryObject, {}) &&
                                    <NicerTooltip placement="top" content="Clear all filters">
                                        <IconButton color="primary" disabled={this.isEquivalent(this.state.queryObject, {})} style={{ marginBottom: 10 }} onClick={this.clearAllFilters}>
                                            <CancelIcon />
                                        </IconButton>
                                    </NicerTooltip>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {params.map(param => {
                                let expiryDate = param.ExpiryDate || 'T'
                                let scheduledExpiryDateString = param.ScheduledExpiryDate || 'T';
                                let today = new Date();
                                let offsetInHours = today.getTimezoneOffset() / 60;
                                let todayUTC = new Date();
                                todayUTC.setHours(todayUTC.getHours() + offsetInHours);
                                let scheduledExpiryDate = new Date(scheduledExpiryDateString);
                                let scheduledExpired = todayUTC >= scheduledExpiryDate;
                                const currentLMSVersion = this.props.currentLMSVersion;

                                return (
                                    <TableRow key={param.FlightID}>{/*need to add the object id to use here*/}
                                        {/* <TableCell padding="none" style={sensorNameColumnStyle}><Link to={"/ip/params/" + param._id}>{param.CompositeID}</Link></TableCell> */}
                                        <TableCell padding="none" style={flightIdColumnStyle}>{param.Aircraft}</TableCell>
                                        <TableCell padding="none" style={statusColumnStyle}><TableAircraftTypeIcon type={param.AircraftType} /></TableCell>
                                        <TableCell padding="none" style={sensorNameColumnStyle}>{param.SensorName}</TableCell>
                                        <TableCell padding="none" style={flightIdColumnStyle}>{param.SurveyDate.split("T")[0]}<br />{param.SurveyDate.split("T")[1]}</TableCell>
                                        <TableCell padding="none" style={flightIdColumnStyle}>{expiryDate.split("T")[0]}<br />{expiryDate.split("T")[1]}</TableCell>
                                        <TableCell padding="none" style={flightIdColumnStyle}>
                                            <span style={expiryDate === 'T' ? { display: 'inline' } : { display: 'none' }}>
                                                <span style={scheduledExpired ? { color: '#FF0000', fontWeight: 'bold' } : {}}>{scheduledExpiryDateString.split("T")[0]}</span>
                                            </span>
                                        </TableCell>
                                        <TableCell padding="none" style={flightIdColumnStyle}>
                                            <span style={currentLMSVersion !== param.LMSVersion ? { fontWeight: 'bold', color: '#FF0000' } : { color: '#000000' }}>{param.LMSVersion}</span>
                                        </TableCell>
                                        <TableCell padding="none" style={flightIdColumnStyle}>{param.Updated.split("T")[0]}<br />{param.Updated.split("T")[1].split("+")[0]}</TableCell>
                                        <TableCell padding="none" style={statusColumnStyle}><ProcessingParamStatusIcon isActive={param.IsActive} expiryDate={param.ExpiryDate} /></TableCell>
                                        <TableCell padding="none" style={statusColumnStyle}>
                                            <NicerTooltip placement="right" content={<span>Click to view parameters</span>}>
                                                <IconButton style={{width:50,height:50}}>
                                                    <Link to={"/ip/params/" + param._id}><Search style={{ color: '#1a3a69' }} /></Link>
                                                </IconButton>
                                            </NicerTooltip>
                                        </TableCell>
                                        <TableCell padding="none" style={statusColumnStyle}>
                                            {param.IsActive ? //will need to tweak or remove this, once propper permissions are in
                                                <IconButton disabled={true} style={{width:50,height:50}}>
                                                    <Link to={"/ip/params/" + param._id + "/edit"}><Edit style={{ color: '#CCCCCC' }} /></Link>
                                                </IconButton> :
                                                <IconButton disabled={false} style={{width:50,height:50}}>
                                                    <Link to={"/ip/params/" + param._id + "/edit"}><Edit style={{ color: '#1a3a69' }} /></Link>
                                                </IconButton>
                                            }
                                        </TableCell>
                                        <TableCell padding="none" style={statusColumnStyle}>
                                            <IconButton disabled={true} style={{width:50,height:50}}>
                                                <Link to={"/ip/params/" + param._id}><DeleteForever style={{ color: '#CCCCCC' }} /></Link>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            }

                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    count={queryParams.total}
                                    rowsPerPage={10}
                                    page={queryParams.page - 1}
                                    onChangePage={this.changePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>

                </Grid >
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        paramsListLoading: state.paramsListLoading,
        queryParams: state.paramsQueryParams,
        params: state.params,
        currentLMSVersion: state.appSettings ? state.appSettings.LMSCurrentVersion : null,
        sensorNames: state.uniqueValuesFetchSuccess && state.uniqueValuesFetchSuccess["calibration_parameters.SensorName"] ?
            state.uniqueValuesFetchSuccess["calibration_parameters.SensorName"].map(item => { return { "label": item, "value": item } }) : [],
        sensorNamesLoading: state.uniqueValuesFetchLoading,
        sensorNamesHasError: state.uniqueValuesFetchHasError,
        aircraftListLoading: state.aircraftListLoading,
        aircraft: state.aircraft ? state.aircraft.map(item => { return { "label": item.Name, "value": item.Name } }) : [],
    };
};

// //this is needed to match thre returned result to properties
const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (token) => { dispatch(paramsFetchList(token)) },
        setQueryParams: payload => {
            dispatch(paramsQueryParams(payload))
            dispatch(paramsFetchList())
        },
        fetchUniqueValues: (token, collection, field) => { dispatch(uniqueValuesFetch(token, collection, field)) },
        fetchAircraft: (token) => {dispatch(aircraftFetchList(token));},
        clearAllFilters: () => { dispatch(clearAllFilters(true)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingParamsList);