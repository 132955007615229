import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import { 
    Grid, CircularProgress
} from '@material-ui/core'
import { 
    handleFetchFlightByFlightId, handleFetchSensorEquipmentList,
    updateUploadMeta, updateUploadStatus, updateExistingData,
    clearUploaderStore
} from './UploaderActions'
import UploadPanel, { VALID_STATUS } from './UploadPanel'
import UploadProgress from './UploadProgress'
import { UploaderStore, FlightUploaMeta } from './UploaderTypes'
import './commonStyles.css'

//////////////////////////////////
//          Interfaces          //
//////////////////////////////////
interface URLParams {
    flightId: string;
    uploadId: string;
}

//////////////////////////////////
//           Constants          //
//////////////////////////////////
const REDIRECT_TO_HOMEPAGE_TIMEOUT = 5 * 1000           // unit: ms

//////////////////////////////////
//           Component          //
//////////////////////////////////
const Uploader = (props: UploaderFromRedux) => {
    // Props from Redux mapStateToProps
    const { uploaderStore } = props
    // Props from Redux mapDispatchToProps
    const { 
        handleFetchFlightByFlightId, handleFetchSensorEquipmentList,
        updateUploadMeta, updateUploadStatus, updateExistingData,
        clearUploaderStore
    } = props
    
    const {flightId, uploadId} = useParams<URLParams>()
    let history = useHistory()
    // A bit confusing here. So have to explain it. 
    // isFetchDataSucceed != !isFetchDataFailed. Because it may be under "unknown" status. 
    // For example, it is fetching flight data but have got response.
    const [isFetchDataFailed, setIsFetchDataFailed] = useState<boolean>(false)
    const [isFetchDataSucceed, setIsFetchDataSucceed] = useState<boolean>(false)

    // Initialization
    useEffect(() => {
        if(validateFlightId() && validateUploadId()) {
            handleFetchFlightByFlightId(flightId)
            handleFetchSensorEquipmentList()
        }
        return () => {
            clearUploaderStore()
        }
    }, [])

    useEffect(() => {
        if(uploaderStore.flight) {
            const flightUploadMeta = extractFlightUploaMeta()
            if(Object.keys(flightUploadMeta).length) {
                updateUploadMeta({
                    flightId,
                    uploadId,
                    // ...(flightUploadMeta as FlightUploaMeta)
                    sensorName: (flightUploadMeta as FlightUploaMeta).sensorName,
                    uploadType: (flightUploadMeta as FlightUploaMeta).uploadType,
                    user: (flightUploadMeta as FlightUploaMeta).user,
                    updated: (flightUploadMeta as FlightUploaMeta).updated,
                    isUpdate: (flightUploadMeta as FlightUploaMeta).isUpdate,
                    processingTemplate: uploaderStore.flight
                                                ? (uploaderStore.flight.Sensors[0] as any).Project.ProcessingTemplate
                                                : ''
                })
                updateUploadStatus((flightUploadMeta as FlightUploaMeta).status)
                updateExistingData((flightUploadMeta as FlightUploaMeta).isUpdate 
                                    || [VALID_STATUS.DATA_READY].indexOf((flightUploadMeta as FlightUploaMeta).status) > -1)
            }
        }
    }, [uploaderStore.flight])

    useEffect(() => {
        (uploaderStore.isFetchFlightSucceed !== undefined) && (uploaderStore.isFetchSensorEquipmentSucceed !== undefined)
            && setIsFetchDataFailed((!uploaderStore.isFetchingFlight && !uploaderStore.isFetchFlightSucceed) 
                                || (!uploaderStore.isFetchingSensorEquipment && !uploaderStore.isFetchSensorEquipmentSucceed))
        setIsFetchDataSucceed(!!(!uploaderStore.isFetchingFlight && uploaderStore.isFetchFlightSucceed) 
                                && !!(!uploaderStore.isFetchingSensorEquipment && uploaderStore.isFetchSensorEquipmentSucceed))
    }, [uploaderStore.isFetchingFlight, uploaderStore.isFetchFlightSucceed, 
        uploaderStore.isFetchingSensorEquipment, uploaderStore.isFetchSensorEquipmentSucceed])

    useEffect(() => {
        if([VALID_STATUS.UPLOAD_CANCELLED, VALID_STATUS.UPDATE_CANCELLED].indexOf(uploaderStore.status) > -1) {
            redirectToHomepage()
        }
    }, [isFetchDataFailed, uploaderStore.status])

    const validateFlightId = (): boolean => {
        const flightIdRegExp = /^FL(\d{6})$/
        return flightIdRegExp.test(flightId)
    }

    const validateUploadId = (): boolean => {
        const uploadIdRegExp = /^([A-Za-z0-9]{24})$/
        return uploadIdRegExp.test(uploadId)
    }

    const extractFlightUploaMeta = (): FlightUploaMeta | {} => {
        let flightUploadMeta: FlightUploaMeta | {} = {}
        if(uploaderStore.isFetchFlightSucceed && uploaderStore.flight) {
            uploaderStore.flight.Uploads.some(flightUploadElement => {
                if(flightUploadElement._id === uploadId) {
                    flightUploadMeta = {
                        sensorName: flightUploadElement.SensorName,
                        uploadType: flightUploadElement.Type,
                        status: flightUploadElement.Status,
                        user: flightUploadElement.User || undefined,
                        updated: flightUploadElement.Updated || undefined,
                        isUpdate: flightUploadElement.IsUpdate || false
                    }
                    return true
                } else {
                    return false
                }
            })
        }
        return flightUploadMeta
   }

   const redirectToHomepage = (timeout: number=REDIRECT_TO_HOMEPAGE_TIMEOUT) => {
        window.setTimeout(()=> {
            history.push(`/post`)
       }, timeout)
   }

//    const mapping

    return (
        <>
            {(uploaderStore.isFetchingFlight || uploaderStore.isFetchingSensorEquipment) &&
                <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                    <CircularProgress />
                    <div className='pt-5' >Loading upload details...</div>
                </Grid>
            }
            {/* {isFetchDataFailed
                && (uploaderStore.status === VALID_STATUS.UPLOAD_PAUSED
                        ? <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                            <strong className='mb-3'>Load flight details failed.</strong>
                            <strong>Please check your network then refresh the page.</strong>
                        </Grid>
                        : <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                            <strong className='mb-3'>{flightId} upload job cancelled successfully.</strong>
                            <strong>It is going to redirect to POST dashboard in seconds...</strong>
                        </Grid>)
            } */}
            {isFetchDataFailed
                && <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                        <strong className='mb-3'>Load flight or sensor details failed.</strong>
                        <strong className='mb-3'>Please check your network then refresh the page.</strong>
                        <strong>If you see this issue continually, please re-login LiDARnetics.</strong>
                    </Grid>
            }
            {(//!uploaderStore.isFetchingFlight 
                //&& !uploaderStore.isFetchingSensorEquipment
                // && !uploaderStore.isFetchingUploadProgress
                isFetchDataSucceed
                && uploaderStore.uploadMeta)
                && <Grid direction="column" justify="center" alignItems="center" spacing={0}
                    // style={{ display: (uploaderStore.isFetchingFlight || uploaderStore.isFetchingSensorEquipment) ? 'none' : '' }}
                    className='pt-2' 
                >
                    <UploadPanel />
                    <UploadProgress />
                </Grid>
            }
            {uploaderStore.status === 'UPLOAD_CANCELLED'
                && <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                    <strong className='mb-3'>The {flightId} upload job has been successfully cancelled.</strong>
                    <strong>It will redirect to the dashboard in a few seconds...</strong>
                </Grid>
            }
        </>
    )
}

// export default Uploader
const mapStateToProps = (rootState: any) => ({
    // authToken: rootState.token,
    uploaderStore: rootState.uploader as UploaderStore
})

const mapDispatchToProps = { 
    handleFetchFlightByFlightId, handleFetchSensorEquipmentList,
    updateUploadMeta, updateUploadStatus, updateExistingData,
    clearUploaderStore
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type UploaderFromRedux = ConnectedProps<typeof connector>

const ConnectedUploader = connector(Uploader)

export default ConnectedUploader