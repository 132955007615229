import axios from 'axios';
import Config from '../app/Config.json'

const BASE_URI = Config.IP_URI

export function sensorEquipmentListHasError(bool) {
    return {
        type: 'SENSOR_EQUIPMENT_LIST_HAS_ERROR',
        hasError: bool
    };
}

export function sensorEquipmentListLoading(bool) {
    return {
        type: 'SENSOR_EQUIPMENT_LIST_LOADING',
        isLoading: bool
    };
}

export function sensorEquipmentListSuccess(sensorEquipment) {
    return {
        type: 'SENSOR_EQUIPMENT_LIST_SUCCESS',
        sensorEquipment
    };
}
/**
 * 
 * @param {*} page, total, pageSize 
 */
export function sensorEquipmentQueryParams(payload) {
    return {
        type: 'SENSOR_EQUIPMENT_QUERY_PARAMS',
        payload
    }
}

export function sensorEquipmentFetchList(token) {
    return (dispatch, getState) => {

        dispatch(sensorEquipmentListLoading(true));
        let state = getState(), { page, pageSize,sortByName, ...queryParams } = state.sensorEquipmentQueryParams
        token = token || state.token

        //pagesize overriden for the time being to disable paging
        pageSize = -1;
        sortByName = "Name 1";
        
        const server = BASE_URI + 'sensor-equipment' + `?page=${page}&pageSize=${pageSize}&sort=${sortByName}`, headers = { authorization: 'Bearer ' + token };

        axios.get(server, { params: queryParams.params, headers }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }

            dispatch(sensorEquipmentListLoading(false));

            if (response.status === 200){
                dispatch(sensorEquipmentListSuccess(response.data));
            }

            const total = parseInt(response.headers['x-total-count'])
            dispatch(sensorEquipmentQueryParams({ total }))

            return response;

        }).catch(() => {
            dispatch(sensorEquipmentListLoading(false));
            dispatch(sensorEquipmentListHasError(true))
        });
    };
}