import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Switch } from 'react-router-dom'
import PrivateRoute from '../common/PrivateRoute'
import TMProcessingTabs from './TMProcessingTabs'
import TMProcessingList from './TMProcessingList'

const TMProcessingIndex = () => {
    return (
        <>
            <TMProcessingTabs />
            <PrivateRoute exact path='/iptm' component={TMProcessingList} />
        </>
    )
}

export default TMProcessingIndex
