import axios from 'axios';
import Config from '../app/Config.json'

const BASE_URI = Config.BASE_URI

export function authenticationError(bool) {
    return {
        type: 'AUTHENTICATION_ERROR',
        hasError: bool
    };
}

export function authenticationLoading(bool) {
    return {
        type: 'AUTHENTICATION_LOADING',
        isLoading: bool
    };
}

export function authenticationSuccess(token) {
    return {
        type: 'AUTHENTICATION_SUCCESS',
        token
    };
}

export function logout(){
    return {
        type: 'LOGOUT',
        token: null
    };
}

export function authenticate(username, password) {
    return (dispatch) => {
        dispatch(authenticationLoading(true));

        axios.get(
            BASE_URI + 'authenticate',
            {
                headers: {authorization: 'Basic '+btoa(username+':'+password)}
            }
        )
        .then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
    
            dispatch(authenticationLoading(false));
            dispatch(authenticationError(false))
            localStorage.setItem('LiDARnetics.token',response.data.token);

            return response;
        })
        .then((response) => dispatch(authenticationSuccess(response.data.token)))
        .catch(() => {
            dispatch(authenticationLoading(false));
            dispatch(authenticationError(true))
        });
    };
}

export function renewToken(token) {
    return (dispatch) => {
        dispatch(authenticationLoading(true));
        return axios.get(
            BASE_URI + 'renew',
            {
                headers: {authorization: 'Bearer ' + token}
            }
        )
        .then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
    
            dispatch(authenticationLoading(false));
            dispatch(authenticationError(false))
            localStorage.setItem('LiDARnetics.token',response.data.token);

            return response;
        })
        .then((response) => {
            dispatch(authenticationSuccess(response.data.token))
            return response.data.token
        })
        .catch(() => {
            dispatch(authenticationLoading(false));
            dispatch(authenticationError(true))
            return null
        });
    };
}

export function getAppSettings(token) {
    return (dispatch) => {
        dispatch(appSettingsLoading(true));

        axios.get(
            BASE_URI + 'settings',
            {
                headers: {authorization: 'Bearer ' + token}
            }
        )
        .then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
    
            dispatch(appSettingsLoading(false));
            dispatch(appSettingsError(false))
            // localStorage.setItem('LiDARnetics.ipAddress',response.data.ipAddress);

            return response;
        })
        .then((response) => dispatch(appSettingsSuccess(response.data)))
        .catch(() => {
            dispatch(appSettingsLoading(false));
            dispatch(appSettingsError(true))
        });
    };
}

export function appSettingsError(bool) {
    return {
        type: 'APP_SETTINGS_ERROR',
        hasError: bool
    };
}

export function appSettingsLoading(bool) {
    return {
        type: 'APP_SETTINGS_LOADING',
        isLoading: bool
    };
}

export function appSettingsSuccess(appSettings) {
    return {
        type: 'APP_SETTINGS_SUCCESS',
        appSettings
    };
}