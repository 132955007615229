export function sensorsListHasError(state = false, action) {
    switch (action.type) {
        case 'SENSORS_LIST_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function sensorsListLoading(state = false, action) {
    switch (action.type) {
        case 'SENSORS_LIST_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function sensors(state = null, action) {
    switch (action.type) {
        case 'SENSORS_LIST_SUCCESS':
            return action.sensors;

        default:
            return state;
    }
}

// export function sensorsQueryParams(state = {
//     page: 1, pageSize: 10, total: -1//, filter: 'All'
// }, action) {
//     switch (action.type) {
//         case 'SENSORS_QUERY_PARAMS':
//             return { ...state, ...action.payload };

//         default:
//             return state;
//     }
// }

export function gpsListHasError(state = false, action) {
    switch (action.type) {
        case 'GPS_LIST_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function gpsListLoading(state = false, action) {
    switch (action.type) {
        case 'GPS_LIST_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function gps_list(state = null, action) {
    switch (action.type) {
        case 'GPS_LIST_SUCCESS':
            return action.gps_list;

        default:
            return state;
    }
}

export function submitCustomGPSProcessingHasError(state = false, action) {
    switch (action.type) {
        case 'SUBMIT_CUSTOM_GPS_PROCESSING_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function submitCustomGPSProcessingLoading(state = false, action) {
    switch (action.type) {
        case 'SUBMIT_CUSTOM_GPS_PROCESSING_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function submitCustomGPSProcessingSuccess(state = null, action) {
    switch (action.type) {
        case 'SUBMIT_CUSTOM_GPS_PROCESSING_SUCCESS':
            return action.result;

        default:
            return state;
    }
}
