import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ClusterStatusIcon from './ClusterStatusIcon'; // Import your ClusterStatusIcon component
import Slider from "react-slick";
import { Grid } from '@material-ui/core';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './TMProcessing.css';     // Must below slick-carousel native CSS files

const SlickArrow = (props) => {
    let arrowDirectionClassName = (props.type === "next") ? "slick-next" : "slick-prev";
    return (
        // Default element copied from slick-carousel lib
        <button type="button"
            className={props.onClick ? `slick-arrow ${arrowDirectionClassName}` : `slick-arrow ${arrowDirectionClassName} slick-disabled`}
            onClick={(event) => {
                props.onClick && props.onClick(event)
                props.onClickArrowCallback && props.onClickArrowCallback()
            }}
        >
            Previous
        </button>
    )
}

const sliderStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start' // Align items to the left
};


const ClusterStatusIconList = ({ ClusterProcessing, process, pipelineGuid, blockRow, isRowBlocked }) => {
    if (!Array.isArray(ClusterProcessing)) {
        console.error('ClusterProcessing prop is not an array');
        return null;
    }

    // Settings for the slider
    // Inside the ClusterStatusIconList component
    const sliderRef = useRef(null)
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 11,
        slidesToScroll: 3,
        adaptiveHeight: true,
        arrows: true,
        swipe: false,
    };
    const [isSlickArrowClicked, setIsSlickArrowClicked] = useState(false)

    return (
      <Grid container direction="row" alignItems="center" justify="flex-start" style={{ marginLeft: 34 }}>
        <div style={{ width: 600 }}>
            <Slider {...settings} ref={sliderRef} className="my-custom-slider">
              {ClusterProcessing.map((processingItem, index) => (
                  <Grid item
                    container
                    key={index}
                    direction="column"
                    style={{
                        width: 50,
                        height: 50,
                        margin: 1,
                        position: "relative",
                        display: 'flex', // Adding flex here
                        justifyContent: 'flex-start' // And the desired justification
                  }}>
                  <div className='label2__box' style={{ textAlign: 'center', zIndex: 2 }}>
                    <p className="label2__box--font" style={{ margin: '0' }}>
                      {processingItem.label}
                    </p>
                  </div>
                  <ClusterStatusIcon 
                    process={process} 
                    cluster={processingItem}
                    iconId={`${pipelineGuid}-${index}`}
                    blockRow={() => blockRow(pipelineGuid)}
                    isBlocked={isRowBlocked(pipelineGuid)}
                    isActive={process.Active}
                    isArchived={process.isArchived}
                    showSuperseded={processingItem.showSuperseded}
                  />
                </Grid>
              ))}
            </Slider>
            </div>
        </Grid>
      );
};

ClusterStatusIconList.propTypes = {
    ClusterProcessing: PropTypes.arrayOf(PropTypes.object).isRequired,
    process: PropTypes.object.isRequired, // Make sure process is passed and is of correct type
};

export default ClusterStatusIconList;