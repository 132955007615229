export function createProjectSuccess(state = null, action) {
  switch (action.type) {
    case "CREATE_PROJECT_SUCCESS":
      return action.project;

    default:
      return state;
  }
}

export function createProjectHasError(state = false, action) {
  switch (action.type) {
    case "CREATE_PROJECT_HAS_ERROR":
      return { error: action.hasError, message: action.message };

    default:
      return state;
  }
}

export function searchClient(state = null, action) {
  switch (action.type) {
    case "SEARCH_CLIENT_SUCCESS":
      return action.searchResult;

    default:
      return state;
  }
}

export function searchClientHasError(state = false, action) {
  switch (action.type) {
    case "SEARCH_CLIENT_HAS_ERROR":
      return action.hasError;

    default:
      return state;
  }
}

export function searchProjectManager(state = null, action) {
  switch (action.type) {
    case "SEARCH_PROJECT_MANAGER_SUCCESS":
      return action.searchResult;

    default:
      return state;
  }
}

export function searchProjectManagerHasError(state = false, action) {
  switch (action.type) {
    case "SEARCH_PROJECT_MANAGER_HAS_ERROR":
      return action.hasError;

    default:
      return state;
  }
}
