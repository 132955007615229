import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';
import { HeloIcon } from '../icons/svgIcons'
import LocalAirport from '@material-ui/icons/LocalAirport'
import NicerTooltip from './NicerTooltip';

class TableAircraftTypeIcon extends Component {

    render() {
        const { type } = this.props;
        return (
            <div>
                {{
                    ['FixedWing']:
                        <NicerTooltip placement="right" content={<span>Fixed-Wing</span>}>
                            <LocalAirport style={{ color: '#1a3a69' }} />
                        </NicerTooltip>,
                    ['Rotary']:
                        <NicerTooltip placement="right" content={<span>Rotary</span>}>
                            <HeloIcon style={{ color: '#1a3a69' }} />
                        </NicerTooltip>,
                    //... may have other types like 'UAV' or variants etc
                }[type]}
            </div>
        );
    }
}

export default TableAircraftTypeIcon;