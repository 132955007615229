import axios from 'axios';
import Config from '../app/Config.json'

const BASE_URI = Config.TM_CLIENT_URI;
const ORCHESTRATION_URI = Config.TM_ORCHESTRATION_URI;

export const TM_PROCESSING_QUERY_PARAMS = 'TM_PROCESSING_QUERY_PARAMS';
export const tmProcessingQueryParams = payload => ({type: TM_PROCESSING_QUERY_PARAMS,payload})

export const FETCH_TM_PROCESSING_START = 'FETCH_TM_PROCESSING_START';
export const FETCH_TM_PROCESSING_SUCCESS = 'FETCH_TM_PROCESSING_SUCCESS';
export const FETCH_TM_PROCESSING_FAILURE = 'FETCH_TM_PROCESSING_FAILURE';

export const fetchTMProcessing = (page = 1, limit = 10) => {

    const server = `${BASE_URI}processing?page=${page}&limit=${limit}`;

    return (dispatch, getState) => {
        const state = getState();
        const token = state.token; // Ensure this line is correct, original line had undeclared variable
        const queryParams = state.TMProcessingQueryParamsReducer.tmQueryParams;

        const headers = { authorization: 'Bearer ' + token };

        dispatch({ type: FETCH_TM_PROCESSING_START });

        axios.get(server, { params: queryParams, headers })
            .then(response => {
                const { documents, count } = response.data; // Destructure documents and count from response data
                dispatch({ type: FETCH_TM_PROCESSING_SUCCESS, payload: { documents, count } }); // Dispatch both as payload
            })
            .catch(error => {
                dispatch({ type: FETCH_TM_PROCESSING_FAILURE, payload: error });
            });
    };
};

export const UPDATE_QA_DATA_START = 'UPDATE_QA_DATA_START';
export const UPDATE_QA_DATA_SUCCESS = 'UPDATE_QA_DATA_SUCCESS';
export const UPDATE_QA_DATA_FAILURE = 'UPDATE_QA_DATA_FAILURE';

export const updateQADataStart = () => ({ type: UPDATE_QA_DATA_START });
export const updateQADataSuccess = payload => ({ type: UPDATE_QA_DATA_SUCCESS, payload });
export const updateQADataFailure = error => ({ type: UPDATE_QA_DATA_FAILURE, error });

export const updateQaData = (data) => (dispatch, getState) => {
  dispatch(updateQADataStart());
  const server = `${ORCHESTRATION_URI}qa`;

  const state = getState();
  const token = state.token; 

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  axios.put(server, data, config)
    .then(response => {
      dispatch(updateQADataSuccess(response.data));
    })
    .catch(error => {
      dispatch(updateQADataFailure(error.data));
    });
};

export const UPDATE_QAWITHERRORS_DATA_START = 'UPDATE_QAWITHERRORS_DATA_START';
export const UPDATE_QAWITHERRORS_DATA_SUCCESS = 'UPDATE_QAWITHERRORS_DATA_SUCCESS';
export const UPDATE_QAWITHERRORS_DATA_FAILURE = 'UPDATE_QAWITHERRORS_DATA_FAILURE';

export const updateQAWithErrorsDataStart = () => ({ type: UPDATE_QAWITHERRORS_DATA_START });
export const updateQAWithErrorsDataSuccess = payload => ({ type: UPDATE_QAWITHERRORS_DATA_SUCCESS, payload });
export const updateQAWithErrorsDataFailure = error => ({ type: UPDATE_QAWITHERRORS_DATA_FAILURE, error });

export const updateQaWithErrorsData = (data) => (dispatch, getState) => {
  dispatch(updateQAWithErrorsDataStart());
  const server = `${ORCHESTRATION_URI}qawitherrors`;

  const state = getState();
  const token = state.token; 

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  axios.put(server, data, config)
    .then(response => {
      dispatch(updateQAWithErrorsDataSuccess(response.data));
    })
    .catch(error => {
      dispatch(updateQAWithErrorsDataFailure(error.data));
    });
};




export const UPDATE_IGNORE_DATA_START = 'UPDATE_IGNORE_DATA_START';
export const UPDATE_IGNORE_DATA_SUCCESS = 'UPDATE_IGNORE_DATA_SUCCESS';
export const UPDATE_IGNORE_DATA_FAILURE = 'UPDATE_IGNORE_DATA_FAILURE';

export const updateIgnoreDataStart = () => ({ type: UPDATE_IGNORE_DATA_START });
export const updateIgnoreDataSuccess = payload => ({ type: UPDATE_IGNORE_DATA_SUCCESS, payload });
export const updateIgnoreDataFailure = error => ({ type: UPDATE_IGNORE_DATA_FAILURE, error });

export const updateIgnoreData = (data) => (dispatch, getState) => {
  dispatch(updateIgnoreDataStart());
  const server = `${ORCHESTRATION_URI}ignore`;

  const state = getState();
  const token = state.token; 

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  axios.put(server, data, config)
    .then(response => {
      dispatch(updateIgnoreDataSuccess(response.data));
    })
    .catch(error => {
      dispatch(updateIgnoreDataFailure(error.data));
    });
};

export const UPDATE_MANUALLY_PROCESSED_DATA_START = 'UPDATE_MANUALLY_PROCESSED_DATA_START';
export const UPDATE_MANUALLY_PROCESSED_DATA_SUCCESS = 'UPDATE_MANUALLY_PROCESSED_DATA_SUCCESS';
export const UPDATE_MANUALLY_PROCESSED_DATA_FAILURE = 'UPDATE_MANUALLY_PROCESSED_DATA_FAILURE';

export const updateManuallyProcessedDataStart = () => ({ type: UPDATE_MANUALLY_PROCESSED_DATA_START });
export const updateManuallyProcessedDataSuccess = payload => ({ type: UPDATE_MANUALLY_PROCESSED_DATA_SUCCESS, payload });
export const updateManuallyProcessedDataFailure = error => ({ type: UPDATE_MANUALLY_PROCESSED_DATA_FAILURE, error });

export const updateManuallyProcessedData = (data) => (dispatch, getState) => {
  dispatch(updateManuallyProcessedDataStart());
  const server = `${ORCHESTRATION_URI}manuallyprocessed`;

  const state = getState();
  const token = state.token; 

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  axios.put(server, data, config)
    .then(response => {
      dispatch(updateManuallyProcessedDataSuccess(response.data));
    })
    .catch(error => {
      dispatch(updateManuallyProcessedDataFailure(error.data));
    });
};

export const UPDATE_MARK_SUPERSEDED_DATA_START = 'UPDATE_MARK_SUPERSEDED_DATA_START';
export const UPDATE_MARK_SUPERSEDED_DATA_SUCCESS = 'UPDATE_MARK_SUPERSEDED_DATA_SUCCESS';
export const UPDATE_MARK_SUPERSEDED_DATA_FAILURE = 'UPDATE_MARK_SUPERSEDED_DATA_FAILURE';

export const updateMarkSupersededDataStart = () => ({ type: UPDATE_MARK_SUPERSEDED_DATA_START });
export const updateMarkSupersededDataSuccess = payload => ({ type: UPDATE_MARK_SUPERSEDED_DATA_SUCCESS, payload });
export const updateMarkSupersededDataFailure = error => ({ type: UPDATE_MARK_SUPERSEDED_DATA_FAILURE, error });

export const updateMarkSuperseded = (data) => (dispatch, getState) => {
  dispatch(updateMarkSupersededDataStart());
  const server = `${ORCHESTRATION_URI}marksuperseded`;

  const state = getState();
  const token = state.token; 

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  axios.put(server, data, config)
    .then(response => {
      dispatch(updateMarkSupersededDataSuccess(response.data));
    })
    .catch(error => {
      dispatch(updateMarkSupersededDataFailure(error.data));
    });
};

export const UPDATE_RESUBMIT_DATA_START = 'UPDATE_RESUBMIT_DATA_START';
export const UPDATE_RESUBMIT_DATA_SUCCESS = 'UPDATE_RESUBMIT_DATA_SUCCESS';
export const UPDATE_RESUBMIT_DATA_FAILURE = 'UPDATE_RESUBMIT_DATA_FAILURE';

export const updateResubmitDataStart = () => ({ type: UPDATE_RESUBMIT_DATA_START });
export const updateResubmitDataSuccess = payload => ({ type: UPDATE_RESUBMIT_DATA_SUCCESS, payload });
export const updateResubmitDataFailure = error => ({ type: UPDATE_RESUBMIT_DATA_FAILURE, error });

export const updateResubmit = (data) => (dispatch, getState) => {
  dispatch(updateResubmitDataStart());
  const server = `${ORCHESTRATION_URI}resubmit`;

  const state = getState();
  const token = state.token; 

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  axios.put(server, data, config)
    .then(response => {
      dispatch(updateResubmitDataSuccess(response.data));
    })
    .catch(error => {
      dispatch(updateResubmitDataFailure(error.data));
    });
};

export const UPDATE_CANCEL_JOB_START = 'UPDATE_CANCEL_JOB_START';
export const UPDATE_CANCEL_JOB_SUCCESS = 'UPDATE_CANCEL_JOB_SUCCESS';
export const UPDATE_CANCEL_JOB_FAILURE = 'UPDATE_CANCEL_JOB_FAILURE';

export const updateCancelJobStart = () => ({ type: UPDATE_CANCEL_JOB_START });
export const updateCancelJobSuccess = payload => ({ type: UPDATE_CANCEL_JOB_SUCCESS, payload });
export const updateCancelJobFailure = error => ({ type: UPDATE_CANCEL_JOB_FAILURE, error });

export const updateCancelJob = (data) => (dispatch, getState) => {
  dispatch(updateCancelJobStart());
  const server = `${ORCHESTRATION_URI}cancel`;

  const state = getState();
  const token = state.token; 

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  axios.put(server, data, config)
    .then(response => {
      dispatch(updateCancelJobSuccess(response.data));
    })
    .catch(error => {
      dispatch(updateCancelJobFailure(error.data));
    });
};
