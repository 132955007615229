const HeartbeatWorker = () => {
    /* eslint no-restricted-globals: 1 */
    var intervalId = null
    self.addEventListener("message", event => {
        if (!event) return;

        const { msg, url, token, timeInterval, browserFingerprint } = event.data
        
        const heartbeatUpdateRequest = (prevTimestamp=null) => {
            fetch(
                url,
                {
                    method: 'PUT',
                    mode: 'cors',
                    headers: { 
                        authorization: "Bearer " + token, 
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ browserFingerprint: browserFingerprint })
                }
            ).then(response => {
                // console.log(`heartbeat: ${prevTimestamp}, ${Date.now()}, ${(Date.now() - prevTimestamp) / 1000}`)
            }).catch(error => {
                console.log(error)
            })
        }

        const setHeartbeatUpdateTimer = () => {
            let prevTimestamp = Date.now()
            intervalId = setInterval(() => {
                heartbeatUpdateRequest(prevTimestamp)
                prevTimestamp = Date.now()
            }, timeInterval)
            return intervalId
        }

        if(msg === 'terminate') {
            if(intervalId) {
                clearTimeout(intervalId)
            }
            self.close();
            return;
        } else if(msg === 'start') {
            if(url && token && timeInterval) {
                intervalId = setHeartbeatUpdateTimer()
                intervalId && console.log(`Heartbeat web worker started`)
            }
        } else if(msg === 'tokenRenew') {
            if(intervalId) {
                clearTimeout(intervalId)
            }
            intervalId = null
            if(url && token && timeInterval) {
                // let prevTimestamp = Date.now()
                intervalId = setHeartbeatUpdateTimer()
                intervalId && console.log(`Heartbeat web worker token renewed`)
            }
        }
    })
}

export default HeartbeatWorker