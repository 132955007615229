
import {
  FETCH_TM_PROCESSING_START,
  FETCH_TM_PROCESSING_SUCCESS,
  FETCH_TM_PROCESSING_FAILURE,
  UPDATE_QAWITHERRORS_DATA_START,
  UPDATE_QAWITHERRORS_DATA_SUCCESS,
  UPDATE_QAWITHERRORS_DATA_FAILURE,
  UPDATE_QA_DATA_START,
  UPDATE_QA_DATA_SUCCESS,
  UPDATE_QA_DATA_FAILURE,
  UPDATE_IGNORE_DATA_START,
  UPDATE_IGNORE_DATA_SUCCESS,
  UPDATE_IGNORE_DATA_FAILURE,
  UPDATE_MANUALLY_PROCESSED_DATA_START,
  UPDATE_MANUALLY_PROCESSED_DATA_SUCCESS,
  UPDATE_MANUALLY_PROCESSED_DATA_FAILURE,
  UPDATE_MARK_SUPERSEDED_DATA_START,
  UPDATE_MARK_SUPERSEDED_DATA_SUCCESS,
  UPDATE_MARK_SUPERSEDED_DATA_FAILURE,
  UPDATE_RESUBMIT_DATA_START,
  UPDATE_RESUBMIT_DATA_SUCCESS,
  UPDATE_RESUBMIT_DATA_FAILURE,
  UPDATE_CANCEL_JOB_START,
  UPDATE_CANCEL_JOB_SUCCESS,
  UPDATE_CANCEL_JOB_FAILURE,
  TM_PROCESSING_QUERY_PARAMS

} from './TMProcessingActions';

const initialStateProcessing = {
  data: [],
  isLoading: false,
  error: null,
  total: 0,
};

export const TMProcessingReducer = (state = initialStateProcessing, action) => {
  switch (action.type) {
    case FETCH_TM_PROCESSING_START:
      return { ...state, isLoading: true, error: null };

    case FETCH_TM_PROCESSING_SUCCESS:
      const { documents, count } = action.payload || {}; // Destructure documents and count from payload
      return { ...state, isLoading: false, data: documents || [], total: count || 0 }; // Update data and total with received values, defaulting to initial values if they are not provided

    case FETCH_TM_PROCESSING_FAILURE:
      return { ...state, isLoading: false, error: action.payload, total: 0 }; // Reset total count on error

    default:
      return state;
  }
};



const initialStateQaWithErrors = {
  loading: false,
  data: null,
  error: null
};

export const TMQAWithErrorsReducer = (state = initialStateQaWithErrors, action) => {
  switch (action.type) {
    case UPDATE_QAWITHERRORS_DATA_START:
      return {
        ...state,
        loading: true
      };
    case UPDATE_QAWITHERRORS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case UPDATE_QAWITHERRORS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

const initialStateQa = {
  loading: false,
  data: null,
  error: null
};

export const TMQAReducer = (state = initialStateQa, action) => {
  switch (action.type) {
    case UPDATE_QA_DATA_START:
      return {
        ...state,
        loading: true
      };
    case UPDATE_QA_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case UPDATE_QA_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

const initialStateIgnore = {
  loading: false,
  data: null,
  error: null
};

export const TMIgnoreReducer = (state = initialStateIgnore, action) => {
  switch (action.type) {
    case UPDATE_IGNORE_DATA_START:
      return {
        ...state,
        loading: true
      };
    case UPDATE_IGNORE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case UPDATE_IGNORE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

const initialStateManuallyProcessed = {
  loading: false,
  data: null,
  error: null
};

export const TMManuallyProcessedReducer = (state = initialStateManuallyProcessed, action) => {
  switch (action.type) {
    case UPDATE_MANUALLY_PROCESSED_DATA_START:
      return {
        ...state,
        loading: true
      };
    case UPDATE_MANUALLY_PROCESSED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case UPDATE_MANUALLY_PROCESSED_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

const initialStateMarkSuperseded = {
  loading: false,
  data: null,
  error: null
};

export const TMMarkSupersededReducer = (state = initialStateMarkSuperseded, action) => {
  switch (action.type) {
    case UPDATE_MARK_SUPERSEDED_DATA_START:
      return {
        ...state,
        loading: true
      };
    case UPDATE_MARK_SUPERSEDED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case UPDATE_MARK_SUPERSEDED_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};



const initialStateResubmit = {
  loading: false,
  data: null,
  error: null
};

export const TMResubmitReducer = (state = initialStateResubmit, action) => {
  switch (action.type) {
    case UPDATE_RESUBMIT_DATA_START:
      return {
        ...state,
        loading: true
      };
    case UPDATE_RESUBMIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case UPDATE_RESUBMIT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

const initialStateCancelJob = {
  loading: false,
  data: null,
  error: null
};

export const TMCancelJobReducer = (state = initialStateCancelJob, action) => {
  switch (action.type) {
    case UPDATE_CANCEL_JOB_START:
      return {
        ...state,
        loading: true
      };
    case UPDATE_CANCEL_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case UPDATE_CANCEL_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

export function TMProcessingQueryParamsReducer(state = {}, action) {
  switch (action.type) {
      case TM_PROCESSING_QUERY_PARAMS:
          return { ...state, tmQueryParams: action.payload };

      default:
          return state;
  }
}