import React, { Component } from 'react';
import { Popper, Paper, ClickAwayListener, Grow, Fade } from '@material-ui/core';

class NicerTooltip extends Component {

    state = {
        anchorEl: null,
        open: false
    };

    handleOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <div>
                <div
                    onMouseEnter={this.handleOpen}
                    onMouseOver={this.handleOpen}
                    onMouseLeave={this.handleClose}
                >
                    {this.props.children}
                </div>
                <Popper open={open} anchorEl={anchorEl} transition placement={this.props.placement} container={this.props.container}>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper style={{padding:10, fontSize:12, backgroundColor:"#1a3a69", color:"#FFFFFF"}}>
                                {this.props.content}
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        );
    }
}

export default NicerTooltip