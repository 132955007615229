import React, { useEffect } from 'react';
import { Switch, Redirect  } from 'react-router-dom'
import { connect } from "react-redux";
import { useMsalAuthentication, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionType, InteractionStatus  } from "@azure/msal-browser";
import MainHeader from "./MainHeader";
import MainTabBar from "./MainTabBar";
import PostIndex from "../flight/PostIndex";
import { getAppSettings } from "../auth/AuthenticationActions"
import ProcessingIndex from "../processing/ProcessingIndex";
import ObjectExplorer from '../explorer/ObjectExplorer'
import TMProcessingIndex from '../tmprocessing/TMProcessingIndex'
import PrivateRoute from '../common/PrivateRoute';
import Grid from '@material-ui/core/Grid';
import { loginRequest } from '../auth/authConfig'
import { isMemberOfGuestGroups } from '../common/PrivateRoute'

const Main = (props: any) => {
    const { token, appSettings, manageNotification, getAppSettings } = props
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    useEffect(() => {
        if(token && !appSettings) {
            getAppSettings(token);
        }
    }, [token, appSettings]);

    return (
        <>
            <Grid container direction="column" alignItems="center">
                <MainHeader label="LiDARnetics" />
                <MainTabBar manageNotification={manageNotification} />
                {
                    (inProgress === InteractionStatus.None)
                        ? isAuthenticated
                            ? <>
                                {
                                    isMemberOfGuestGroups(token)
                                        ? <Switch>
                                            <PrivateRoute exact path="/ip*" component={ProcessingIndex} />
                                            <PrivateRoute exact path="/" render={() => (<Redirect to="/ip" />)} />
                                        </Switch>
                                        : <Switch>
                                            <PrivateRoute exact path="/post*" component={PostIndex} />
                                            <PrivateRoute exact path="/iptm*" component={TMProcessingIndex} />
                                            <PrivateRoute exact path="/ip*" component={ProcessingIndex} />
                                            <PrivateRoute exact path="/explorer*" component={ObjectExplorer} />
                                            <PrivateRoute exact path="/" render={() => (<Redirect to="/post" />)} />
                                        </Switch>
                                }
                            </>
                            // : <Redirect to="/login" />
                            // Actually make no sense here because it would never run to here
                            : <>                                            
                                <h3 style={{ textAlign: 'center' }}>        
                                    Authenticating...
                                </h3>
                            </>
                        : <h3 style={{ textAlign: 'center' }}>
                            Authenticating...
                        </h3>

                }
            </Grid>
        </>
    )
}

// export default Main
const mapStateToProps = (state: any) => {
    return {
      token: state.token,
      manageNotification: state.getManageNotification,
      appSettings: state.appSettings
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      getAppSettings: (token: string) => dispatch(getAppSettings(token)),
      // getNotifications: (token) => dispatch(getNotifications(token)),
    //   updateToken: (token: string) => dispatch(authenticationSuccess(token)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Main);