import React, { Component } from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy'
import { UploadTypes, UploadLabels } from '../upload/UploadTypeEnum';
import NicerTooltip from '../common/NicerTooltip';
import Config from '../app/Config.json'

class UploadReplace extends Component {

    render() {
        const s3Path = (this.props.uploadTypes[0] === "LiDAR" 
                            ? Config.UPLOAD_LIDAR_BUCKET 
                            : this.props.uploadTypes[0] in ["TerrainMapperLAZ", "TerrainMapperGPS"]
                                ? Config.UPLOAD_TM_LIDAR_BUCKET
                                : Config.UPLOAD_IMAGERY_BUCKET) 
                            + "/" + this.props.uploadFolder;
        return (
            <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                <div style={{ fontWeight: 'bold' }}>FLIGHT UPLOAD COMPLETE &amp; DATA READY FOR PROCESSING</div>
                <p style={{ paddingTop: 5 }}>Data for this Flight was successfully uploaded, or last updated, on <strong>{this.props.uploadDate.split("T")[0]}</strong> at <strong>{this.props.uploadDate.split("T")[1]} (UTC)</strong>, by user <strong>{this.props.uploadUser}</strong>.</p>


                    <Grid container direction="row" alignItems="center" justify="center">
                        The data is located in S3 at: &nbsp;<strong>{ s3Path }</strong>
                        <NicerTooltip placement="right" content={<span>Copy S3 Link to clipboard</span>}>
                            <IconButton color="inherit"
                                onClick={() => navigator.clipboard.writeText(s3Path) }>
                                <FileCopy color="primary" />
                            </IconButton>
                        </NicerTooltip>
                    </Grid>

                <p>A new upload at this stage can <strong>ADD</strong> to or <strong>REPLACE</strong> parts of this data.</p>
                <p>Alternatively, the existing uploaded data can be <strong>DELETED</strong>.</p>

                <p>Please contact your team leader if you need the upload deleted or replaced.</p>


                <Grid container direction="row" justify='center' alignItems='center' style={{ paddingTop: 10 }}>
                    <Button
                        disabled={true}
                        variant="contained"
                        component="span"
                        onClick={this.props.onReplaceDisplay}
                        color="primary" style={{ width: 260, height: 30, marginRight: 10 }}>DELETE All Existing Data</Button>

                    <Button 
                        // disabled={true}
                        variant="contained"
                        component="span"
                        onClick={this.props.onUpdateDisplay}
                        color="primary"
                        style={{ width: 260, height: 30 }}>Add / Update Existing Data</Button>
                </Grid>

            </Grid>
        );
    }
}

export default UploadReplace;