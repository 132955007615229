import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { UploadTypes } from '../upload/UploadTypeEnum';

class UploadConfirmationDialog extends Component {

    //this resets the file input value to fix a problem where choosing the same file/folder again wont fire an event, onChange or otherwise
    inputReset = () => {
        ReactDOM.findDOMNode(this.refs.replaceButton).value = ''
    }

    render() {
        return (
            <Dialog disableBackdropClick disableEscapeKeyDown open={this.props.open}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    {this.props.children}

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => { this.props.onCancel(false) }} color="primary">
                        Cancel
                    </Button>

                        <div>
                            <input accept="*"
                                id="replaceButton"
                                webkitdirectory="true"
                                multiple type="file"
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                    this.props.onSkipToFileSelection(event,this.props.currentUploadType)}
                                }
                                ref="replaceButton" />

                            <label htmlFor="replaceButton">
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="primary"
                                    onClick={this.inputReset}
                                    style={{ width: 100, marginRight: 10, backgroundColor: '#ff0000' }}>
                                    {this.props.confirmLabel}
                                </Button>
                            </label>
                        </div>
                    {/* } */}

                </DialogActions>
            </Dialog>
        );
    }
}

export default UploadConfirmationDialog;