export function addStatusValue(state = null, action) {
    switch (action.type) {
        case 'ADD_COMPOSITE_FILTER_STATUS':
            return action.value;

        default:
            return state;
    }
}

export function removeStatusValue(state = null, action) {
    switch (action.type) {
        case 'REMOVE_COMPOSITE_FILTER_STATUS':
            return action.value;

        default:
            return state;
    }
}

export function removeAllStatusValues(state = null, action) {
    switch (action.type) {
        case 'REMOVE_ALL_COMPOSITE_FILTER_STATUS':
            return action.value;

        default:
            return state;
    }
}

export function clearAllFilters(state = null, action) {
    switch (action.type) {
        case 'CLEAR_ALL_FILTERS':
            return action.value;

        default:
            return state;
    }
}

export function uniqueValuesFetchSuccess(state = null, action) {
    switch (action.type) {
        case 'UNIQUE_VALUES_FETCH_SUCCESS':
            return action.uniqueValues;

        default:
            return state;
    }
}

export function uniqueValuesFetchHasError(state = false, action) {
    switch (action.type) {
        case 'UNIQUE_VALUES_FETCH_HAS_ERROR':
            return action.isLoading;

        default:
            return state;
    }
}

export function uniqueValuesFetchLoading(state = false, action) {
    switch (action.type) {
        case 'UNIQUE_VALUES_FETCH_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}
