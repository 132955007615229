import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Checkbox, IconButton, FormControlLabel, Grid, Tooltip } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
class FileValidationDialog extends Component {
    state ={
        disableCompression:false
    }

    // handleChange = () => {
    //     this.setState({disableCompression:!this.state.disableCompression})
    // }

    render() {
        const invalidFiles = [...this.props.results.invalidFiles];
        const validFiles = [...this.props.results.validFiles];
        const errorMessages = [...this.props.results.errorMessages];

        return (
            <Dialog disableBackdropClick disableEscapeKeyDown onEnter={this.reset} open={this.props.open} fullWidth={true} maxWidth={'lg'}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    {
                        errorMessages.length === 0 &&
                        <Grid container direction="row" justify="center" alignItems="center" style={{ paddingBottom: 15 }}>
                            <CheckCircleOutline style={{ fontSize: 36, color: '#006600', paddingRight: 5 }} />
                            <div>
                                Minimum <strong>{this.props.results.uploadTypeLabel}</strong> requirements have been met, all <strong>VALID</strong> files will be uploaded.
                                <br />Please review the results below and be aware of any files that <strong>WILL NOT</strong> be uploaded.
                            </div>
                        </Grid>
                    }
                    {
                        errorMessages.length > 0 &&
                        <Grid container direction="row" justify="center" alignItems="center" style={{ paddingBottom: 15 }}>
                            <ErrorOutline style={{ fontSize: 36, color: '#FF0000', paddingRight: 5 }} />
                            <div>
                                Minimum <strong>{this.props.results.uploadTypeLabel}</strong> requirements have not been met.
                                <br />No files can be uploaded until this is corrected.
                                <br />Scroll down to see what is needed.
                            </div>
                        </Grid>
                    }
                    <Grid container direction="row" justify="space-around">
                        {validFiles.length > 0 &&
                            <Grid item style={{ paddingRight: 10, width: invalidFiles.length > 0 ? '50%' : '100%' }}>
                                <span style={{ fontWeight: 'bold' }}>Valid Files</span>
                                <span> ({validFiles.length} will be uploaded):</span>
                                <List dense={true} style={{ backgroundColor: '#e6ffe6' }}>
                                    {validFiles.map((filePath) => (
                                        <ListItem key={filePath} divider style={{ fontSize: 14, paddingTop: 2, paddingBottom: 2 }}>
                                            <Tooltip title={filePath} placement="right">
                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", direction: 'rtl', display: 'inline-block' }}>
                                                    <bdi>{filePath}</bdi>
                                                </div>
                                            </Tooltip>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        }
                        {invalidFiles.length > 0 &&
                            <Grid item direction="column" style={{ width: validFiles.length > 0 ? '50%' : '100%' }}>
                                <span style={{ fontWeight: 'bold' }}>Invalid Files</span>
                                <span> ({invalidFiles.length} won't be uploaded):</span>
                                <List dense={true} style={{ backgroundColor: '#FF9999' }}>
                                    {invalidFiles.map((filePath) => (
                                        <ListItem key={filePath} divider style={{ fontSize: 14, paddingTop: 2, paddingBottom: 2 }}>
                                            <Tooltip title={filePath} placement="right">
                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", direction: 'rtl' }}>
                                                    <bdi>{filePath}</bdi>
                                                </div>
                                            </Tooltip>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        }
                    </Grid>
                    {
                        errorMessages.length > 0 &&
                        <Grid container alignItems='center' justify='center'>
                            <Grid container direction="row" justify="center" alignItems="center" style={{ paddingTop: 15 }}>
                                <ErrorOutline style={{ fontSize: 36, color: '#FF0000', paddingRight: 5 }} />
                                <div>
                                    <strong>Outstanding minimum requirements:</strong><br />(must be met before any upload can commence)
                                </div>
                            </Grid>
                            <List dense={true}>
                                {errorMessages.map((error) => (
                                    <ListItem key={error} divider style={{ fontSize: 14, paddingTop: 2, paddingBottom: 2 }}>{error}</ListItem>
                                ))}
                            </List>
                        </Grid>
                    }

                </DialogContent>
                <DialogActions>
                    <Grid container direction="column" alignItems="flex-end" style={{marginRight:20, marginBottom: 20}}>
                        <Grid container direction="row" alignItems="center" justify="flex-end">
                            <Button variant="contained" color="primary" onClick={this.props.onCancel}>
                                Cancel
                            </Button>
                            <Button 
                                variant="contained" color="primary" 
                                style={{marginLeft:10}}
                                onClick={()=>this.props.onUpload(this.state.disableCompression)} 
                                disabled={errorMessages.length > 0}
                            >
                                Upload
                            </Button>
                        </Grid>
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    // onClick={()=>this.setState(disableCompression:!this.state.Disable)}
                                    checked={this.state.disableCompression}
                                    onChange={this.handleChange}
                                    color="primary"
                                />
                            }
                            label="Disable Compression (only recommended for FAST connections)"
                            labelPlacement="start"
                        /> */}
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}

export default FileValidationDialog;