import axios from 'axios';
import Config from '../app/Config.json'

const BASE_URI = Config.IP_URI

export function aircraftListHasError(bool) {
    return {
        type: 'AIRCRAFT_LIST_HAS_ERROR',
        hasError: bool
    };
}

export function aircraftListLoading(bool) {
    return {
        type: 'AIRCRAFT_LIST_LOADING',
        isLoading: bool
    };
}

export function aircraftListSuccess(aircraft) {
    return {
        type: 'AIRCRAFT_LIST_SUCCESS',
        aircraft
    };
}
/**
 * 
 * @param {*} page, total, pageSize 
 */
export function aircraftQueryParams(payload) {
    return {
        type: 'AIRCRAFT_QUERY_PARAMS',
        payload
    }
}

export function aircraftFetchList(token) {
    return (dispatch, getState) => {
        dispatch(aircraftListLoading(true));
        let state = getState(),
            { page, pageSize,sortByName, ...queryParams } = state.aircraftQueryParams
        token = token || state.token

        //pagesize overriden for the time being to disable paging
        pageSize = -1;
        sortByName = "Name 1";
        
        const server = BASE_URI + 'aircraft' + `?page=${page}&pageSize=${pageSize}&sort=${sortByName}`,//+queryString,
            headers = { authorization: 'Bearer ' + token };

        axios.get(server, { params: queryParams.params, headers }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(aircraftListLoading(false));
            //console.log(response)
            const total = parseInt(response.headers['x-total-count'])
            dispatch(aircraftQueryParams({ total }));
            return response;
        }).then((response) => {
            console.log(response.status);
            dispatch(aircraftListSuccess(response.data))}).catch((response) => {
            dispatch(aircraftListLoading(false));
            dispatch(aircraftListHasError(true))
        });
    };
}