import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Checkbox, IconButton, FormControlLabel, Tooltip, Grid } from '@material-ui/core';
import { throws } from 'assert';

class FileSelectionDialog extends Component {

    state = {
        checked: [],
        selectAll: false,
        dirty: false,
        fileTypeDict: {}
    };

    constructor(props) {
        super(props)
        // console.log("constructor: "+props.files.length)
        this.gpsRegex = /^(\d{3}|\d{2}n|\d{2}g|\d{1}n|\d{1}g|n|g|pos|POS|gpso|GPSO|gpsn|GPSN)$/;
    }

    reset = () => {
        this.setState({
            checked: [],
            selectAll: false
        });
        if (!this.props.isUpdate)
            this.setAllChecked();
        this.getUniqueFileTypes();
    }

    handleToggle = (file) => {
        const newChecked = [...this.state.checked];
        const currentIndex = this.state.checked.indexOf(file);
        let files = [...this.props.files]
        let selectAll = false;

        if (currentIndex === -1) {
            newChecked.push(file);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        if (files.length === newChecked.length) {
            selectAll = true;
        }

        this.setState({
            checked: newChecked,
            selectAll: selectAll
        });
    };

    setAllChecked = () => {
        let files = [...this.props.files];
        let newArray = [];
        files.forEach((file, index) => {
            newArray.push(file);
            this.refs["checkbox_" + index].checked = true;
        });

        this.setState({
            checked: newArray,
            selectAll: true,
            dirty: true
        });
    }

    handleToggleAll = (event) => {
        let files = [...this.props.files];
        let newArray = [];
        files.forEach((file, index) => {
            if (event.target.checked)
                newArray.push(file);
            this.refs["checkbox_" + index].checked = event.target.checked;
        });

        this.setState({
            checked: newArray,
            selectAll: event.target.checked
        });
    }

    getUniqueFileTypes = () => {
        let files = [...this.props.files];
        let fileDict = {};
        files.forEach((file, index) => {
            let parts = file.webkitRelativePath.split(".");
            let ext = parts[parts.length - 1].toLowerCase();
            if (RegExp(this.gpsRegex, "g").test(ext))
                ext = 'gps';
            if (fileDict[ext] === undefined) {
                fileDict[ext] = [file];//this could be a more complex obj w label etc
            }
            else {
                fileDict[ext].push(file);
            }
        });
        this.setState({ fileTypeDict: fileDict });
    }

    isExtToggledOn = (extToTest) => {
        let found = this.state.checked.find(file => {
            let parts = file.webkitRelativePath.split(".");
            let ext = parts[parts.length - 1].toLowerCase();
            if (extToTest === "gps")
                return RegExp(this.gpsRegex, "g").test(ext);
            return ext === extToTest.toLowerCase();
        });

        return found !== undefined;
    }

    handleToggleByType = (extToToggle, files) => {

        let newChecked = this.state.checked.filter(file => {
            let parts = file.webkitRelativePath.split(".");
            let ext = parts[parts.length - 1].toLowerCase();
            if (extToToggle.toLowerCase() === "gps") {
                return !RegExp(this.gpsRegex, "g").test(ext);
            }
            else if (extToToggle.toLowerCase() === ext) {
                return false;
            }
            return true;
        });

        if (!this.isExtToggledOn(extToToggle))
            newChecked = newChecked.concat(files);

        this.setState({
            checked: newChecked,
            selectAll: this.props.files.length === newChecked.length
        });
    }

    render() {
        const files = [...this.props.files];
        const fileTypes = ['*.kmz (1 file)', '*.range (2 files)', 'gps (15 files)'];

        return (
            <Dialog disableBackdropClick disableEscapeKeyDown onEnter={this.reset} open={this.props.open} fullWidth={true} maxWidth={'lg'}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" justify="space-around">
                        {/* <Grid item style={{height: 500, overflow:"auto"}}> */}
                        <Grid item>
                            <p>Selections may <span style={{ fontWeight: 'bold' }}>ADD</span> to or <span style={{ fontWeight: 'bold' }}>OVERWRITE</span> parts of the existing data.</p>

                            <Grid item style={{ width: 700, height: "60vh", overflow: "auto" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.selectAll}
                                            onChange={(event) => this.handleToggleAll(event)}
                                        />
                                    }
                                    label="All"
                                />

                                <List dense={true}>
                                    {files.map((file, index) =>
                                        <ListItem style={{ height: 30 }}
                                            key={file.webkitRelativePath}>
                                            <Checkbox
                                                ref={"checkbox_" + index}
                                                onChange={(event) => this.handleToggle(file)}
                                                checked={this.state.checked.indexOf(file) !== -1}
                                            />
                                            <ListItemText>
                                                <Tooltip title={file.webkitRelativePath} placement="right">
                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", direction: 'rtl' }}>
                                                        {file.webkitRelativePath}
                                                    </div>
                                                </Tooltip>
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <p>Toggle by file type:</p>
                            <List dense={true}>
                                {Object.entries(this.state.fileTypeDict).map(item =>
                                    <ListItem style={{ height: 30, margin: 10 }}
                                        key={item[0]}>
                                        <Checkbox
                                            ref={"type_checkbox_" + item[0]}
                                            onChange={(event) => this.handleToggleByType(item[0], item[1])}
                                            checked={this.isExtToggledOn(item[0])}
                                        />
                                        <ListItemText>
                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", direction: 'rtl' }}>
                                                {item[0]} ({item[1].length} files)
                                            </div>
                                        </ListItemText>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                    <p>{this.state.checked.length} of {this.props.files.length} files selected</p>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.props.onCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary"
                        disabled={this.state.checked.length === 0}
                        onClick={() => {
                            //sorting, for validation
                            this.state.checked.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                            this.props.onConfirm(this.state.checked)
                            this.reset()
                        }}>
                        Confirm Selection
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default FileSelectionDialog;