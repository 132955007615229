import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import AccessTime from '@material-ui/icons/AccessTime';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import PauseCircleoutline from '@material-ui/icons/PauseCircleOutline';
import { CircularProgress } from '@material-ui/core';

// const WAITING = 'waiting';

class UploadStatusIcon extends Component {

    render() {
        const { status } = this.props;
        return (
            <div style={{ padding: 8, width: 105 }}>
                {{
                    [UploadStates.WAITING]:
                        <Grid container direction="column" justify="center" alignItems="center">
                            <div style={{ color: '#a3a3a3', display: 'flex', textAlign: 'center', alignItems: 'center', height: 72 }} >
                                <p>{this.props.label}</p>
                            </div>
                            <AccessTime style={{ fontSize: 36, color: '#a3a3a3' }} />
                        </Grid>,
                    [UploadStates.IN_PROGRESS]:
                        <Grid container direction="column" justify="center" alignItems="center">
                            <div style={{fontWeight: 'bold', display: 'flex', textAlign: 'center', alignItems: 'center', height: 72}}>
                                    <p>{this.props.label}</p>
                            </div>
                            {
                                this.props.progress &&
                                <div>
                                    <label for="compress-progress-id" style={{ fontSize: 8, fontWeight: 'bold', position: 'absolute', marginTop: 13, marginLeft: 4 }}>100 %</label>
                                    <CircularProgress id="compress-progress-id" style={{ marginTop: 2 }} size={30} />
                                </div>
                            }
                            {!this.props.progress && <CircularProgress style={{ marginTop: 2 }} size={30} />}
                        </Grid>,
                    [UploadStates.PAUSED]:
                        <Grid container direction="column" justify="center" alignItems="center">
                            <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', height: 72}}>
                                <p>{this.props.label}</p>
                            </div>
                            <PauseCircleoutline style={{ fontSize: 36, color: '#1a3a69' }} />
                        </Grid>,
                    [UploadStates.COMPLETED]:
                        <Grid container direction="column" justify="center" alignItems="center">
                            <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', height: 72}}>
                                <p>{this.props.label}</p>
                            </div>
                            <CheckCircleOutline style={{ fontSize: 36, color: '#006600' }} />
                        </Grid>,
                    [UploadStates.FAILED]:
                        <Grid container direction="column" justify="center" alignItems="center">
                            <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', height: 72}}>
                                <p>{this.props.label}</p>
                            </div>
                            <ErrorOutline style={{ fontSize: 36, color: '#FF0000' }} />
                        </Grid>,
                }[status]}
            </div>
        );
    }
}

//this receives the actions / state changes
const mapStateToProps = (state) => {
    return {
        // posts: state
    }
}

export default connect(mapStateToProps)(UploadStatusIcon);
// export default UploadStatusIcon;

export const UploadStates = {
    WAITING: 'WAITING',
    IN_PROGRESS: 'IN_PROGRESS',
    PAUSED: 'PAUSED',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED'

}