import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import axios, { AxiosResponse, CancelTokenSource, CancelToken, CancelTokenStatic } from 'axios'
import { IP_URI } from "../app/Config.json"
import { 
    QueryParams, 
    UpdateQueryParamsAction, 
    Pagination, 
    UpdatePaginationAction,
    UpdateTotalItemCountAction,
    ProductListCategory,
    AggregatedProduct,
    AggregatedProductList,
    StoreProductListAction,
    ProductListDashboard
 } from './ProductListTypes'

//////////////////////////////////
//           Constants          //
//////////////////////////////////
export const PRODUCT_LIST_ACTION_TYPE = ({
    UPDATE_PRODUCT_LIST_QUERY_PARAMS: 'UPDATE_PRODUCT_LIST_QUERY_PARAMS',
    CLEAR_ALL_PRODUCT_LIST_QUERY_PARAMS: 'CLEAR_ALL_PRODUCT_LIST_QUERY_PARAMS',
    UPDATE_PRODUCT_LIST_TOTAL_ITEM_COUNT: 'UPDATE_PRODUCT_LIST_TOTAL_ITEM_COUNT',
    UPDATE_PRODUCT_LIST_PAGINATION: 'UPDATE_PRODUCT_LIST_PAGINATION',
    FETCH_PRODUCT_LIST: 'FETCH_PRODUCT_LIST',
    STORE_PRODUCT_LIST: 'STORE_PRODUCT_LIST',
    FETCH_PRODUCT_LIST_SUCCEED: 'FETCH_PRODUCT_LIST_SUCCEED',
    FETCH_PRODUCT_LIST_FAILED: 'FETCH_PRODUCT_LIST_FAILED',
    ENABLE_PRODUCT_LIST_POLLING: 'ENABLE_PRODUCT_LIST_POLLING',
    DISABLE_PRODUCT_LIST_POLLING: 'DISABLE_PRODUCT_LIST_POLLING',
    CLEAR_ALL_PRODUCT_LIST: 'CLEAR_ALL_PRODUCT_LIST',
})

//////////////////////////////////
//        ActionCreators        //
//////////////////////////////////
export const updateQueryParams = (queryParams: QueryParams): UpdateQueryParamsAction => ({
    type: PRODUCT_LIST_ACTION_TYPE.UPDATE_PRODUCT_LIST_QUERY_PARAMS,
    queryParams
})

export const clearAllQueryParams = (): Action<string> => ({
    type: PRODUCT_LIST_ACTION_TYPE.CLEAR_ALL_PRODUCT_LIST_QUERY_PARAMS
})

export const updateTotalItemCount = (totalItemCount: number): UpdateTotalItemCountAction => ({
    type: PRODUCT_LIST_ACTION_TYPE.UPDATE_PRODUCT_LIST_TOTAL_ITEM_COUNT,
    totalItemCount
})

export const updatePagination = (pagination: Pagination): UpdatePaginationAction => ({
    type: PRODUCT_LIST_ACTION_TYPE.UPDATE_PRODUCT_LIST_PAGINATION,
    pagination
})

export const fetchProductList = (): Action<string> => ({
    type: PRODUCT_LIST_ACTION_TYPE.FETCH_PRODUCT_LIST,
})

export const storeProductList = 
        (fetchedProductList: AggregatedProductList): StoreProductListAction => ({
    type: PRODUCT_LIST_ACTION_TYPE.STORE_PRODUCT_LIST,
    fetchedProductList: fetchedProductList
})

export const fetchProductListSucceed = (): Action<string> => ({
    type: PRODUCT_LIST_ACTION_TYPE.FETCH_PRODUCT_LIST_SUCCEED
})

export const fetchProductListFailed = (): Action<string> => ({
    type: PRODUCT_LIST_ACTION_TYPE.FETCH_PRODUCT_LIST_FAILED
})

export const enablePolling = (): Action<string> => ({
    type: PRODUCT_LIST_ACTION_TYPE.ENABLE_PRODUCT_LIST_POLLING
})

export const disablePolling = (): Action<string> => ({
    type: PRODUCT_LIST_ACTION_TYPE.DISABLE_PRODUCT_LIST_POLLING
})

export const clearAll = (): Action<string> => ({
    type: PRODUCT_LIST_ACTION_TYPE.CLEAR_ALL_PRODUCT_LIST
})

//////////////////////////////////
//         Async Actions        //
//////////////////////////////////
const fetchProductListPromise = (
        token: string, 
        pagination: Pagination, 
        queryParams: QueryParams,
        cancelTokenSource?: CancelTokenSource,
    ): Promise<AxiosResponse<AggregatedProductList>> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response: AxiosResponse<AggregatedProductList> = await axios({
                method: 'get',
                url: IP_URI + 'products',
                params: Object.assign({}, pagination, queryParams),
                cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
                headers: { authorization: "Bearer " + token },
            });
            return resolve(response);
        } catch (error) {
            if(axios.isCancel(error)) {
                // console.log(`Request canceled: ${error.message}`)
            } else {
                return reject(String(error));
            }
        }
    })
}

export const handleFetchProductList = (category: ProductListCategory, cancelTokenSource?: CancelTokenSource): ThunkAction<Promise<AggregatedProductList | null>, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { token, productList } = getState()
        const clusterCategoryQueryParams = category 
                                            ? { 'ClusterProcessing.Category': `${category}` }
                                            : undefined
        dispatch(fetchProductList())
        return (
            fetchProductListPromise(
                    token,
                    (productList as ProductListDashboard).pagination, 
                    Object.assign({}, (productList as ProductListDashboard).queryParams, clusterCategoryQueryParams),
                    cancelTokenSource
            )
            .then((response: AxiosResponse<AggregatedProductList>) => {
                // console.log(`Fetch Product List Succeed: ${JSON.stringify(response, null, 4)}`)
                // Have to invoke getState() again because it is a closure to access the productList in outer scope.
                const currentState = getState()
                // State will be re-initialized after unmounting. 
                // Should not run the below block of code any more after unmounting.
                if(currentState.productList.isFetching) {
                    // console.log(`Processing after fetch data`)
                    // If queryParams is updated while fetching.
                    // It will abort the current response and wait for the next response which is triggered by filter update.
                    if(JSON.stringify(productList.queryParams) !== JSON.stringify(currentState.productList.queryParams)) {
                        // console.log(`queryParams has been changed during the period of fetching data: 
                        //                 Previous: ${JSON.stringify(productList.queryParams, null, 4)},
                        //                 Now: ${JSON.stringify(currentState.productList.queryParams, null, 4)}`)
                        return null
                    }
                    // If pagination is updated while fetching.
                    // It will abort the current response and wait for the next response which is triggered by filter update.
                    if(JSON.stringify(productList.pagination) !== JSON.stringify(currentState.productList.pagination)) {
                        // console.log(`pagination has been changed during the period of fetching data: 
                        //                 Previous: ${JSON.stringify(productList.pagination, null, 4)},
                        //                 Now: ${JSON.stringify(currentState.productList.pagination, null, 4)}`)
                        return null
                    }
                    const totalItemCount = parseInt(response.headers['x-total-count'])
                    dispatch(updateTotalItemCount(totalItemCount))
                    const fetchedProductList = response.data
                    dispatch(storeProductList(fetchedProductList))
                    dispatch(enablePolling())                       // Enable polling after first fetching
                    dispatch(fetchProductListSucceed())
                    return fetchedProductList
                }
                return null
            }).catch(error => {
                // console.log(`Fetch Product List Failed: ${JSON.stringify(error, null, 4)}`)
                dispatch(fetchProductListFailed())
                return []
            })
        )
    }
}