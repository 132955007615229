import React, { Component } from 'react';
import { TableCell, Input, InputAdornment, IconButton, TextField, Button } from '@material-ui/core';

class FilterDateField extends Component {
    constructor(props) {
        super(props);
        this.inputRef = null;
    }

    clearField = () => {
        this.inputRef.value = '';
        this.props.onUpdate(this.inputRef.name, '');
    }

    onUpdate = () => {
        this.props.onUpdate(this.inputRef.name, this.inputRef.value)
    }


    render() {
        const componentStyle = { width: this.props.width };
        return (
            <TableCell padding="none" style={{ position: 'relative', paddingLeft: 10,paddingRight: 1, top: -15, width: componentStyle.width }}>
                   <Input
                style={{ fontSize: 10, width: 100}}
                name={this.props.name}
                inputRef={input => (this.inputRef = input)}
                onChange={this.onUpdate}
                type="date"
                />
            </TableCell>
        );
    }
}

export default FilterDateField;