import React, { Component } from 'react';
import NicerTooltip from '../common/NicerTooltip';
import { Grid } from '@material-ui/core';
import Tune from '@material-ui/icons/Tune';
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import { ClipboardCheck } from '../icons/svgIcons'

class FlightTypeTableIcon extends Component {

    render() {
        const { type } = this.props;
        const small = type.length > 1 ? true : false;

        return (
            <div>
                {type.map((value, index) => {
                    return (
                        <Grid container direction="column" alignItems="center" justify="center">
                            <TypeIcon type={value} shrink={small} index={index} />
                        </Grid>
                    )
                })}
            </div>
        );
    }
}

function TypeIcon(props) {
    const { type, shrink, index } = props;
    return (
        <div>
            {{
                ['Test Flight - Calibration Flight.']:
                    <NicerTooltip placement="right" content={<span>Calibration Flight</span>}>
                        <Tune style={{ color: '#1a3a69', transform: shrink ? 'scale(.75, .75)' : '', marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
                ['Successful Capture']:
                    <NicerTooltip placement="right" content={<span>Client Flight</span>}>
                        <MonetizationOn style={{ color: '#1a3a69', transform: shrink ? 'scale(.75, .75)' : '', marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
                ["Test Flight - Sensor Testing"]:
                    <NicerTooltip placement="right" content={<span>Test Flight - Sensor Testing</span>}>
                        <ClipboardCheck style={{ color: '#1a3a69', transform: shrink ? 'scale(.75, .75)' : '', marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
                ["Test Flight - Aircraft Testing"]:
                    <NicerTooltip placement="right" content={<span>Test Flight - Aircraft Testing</span>}>
                        <ClipboardCheck style={{ color: '#1a3a69', transform: shrink ? 'scale(.75, .75)' : '', marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
                ["Test Flight - Pilot Training"]:
                    <NicerTooltip placement="right" content={<span>Test Flight - Pilot Training</span>}>
                        <ClipboardCheck style={{ color: '#1a3a69', transform: shrink ? 'scale(.75, .75)' : '', marginTop: index > 0 ? -5 : 0, marginBottom: index > 0 ? 0 : -5 }} />
                    </NicerTooltip>,
            }[type]}
        </div>
    )
}

export default FlightTypeTableIcon;