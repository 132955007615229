import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
class GPSCheckDialog extends Component {

    render() {
        
        return (
            <Dialog disableBackdropClick disableEscapeKeyDown open={this.props.open} >
                <DialogTitle>GPS Check</DialogTitle>
                <DialogContent>
                    <Grid container alignItems='center' justify='center'>
                        <Grid container direction="row" justify="center" alignItems="center" style={{ paddingTop: 15 }}>
                            <ErrorOutline style={{ fontSize: 36, color: '#FF0000', paddingRight: 5 }} />
                            <div>
                                GPS files don't start at .000 for the following GPS flight segments:<br/><br/>
                                {   
                                    this.props.parts &&
                                    this.props.parts.map(item =>
                                    <span style={{fontWeight:"bold", paddingLeft:20}}>{item}<br/></span>
                                )}
                                <br/>Is this valid?
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.props.onCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.props.onConfirm} >
                        Continue
                    </Button>
                    
                </DialogActions>
            </Dialog>
        );
    }
}

export default GPSCheckDialog;