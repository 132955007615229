import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import ConfirmationDialog from './ConfirmationDialog';
import { throws } from 'assert';

class QALogDialog extends Component {

    state = {
        issueDesc: "",
        issueRes: ""
    }

    render() {
        return (
            <ConfirmationDialog
                title="QA Log"
                confirmLabel="UPDATE"
                onCancel={() => { 
                    this.props.onCancel();
                }}
                onConfirm={() => {
                    this.props.onConfirm(this.state.issueDesc,this.state.issueRes)
                }}
                confirmDisabled={this.state.issueDesc.length === 0 || this.state.issueRes.length === 0}
                open={this.props.dialogOpen}>
                <p>Please record an <strong>Issue Description</strong> and <strong>Issue Resolution</strong> below.</p>

                <TextField
                    autoFocus
                    style={{ width: "100%" }}
                    id="filled-multiline-static"
                    label="Issue Description"
                    multiline
                    rows="4"
                    margin="normal"
                    variant="filled"
                    onChange={e => this.setState({issueDesc:e.currentTarget.value})}
                />

                <TextField
                    style={{ width: "100%" }}
                    id="filled-multiline-static"
                    label="Issue Resolution"
                    multiline
                    rows="4"
                    margin="normal"
                    variant="filled"
                    onChange={e => this.setState({issueRes:e.currentTarget.value})}
                />

            </ConfirmationDialog>
        );
    }
}

export default QALogDialog;