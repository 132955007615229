import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchTMProcessing,
  tmProcessingQueryParams,
} from "./TMProcessingActions";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Paper,
  TablePagination,
  TableFooter,
  IconButton,
  Dialog,
} from "@material-ui/core";
import ClusterStatusIconList from "./ClusterStatusIconList";
import TableEllipsisIcon from "../common/TableEllipsisIcon";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TMProcessing.css"; // Must below slick-carousel native CSS files
import NicerTooltip from "../common/NicerTooltip";
import Search from "@material-ui/icons/Search";
import FilterDialog from "../processing/FilterDialog";
import {
  clearAllFilters,
  uniqueValuesFetch,
} from "../common/CompositeFilterActions";
import SearchBar from '../common/SearchBar';

const TMProcessingList = ({
  data,
  isLoading,
  error,
  fetchTMProcessing,
  total /*, sensorEquipment*/,
  token,
  fetchUniqueValues,
  projectManagers,
  updateQueryParams,
}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); // or whatever your page size should be
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [queryFilters, setQueryFilters] = useState({});
  const [prevQueryFilters, setPrevQueryFilters] = useState({});
  const [filterOnFocusIndex, setFilterOnFocusIndex] = useState(-1);
  const [sensorEquipment, setSensorEquipment] = useState([
    { label: "Terrain Mapper Bro!", value: "TM" },
  ]);
  const [processingTemplates, setProcessingTemplates] = useState([]);
  // const [projectManagers, setProjectManagers] = useState([]);

  const flightIdColumnStyle = { textAlign: "center", width: 80 };
  const dateColumnStyle = { textAlign: "center", width: 100 };
  const timeColumnStyle = { textAlign: "center", width: 80 };
  const statusColumnStyle = { textAlign: "center", width: 50 };
  const sensorNameColumnStyle = { textAlign: "center", width: 150 };
  const projectNameColumnStyle = { textAlign: "center", width: 280 };
  const pipelineColumnStyle = { textAlign: "center", width: 625 };
  const headerDivTextStyle = { fontWeight: "bolder", color: "#000000" };
  const processingTemplateColumnStyle = { textAlign: "center", width: 50 };

  const [recentlyUpdated, setRecentlyUpdated] = useState(new Set());

  const blockRow = (pipelineGuid) => {
    setRecentlyUpdated((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.add(pipelineGuid);
      return newSet;
    });
  };

  const isRowBlocked = (pipelineGuid) => recentlyUpdated.has(pipelineGuid);

  useEffect(() => {
    // Function to fetch data with current page and limit
    const fetchData = () => {
      fetchTMProcessing(page, limit);

      setRecentlyUpdated(new Set());
    };

    // Call the function once when the component mounts
    fetchData();

    fetchUniqueValues(token, "project", "ProjectManager");

    // Set up the interval to fetch data every 30 seconds
    const intervalId = setInterval(fetchData, 30000); // 30000 milliseconds = 30 seconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [fetchTMProcessing, page, limit, queryFilters]); // Dependencies for useEffect

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1); // Update page state to trigger new fetch
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1)); // Don't go below page 1
  };

  // Adjusted pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleOpenFilterDialog = (index = -1) => {
    setIsFilterDialogOpen(true);
    if (index >= 0) {
      let originalFilterIndex = index - 2 > 0 ? index - 2 : 0;
      setFilterOnFocusIndex(originalFilterIndex);
    } else {
      setFilterOnFocusIndex(-1);
    }
  };

  const handleFilterDialogOnClose = () => {
    setIsFilterDialogOpen(false);
  };

  const handleUpdateQueryFilters = (queryFilters) => {
    setQueryFilters(queryFilters);

    let qp = generateQueryParams(queryFilters);
    updateQueryParams(qp);
  };

  const formatPrimaryIdQueryParam = (queryFilters, filterName) => {
    const SENSOR_ID_KEY_NAME = "sensorId";
    const PROJECT_ID_KEY_NAME = "projectId";
    const FLIGHT_ID_KEY_NAME = "flightId";
    const primaryIdFilterList = queryFilters[filterName] || [];
    let sensor_id_list = [];
    let project_id_list = [];
    let flight_id_list = [];
    primaryIdFilterList.forEach((primaryId) => {
      if (primaryId.startsWith("SL")) sensor_id_list.push(primaryId);
      if (primaryId.startsWith("PRJ")) project_id_list.push(primaryId);
      if (primaryId.startsWith("FL")) flight_id_list.push(primaryId);
    });
    let queryParam = {};
    if (sensor_id_list.length)
      queryParam[SENSOR_ID_KEY_NAME] = `${sensor_id_list.join(",")}`;
    if (project_id_list.length)
      queryParam[PROJECT_ID_KEY_NAME] = `${project_id_list.join(",")}`;
    if (flight_id_list.length)
      queryParam[FLIGHT_ID_KEY_NAME] = `${flight_id_list.join(",")}`;
    return queryParam;
  };

  const formatTextListQueryParam = (queryFilters, filterName, keyName) => {
    if (queryFilters[filterName] && queryFilters[filterName].length) {
      return { [keyName]: queryFilters[filterName].join(",") };
    } else {
      return {};
    }
  };

  const formatDateQueryParam = (queryFilters, dateStartFilterName, dateEndFilterName, keyName) => {
    if (queryFilters[dateStartFilterName] && queryFilters[dateEndFilterName]) {
        return { [keyName]: `${queryFilters[dateStartFilterName]}+${queryFilters[dateEndFilterName]}` }
    } else {
        return {}
    }
  }

  const sortAllFilterList = (queryFilters) => {
    const isSurveyDateStartDefined = !!queryFilters.surveyDateStart
    const isSurveyDateEndDefined = !!queryFilters.surveyDateEnd
    return [
        {
            name: 'Flight Log', filterList: queryFilters.primaryIdFilterList
                ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('FL'))
                : []
        },
        {
            name: 'Sensor Log', filterList: queryFilters.primaryIdFilterList
                ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('SL'))
                : []
        },
        {
            name: 'Project Log', filterList: queryFilters.primaryIdFilterList
                ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('PRJ'))
                : []
        },
        { name: 'Aircraft', filterList: queryFilters.aircraftFilterList },
        {
            name: 'Survey Date', filterList: (isSurveyDateStartDefined && isSurveyDateEndDefined)
                ? [
                    `Start: ${queryFilters.surveyDateStart}`, `End: ${queryFilters.surveyDateEnd}`
                ]
                : []
        },
        { name: 'Sensor', filterList: queryFilters.sensorFilterList },
        { name: 'Project Manager', filterList: queryFilters.projectManagerFilterList },
        { name: 'Processing Template', filterList: queryFilters.processingTemplateFilterList }
    ]
  }

  const clearAllFilters = () => {
      setQueryFilters({});

      let qp = generateQueryParams({});
      updateQueryParams(qp);
  }

  const isAnyFilterApplied = (queryFilters) => {
    if (queryFilters && Object.keys(queryFilters).length) {
        return Object.keys(queryFilters).some(queryFilterKey => queryFilters[queryFilterKey].length)
    } else {
        return false
    }
  }

  const generateQueryParams = (qf) => {
    let primaryIdQueryParam = formatPrimaryIdQueryParam(qf,"primaryIdFilterList");
    let aircraftQueryParam = formatTextListQueryParam(qf, 'aircraftFilterList', 'aircraft')
    let sensorQueryParam = formatTextListQueryParam(qf, 'sensorFilterList', 'sensor')
    let projectManagerQueryParam = formatTextListQueryParam(qf, 'projectManagerFilterList', 'projectManager')
    let processingTemplateQueryParam = formatTextListQueryParam(qf, 'processingTemplateFilterList', 'ProcessingTemplate')
    let surveyDateQueryParam = formatDateQueryParam(qf, 'surveyDateStart', 'surveyDateEnd', 'surveyDate')
    let queryParams = {
      ...aircraftQueryParam,...primaryIdQueryParam,...sensorQueryParam,...projectManagerQueryParam,
      ...processingTemplateQueryParam, ...surveyDateQueryParam
    };
    return queryParams;
  };
  if (isLoading && !data.length) return <p>Loading...</p>; // Only show loading if there is no data
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
    {
                    isAnyFilterApplied(queryFilters)
                    && <SearchBar
                        width={1410}
                        filtersBtnOnClick={handleOpenFilterDialog}
                        sortedFilterList={sortAllFilterList(queryFilters)}
                        clearAllFiltersCallBack={clearAllFilters}
                    />
                }

      <Grid container justify="center" alignItems="flex-start">
        <div style={{ width: "1345px" }}>
          <Table>
            <TableHead>
              <TableRow style={{ height: "65px", textAlign: "center" }}>
                {/* Adjust cell widths here, e.g., style={{ width: 'someWidth' }} */}
                <TableCell padding="none" style={flightIdColumnStyle}>
                  <div style={headerDivTextStyle}>
                    <div style={{ marginBottom: "2px" }}>Sensor ID</div>
                    <div style={{ marginBottom: "2px" }}>Flight ID</div>
                    <div style={{ marginBottom: "2px" }}>Project ID</div>
                  </div>
                </TableCell>
                <TableCell padding="none" style={sensorNameColumnStyle}>
                  <div style={headerDivTextStyle}>
                    <div style={{ marginBottom: "2px" }}>Aircraft</div>
                    <div style={{ marginBottom: "2px" }}>Sensor</div>
                  </div>
                </TableCell>
                <TableCell padding="none" style={dateColumnStyle}>
                  <div style={headerDivTextStyle}>
                    <div style={{ marginBottom: "2px" }}>Survey Date (UTC)</div>
                  </div>
                </TableCell>
                <TableCell padding="none" style={projectNameColumnStyle}>
                  <div style={headerDivTextStyle}>
                    <div style={{ marginBottom: "2px" }}>Project Name</div>
                    <div style={{ marginBottom: "2px" }}>Client Name</div>
                  </div>
                </TableCell>
                <TableCell padding="none" style={processingTemplateColumnStyle}>
                  <div style={headerDivTextStyle}>
                    <div style={{ marginBottom: "2px" }}>
                      Processing
                      <br />
                      Template
                    </div>
                  </div>
                </TableCell>
                <TableCell padding="none" style={pipelineColumnStyle}>
                  <div style={headerDivTextStyle}>
                    <div style={{ marginBottom: "2px" }}>Processing</div>
                  </div>
                </TableCell>{" "}
                {/* Assign majority of width here */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => {
                const metadata = item.metadata.WoolpertAPAC;
                return (
                  <TableRow key={item.guid} style={{ height: "65px" }}>
                    <TableCell
                      padding="none"
                      style={flightIdColumnStyle}
                      justify="center"
                    >
                      <NicerTooltip
                        placement="right"
                        content={
                          <div>
                            <div>
                              <b>Project Status: </b>
                              {metadata.projectStatus}
                            </div>
                          </div>
                        }
                      >
                        <div>
                          {metadata.flightId}
                          <br />
                          {metadata.projectId}
                          <br />
                          {metadata.sensorId}
                        </div>
                      </NicerTooltip>
                    </TableCell>
                    <TableCell
                      padding="none"
                      style={sensorNameColumnStyle}
                      justify="center"
                    >
                      <NicerTooltip
                        placement="right"
                        content={
                          <div>
                            <b>Sensor: </b>
                            {metadata.sensorName}
                          </div>
                        }
                      >
                        <div>
                          {metadata.aircraft}
                          <br />
                          {metadata.sensor}
                        </div>
                      </NicerTooltip>
                    </TableCell>
                    <TableCell
                      padding="none"
                      style={dateColumnStyle}
                      justify="center"
                    >
                      <NicerTooltip
                        placement="right"
                        content={
                          <div>
                            <div>
                              <b>Wheels Up: </b>
                              {metadata.wheelsUp.$date.replace("T", ' ')}
                            </div>
                            <div>
                              <b>Wheels Down: </b>
                              {metadata.wheelsDown.$date.replace("T", ' ')}
                            </div>
                          </div>
                        }
                      >
                        <div>{metadata.surveyDate.$date.replace("T", ' ')}</div>
                      </NicerTooltip>
                    </TableCell>
                    <TableCell
                      padding="none"
                      style={projectNameColumnStyle}
                      justify="center"
                    >
                      <NicerTooltip
                        placement="right"
                        content={
                          <div>
                            <div>
                              <b>Project Name: </b>
                              {metadata.projectName}
                            </div>
                            <div>
                              <b>Project Manager: </b>
                              {metadata.projectManager}
                            </div>
                          </div>
                        }
                      >
                        <div>
                          {metadata.projectName}
                          <br />
                          {metadata.clientName}
                        </div>
                      </NicerTooltip>
                    </TableCell>
                    <TableCell
                      padding="none"
                      style={processingTemplateColumnStyle}
                      justify="center"
                    >
                      <TableEllipsisIcon
                        value={
                          item.ProcessingTemplate ||
                          "Unkown Processsing Template"
                        }
                        isActive={item.Active}
                        isEoOnly={false}
                        isArchived={false} // Skip it because ARCHIVED status has been fully presented by downstream icons
                      />
                    </TableCell>

                    <TableCell padding="none" style={pipelineColumnStyle}>
                      {" "}
                      {/* Assign majority of width here */}
                      <ClusterStatusIconList
                        ClusterProcessing={item.ClusterProcessing}
                        process={item}
                        pipelineGuid={item.guid}
                        blockRow={blockRow}
                        isRowBlocked={isRowBlocked}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <TablePagination
                      component="div"
                      count={total} // You need to provide total count of items from the API
                      page={page - 1} // TablePagination component is zero-based index
                      onChangePage={handleChangePage}
                      rowsPerPage={limit}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      rowsPerPageOptions={[10, 25, 50, 100]} // Options for items per page
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        <div style={{ display: "inline", paddingLeft: "24 px" }}>
          <NicerTooltip placement="top" content="Search">
            <IconButton
              color="primary"
              style={{ marginBottom: 10 }}
              onClick={handleOpenFilterDialog}
            >
              <Search />
            </IconButton>
          </NicerTooltip>
        </div>
      </Grid>
      {/* <TMFilterDialog
        open={true}
      /> */}
      <FilterDialog
        open={isFilterDialogOpen}
        onClose={handleFilterDialogOnClose}
        queryFilters={queryFilters}
        updateQueryFiltersCB={handleUpdateQueryFilters}
        selectFilterOptions={{
          processingTemplates: [
            { label: "StepFunctionTest", value: "StepFunctionTest" },
          ],
          projectManagers: [...projectManagers],
          sensors: [
            { label: "Leica Terrain Mapper2 - 527", value: "527" },
            { label: "Leica Terrain Mapper2 - 528", value: "528" }
          ], //[...sensorEquipment]
        }}
        filterOnFocusIndex={filterOnFocusIndex}
      />
    </>
  );
};

// Adjusted mapStateToProps to add total count of items
const mapStateToProps = (state) => ({
  token: state.token,
  data: state.TMProcessingReducer.data,
  isLoading: state.TMProcessingReducer.isLoading,
  error: state.TMProcessingReducer.error,
  total: state.TMProcessingReducer.total,
  // sensorEquipment: state.sensorEquipment ? state.sensorEquipment.map(item => { return { "label": item.Name, "value": item.Name } }) : [],
  projectManagers:
    state.uniqueValuesFetchSuccess &&
    state.uniqueValuesFetchSuccess["project.ProjectManager"]
      ? state.uniqueValuesFetchSuccess["project.ProjectManager"].map((item) => {
          return { label: item, value: item };
        })
      : [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTMProcessing: (page, limit) => {
      dispatch(fetchTMProcessing(page, limit));
    },
    fetchUniqueValues: (token, collection, field) => {
      dispatch(uniqueValuesFetch(token, collection, field));
    },
    updateQueryParams: (payload) => {
      dispatch(tmProcessingQueryParams(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TMProcessingList);
// export default connect(mapStateToProps, { fetchTMProcessing })(TMProcessingList);
