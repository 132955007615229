import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { throws } from 'assert';

class GenericDisplayDialog extends Component {

    render() {
        return (
            <Dialog disableBackdropClick disableEscapeKeyDown open={this.props.open} fullWidth={true} maxWidth={'lg'}>
                {/* fullWidth={true} maxWidth={'lg'} */}
                <DialogTitle>{this.props.title}
                    <div>{this.props.header}</div>
                </DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
                <DialogActions>

                    {this.props.footer}

                    <Button variant="contained" onClick={() => { this.props.onConfirm() }} color="primary">
                        {this.props.confirmLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default GenericDisplayDialog;