import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import http from "./HttpService";
import style from "./Styles";
import { BASE_URI } from "../app/Config.json";
import HelpIcon from "@material-ui/icons/Help";
import NicerTooltip from "../common/NicerTooltip";
import { IconButton, Grid } from "@material-ui/core";

class ITSupport extends Component {
  state = {
    open: false,
    issue: {
      summary: "",
      description: "",
      priority: "4",
      selectedFile: null,
    },
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      open: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== prevState.open) {
      this.setState({
        open: this.props.open,
        issue: {
          summary: "",
          description: "",
          priority: "4",
          selectedFile: null,
        },
      });
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const issue = { ...this.state.issue };
    issue[input.name] = input.value;
    this.setState({ issue });
  };

  handlePriority = (event) => {
    const issue = { ...this.state.issue };
    issue[event.target.name] = event.target.value;
    this.setState({ issue });
  };

  handleUpload = (event) => {
    const issue = { ...this.state.issue };
    issue.selectedFile = event.target.files[0];
    this.setState({ issue });
  };

  submitIssue = async () => {
    const body = this.getRequestBody(this.state.issue);
    try {
      const response = await http.post(`${BASE_URI}support`, body, {
        headers: { authorization: "Bearer " + this.props.token },
      });
      this.props.snackbar(
        true,
        "success",
        `Your issue is successfully lodged. Issue reference code ${response.data.issueKey}`
      );
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        var errorMessage = "";
        if (ex.response.status === 401) {
          errorMessage = "Unauthorized";
        } else {
          errorMessage = "Bad Request";
        }
        this.props.snackbar(true, "error", errorMessage);
      }
    }
    this.handleClose();
  };

  getRequestBody(issue) {
    var data = new FormData();
    for (const [key, value] of Object.entries(issue)) {
      data.append(key, value);
    }
    data.append("user", this.props.user);
    data.append("url", window.location.href);
    return data;
  }

  render() {
    const { issue } = this.state;
    return (
      <Dialog
        ref={this.myRef}
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.submitIssue();
          }}
        >
          <DialogTitle id="form-dialog-title">Submit Issue</DialogTitle>

          <Grid style={{ position: "absolute", right: 5, top: 5 }}>
            <NicerTooltip
              placement="left"
              content={<span>Help</span>}
              container={this.myRef.current}
            >
              <IconButton
                onClick={() =>
                  window.open(
                    "https://aamgroupglobal.sharepoint.com/AAM Wiki/Support.aspx"
                  )
                }
                style={{ width: 50, height: 50 }}
              >
                <HelpIcon style={{ color: "#1a3a69" }} />
              </IconButton>
            </NicerTooltip>
          </Grid>

          <DialogContent>
            <DialogContentText>Please enter issue details</DialogContentText>
            <TextField
              autoFocus
              required
              value={issue.summary}
              onChange={this.handleChange}
              margin="dense"
              name="summary"
              id="issue-summary"
              label="Summary"
              fullWidth
            />
            <TextField
              required
              value={issue.description}
              InputProps={style.issueDescriptionStyle}
              name="description"
              id="issue-description"
              label="Description"
              multiline
              rows={8}
              onChange={this.handleChange}
              margin="dense"
              fullWidth
            />
            <FormControl style={{ marginBottom: 30 }}>
              <InputLabel htmlFor="issue-priority">Priority</InputLabel>
              <Select
                value={issue.priority}
                onChange={this.handlePriority}
                id="issue-priority"
                inputProps={{
                  name: "priority",
                  id: "issue-priority",
                }}
              >
                <MenuItem value={"4"}>Low</MenuItem>
                <MenuItem value={"3"}>Medium</MenuItem>
                <MenuItem value={"2"}>High</MenuItem>
              </Select>
            </FormControl>
            <div>
              <input
                accept="*"
                multiple
                type="file"
                id="upload-issue"
                style={{ display: "none" }}
                onChange={this.handleUpload}
              />
              <label htmlFor="upload-issue">
                <Button variant="contained" component="span" color="primary">
                  Attach File
                </Button>
                <span>
                  {this.state.issue.selectedFile !== null
                    ? this.state.issue.selectedFile.name
                    : ""}
                </span>
              </label>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={
                !(this.state.issue.summary && this.state.issue.description)
              }
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default ITSupport;
