import React, { useState } from 'react';
// import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

const styles = {
    bigIndicator: {
        height: 5,
    },
};

const TMProcessingTabs = (props) => {
    const { classes } = props
    const [tabIndex, setTabIndex] = useState(0)

    const handleChange = (event, value) => {
        setTabIndex(value)
    }

    return (
        <>
            <Tabs
                style={{ backgroundColor: '#CCCCCC', marginBottom: 10, width: '100vw', minWidth: 1425 }}
                classes={{ indicator: classes.bigIndicator }}
                value={tabIndex}
                onChange={handleChange}
                indicatorColor="secondary"
                centered
            >
                <Tab 
                    label="DASHBOARD" 
                    component={Link} 
                    to="/iptm" 
                    style={{ color: '#1a3a69', fontWeight: 'bolder' }} 
                />
            </Tabs>
        </>
    )
}

export default withStyles(styles)(withRouter(TMProcessingTabs))
