import { Action, ActionCreator, StateFromReducersMapObject } from 'redux'
import { ThunkAction } from 'redux-thunk'
import axios from 'axios'
import { IP_URI } from "../app/Config.json"
import { 
    LidarConfiguration,
    RasterConfiguration,
    VectorConfiguration,
    AddLidarConfigurationAction, 
    AddRasterConfigurationAction,
    AddVectorConfigurationAction,
    UpdateLidarConfigurationAction,
    UpdateRasterConfigurationAction,
    UpdateVectorConfigurationAction,
    InstructionsHeader,
    InstructionsHeaderAction,
    CreateInstructionRequest,
    Instruction,
    ProductConfigurationField,
    StoreFetchedInstructionToStateAction,
    // FilteredInstructionResponse,
    UpdateInstructionRequest,
    FetchInstructionResponse,
    ClientSite,
    SiteNameElement,
    QueryParams
 } from './InstructionsTypes';


//////////////////////////////////
//           Constants          //
//////////////////////////////////
export const INSTRUCTIONS_ACTION_TYPE = ({
    ADD_INSTRUCTIONS_HEADER: 'ADD_INSTRUCTIONS_HEADER',
    UPDATE_INSTRUCTIONS_HEADER: 'UPDATE_INSTRUCTIONS_HEADER',
    ADD_POINTCLOUD_CONFIGURATION: 'ADD_POINTCLOUD_CONFIGURATION',
    UPDATE_POINTCLOUD_CONFIGURATION: 'UPDATE_POINTCLOUD_CONFIGURATION',     // may need index in products
    // DELETE_POINTCLOUD_CONFIGURATION:  'DELETE_POINTCLOUD_CONFIGURATION',
    ADD_RASTER_CONFIGURATION: 'ADD_RASTER_CONFIGURATION',
    UPDATE_RASTER_CONFIGURATION: 'UPDATE_RASTER_CONFIGURATION',             // may need index in products
    // DELETE_RASTER_CONFIGURATION: 'DELETE_RASTER_CONFIGURATION',
    ADD_VECTOR_CONFIGURATION: 'ADD_VECTOR_CONFIGURATION',
    UPDATE_VECTOR_CONFIGURATION: 'UPDATE_VECTOR_CONFIGURATION',             // may need index in products
    // DELETE_VECTOR_CONFIGURATION: 'DELETE_VECTOR_CONFIGURATION',
    CREATE_INSTRUCTION: 'CREATE_INSTRUCTION',
    CREATE_INSTRUCTION_SUCCEED: 'CREATE_INSTRUCTION_SUCCEED',
    CREATE_INSTRUCTION_FAILED: 'CREATE_INSTRUCTION_FAILED',
    DELETE_PRODUCT: 'DELETE_PRODUCT',                                       // may need index in products
    FETCH_INSTRUCTION: 'FETCH_INSTRUCTION',
    FETCH_INSTRUCTION_SUCCEED: 'FETCH_INSTRUCTION_SUCCEED',
    FETCH_INSTRUCTION_FAILED: 'FETCH_INSTRUCTION_FAILED',
    STORE_FETCHED_INSTRUCTION_TO_STORE: 'STORE_FETCHED_INSTRUCTION_TO_STORE',
    URL_QUERY_PARAM_EXTRACTED: 'URL_QUERY_PARAM_EXTRACTED',
    CLEAR_ALL: 'INSTRUCTIONS_STATE_CLEAR_ALL',
    UPDATE_INSTRUCTION: 'UPDATE_INSTRUCTION',
    UPDATE_INSTRUCTION_SUCCEED: 'UPDATE_INSTRUCTION_SUCCEED',
    UPDATE_INSTRUCTION_FAILED: 'UPDATE_INSTRUCTION_FAILED',
    FETCH_SITENAME: 'FETCH_SITENAME',
    FETCH_SITENAME_SUCCEED: 'FETCH_SITENAME_SUCCEED',
    FETCH_SITENAME_FAILED: 'FETCH_SITENAME_FAILED',
    FETCH_INSTRUCTIONS_BY_PROJECT_ID: 'FETCH_INSTRUCTIONS_BY_PROJECT_ID',
    FETCH_INSTRUCTIONS_BY_PROJECT_ID_SUCCEED: 'FETCH_INSTRUCTIONS_BY_PROJECT_ID_SUCCEED',
    FETCH_INSTRUCTIONS_BY_PROJECT_ID_FAILED: 'FETCH_INSTRUCTIONS_BY_PROJECT_ID_FAILED',
})

//////////////////////////////////
//        ActionCreators        //
//////////////////////////////////
// TypeScript introduced Omit after v3.5. Have to define it manually.
type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

// const POINTCLOUD_PRODUCT = [
//     { value: 'POINTCLOUD', label: 'Point Cloud' },
// ]

export const addPointcloudConfiguration = 
    (lidarConfiguration: Omit<LidarConfiguration, 'productType'>): AddLidarConfigurationAction => ({
        type: INSTRUCTIONS_ACTION_TYPE.ADD_POINTCLOUD_CONFIGURATION,
        productType: 'LiDAR',
        // product: POINTCLOUD_PRODUCT[0].value,           // There is only one element
        // product: lidarConfiguration.format,
        ...lidarConfiguration
})

export const addRasterConfiguration = 
    (rasterConfiguration: Omit<RasterConfiguration, 'productType'>): AddRasterConfigurationAction  => ({
        type: INSTRUCTIONS_ACTION_TYPE.ADD_RASTER_CONFIGURATION,
        productType: 'Raster',
        ...rasterConfiguration
})

export const addVectorConfiguration = 
    (vectorConfiguration: Omit<VectorConfiguration, 'productType'>): AddVectorConfigurationAction => ({
        type: INSTRUCTIONS_ACTION_TYPE.ADD_VECTOR_CONFIGURATION,
        productType: 'Vector',
        ...vectorConfiguration
})

export const deleteProduct = (productIndex: number) => ({
    type: INSTRUCTIONS_ACTION_TYPE.DELETE_PRODUCT,
    productIndex,
})

export const updatePointcloudConfiguration = 
    (lidarConfiguration: Omit<LidarConfiguration, 'productType'>, productIndex: number): UpdateLidarConfigurationAction => ({
        type: INSTRUCTIONS_ACTION_TYPE.UPDATE_POINTCLOUD_CONFIGURATION,
        productType: 'LiDAR',
        ...lidarConfiguration,
        productIndex,
})

export const updateRasterConfiguration = 
    (rasterConfiguration: Omit<RasterConfiguration, 'productType'>, productIndex: number): UpdateRasterConfigurationAction  => ({
        type: INSTRUCTIONS_ACTION_TYPE.UPDATE_RASTER_CONFIGURATION,
        productType: 'Raster',
        ...rasterConfiguration,
        productIndex,
})

export const updateVectorConfiguration = 
    (vectorConfiguration: Omit<VectorConfiguration, 'productType'>, productIndex: number): UpdateVectorConfigurationAction => ({
        type: INSTRUCTIONS_ACTION_TYPE.UPDATE_VECTOR_CONFIGURATION,
        productType: 'Vector',
        ...vectorConfiguration,
        productIndex,
})

export const addInstructionsHeader = (instructionsHeader: InstructionsHeader): InstructionsHeaderAction => ({
    type: INSTRUCTIONS_ACTION_TYPE.ADD_INSTRUCTIONS_HEADER,
    ...instructionsHeader
})

export const createInstruction = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.CREATE_INSTRUCTION
})

export const createInstructionSucceed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.CREATE_INSTRUCTION_SUCCEED
})

export const createInstructionFailed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.CREATE_INSTRUCTION_FAILED
})

export const fetchInstruction = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTION,
})

export const fetchInstructionSucceed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTION_SUCCEED
})

export const fetchInstructionFailed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTION_FAILED
})

export const storeFetchedInstructionToState = 
        (fetchedInstruction: FetchInstructionResponse): StoreFetchedInstructionToStateAction => ({
    type: INSTRUCTIONS_ACTION_TYPE.STORE_FETCHED_INSTRUCTION_TO_STORE,
    fetchedInstruction
})

export const clearAll = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.CLEAR_ALL
})

export const updateInstruction = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.UPDATE_INSTRUCTION
})

export const updateInstructionSucceed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.UPDATE_INSTRUCTION_SUCCEED
})

export const updateInstructionFailed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.UPDATE_INSTRUCTION_FAILED
})

export const fetchSiteName = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_SITENAME
})

export const fetchSiteNameSucceed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_SITENAME_SUCCEED
})

export const fetchSiteNameFailed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_SITENAME_FAILED
})

export const fetchInstructionsByProjectId = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTIONS_BY_PROJECT_ID
})

export const fetchInstructionsByProjectIdSucceed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTIONS_BY_PROJECT_ID_SUCCEED
})

export const fetchInstructionsByProjectIdFailed = (): Action<string> => ({
    type: INSTRUCTIONS_ACTION_TYPE.FETCH_INSTRUCTIONS_BY_PROJECT_ID_FAILED
})

//////////////////////////////////
//         Async Actions        //
//////////////////////////////////
export const handleCreateInstruction = (queryParams: QueryParams): ThunkAction<Promise<any>, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { token } = getState()
        // const { isSubmitting, isSubmitSuccess, isFetching, isFetchSuccess, controlFileName, ...rest } = getState().instructions as Instruction
        const { instructions } = getState()
        const CreateInstructionRequest: CreateInstructionRequest = {
            projectId: (instructions as Instruction).projectId,
            siteName: (instructions as Instruction).siteName,
            control: (instructions as Instruction).control,
            controlPoints: (instructions as Instruction).controlPoints,
            horizontalDatum: (instructions as Instruction).horizontalDatum,
            verticalDatum: (instructions as Instruction).verticalDatum,
            controlVerticalAccuracy: (instructions as Instruction).controlVerticalAccuracy,
            targetHorizontalDatum: (instructions as Instruction).targetHorizontalDatum,
            targetVerticalDatum: (instructions as Instruction).targetVerticalDatum,
            tileExtents: (instructions as Instruction).tileExtents,
            tileNaming: (instructions as Instruction).tileNaming,
            products: (instructions as Instruction).products,
        }
        // console.log(`token: ${token}`)
        // console.log(`getState: ${JSON.stringify(rest, null, 4)}`)
        // console.log(`query params: ${JSON.stringify(queryParams, null, 4)}`)
        dispatch(createInstruction())
        return (
            axios({
                method: 'post',
                url: IP_URI + 'instructions',
                // data: rest,
                data: CreateInstructionRequest,
                params: queryParams,
                headers: { authorization: "Bearer " + token },
            })
            .then((response) => {
                // console.log(`Submit Success: ${JSON.stringify(response.data, null, 4)}`)
                dispatch(clearAll())
                return dispatch(createInstructionSucceed())
                // If the two actions are list in below order, the snackbar that indicates the submit result will be abnormal
                // dispatch(createInstructionSucceed())
                // return dispatch(clearAll())
            })
            .catch((error) => {
                // console.log(`Submit Failed: ${JSON.stringify(error.response, null, 4)}`)
                return dispatch(createInstructionFailed())
            })
        )
    }
}

// const VALID_INSTRUCTION_RESPONSE_FIELD: Array<FetchInstructionResponseField> = [
//     'projectId', 'siteName', 'control', 'controlPoints', 
//     'horizontalDatum', 'verticalDatum', 'controlVerticalAccuracy', 
//     'targetHorizontalDatum', 'targetVerticalDatum', 'products',
//     'tileExtents', 'tileNaming', 'numbering'
// ]

const VALID_PRODUCT_CONFIGURATION_RESPONSE_FIELD: Array<ProductConfigurationField> = [
    'productType', 'product', 'format', 'tileFormat', 
    'lidarClasses', 'lidarMkpHorizSpacing',         // Point Cloud
    'rasterGridSize', //'rasterSurfaceTypes',       // Raster
    'vectorContourInterval'                         // Vector
]

interface ObjectWithStringKey {
    [key: string]: any;
}

const filterObjectByValidKeys = (rawObject: ObjectWithStringKey, validKeys: Array<string>): ObjectWithStringKey => {
    if(typeof rawObject !== 'object') return {}
    if(rawObject === {} || rawObject === null) return {}

    let filteredObject: ObjectWithStringKey = {}
    Object.keys(rawObject).forEach(key => {
        if(validKeys.includes(key)) {
            filteredObject[key] = rawObject[key]
        }
    })
    return filteredObject
}

const filterInstructionByValidKeys = (
            rawInstruction: ObjectWithStringKey, 
            // validInstructionResponseField: Array<string>, 
            validProductConfigurationResponseField: Array<string>
        ): FetchInstructionResponse => {
    // let filteredInstruction = filterObjectByValidKeys(rawInstruction, validInstructionResponseField)
    let filteredInstruction = Object.assign({}, rawInstruction)
    if(filteredInstruction['products'] && filteredInstruction.products.length) {
        filteredInstruction['products'] = filteredInstruction['products'].map((rawProduct: ObjectWithStringKey, productIndex: number) => {
            return filterObjectByValidKeys(rawProduct, validProductConfigurationResponseField)
        })
    }
    // if('_id' in rawInstruction) {
    //     filteredInstruction['id'] = rawInstruction['_id']
    // }
    return filteredInstruction as FetchInstructionResponse
}

interface ExcludeParams {
    exclude: string;
}

type HandleFetchInstructionPromisesResponse = [
    FetchInstructionResponse,
    Array<string>
]

// sample argument: 
//      excludeParams = { exclude: 'aois,Updated' }             // split up with comma, no space.
// export const handleFetchInstruction = (instructionId: string, excludeParams: ExcludeParams | undefined = undefined): ThunkAction<Promise<Action<string>>, any, unknown, Action<string>> => {
//     return (dispatch, getState) => {
//         const { token } = getState()
//         dispatch(fetchInstruction())
//         // console.log(`handleFetchInstruction: instructionId: ${instructionId}`)/
//         let queryParams = (excludeParams !== undefined && excludeParams['exclude'] !== undefined)
//                             ? `?exclude=${(excludeParams as ExcludeParams)['exclude']}`
//                             : ''
//         return (
//             axios({
//                 method: 'get',
//                 url: IP_URI + 'instructions/id/' + instructionId + queryParams,
//                 headers: { authorization: "Bearer " + token }
//             })
//             .then(response => {
//                 // console.log(`Fetch Success: ${JSON.stringify(response.data, null, 4)}`)
//                 // let filteredInstruction = filterInstructionByValidKeys(response.data, VALID_INSTRUCTION_RESPONSE_FIELD, VALID_PRODUCT_CONFIGURATION_RESPONSE_FIELD)
//                 let filteredInstruction = filterInstructionByValidKeys(response.data, VALID_PRODUCT_CONFIGURATION_RESPONSE_FIELD)
//                 // console.log(`Filtered Instruction: ${JSON.stringify(filteredInstruction, null, 4)}`)
//                 // dispatch(storeFetchedInstructionToState(filteredInstruction))
//                 dispatch(storeFetchedInstructionToState(filteredInstruction))
//                 return dispatch(fetchInstructionSucceed())
//             })
//             .catch(error => {
//                 return dispatch(fetchInstructionFailed())
//             })
//         )
//     }
// }

// export const handleFetchSiteName = (clientId: string): ThunkAction<Promise<Array<string>>, any, unknown, Action<string>> => {
//     return (dispatch, getState) => {
//         const { token } = getState()
//         dispatch(fetchSiteName())
//         return (
//             axios({
//                 method: 'get',
//                 url: IP_URI + 'client-site/clientId/' + clientId,
//                 headers: { authorization: "Bearer " + token }
//             }).then(response => {
//                 // console.log(`Fetch Site Names Succeed: ${JSON.stringify(response.data, null, 4)}`)
//                 let clientSiteResponse: ClientSite = response.data
//                 dispatch(fetchSiteNameSucceed())
//                 return clientSiteResponse.siteNames.map(siteName => siteName.trim())
//             }).catch(error => {
//                 // console.log(`Fetch Site Names Failed: ${JSON.stringify(error, null, 4)}`)
//                 dispatch(fetchSiteNameFailed())
//                 return []
//             })
//         )
//     }
// }

const fetchInstructionPromise = (token: string, instructionId: string, queryParams: string): Promise<FetchInstructionResponse | any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: IP_URI + 'instructions/id/' + instructionId + queryParams,
                headers: { authorization: "Bearer " + token }
            });
            return resolve(filterInstructionByValidKeys(response.data, VALID_PRODUCT_CONFIGURATION_RESPONSE_FIELD));
        } catch (error) {
            return reject(error);
        }
    })
}

const fetchSiteNamePromise = (token: string, clientId: string): Promise<Array<SiteNameElement>> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: IP_URI + 'client-site/clientId/' + clientId,
                headers: { authorization: "Bearer " + token }
            })
            let clientSiteResponse: ClientSite = response.data
            return resolve(clientSiteResponse.siteNames.map(
                siteNameElement => ({
                    ...siteNameElement, 
                    siteName: siteNameElement.siteName.trim()
                })
            ))
        } catch (error) {
            return reject(String(error))
        }
    })
}

// Sample argument: 
//      excludeParams = { exclude: 'aois,Updated' }             // split up with comma, no space.
//      clientId= 'RIOT14'
// Once clientId is passed in, the function would fetch client site name
export const handleFetchInstruction = (
        instructionId: string, 
        excludeParams: ExcludeParams | undefined = undefined, 
        clientId: string | undefined = undefined): ThunkAction<Promise<Array<any> | any>, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { token } = getState()
        // console.log(`handleFetchInstruction: instructionId: ${instructionId}`)/
        let queryParams = (excludeParams !== undefined && excludeParams['exclude'] !== undefined)
            ? `?exclude=${(excludeParams as ExcludeParams)['exclude']}`
            : ''
        let promises: Array<Promise<any>> = [
            fetchInstructionPromise(token, instructionId, queryParams), 
        ]
        clientId && promises.push(fetchSiteNamePromise(token, clientId))
        // console.log(`Promises Job Count: ${promises}, ${promises.length}`)
        dispatch(fetchInstruction())
        return (
            // fetchInstructionPromise(token, instructionId, queryParams)\
            Promise.all(promises)
            .then((response: Array<any>) => {
                // console.log(`Fetch Success: ${JSON.stringify(response, null, 4)}`)
                // console.log(`Filtered Instruction: ${JSON.stringify(response[0], null, 4)}`)
                response[0] && dispatch(storeFetchedInstructionToState(response[0] as FetchInstructionResponse))
                // response[1] && console.log(`Site Name Options: ${response[1]}`)
                dispatch(fetchInstructionSucceed())
                return response
            })
            .catch(error => {
                dispatch(fetchInstructionFailed())
                throw Error(String(error))
                // return error
            })
        )
    }
}

export const handleFetchSiteName = (clientId: string): ThunkAction<Promise<Array<SiteNameElement>>, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { token } = getState()
        dispatch(fetchSiteName())
        return (
            fetchSiteNamePromise(token, clientId)
            .then((response: Array<SiteNameElement>) => {
                // console.log(`Fetch Site Names Succeed: ${JSON.stringify(response, null, 4)}`)
                dispatch(fetchSiteNameSucceed())
                return response
            }).catch(error => {
                // console.log(`Fetch Site Names Failed: ${JSON.stringify(error, null, 4)}`)
                dispatch(fetchSiteNameFailed())
                return []
            })
        )
    }
}

export const handleUpdateInstruction = (instructionId: string): ThunkAction<Promise<any>, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { token } = getState()
        const { instructions } = getState()
        const updateInstructionRequest: UpdateInstructionRequest = {
            projectId: (instructions as Instruction).projectId,
            siteName: (instructions as Instruction).siteName,
            control: (instructions as Instruction).control,
            controlPoints: (instructions as Instruction).controlPoints,
            horizontalDatum: (instructions as Instruction).horizontalDatum,
            verticalDatum: (instructions as Instruction).verticalDatum,
            controlVerticalAccuracy: (instructions as Instruction).controlVerticalAccuracy,
            targetHorizontalDatum: (instructions as Instruction).targetHorizontalDatum,
            targetVerticalDatum: (instructions as Instruction).targetVerticalDatum,
            tileExtents: (instructions as Instruction).tileExtents,
            tileNaming: (instructions as Instruction).tileNaming,
            products: (instructions as Instruction).products,
        }
        // console.log(`token: ${token}`)
        // console.log(`getState: ${JSON.stringify(rest, null, 4)}`)
        dispatch(updateInstruction())
        return (
            axios({
                method: 'put',
                url: IP_URI + 'instructions/id/' + instructionId,
                // data: rest,
                data: updateInstructionRequest,
                headers: { authorization: "Bearer " + token },
            })
            .then((response) => {
                // console.log(`Update Success: ${JSON.stringify(response.data, null, 4)}`)
                dispatch(clearAll())
                return dispatch(updateInstructionSucceed())
            })
            .catch((error) => {
                // console.log(`Update Failed: ${JSON.stringify(error.response, null, 4)}`)
                return dispatch(updateInstructionFailed())
            })
        )
    }
}

const fetchInstructionsByProjectIdPromise = (token: string, projectId: string, queryParams: string): Promise<Array<FetchInstructionResponse> | any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: IP_URI + 'instructions/projectId/' + projectId + queryParams,
                headers: { authorization: "Bearer " + token }
            })
            let fetchedinstructionsResponse: Array<FetchInstructionResponse> = response.data
            // console.log(`Response from fetchInstructionsByProjectIdPromise: ${JSON.stringify(fetchedinstructionsResponse, null, 4)}`)
            return resolve(fetchedinstructionsResponse)
        } catch (error) {
            return reject(error)
        }
    })
}

export const handleFetchInstructionsByProjectId = (
        projectId: string, 
        excludeParams: ExcludeParams): ThunkAction<Promise<Array<any>>, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { token } = getState()
        const  queryParams: string = (excludeParams !== undefined && excludeParams['exclude'] !== undefined)
            ? `?exclude=${(excludeParams as ExcludeParams)['exclude']}`
            : ''
        dispatch(fetchInstructionsByProjectId())
        return (
            fetchInstructionsByProjectIdPromise(token, projectId, queryParams)
            .then((response: Array<FetchInstructionResponse>) => {
                // console.log(`Fetch Instructions Succeed: ${JSON.stringify(response, null, 4)}`)
                dispatch(fetchInstructionsByProjectIdSucceed())
                return response
            }).catch(error => {
                // console.log(`Fetch Instructions Failed: ${JSON.stringify(error, null, 4)}`)
                dispatch(fetchInstructionsByProjectIdFailed())
                return []
            })
        )
    }
}