import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Grid, Checkbox, Button, List, ListItem } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { saveAs } from 'file-saver';
import ClusterProcessingStatusTableIcon from '../processing/ClusterProcessingStatusTableIcon';
import { resubmitClusterProcessing, processingUpdate } from '../processing/ProcessingActions'
import ConfirmationDialog from '../common/ConfirmationDialog'
import GenericDisplayDialog from '../common/GenericDisplayDialog';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../products/dynamicClusterStatusIconWithSlick.css'        // Must below slick-carousel native CSS files
import Slider from "react-slick";
import QALogDialog from '../common/QALogDialog';
import ClusterStatusIcon from '../tmprocessing/ClusterStatusIcon'

// Customize Arrows
// interface SlickArrowProps {
//     type: 'next' | 'prev';
//     onClickArrowCallback: () => any;
// }

const SlickArrow = (props) => {
    let arrowDirectionClassName = (props.type === "next") ? "slick-next" : "slick-prev";
    return (
        // Default element copied from slick-carousel lib
        <button type="button"
            className={props.onClick ? `slick-arrow ${arrowDirectionClassName}` : `slick-arrow ${arrowDirectionClassName} slick-disabled`}
            onClick={(event) => {
                props.onClick && props.onClick(event)
                props.onClickArrowCallback && props.onClickArrowCallback()
            }}
        >
            Previous
        </button>
    )
}

function DynamicClusterStatusIcon(props) {
    const { process, isWaitingForPollingResponse, user, explorerOnClickCallback } = props;
    // const clusterFilter = props.clusterFilter;

    // Resubmit Dialog
    const [onClickClusterName, setOnClickClusterName] = useState('')
    const [resubmitDialogOpen, setResubmitDialogOpen] = useState(false)
    const [QALogDialogOpen, setQALogDialogOpen] = useState(false)
    const [statusToUpdate, setStatusToUpdate] = useState('')
    // Cluster Result Dialog
    const [displayDialogOpen, setDisplayDialogOpen] = useState(false)
    const [clusterCombinedResults, setClusterCombinedResults] = useState([])
    const [clusterFilteredResults, setClusterFilteredResults] = useState([])
    const [clusterResultsSummary, setClusterResultsSummary] = useState([])
    const [clusterResultsName, setClusterResultsName] = useState('')
    const [clusterFilterList, setClusterFilterList] = useState([])
    const [filteredCluster, setFilteredCluster] = useState([])
    // Pipeline slick
    const sliderRef = useRef(null)
    const slickSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 11,
        slidesToScroll: 3,
        adaptiveHeight: true,
        arrows: true,
        swipe: false,
    }
    const [isSlickArrowClicked, setIsSlickArrowClicked] = useState(false)             // Do not auto reset anymore once arrows of slick bar are clicked

    useEffect(() => {
        process.ClusterProcessing
            // && setFilteredCluster(process.ClusterProcessing.filter(cluster => cluster.Category == clusterFilter))
            && setFilteredCluster(process.ClusterProcessing)
    }, [process.ClusterProcessing])


    const showQACompledtWErrorsDialog = (clusterName,statusToUpdate) => {
        setQALogDialogOpen(true)
        setStatusToUpdate(statusToUpdate)
        setOnClickClusterName(clusterName)
    }

    const showClusterResubmitDialog = (clusterName) => {
        setResubmitDialogOpen(true)
        setOnClickClusterName(clusterName)
    }

    // This function inherited from PROD and has been migrated from a class component to a function component
    // TODO: It doesn't work well. Will fix this when we have clear ideas.
    const showClusterResultsDialog = (clusterProcessingArray, clusterName, sensorID) => {
        //urgh... more specifics, will replace this when generic cluster handling is implemented
        let clusterResults = [];
        if (clusterName === 'ICP') {
            clusterResults = clusterProcessingArray.filter(cluster => cluster.Name === 'ICP1_MineIP1' || cluster.Name === 'ICP2_MineIP1');
        }
        else
            clusterResults = clusterProcessingArray.filter(cluster => cluster.Name === clusterName)

        let combinedResults = []
        let filteredErrorCount = {};
        clusterResults.forEach((result) => {
            if (result.FailedJobCount > 0) {
                result.FailedFiles.forEach((item) => {
                    combinedResults.push({ 'filename': item.file_names, 'errors': item.failure_message })//,'task':result.Name})
                    item.failure_message.forEach((errMsg) => {
                        //unfortunatley using some lastools specific filtering
                        if (!RegExp(/ERROR: no input specified/, "g").test(errMsg)) {

                            //generalising these 'cannot realloc point_buffer' errors
                            if (RegExp(/ERROR: cannot realloc point_buffer/, "g").test(errMsg)) {
                                errMsg = RegExp(/(.*?)ERROR: cannot realloc point_buffer/, "g").exec(errMsg)[0];
                            }

                            if (filteredErrorCount[errMsg] === undefined) {
                                filteredErrorCount[errMsg] = 1;
                            }
                            else {
                                filteredErrorCount[errMsg] += 1;
                            }
                        }
                    });

                })
            }
        })
        // console.log('...filteredErrorCount', filteredErrorCount)

        let errorSummary = [];
        for (const key in filteredErrorCount) {
            let value = filteredErrorCount[key];

            //optional check for properties from prototype chain
            if (filteredErrorCount.hasOwnProperty(key)) {
                errorSummary.push(value + ": " + key);
            }
        }

        setDisplayDialogOpen(true)
        setClusterCombinedResults(combinedResults)
        setClusterResultsSummary(errorSummary)
        setClusterResultsName(sensorID + "_" + clusterName)
    }

    const filterResults = (checked, item) => {
        // let start_index = item.search(/(?<=\d+: ).+/g);//bah negative lookbehinds dont work in a lot of non-chrome browsers
        let start_index = item.search(/\sProcess:\s/g) + 10;
        let searchString = item.substring(start_index);
        let newFilterList = clusterFilterList.concat()      // shallow copy
        if (checked && clusterFilterList.indexOf(searchString) === -1) {
            newFilterList.push(searchString);
        }
        else if (!checked) {//} && this.state.clusterFilterList.indexOf(searchString) > 0){
            newFilterList = clusterFilterList.filter(errorString => errorString !== searchString);
        }

        let filteredResults = [];
        process.ClusterProcessing.forEach((result) => {
            result.hasOwnProperty('FailedFiles') && result.FailedFiles.forEach((item) => {
                if (newFilterList.length > 0) {
                    let matchFound = false;
                    for (let errMsg of item.failure_message) {
                        for (let filterItem of newFilterList) {
                            if (RegExp(filterItem).test(errMsg)) {
                                matchFound = true;
                                break;
                            }
                        }
                        if (matchFound)
                            break;
                    }

                    if (matchFound) {
                        filteredResults.push({ 'filename': item.file_names, 'errors': item.failure_message })
                    }
                }
                else {
                    filteredResults.push({ 'filename': item.file_names, 'errors': item.failure_message })//,'task':result.Name})
                }

            })
        })

        setClusterFilterList(newFilterList)
        setClusterFilteredResults(filteredResults)
    }

    const indexOfMostUpdatedCluster = (clusters) => {
        if (clusters[0].Status === 'ON_HOLD') {
            return 0
        } else {
            return clusters.reduce((indexOfMax, cluster, idx, array) => Date.parse(cluster.UpdatedDate) >= Date.parse(array[indexOfMax].UpdatedDate)
                ? idx
                : indexOfMax, 0)
        }
    }

    return (
        <>
            <Grid container direction="row" alignItems="center" justify="flex-start" style={{ marginLeft: 34 }}>
                {filteredCluster.length > slickSettings.slidesToShow
                    ? <div style={{ width: 565 }}>
                        <Slider
                            {...slickSettings}
                            ref={sliderRef}
                            prevArrow={<SlickArrow type="prev" onClickArrowCallback={() => setIsSlickArrowClicked(true)} />}
                            nextArrow={<SlickArrow type="next" onClickArrowCallback={() => setIsSlickArrowClicked(true)} />}
                        >
                            {!isSlickArrowClicked && sliderRef && sliderRef.current && sliderRef.current.slickGoTo
                                && sliderRef.current.slickGoTo(Math.max(indexOfMostUpdatedCluster(filteredCluster) - Math.floor(slickSettings.slidesToShow) + 1,
                                    0))
                            }
                            {
                                React.Children.toArray(
                                    filteredCluster.map((cluster, index) => {
                                        return (
                                            <Grid
                                                item
                                                direction="column"
                                                key={index}
                                                style={{ width: 50, height: 50, margin: 1, position: "relative" }}
                                            >
                                                <div className='label__box' >
                                                    <p className='label__box--font' >
                                                        {cluster.Label.replace(' Status', '').replace('\nStatus', '')}
                                                    </p>
                                                </div>
                                                <div style={{ margin: 0, padding: 0, paddingTop: '3px' }} >
                                                <ClusterProcessingStatusTableIcon
                                                        style={{ position: "absolute", width: "100%", height: "100%" }}
                                                        removeQa={!cluster.DisplayQA}

                                                        updateQAWithErrors={(input) => showQACompledtWErrorsDialog(cluster.Name, input) }

                                                        clusterName={cluster.Name}
                                                        process={process}
                                                        isWaitingForPollingResponse={isWaitingForPollingResponse}
                                                        resubmit={() => showClusterResubmitDialog(cluster.Name)}
                                                        showResults={() => { showClusterResultsDialog(process.ClusterProcessing, cluster.Name, process.SensorID) }}
                                                        explorerOnClickCallback={explorerOnClickCallback}
                                                    />
                                                </div>
                                            </Grid>
                                        )
                                    })
                                )
                            }
                        </Slider>
                    </div>
                    : React.Children.toArray(filteredCluster.map((cluster, index) => {
                        return (
                            <Grid
                                item
                                direction="column"
                                key={index}
                                style={{ width: 50, height: 50, margin: 1, position: "relative" }}
                            >
                                <div className='label__box' >
                                    <p className='label__box--font' >
                                        {cluster.Label.replace(' Status', '').replace('\nStatus', '')}
                                    </p>
                                </div>
                                <div style={{ margin: 0, padding: 0, paddingTop: '3px' }} >
                                    <ClusterProcessingStatusTableIcon
                                        style={{ position: "absolute", width: "100%", height: "100%" }}
                                        removeQa={!cluster.DisplayQA}

                                        updateQAWithErrors={(input) => showQACompledtWErrorsDialog(cluster.Name, input) }

                                        clusterName={cluster.Name}
                                        process={process}
                                        isWaitingForPollingResponse={isWaitingForPollingResponse}
                                        resubmit={() => showClusterResubmitDialog(cluster.Name)}
                                        showResults={() => { showClusterResultsDialog(process.ClusterProcessing, cluster.Name, process.SensorID) }}
                                        explorerOnClickCallback={explorerOnClickCallback}
                                    />
                                </div>
                            </Grid>
                        )
                    }))
                }
            </Grid>

            <ConfirmationDialog
                title={`Resubmit Cluster ${onClickClusterName} Job`}
                confirmLabel="PROCEED"
                onCancel={() => setResubmitDialogOpen(false)}
                onConfirm={() => {
                    setResubmitDialogOpen(false)
                    props.resubmitClusterProcessing(props.token, process._id, onClickClusterName);
                }}
                open={resubmitDialogOpen}
            >
                <p>Are you sure you want reprocess this data?</p>
                <p>The existing data (including downstream processing results) will be <span style={{ fontWeight: 'bold' }}>OVERWRITTEN</span></p>
            </ConfirmationDialog>

            <QALogDialog
                dialogOpen={QALogDialogOpen}
                onConfirm={(issueDesc, issueRes) => {
                    let jsonDoc = {};
                    jsonDoc["Status"] = statusToUpdate;//"QA_COMPLETED_WITH_ERRORS"; // how to make the status dynamic???
                    jsonDoc["QAUser"] = user;
                    jsonDoc["QAIssueDesc"] = issueDesc;
                    jsonDoc["QAIssueRes"] = issueRes;

                    props.processingUpdate(props.token, process._id, jsonDoc, onClickClusterName)
                    setQALogDialogOpen(false);
                }}
                onCancel={() => setQALogDialogOpen(false)}
            />

            <GenericDisplayDialog
                title={"Job Results (" + clusterCombinedResults.length + " failures)"}
                confirmLabel="OK"
                onConfirm={() => setDisplayDialogOpen(false)}
                open={displayDialogOpen}
                header={
                    <div>
                        {/* <span style={{ fontSize: 16, fontWeight: 'bold' }}>Failed files ({this.state.clusterResults.length}):</span> */}
                        <br />
                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>Summary of Failed files:</span>
                        {clusterResultsSummary.map((item, index) => (
                            <div style={{ fontSize: 15, fontWeight: 'bold', marginLeft: 10 }}>
                                <Checkbox
                                    style={{ marginTop: -3 }}
                                    onChange={(event, checked) => filterResults(checked, item)}
                                />
                                {item}
                            </div>
                        ))}
                        <br />
                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>Individual Failure Details:</span>
                    </div>
                }
                footer={
                    <div>
                        <Button variant="contained"
                            onClick={() => {

                                // let txt = this.state.clusterResults.map((item) => item.filename+':\r\n'+item.errors.map((err) => err+'\r\n'));
                                let txt = "";
                                clusterCombinedResults.forEach((item) => {
                                    txt += item.filename + ":\r\n";
                                    item.errors.forEach(err => txt += err + '\r\n')
                                });

                                saveAs(new Blob([txt], { type: "text/plain;charset=utf-8" }), clusterResultsName + '_Errors.txt')
                            }}
                            color="primary"
                            disabled={clusterFilteredResults.length === 0}>
                            Save as text file
                        </Button>
                        {/* <Button variant="contained"
                            onClick={() => {
                                saveAs(new Blob(["Hello, world!"], { type: "text/csv;charset=utf-8" }), 'test.csv')
                            }}
                            color="primary">
                            Save as CSV file
                        </Button> */}
                    </div>
                }>
                {
                    clusterFilteredResults && clusterFilteredResults.length > 0 &&
                    <div>

                        <List dense={true}>
                            {clusterFilteredResults.map((item) => (
                                <ListItem key={item.filename} divider style={{ fontSize: 14, paddingTop: 2, paddingBottom: 2 }}>

                                    <ExpansionPanel style={{ backgroundColor: '#FF9999', width: '100%' }}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            File:&nbsp;<span style={{ fontWeight: 'bold' }}>{item.filename}</span>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container direction="column">
                                                {item.errors.map((err) => <div>{err}</div>)}
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                </ListItem>
                            ))}
                        </List>
                    </div>
                }
                {
                    clusterFilteredResults && clusterFilteredResults.length == 0 &&
                    <div style={{ marginLeft: 10 }}>
                        All tasks run successfully or all errors filtered.
                    </div>
                }
            </GenericDisplayDialog>
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    let tokenPayload = state.token ? JSON.parse(atob(state.token.split(".")[1])) : ''
    let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''
    return ({
        token: state.token,
        user
    })
}

const mapDispatchToProps = { resubmitClusterProcessing, processingUpdate }

// export default DynamicClusterStatusIcon;
export default connect(mapStateToProps, mapDispatchToProps)(DynamicClusterStatusIcon);