export function getManageNotification(state = null, action) {
  switch (action.type) {
    case "GET_MANAGE_NOTIFICATION_SUCCESS":
      return action.manageNotification;

    default:
      return state;
  }
}

export function getManageNotificationFailure(state = false, action) {
  switch (action.type) {
    case "GET_MANAGE_NOTIFICATION_HAS_ERROR":
      return action.hasError;

    default:
      return state;
  }
}
