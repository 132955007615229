import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import { 
    Grid, CircularProgress, Typography, Link
} from '@material-ui/core'
import Breadcrumbs from '@material-ui/lab/Breadcrumbs'
import { updateObjectPath, clearAll } from './objectExplorerActions';
import { ObjectExplorerStore } from './ObjectExplorerTypes'
import './explorer.css'


const FolderNavigation = (props: FolderNavigationFromRedux) => {
    // Props from Redux mapStateToProps
    const { objectExplorerStore } = props
    // Props from Redux mapDispatchToProps
    const { updateObjectPath, clearAll } = props

    const [ objectPathSplited, setObjectPathSplited ] = useState<Array<string>>([])
    var history = useHistory() 


    useEffect(() => {
        if(objectExplorerStore.bucketName) {
            let objectPath = objectExplorerStore.bucketName + '/' + objectExplorerStore.prefix
            setObjectPathSplited(objectPath.split('/').slice(0, -1))
        }
    }, [objectExplorerStore.bucketName, objectExplorerStore.prefix])

    const handleOnClick = (index: any) => {
        // console.log(`Path: ${objectPathSplited.slice(0, index+3).join('/')}`)
        let bucketName = objectPathSplited[0]
        let prefix = objectPathSplited.slice(1, index+3).join('/') + '/'
        clearAll()
        updateObjectPath(bucketName, prefix)
        let queryParams = new URLSearchParams({ bucket: bucketName, prefix })
        history.replace({ search: queryParams.toString() })             // sync the query parameters
    }

    return (
        <>
                {
                    objectPathSplited.length
                        ? <>
                            <Breadcrumbs separator="›" arial-label="Breadcrumb">
                                <p className='breadcrumb__font'>{objectPathSplited[0]}</p>
                                <p className='breadcrumb__font'>{objectPathSplited[1]}</p>
                                {React.Children.toArray(
                                    (objectPathSplited.slice(2, -1)).map((pathPart: string, index: number) => {
                                        return (
                                            <Link 
                                                // color="primary" 
                                                className='breadcrumb__font--link'
                                                style={{ color: '#3366CC' }}
                                                href='javascript:;' 
                                                key={index} 
                                                onClick={(event: any) => handleOnClick(index)}
                                            >
                                                {pathPart}
                                            </Link>)
                                    }))
                                }
                                <p className='breadcrumb__font'>{objectPathSplited[objectPathSplited.length-1] + '/'}</p>
                            </Breadcrumbs>
                        </>
                        : <p className='breadcrumb__font'>N/A</p>
                }
        </>
    )
}

// export default FolderNavigation
const mapStateToProps = (rootState: any) => ({ 
    objectExplorerStore: rootState.objectExplorer as ObjectExplorerStore
 })

const mapDispatchToProps = { 
    updateObjectPath, clearAll
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type FolderNavigationFromRedux = ConnectedProps<typeof connector>

const ConnectedFolderNavigation = connector(FolderNavigation)

export default ConnectedFolderNavigation