export function flightsListHasError(state = false, action) {
    switch (action.type) {
        case 'FLIGHTS_LIST_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function flightsListLoading(state = false, action) {
    switch (action.type) {
        case 'FLIGHTS_LIST_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function flights(state = null, action) {
    switch (action.type) {
        case 'FLIGHTS_LIST_SUCCESS':
            return action.flights;

        default:
            return state;
    }
}

export function flightItemHasError(state = false, action) {
    switch (action.type) {
        case 'FLIGHT_ITEM_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function flightItemLoading(state = false, action) {
    switch (action.type) {
        case 'FLIGHT_ITEM_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function flightsQueryParams(state = {
    page: 1, pageSize: 10, total: -1//, filter: 'All'
}, action) {
    switch (action.type) {
        case 'FLIGHTS_QUERY_PARAMS':
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

export function flight(state = null, action) {
    switch (action.type) {
        case 'FLIGHT_ITEM_SUCCESS':
            // action.flight.updateFromServer = true;
            return action.flight;

        default:
            return state;
    }
}

export function flightItemUpdateHasError(state = false, action) {
    switch (action.type) {
        case 'FLIGHT_ITEM_UPDATE_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function flightItemUpdateLoading(state = false, action) {
    switch (action.type) {
        case 'FLIGHT_ITEM_UPDATE_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function flightItemUpdateSuccess(state = false, action) {
    switch (action.type) {
        case 'FLIGHT_ITEM_UPDATE_SUCCESS':
            return action.flight;

        default:
            return state;
    }
}

export function concurrentUploadCheckHasError(state = false, action) {
    switch (action.type) {
        case 'CONCURRENT_UPLOAD_CHECK_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function concurrentUploadCheckLoading(state = false, action) {
    switch (action.type) {
        case 'CONCURRENT_UPLOAD_CHECK_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function concurrentUploadCheckSuccess(state = false, action) {
    switch (action.type) {
        case 'CONCURRENT_UPLOAD_CHECK_SUCCESS':
            return action.concurrentUploads;

        default:
            return state;
    }
}