import { Reducer, AnyAction, Store, Action } from 'redux'
import { OBJECT_EXPLORER_ACTION_TYPE } from './objectExplorerActions'
import { ObjectExplorerStore, FetchObjectListResponseMeta } from './ObjectExplorerTypes'

export const PAGE_SIZE = 300
export const initPaginationParams = {
    page: 1,                // index starts from 1
    pageSize: PAGE_SIZE
}

const initState: ObjectExplorerStore = {
    pagination: initPaginationParams,
    bucketName: '',
    prefix: '',
    // bucketName: 'imageryprocessing',
    // prefix: 'DEPT04/PRJ41680_DELWP_DTV_LiDAR_Stage_2/1_RD_In/SL020888/FL018487_002/',
    // prefix: 'DEPT04/PRJ40162_DELWP_Bendigo_Ortho_Summer_2021_22/1_RD_In/',
    // prefix: 'CL03623/PRJ34959_Hughenden_Irrigation_Project/1_RD_In/FL009277_405/Imagery/YC030255/',
    // objectList: [],
    isFetchingObjectList: false,
    bufferedPages: [],
    latestFetchObjectListResponseMeta: {
        nextContinuationToken: null,
        isTruncated: false,
        maxKeys: 0,
        totalKeyCount: 0,
    },
    totalKeyCount: 0,
    isValidatingArchive: false,
}

const objectExplorer: Reducer<ObjectExplorerStore> = (prevState = initState, action: AnyAction) => {
    switch(action.type) {
        case OBJECT_EXPLORER_ACTION_TYPE.UPDATE_OBJECT_PATH: {
            const  { bucketName, prefix } = action
            return ({
                // ...initState,
                ...prevState,
                bucketName,
                prefix,
            })
        }
        case OBJECT_EXPLORER_ACTION_TYPE.FETCH_OBJECT_LIST: {
            return ({
                ...prevState,
                isFetchingObjectList: true
            })
        }
        case OBJECT_EXPLORER_ACTION_TYPE.FETCH_OBJECT_LIST_SUCCEED: {
            const { nextContinuationToken, isTruncated, objectList, totalKeyCount, maxKeys } = action
            if(maxKeys % PAGE_SIZE) {
                return {
                    ...prevState
                }
            } else {
                return ({
                    ...prevState,
                    bufferedPages: [...prevState.bufferedPages, objectList],
                    isFetchingObjectList: false,
                    fetchObjectListSucceed: true,
                    objectList,
                    latestFetchObjectListResponseMeta: {
                        nextContinuationToken,
                        isTruncated,
                        totalKeyCount,
                        maxKeys
                    },
                })}
            }
        case OBJECT_EXPLORER_ACTION_TYPE.FETCH_OBJECT_LIST_FAILED: {
            return ({
                ...prevState,
                isFetchingObjectList: false,
                fetchObjectListSucceed: false
            })
        }
        case OBJECT_EXPLORER_ACTION_TYPE.UPDATE_PAGINATION: {
            const { pagination } = action
            return ({
                ...prevState,
                pagination
            })
        }
        case OBJECT_EXPLORER_ACTION_TYPE.UPDATE_TOTAL_KEY_COUNT: {
            const { totalKeyCount } = action
            return ({
                ...prevState,
                totalKeyCount
            })
        }
        case OBJECT_EXPLORER_ACTION_TYPE.VALIDATE_ARCHIVE: {
            return ({
                ...prevState,
                isValidatingArchive: true
            })
        }
        case OBJECT_EXPLORER_ACTION_TYPE.VALIDATE_ARCHIVE_SUCCEED: {
            const { isArchivedSucceed } = action
            return ({
                ...prevState,
                isValidatingArchive: false,
                validateArchiveSucceed: true,
                isArchivedSucceed
            })
        }
        case OBJECT_EXPLORER_ACTION_TYPE.VALIDATE_ARCHIVE_FAILED: {
            return ({
                ...prevState,
                isValidatingArchive: false,
                validateArchiveSucceed: false,
            })
        }
        case OBJECT_EXPLORER_ACTION_TYPE.CLEAR_ALL:
            return {
                ...initState
            }
        default:
            return {
                ...prevState
            }
    }
}

export default objectExplorer