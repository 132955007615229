import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { isMemberOfGuestGroups } from '../common/PrivateRoute'

const styles = {
    bigIndicator: {
        height: 5,
    },
};

class ProcessingTabs extends Component {

    constructor(props) {
        super(props)
        this.handleRoute(true);
    }

    state = {
        value: 0,
    };

    componentWillUpdate = () => {
        this.handleRoute(false);
    }

    handleRoute = (inConstructor) => {
        switch (this.props.history.location.pathname) { 
            case String(this.props.history.location.pathname.match(/\/ip/g)):
                if (this.state.value !== 0)
                    inConstructor ? this.state.value = 0 : this.setState({ value: 0 });
                break;
            // case String(this.props.history.location.pathname.match(/\/ip\/params(\/.*)?/g)):
            case String(this.props.history.location.pathname.match(/\/ip\/params(\/)?/g)):
                if (this.state.value !== 1)
                    inConstructor ? this.state.value = 1 : this.setState({ value: 1 });
                break;
            case String(this.props.history.location.pathname.match(/\/ip\/params\/[a-f\d]{24}/g)):
            case String(this.props.history.location.pathname.match(/\/ip\/params\/[a-f\d]{24}\/edit/g)):
                if (this.state.value !== 2)
                    inConstructor ? this.state.value = 2 : this.setState({ value: 2 });
                break;
            case String(this.props.history.location.pathname.match(/\/ip\/params\/new/g)):
                if (this.state.value !== 2)
                    inConstructor ? this.state.value = 2 : this.setState({ value: 2 });
                break;
            
            case String(this.props.history.location.pathname.match(/\/ip\/imagery-params(\/)?/g)):
                if (this.state.value !== 2)
                    inConstructor ? this.state.value = 2 : this.setState({ value: 2 });
                break;
            case String(this.props.history.location.pathname.match(/\/ip\/imagery-params\/[a-f\d]{24}/g)):
            case String(this.props.history.location.pathname.match(/\/ip\/imagery-params\/[a-f\d]{24}\/edit/g)):
                if (this.state.value !== 3)
                    inConstructor ? this.state.value = 3 : this.setState({ value: 3 });
                break;
            case String(this.props.history.location.pathname.match(/\/ip\/imagery-params\/new/g)):
                if (this.state.value !== 3)
                    inConstructor ? this.state.value = 3 : this.setState({ value: 3 });
                break;

            //what about when params are visible!!!
            case String(this.props.history.location.pathname.match(/\/ip\/custom_processing\/sensor/g)):
                if (this.state.value !== 3)
                    inConstructor ? this.state.value = 3 : this.setState({ value: 3 });
                break;

            default:
                if (this.state.value !== 0)
                    inConstructor ? this.state.value = 0 : this.setState({ value: 0 });
                break;
        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;

        //TODO remove this tetrarays nonsense and replace with a REAL permissions model
        if (isMemberOfGuestGroups(this.props.token)) {
            return (
                <Tabs
                    style={{ backgroundColor: '#CCCCCC', marginBottom: 10, width: '100vw', minWidth: 1425 }}
                    classes={{ indicator: classes.bigIndicator }}
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="secondary"
                    centered>

                    <Tab label="DASHBOARD" component={Link} to="/ip" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />

                </Tabs>
            );
        }

        return (
            <Tabs
                style={{ backgroundColor: '#CCCCCC', marginBottom: 10, width: '100vw', minWidth: 1425 }}
                classes={{ indicator: classes.bigIndicator }}
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="secondary"
                centered>

                <Tab label="DASHBOARD" component={Link} to="/ip" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />

                <Tab label="LIDAR PARAMETERS" component={Link} to="/ip/params" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />

                {this.props.history.location.pathname.match(/\/ip\/params\/[a-f\d]{24}/g) &&
                    <Tab label="SELECTED PARAMETER" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />
                }

                {this.props.history.location.pathname.match(/\/ip\/params\/new/g) &&
                    <Tab label="NEW PARAMETER" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />
                }

                <Tab label="IMAGERY PARAMETERS" component={Link} to="/ip/imagery-params" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />

                {this.props.history.location.pathname.match(/\/ip\/imagery-params\/[a-f\d]{24}/g) &&
                    <Tab label="SELECTED PARAMETER" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />
                }

                {this.props.history.location.pathname.match(/\/ip\/imagery-params\/new/g) &&
                    <Tab label="NEW PARAMETER" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />
                }

                <Tab label="CUSTOM PROCESSING" component={Link} to="/ip/custom_processing/sensor" style={{ color: '#1a3a69', fontWeight: 'bolder' }} />

            </Tabs>
        );
    }
}

ProcessingTabs.propTypes = {
    classes: PropTypes.object.isRequired,
};

//TODO: reinstate below when this tetrasys nonsense is removed
// export default withStyles(styles)(withRouter(ProcessingTabs));

//TODO: below must be removed when a real permissions model is added
const mapStateToProps = (state, ownProps) => {
    let tokenPayload = state.token ? JSON.parse(atob(state.token.split(".")[1])) : ''
    let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''
    return {
        token: state.token,
        user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ProcessingTabs))