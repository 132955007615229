import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import Config from '../app/Config.json'
import { 
    Grid, Snackbar, Table, TableHead, TableRow, TableCell, TableBody, 
    CircularProgress, Dialog, SnackbarContent
 } from '@material-ui/core';
import Uploader from '../upload/Uploader';
import InfoIcon from '@material-ui/icons/Info'
import CancelIcon from '@material-ui/icons/Cancel'
import LocalAirport from '@material-ui/icons/LocalAirport'
import FlightDataDispatchTableIcon from './FlightDataDispatchTableIcon';
import FlightTypeTableIcon from './FlightTypeTableIcon';
import TableAircraftTypeIcon from '../common/TableAircraftTypeIcon';
import TableSensorTypeIcon from '../common/TableSensorTypeIcon';
import { flightsFetchList, flightsQueryParams } from './FlightActions'
import { handleValidateArchive } from '../explorer/objectExplorerActions'
import NicerTooltip from '../common/NicerTooltip';
class FlightUploadList extends Component {

    constructor(props) {
        super(props);
        this.getFlight();
        // console.log("...this.props.FlightID", this.props.match.params.FlightID)
    }

    state = {
        pollingTimeoutId: null,
        polling: false,
        isValidateArchiveDialogOpen: false,
        isValidateArchiveSnackBarOpen: false,
        archiveS3Path: '',
    }

    componentDidUpdate(prevProps) {
        if (!this.props.flightUploadListLoading && prevProps.flightUploadListLoading) {
            if (this.state.pollingTimeoutId) {
                clearTimeout(this.state.pollingTimeoutId);
                this.state.pollingTimeoutId = null
                this.state.polling = false;
            }

            this.state.pollingTimeoutId = setTimeout(() => {
                this.state.polling = true;
                this.getFlight();
            }, Config.FAST_POLLING);
        }
        if(prevProps.explorerStore.isValidatingArchive !== this.props.explorerStore.isValidatingArchive) {
            this.setState({ isValidateArchiveDialogOpen: this.props.explorerStore.isValidatingArchive })
            if(!this.props.explorerStore.isValidatingArchive) {
                this.setState({ isValidateArchiveSnackBarOpen: true })
                if(this.props.explorerStore.validateArchiveSucceed && this.props.explorerStore.isArchivedSucceed) {
                    const archiveS3PathSplited = this.state.archiveS3Path.split('/')
                    let archiveBucket = archiveS3PathSplited[0]
                    let archivePrefix = archiveS3PathSplited.slice(1).join('/') + (this.state.archiveS3Path.endsWith('/') ? '' : '/')
                    let uri = encodeURI(`/explorer?bucket=${archiveBucket}&prefix=${archivePrefix}`)
                    window.open(uri)
                }
            }
        }
    }

    componentDidMount() {
        document.title = "LiDARnetics: " + this.props.history.location.pathname.split("/").pop();
    }

    componentWillUnmount() {
        document.title = "LiDARnetics";
        if (this.state.pollingTimeoutId) {
            clearTimeout(this.state.pollingTimeoutId);
            this.state.pollingTimeoutId = null
            this.state.polling = false;
        }
        //to clear the params that were limiting the list to the current Flight
        this.props.fetchData(this.props.token, { params: {}, page: 1 });
    }

    getFlight = () => {
        // this.props.fetchData(this.props.token, this.props.match.params.FlightID);
        this.props.fetchData(this.props.token, { params: { "FlightID": this.props.match.params.FlightID }, page: 1 });
    }

    getSensorValues = (flight, key) => {
        let values = flight.Sensors.map(s => s[key])
            .filter(v => v != null && v != 'null')
        values = values.filter((item, pos) => values.indexOf(item) == pos) //remove duplicated
        return values;
    }

    getSensorProject = (flight, sensorId) => {
        let sensorMatch = flight.Sensors.find(sensor => sensor.SensorID == sensorId)
        // console.log(sensorMatch)
        if (sensorMatch) {//TODO remove once legacy schema migrated
            return sensorMatch.Project;
        }
        return "";
    }

    // getProjectValues = (flight, projectId, key) => {
    //     for (let i = 0; i < flight.Projects.length; i++) {
    //         if (flight.Projects[i]["ProjectID"] == projectId)
    //             return flight.Projects[i][key]
    //     }
    //     return '';
    // }

    getSensorAndUploadValues = (flight, key) => {
        let values = [];

        let sensorDict = {};
        flight.Sensors.forEach(sensor => {
            if (sensor.Sensor === undefined && sensor.SecondarySensor !== undefined) {
                sensor.Sensor = sensor.SecondarySensor;
                delete sensor.SecondarySensor;
            }

            if (sensorDict[sensor.Sensor] == null)
                sensorDict[sensor.Sensor] = sensor;
            else {
                if (sensor.DataDispatchMethodLiDAR !== "Not Applicable" || sensor.DataDispatchMethodImagery !== "Not Applicable")
                    sensorDict[sensor.Sensor] = sensor;
            }
        });
        let distinctSensors = [];
        for (var dictKey in sensorDict) {
            distinctSensors.push(sensorDict[dictKey]);
        }

        // console.log("...distinctSensors",distinctSensors)
        //filtering by sensor name, so not duplicated across sensor logs
        // let distinctSensors = flight.Sensors.filter((obj, pos, arr) => {
        //     return arr.map(mapObj => mapObj["Sensor"]).indexOf(obj["Sensor"]) === pos;
        // });

        distinctSensors.forEach(item => {
            let newObj = {};
            newObj["Dispatch"] = item[key];
            newObj["Sensor"] = item["Sensor"];
            newObj["FlightID"] = flight.FlightID;
            let terrainMapperUploads = [];
            if (flight.Uploads) {
                for (let i = 0; i < flight.Uploads.length; i++) {
                    if (flight.Uploads[i]["SensorName"] === item["Sensor"] && key === "DataDispatchMethodLiDAR" && flight.Uploads[i]["Type"] === "LiDAR") {
                        newObj["UploadStatus"] = flight.Uploads[i]["Status"];
                        newObj["UploadID"] = flight.Uploads[i]["_id"];
                        newObj["Type"] = 'LiDAR';
                        newObj["UploadUser"] = flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '';
                        break;
                    }
                    else if (item["Sensor"] && flight.Uploads[i]["SensorName"] === item["Sensor"] && key === "DataDispatchMethodImagery" && ['LargeFormatImagery', 'MediumFormatImagery'].includes(flight.Uploads[i]["Type"])) {
                        newObj["UploadStatus"] = flight.Uploads[i]["Status"];
                        newObj["UploadID"] = flight.Uploads[i]["_id"];
                        newObj["Type"] = 'Imagery';
                        newObj["UploadUser"] = flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '';
                        break;
                    }
                    else if (item["Sensor"] && flight.Uploads[i]["SensorName"] === item["Sensor"] && key === "DataDispatchMethodLiDAR" && ["TerrainMapperLAZ", "TerrainMapperGPS"].includes(flight.Uploads[i]["Type"])) {
                        // newObj["UploadStatus"] = flight.Uploads[i]["Status"];
                        // newObj["UploadID"] = flight.Uploads[i]["_id"];
                        // newObj["Type"] = flight.Uploads[i]["Type"];
                        // newObj["UploadUser"] = flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '';
                        // break;
                        terrainMapperUploads.push({
                            "Dispatch":item[key],
                            "Sensor":item["Sensor"],
                            "FlightID":flight.FlightID,
                            "UploadStatus":flight.Uploads[i]["Status"],
                            "UploadID":flight.Uploads[i]["_id"],
                            "Type":flight.Uploads[i]["Type"],
                            "UploadUser":flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '',
                        });
                    }
                    else if (flight.Uploads[i]["SensorName"] === item["SecondarySensor"] && key === "DataDispatchMethodImagery" && ['LargeFormatImagery', 'MediumFormatImagery'].includes(flight.Uploads[i]["Type"])) {
                        newObj["UploadStatus"] = flight.Uploads[i]["Status"];
                        newObj["UploadID"] = flight.Uploads[i]["_id"];
                        newObj["Type"] = 'Imagery';
                        newObj["UploadUser"] = flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '';
                        break;
                    }
                    // else
                    // {
                    //     newObj["Dispatch"] = "Not Applicable";
                    // }
                    // else{
                    //     newObj["UploadStatus"] = "READY_FOR_UPLOAD";
                    //     newObj["UploadID"] = flight.Uploads[i]["_id"];
                    // }
                }
            }
            if (terrainMapperUploads.length == 2){
                values.push(terrainMapperUploads[0]);
                values.push(terrainMapperUploads[1]);
            }
            else if (terrainMapperUploads.length == 1)
                values.push(terrainMapperUploads[0]);
            else
                values.push(newObj);
        });
        // console.log('...values',values)
        return values;
    }

    getArchiveS3Path = (flight, uploadType = 'LiDAR') => {
        if (uploadType === 'LiDAR' && flight['archiveS3PathLiDAR']) {
            return flight['archiveS3PathLiDAR']
        } else if (uploadType === 'Imagery' && flight['archiveS3PathImagery']) {
            return flight['archiveS3PathImagery']
        } else {
            return null
        }
    }

    handleExplorerOnClick = ({type, uploadID, archiveS3Path}) => {
        let sourceBucket = (type === "LiDAR") ? Config.UPLOAD_LIDAR_BUCKET : Config.UPLOAD_IMAGERY_BUCKET
        let sourcePrefix = `${uploadID}/`
        this.setState({ archiveS3Path })
        this.props.handleValidateArchive(sourceBucket, sourcePrefix)
    }

    render() {
        let { flights, classes, queryParams } = this.props;
        flights = flights || [];
        const flightIdColumnStyle = { textAlign: "center", width: 75 };
        const uploadStatusColumnStyle = { textAlign: "center", width: 50 };
        const sensorIdColumnStyle = { textAlign: "center", width: 75 };
        const dateColumnStyle = { textAlign: "center", width: 80 };
        const sensorNameColumnStyle = { textAlign: "center", width: 160 };
        const aircraftColumnStyle = { textAlign: "center", width: 70 };
        const dispatchColumnStyle = { textAlign: "center", width: 50 };

        const headerDivTextStyle = { fontWeight: "bolder", color: "#000000" };
        if (this.props.flightUploadListLoading && this.state.polling === false)
            return (
                <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                    <CircularProgress />
                    <div style={{ paddingTop: 5 }}>Loading Flight <span style={{ fontWeight: 'bold' }}>{this.props.history.location.pathname.split("/").pop()}</span> Details...</div>
                </Grid>
            )
        else
            return (
                <>
                    <div style={{ width: 1000, display: this.props.flightUploadListLoading && this.state.polling === false ? 'none' : 'inline' }}>
                        <Grid container direction="column" alignItems="center" >
                            <Grid container direction="row" alignItems="center" justify="center" style={{ paddingTop: 10, paddingBottom: 10, background: "#FFFFFF", marginBottom: 10 }}>
                                <div style={{ fontSize: 28, fontWeight: "bolder", color: "#1a3a69", marginRight: 8 }}>
                                    <LocalAirport style={{ marginBottom: -6, fontSize: 32, paddingRight: 5 }} />
                                    Flight:</div>
                                <div style={{ fontSize: 28, fontWeight: "bold", color: "#000000" }}>{this.props.match.params.FlightID}</div>
                            </Grid>
                        </Grid>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Flight ID</div></TableCell> */}
                                    <TableCell padding="none" style={uploadStatusColumnStyle}><div style={headerDivTextStyle}>Flight Type</div></TableCell>
                                    <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Sensor ID(s)</div></TableCell>
                                    <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Project ID(s)</div></TableCell>
                                    <TableCell padding="none" style={dateColumnStyle}><div style={headerDivTextStyle}>Survey Date<br />(UTC)</div></TableCell>
                                    <TableCell padding="none" style={dateColumnStyle}><div style={headerDivTextStyle}>Survey Start<br />Survey End<br />(UTC)</div></TableCell>
                                    <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>Project Manager</div></TableCell>
                                    <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>Sensor(s)</div></TableCell>
                                    <TableCell padding="none" style={uploadStatusColumnStyle}><div style={headerDivTextStyle}>Sensor Type(s)</div></TableCell>
                                    <TableCell padding="none" style={aircraftColumnStyle}><div style={headerDivTextStyle}>Aircraft</div></TableCell>
                                    <TableCell padding="none" style={uploadStatusColumnStyle}><div style={headerDivTextStyle}>Aircraft<br />Type</div></TableCell>
                                    <TableCell padding="none" style={dispatchColumnStyle}><div style={headerDivTextStyle}>LiDAR Dispatch<br />&amp; Status</div></TableCell>
                                    <TableCell padding="none" style={dispatchColumnStyle}><div style={headerDivTextStyle}>Imagery Dispatch<br />&amp; Status</div></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.uploads.map(flight => {

                                    const projectId = this.getSensorValues(flight, "ProjectID")[0];

                                    return (
                                        <TableRow>
                                            <TableCell padding="none" style={uploadStatusColumnStyle}>
                                                <FlightTypeTableIcon type={this.getSensorValues(flight, "Status")} />
                                            </TableCell>

                                            <TableCell padding="none" style={sensorIdColumnStyle}>
                                                {this.getSensorValues(flight, "SensorID").map(value => { return (<span>{value}<br /></span>); })}
                                            </TableCell>

                                            <TableCell padding="none" style={sensorIdColumnStyle}>
                                                {
                                                    this.getSensorValues(flight, "SensorID").map(sensorId => {
                                                        let project = this.getSensorProject(flight, sensorId);
                                                        return (
                                                            <NicerTooltip placement="right"
                                                                content={
                                                                    <span><strong>Project Name:</strong>&nbsp;{project["ProjectName"]}<br />
                                                                        <strong>Project Status:</strong>&nbsp;{project["ProjectStatus"]}</span>
                                                                }>
                                                                {project["ProjectID"]}
                                                            </NicerTooltip>);
                                                    })
                                                }
                                            </TableCell>

                                            <TableCell padding="none" style={dateColumnStyle}>
                                                {this.getSensorValues(flight, "SurveyStart").map(value => { return (<span>{value.split("T")[0]}<br /></span>); })}
                                            </TableCell>

                                            <TableCell padding="none" style={dateColumnStyle}>
                                                <tr>
                                                    <td>
                                                        {this.getSensorValues(flight, "SurveyStart").map(value => { return (<span>{value.split("T")[1]}&nbsp;-<br /></span>); })}
                                                    </td>
                                                    <td>
                                                        {this.getSensorValues(flight, "SurveyEnd").map(value => { return (<span>{value.split("T")[1]}<br /></span>); })}
                                                    </td>
                                                </tr>
                                            </TableCell>

                                            <TableCell padding="none" style={sensorNameColumnStyle}>
                                            {
                                                this.getSensorValues(flight, "SensorID").map(sensorId => {
                                                    return (<span>{this.getSensorProject(flight, sensorId)["ProjectManager"]}<br/></span>)
                                                })
                                            }
                                            </TableCell>
                                            {/* <TableCell padding="none" style={sensorNameColumnStyle}>
                                                {this.getSensorValues(flight, "Sensor").map(value => {
                                                    return (<span>{value}
                                                        {
                                                            this.getSensorValues(flight, "SecondarySensor") !== "" &&
                                                            this.getSensorValues(flight, "SecondarySensor")[0] !== "NA" &&
                                                            this.getSensorValues(flight, "SecondarySensor") !== [] &&
                                                            <span style={{ fontSize: 9, color: "#757575" }}>
                                                                <br />
                                                                {this.getSensorValues(flight, "SecondarySensor")}
                                                            </span>
                                                        }
                                                    </span>);
                                                })}
                                            </TableCell> */}
                                            <TableCell padding="none" style={sensorNameColumnStyle}>
                                            {
                                                
                                                flight.Sensors.map(sensor => {
                                                    return (
                                                        <span>
                                                            {sensor.Sensor}<br/>
                                                            {
                                                                sensor.SecondarySensor &&
                                                                sensor.SecondarySensor != "NA" &&
                                                                <span style={{ fontSize: 9, color: "#757575" }}>{sensor.SecondarySensor}</span>
                                                            }
                                                        </span>
                                                    );
                                                })
                                            }
                                        </TableCell>
                                            <TableCell padding="none" style={uploadStatusColumnStyle}>
                                                <TableSensorTypeIcon sensorType={this.getSensorValues(flight, "Type")} />
                                            </TableCell>
                                            <TableCell padding="none" style={aircraftColumnStyle}>{flight.Aircraft}</TableCell>
                                            <TableCell padding="none" style={uploadStatusColumnStyle}>
                                                <TableAircraftTypeIcon type={flight.AircraftType} />
                                            </TableCell>
                                            <TableCell padding="none" style={dispatchColumnStyle}>
                                                <FlightDataDispatchTableIcon
                                                    type="LiDAR"
                                                    status={this.getSensorAndUploadValues(flight, "DataDispatchMethodLiDAR")} 
                                                    archiveS3Path={this.getArchiveS3Path(flight, 'LiDAR')}
                                                    documentMajorVersion={flight['DocumentMajorVersion']}
                                                    explorerOnClick={this.handleExplorerOnClick}
                                                />
                                            </TableCell>
                                            <TableCell padding="none" style={dispatchColumnStyle}>
                                                <FlightDataDispatchTableIcon
                                                    type="Imagery"
                                                    status={this.getSensorAndUploadValues(flight, "DataDispatchMethodImagery")} 
                                                    archiveS3Path={this.getArchiveS3Path(flight, 'Imagery')}
                                                    documentMajorVersion={flight['DocumentMajorVersion']}
                                                    explorerOnClick={this.handleExplorerOnClick}
                                                />

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            {/* <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    // colSpan={7}
                                    count={queryParams.total}
                                    rowsPerPage={queryParams.pageSize}
                                    page={queryParams.page - 1}
                                    onChangePage={this.changePage}
                                // onChangeRowsPerPage={this.changePageSize}
                                />
                            </TableRow>
                        </TableFooter> */}
                        </Table>

                        <Grid container direction="row" alignItems="center" justify="center" style={{ paddingTop: 20, fontSize: 14 }}>
                            <strong>NOTE:</strong>&nbsp;When the same sensor has multiple sensor logs, the data can only be uploaded once for that sensor.
                        </Grid>
                    </div>
                    <Dialog
                        open={this.state.isValidateArchiveDialogOpen}
                        PaperProps={{
                            style: {
                            backgroundColor: '#808080e6',
                            boxShadow: 'none',
                            minHeight: '150px',
                            minWidth: '300px',
                            borderRadius: 10,
                            },
                        }}
                    >
                        <Grid container direction="column" alignItems="center" justify="center" className='pt-3'>
                            <CircularProgress />
                            <div className='pt-5' >Validating archived files...</div>
                        </Grid>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        open={this.state.isValidateArchiveSnackBarOpen}
                        autoHideDuration={4000}
                        onClose={this.handleValidateArchiveSnackBarOnClose}
                    >
                        <SnackbarContent 
                            style={{ backgroundColor: "#1a3a69E5" }}      // have to use inline style or use withStyle()
                            message={
                                <span style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                                    {this.props.explorerStore.validateArchiveSucceed
                                        ? this.props.explorerStore.isArchivedSucceed
                                            ? <>
                                                <InfoIcon className='pr-1' style={{ opacity: 0.7 }} /> 
                                                Archive validation succeed
                                            </>
                                            : <>
                                                <CancelIcon className='pr-1' style={{ opacity: 0.7 }} /> 
                                                Archive validation failed
                                            </>
                                        : <>
                                            <CancelIcon className='pr-1' style={{ opacity: 0.7 }} /> 
                                            Failed to validate archived files
                                        </>
                                    }
                                </span>
                            }
                        />
                    </Snackbar>
                </>
                
            );
    }
}

// export default FlightUploadList;

const mapStateToProps = (state) => {
    let uploads = [];
    if (state.flights) {
        let flight = state.flights[0];

        let sensorDict = {};
        flight.Sensors.forEach(item => {
            if (item.Sensor === undefined && item.SecondarySensor !== undefined) {
                item.Sensor = item.SecondarySensor;
                delete item.SecondarySensor;
            }
            sensorDict[item.Sensor] = sensorDict[item.Sensor] ? sensorDict[item.Sensor].concat(item) : [item];
        });
        // flight.Sensors.forEach(item => {
        //     let name = item.Sensor !== undefined ? item.Sensor : item.SecondarySensor;
        //     sensorDict[name] = sensorDict[name] ? sensorDict[name].concat(item) : [item];
        // });
        for (let item in sensorDict) {
            // console.log('...',item,sensorDict[item])
            let newObj = JSON.parse(JSON.stringify(flight));
            newObj["Sensors"] = sensorDict[item];
            uploads.push(newObj);
        }

        //Sorting the sensors (per flight) by SurveyStart
        for (let i = 0; i < uploads.length; i++) {
            uploads[i].Sensors.sort(function (b, a) {
                a = Date.parse(a.SurveyStart);
                b = Date.parse(b.SurveyStart);
                return a > b ? -1 : a < b ? 1 : 0;
            });
        }

        // use a dictionary to collate the sensor entries then built the uploads array
        //     //filtering by sensor name, so not duplicated across sensor logs
        //     let distinctSensors = flight.Sensors.filter((obj, pos, arr) => {
        //         return arr.map(mapObj => mapObj["Sensor"]).indexOf(obj["Sensor"]) === pos;
        //     });

        //     distinctSensors.forEach(item =>{
        //         let newObj = JSON.parse(JSON.stringify(flight));
        //         newObj["Sensors"]=[item];
        //         uploads.push(newObj);
        //     });
    }
    return {
        token: state.token,
        flightUploadListLoading: state.flightsListLoading,
        uploads: uploads,
        // queryParams: state.processingQueryParams,
        // processing: state.processing,
        // // processingSubmitLoading: state.processingResubmitLoading,
        // processingSubmitSuccessful: state.processingResubmitSuccess && !state.processingResubmitLoading,
        // currentLMSVersion: state.appSettings ? state.appSettings.LMSCurrentVersion : null,
        explorerStore: state.objectExplorer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // fetchData: (token) => { dispatch(flightsFetchList(token)) },
        fetchData: (token, payload) => {
            dispatch(flightsQueryParams(payload))
            dispatch(flightsFetchList(token))
        },
        handleValidateArchive: (bucket, prefix) => { dispatch(handleValidateArchive(bucket, prefix)) },
    };
};

// //this is needed to match thre returned result to properties
// const mapDispatchToProps = (dispatch) => {
//     return {
//         // fetchData: (token) => { dispatch(flightUploadsFetchList(token)) },
//         // setQueryParams: payload => {
//         //     dispatch(processingQueryParams(payload))
//         //     dispatch(processingFetchList())
//         // },
//         // resubmitGPSProcessing: (token, jsonData, id) => { dispatch(resubmitProcessing(token, jsonData, id)) },
//         // resubmitLMSProcessing: (token, id) => { dispatch(resubmitLMSProcessing(token, id)) },
//         // resubmitClusterProcessing: (token, id, clusterName) => { dispatch(resubmitClusterProcessing(token, id, clusterName)) },
//         // updateProcessing: (token, id, jsonData) => { dispatch(processingUpdate(token, id, jsonData)) },
//     };
// };

export default connect(mapStateToProps, mapDispatchToProps)(FlightUploadList);