import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import MapOutlined from "@material-ui/icons/MapOutlined";
// import LocalGroceryStore from "@material-ui/icons/LocalGroceryStore";
import Ballot from "@material-ui/icons/Ballot";
// import AssignmentIcon from "@material-ui/icons/Assignment";
import { BrainIcon, GearsIcon } from "../icons/svgIcons";
import { Link, withRouter } from "react-router-dom";
// import Badge from "@material-ui/core/Badge";
// import NotificationBadge from "../common/NotificationBadge";
import { connect } from 'react-redux';
import compose from 'recompose/compose'
import { isMemberOfGuestGroups } from "../common/PrivateRoute";

const styles = {
  bigIndicator: {
    height: 5,
  },
};

// const NotificationBadge = withStyles({
//   badge: {
//     transform: "scale(1) translate(-30%, -200%)",
//   },
// })(Badge);

class MainTabBar extends Component {
  constructor(props) {
    super(props);
    this.handleRoute(true);
  }

  state = {
    value: 0,
  };

  componentWillUpdate = () => {
    this.handleRoute(false);
  };

  handleRoute = (inConstructor) => {

    //TODO remove this tetrarays nonsense and replace with a REAL permissions model
    if (isMemberOfGuestGroups(this.props.token)) {
      switch (this.props.history.location.pathname) {
        case "/ip":
          if (this.state.value !== 0)
            !inConstructor ? this.setState({ value: 0 }) : (this.state.value = 0);
          break;
        // case "/ai":e
        //   if (this.state.value !== 1)
        //     !inConstructor ? this.setState({ value: 1 }) : (this.state.value = 1);
        //   break;
        default:
          if (this.state.value !== 0)
            !inConstructor ? this.setState({ value: 0 }) : (this.state.value = 0);
      }
      return
    }

    switch (this.props.history.location.pathname) {
      // case "/planning":
      // case String(this.props.history.location.pathname.match(/\/planning(\/.*)?/g)):
      //   if (this.state.value !== 0)
      //     !inConstructor ? this.setState({ value: 0 }) : (this.state.value = 0);
      //   break;
      case "/post":
      case String(this.props.history.location.pathname.match(/\/post(\/.*)?/g)):
        if (this.state.value !== 0)
          !inConstructor ? this.setState({ value: 0 }) : (this.state.value = 0);
        break;
      case "/ip":
      case String(
        this.props.history.location.pathname.match(
          /\/ip(\/custom_processing\/.*)?/g
        )
      ):
      case "/ip/params":
      case String(
        this.props.history.location.pathname.match(/\/ip\/params(\/.*)?/g)
      ):
        if (this.state.value !== 1)
          !inConstructor ? this.setState({ value: 1 }) : (this.state.value = 1);
        break;
        case "/ip/imagery_params":
          case String(
            this.props.history.location.pathname.match(/\/ip\/imagery-params(\/.*)?/g)
          ):
            if (this.state.value !== 1)
              !inConstructor ? this.setState({ value: 1 }) : (this.state.value = 1);
            break;
      // case "/ai":
      // case "/ai/models":
      // case String(
      //   this.props.history.location.pathname.match(/\/ai\/models(\/.*)?/g)
      // ):
      //   if (this.state.value !== 2)
      //     !inConstructor ? this.setState({ value: 2 }) : (this.state.value = 2);
      //   break;

      // case "/products":
      //   case String(
      //     this.props.history.location.pathname.match(
      //       /\/products(\/instructions)?/g
      //     )
      //   ):
      // case String(
      //   this.props.history.location.pathname.match(
      //     /\/products(\/transformation)?/g
      //   )
      // ):
      // case String(
      //   this.props.history.location.pathname.match(/\/products(\/.*)?/g)
      // ):
      //   if (this.state.value !== 3)
      //     !inConstructor ? this.setState({ value: 3 }) : (this.state.value = 3);
      //   break;

      // case "/manage":
      // case String(
      //   this.props.history.location.pathname.match(/\/manage(\/.*)?/g)
      // ):
      //   if (this.state.value !== 3)
      //     !inConstructor ? this.setState({ value:3 }) : (this.state.value = 3);
      //   break;
      case "/iptm":
      case String(
        this.props.history.location.pathname.match(/\/iptm(\/.*)?/g)
      ):
        if (this.state.value !== 2)
          !inConstructor ? this.setState({ value:2 }) : (this.state.value = 2);
        break;
      case "/explorer":
      case String(
        this.props.history.location.pathname.match(/\/explorer(\/.*)?/g)
      ):
        if (this.state.value !== 3)
          !inConstructor ? this.setState({ value:3 }) : (this.state.value = 3);
        break;
      default:
        if (this.state.value !== 0)
          !inConstructor ? this.setState({ value: 0 }) : (this.state.value = 0);
    }
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;

    //TODO remove this tetrarays nonsense and replace with a REAL permissions model
    if (isMemberOfGuestGroups(this.props.token)) {
      return (
        <Tabs
          style={{ backgroundColor: "#1a3a69", width: '100%', minWidth: 1425 }}
          classes={{ indicator: classes.bigIndicator }}
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="secondary"
          centered
        >

          <Tab
            // label="IP"
            label="PROCESSING"
            icon={<GearsIcon />}
            component={Link}
            to="/ip"
            style={{ color: "#FFFFFF" }}
          />

          {/* <Tab
            label="AI"
            icon={<BrainIcon />}
            component={Link}
            to="/ai"
            style={{ color: "#FFFFFF" }}
          /> */}

        </Tabs>
      );

    }

    return (
      <Tabs
        style={{ backgroundColor: "#1a3a69", width: '100vw', minWidth: 1425 }}
        classes={{ indicator: classes.bigIndicator }}
        value={this.state.value}
        onChange={this.handleChange}
        indicatorColor="secondary"
        centered
      >
        {/* <Tab
          // label="POST"
          label="PLANNING"
          icon={<AssignmentIcon />}
          component={Link}
          to="/planning"
          style={{ color: "#FFFFFF" }}
        /> */}

        <Tab
          // label="POST"
          label="UPLOAD"
          icon={<CloudUpload />}
          component={Link}
          to="/post"
          style={{ color: "#FFFFFF" }}
        />

        <Tab
          // label="IP"
          label="Optech PROCESSING"
          icon={<GearsIcon />}
          component={Link}
          to="/ip"
          style={{ color: "#FFFFFF" }}
        />

        {/* <Tab
          label="AI CLASSIFICATION"
          icon={<BrainIcon />}
          component={Link}
          to="/ai"
          style={{ color: "#FFFFFF" }}
        /> */}

        {/* <div> */}
        {/* <Tab
          label="PRODUCT GENERATION"
          icon={<LocalGroceryStore />}
          component={Link}
          to="/products"
          style={{ color: "#FFFFFF" }}
        /> */}
        {/* <img
            src="/coming_soon.png"
            width="160px"
            height="71px"
            alt="coming soon"
            style={{ position: "absolute", marginLeft: -160 }}
          /> */}
        {/* </div> */}
        {/* <Tab
          // label={"MANAGE"}
          label={"DATA MANAGEMENT"}
          icon={<Ballot />}
          component={Link}
          to="/manage"
          style={{ color: "#FFFFFF" }}
        /> */}

        <Tab
            label="Terrain Mapper Processing"
            icon={<MapOutlined />}
            component={Link}
            to="/iptm"
            style={{ color: "#FFFFFF" }}
        />

        {(this.state.value === 3)
          && <Tab
            // label={"MANAGE"}
            label={"DATA MANAGEMENT"}
            icon={<Ballot />}
            // component={Link}
            // to="/manage"
            style={{ color: "#FFFFFF" }}
          />
        }
      </Tabs>
    );
  }
}

MainTabBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

//TODO: reinstate below when this tetrasys nonsense is removed
// export default withStyles(styles)(withRouter(MainTabBar));

//TODO: below must be removed when a real permissions model is added
const mapStateToProps = (state, ownProps) => {
  let tokenPayload = state.token ? JSON.parse(atob(state.token.split(".")[1])) : ''
  let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''
  return {
    token: state.token,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(MainTabBar))
