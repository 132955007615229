import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Grid, List, ListItem, ListItemIcon, ListItemText, TextField, Button, InputAdornment, CircularProgress } from '@material-ui/core';
import { HeloIcon } from '../icons/svgIcons'
import LocalAirport from '@material-ui/icons/LocalAirport'
import Tune from '@material-ui/icons/Tune'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Wifi from '@material-ui/icons/Wifi'
import { paramsCreate, paramsFetchItem, paramsUpdate, resetState, downloadInstrumentFiles } from './ProcessingActions'
import { aircraftFetchList } from './AircraftActions'
import { sensorEquipmentFetchList, sensorEquipmentQueryParams } from './SensorEquipmentActions'
import TableAircraftTypeIcon from '../common/TableAircraftTypeIcon';
import Ajv from 'ajv';
import LMSValidationDialog from './LMSValidationDialog';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import HelpOutline from '@material-ui/icons/HelpOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import pako from "pako"

class ProcessingParams extends Component {

    constructor(props) {
        super(props);

        this.isEditable = true;
        this.updateLoading = false;
    }

    componentDidMount() {

        if (this.props.match.params.CompositeID !== "new") {
            this.getParams();
            this.isEditable = false;
        }

        if (this.props.history.location.pathname.split('/').pop() === "edit" || this.props.match.params.CompositeID === "new") {
            this.isEditable = true;
        }

        if (this.isEditable) {
            // this.props.clearState();
            // this.resetState();
            this.getAircraftAndSensors();
            // console.log('...getting params')
        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.params && nextProps.match.params.CompositeID !== "new") {
            return {
                ...nextProps.params,
                //TODO: should do the below programmatically
                PriGNSSLeverX: prevState.PriGNSSLeverX !== '' ? prevState.PriGNSSLeverX : nextProps.params.PriGNSSLeverX,
                PriGNSSLeverY: prevState.PriGNSSLeverY !== '' ? prevState.PriGNSSLeverY : nextProps.params.PriGNSSLeverY,
                PriGNSSLeverZ: prevState.PriGNSSLeverZ !== '' ? prevState.PriGNSSLeverZ : nextProps.params.PriGNSSLeverZ,
                RefToIMULeverX: prevState.RefToIMULeverX !== '' ? prevState.RefToIMULeverX : nextProps.params.RefToIMULeverX,
                RefToIMULeverY: prevState.RefToIMULeverY !== '' ? prevState.RefToIMULeverY : nextProps.params.RefToIMULeverY,
                RefToIMULeverZ: prevState.RefToIMULeverZ !== '' ? prevState.RefToIMULeverZ : nextProps.params.RefToIMULeverZ,
                RefToIMUMountingAnglX: prevState.RefToIMUMountingAnglX !== '' ? prevState.RefToIMUMountingAnglX : nextProps.params.RefToIMUMountingAnglX,
                RefToIMUMountingAnglY: prevState.RefToIMUMountingAnglY !== '' ? prevState.RefToIMUMountingAnglY : nextProps.params.RefToIMUMountingAnglY,
                RefToIMUMountingAnglZ: prevState.RefToIMUMountingAnglZ !== '' ? prevState.RefToIMUMountingAnglZ : nextProps.params.RefToIMUMountingAnglZ,
                SurveyDate: prevState.SurveyDate !== '' ? prevState.SurveyDate : nextProps.params.SurveyDate,
                ScheduledExpiryDate: prevState.ScheduledExpiryDate !== '' ? prevState.ScheduledExpiryDate : nextProps.params.ScheduledExpiryDate,
                AircraftType: prevState.AircraftType !== '' ? prevState.AircraftType : nextProps.params.AircraftType,
                LMSInstrumentRES: prevState.LMSInstrumentRES !== '' ? prevState.LMSInstrumentRES : nextProps.params.LMSInstrumentRES,
                LMSInstrumentTBL: prevState.LMSInstrumentTBL !== '' ? prevState.LMSInstrumentTBL : nextProps.params.LMSInstrumentTBL,
                LMSInstrumentLCP: prevState.LMSInstrumentLCP !== '' ? prevState.LMSInstrumentLCP : nextProps.params.LMSInstrumentLCP,
                PriGNSSLeverSD: "SD10cm",
                IsActive: true
            }

            // return nextProps.params
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.paramsUpdateLoading && this.props.paramsUpdateLoading === false && this.updateLoading) {
            this.updateLoading = false;
            this.props.history.push('/ip/params');
        }

    }

    componentWillUnmount() {

        this.props.clearState();

    }

    readFileAsJSON = (inputFile, fieldName, compress = false) => {

        let r = new FileReader();
        r.onload =  (e) => {
            let contents = e.target.result;

            let compressed = null;
            if(compress){
                compressed = pako.deflate(JSON.stringify(contents), { to: 'string' });
            // var result = JSON.parse(pako.inflate(test, { to: 'string' }));//just for testing
            }

            this.setState(state => {
                    state['FileName' + fieldName] = inputFile.name;
                    state[fieldName] =  compress ? btoa(compressed) : contents;
                }, () => {
                    this.forceUpdate();
                })

        }
        r.readAsText(inputFile);
    }

    //a hack, because inputs dont work when reselecting the same folder
    inputReset = () => {
        ReactDOM.findDOMNode(this.refs.selectButton).value = '';
    }
    onUpload = (event) => {
        event.persist();

        let sensorNumber = this.state.SensorName.slice(-3);
        let date = this.state.SurveyDate.split('T')[0];//in case in edit mode... db has time in there too
        date = date.split('-');
        date = date[2] + date[1] + date[0];
        // let base_pattern = '^\\d{2}SEN\\d{3}_[A-Z]{2}-[A-Z]{3}_LMS\\d{2}_\\d{8}';
        // let base_pattern = '^(\d{7}|\d{2}SEN\d{3})_[A-Z]{2}-[A-Z]{3}_LMS\d{2}_\d{8}';
        let base_pattern = '^(\\d{4}' + sensorNumber + '|\\d{2}SEN' + sensorNumber + ')_' + this.state.Aircraft + '_LMS\\d{2}_' + date;
        let res_pattern = base_pattern + '.(RES|res)$';
        let tbl_pattern = base_pattern + '.(TBL|tbl)$';
        let lcp_pattern = base_pattern + '.(LCP|lcp)$';

        let schema = {

            "type": "object",
            "properties": {
                "RES": {
                    "type": "array",
                    "minItems": 1,
                    "maxItems": 1,
                    "errorMessage": "Exactly 1 RES file is required.",
                    "items": {
                        "type": "string",
                        "pattern": res_pattern,
                        "errorMessage": "RES file doesn't match naming standard. Please check the sensor number is: " + sensorNumber + ", aircraft serial is: " + this.state.Aircraft + " and date is: " + date + '. Current file name:',
                    },
                },
                "TBL": {
                    "type": "array",
                    "minItems": 1,
                    "maxItems": 1,
                    "errorMessage": "Exactly 1 TBL file is required.",
                    "items": {
                        "type": "string",
                        "pattern": tbl_pattern,
                        "errorMessage": "TBL file doesn't match naming standard. Please check the sensor number is: " + sensorNumber + ", aircraft serial is: " + this.state.Aircraft + " and date is: " + date + '. Current file name:',
                    },
                },
                "LCP": {
                    "type": "array",
                    "minItems": 1,
                    "maxItems": 1,
                    "errorMessage": "Exactly 1 LCP file is required.",
                    "items": {
                        "type": "string",
                        "pattern": lcp_pattern,
                        "errorMessage": "LCP file doesn't match naming standard. Please check the sensor number is: " + sensorNumber + ", aircraft serial is: " + this.state.Aircraft + " and date is: " + date + '. Current file name:',
                    },
                },
            },
            "required": ["RES", "TBL", "LCP"]
        }

        let summary = {
            "RES": [],
            "TBL": [],
            "LCP": []
        }

        let resFile = null;
        let tblFile = null;
        let lcpFile = null;

        for (let i = 0; i < event.target.files.length; i++) {
            let extn = event.target.files[i].name.slice(-3).toLowerCase();
            switch (extn) {
                case 'res':
                    summary['RES'].push(event.target.files[i].name);
                    resFile = event.target.files[i];
                    break;
                case 'tbl':
                    summary['TBL'].push(event.target.files[i].name);
                    tblFile = event.target.files[i];
                    break;
                case 'lcp':
                    summary['LCP'].push(event.target.files[i].name);
                    lcpFile = event.target.files[i];
                    break;
                default:
            }
        }

        let errors = [];
        let ajv = new Ajv({ allErrors: true, verbose: true });
        let valid = ajv.validate(schema, summary);

        if (!valid) {/// previous uploads aren;t evaluated as a whole
            console.log('Calibration file validation failed');
            ajv.errors.forEach(function (error) {
                let errorMessage = "";
                if (error.keyword == "required") {
                    errorMessage = error.schema[error.params.missingProperty].errorMessage;
                }
                else if (error.data instanceof Array) {
                    error.data = error.data.join(', ');//convert to a string that can be wrapped
                    errorMessage = error.parentSchema.errorMessage + ' ' + error.data;
                }
                else {
                    errorMessage = error.parentSchema.errorMessage + ' ' + error.data;
                }
                errors.push(errorMessage);
            });

            this.setState({
                LMSInstrumentRES: '',
                LMSInstrumentTBL: '',
                LMSInstrumentLCP: ''
            }, () => { this.forceUpdate() });
        }
        else {
            console.log('Calibration files valid', valid)

            this.readFileAsJSON(resFile, 'LMSInstrumentRES');
            this.readFileAsJSON(tblFile, 'LMSInstrumentTBL', true);
            this.readFileAsJSON(lcpFile, 'LMSInstrumentLCP');

            this.state.LMSVersion = this.props.currentLMSVersion;
        }

        this.setState({ validationDialogVisible: !valid, errors: errors });

    }

    state = {
        SensorName: '',
        Aircraft: '',
        PriGNSSLeverX: '',
        PriGNSSLeverY: '',
        PriGNSSLeverZ: '',
        RefToIMULeverX: '',
        RefToIMULeverY: '',
        RefToIMULeverZ: '',
        RefToIMUMountingAnglX: '',
        RefToIMUMountingAnglY: '',
        RefToIMUMountingAnglZ: '',
        LMSInstrumentRES: '',
        LMSInstrumentTBL: '',
        LMSInstrumentLCP: '',
        SurveyDate: '',
        ScheduledExpiryDate: '',
        AircraftType: '',
        PriGNSSLeverSD: "SD10cm",
        IsActive: true,
        validationDialogVisible: false,
        LMSVersion: '',
        errors: []
    };

    // resetState = () => {
    //     this.setState({
    //         SensorName: '',
    //         Aircraft: '',
    //         PriGNSSLeverX: '',
    //         PriGNSSLeverY: '',
    //         PriGNSSLeverZ: '',
    //         RefToIMULeverX: '',
    //         RefToIMULeverY: '',
    //         RefToIMULeverZ: '',
    //         RefToIMUMountingAnglX: '',
    //         RefToIMUMountingAnglY: '',
    //         RefToIMUMountingAnglZ: '',
    //         LMSInstrumentRES: '',
    //         LMSInstrumentTBL: '',
    //         LMSInstrumentLCP: '',
    //         SurveyDate: '',
    //         AircraftType: '',
    //         PriGNSSLeverSD: "SD10cm",
    //         IsActive: true,
    //         validationDialogVisible: false,
    //         errors: []
    //     });
    // }

    createNewParams = () => {
        this.updateLoading = true;
        delete this.state.validationDialogVisible;
        delete this.state.errors;
        this.props.paramsCreate(this.props.token, this.state)
    }

    updateParams = () => {
        this.updateLoading = true;
        delete this.state.validationDialogVisible;
        delete this.state.errors;
        this.props.paramsUpdate(this.props.token, this.state._id, this.state)
    }

    getParams = () => {
        // this.updateLoading = true;
        this.props.fetchData(this.props.token, this.props.match.params.CompositeID);
    }

    getAircraftAndSensors = () => {
        this.props.fetchSensorsAndAircraft(this.props.token);
    }

    isExpired = (scheduledExpiryDateString) => {
        if (scheduledExpiryDateString === undefined)
            return false;
        let today = new Date();
        let offsetInHours = today.getTimezoneOffset() / 60;
        let todayUTC = new Date();
        todayUTC.setHours(todayUTC.getHours() + offsetInHours);
        let scheduledExpiryDate = new Date(scheduledExpiryDateString);
        return todayUTC >= scheduledExpiryDate;
    }

    render() {

        let { sensorEquipment, aircraft } = this.props;
        sensorEquipment = sensorEquipment || [];
        aircraft = aircraft || [];

        if (this.props.paramsUpdateLoading)
            return (
                <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                    <CircularProgress />
                    <div style={{ paddingTop: 5 }}>Loading Parameters...</div>
                </Grid>
            )

        return (

            <Grid container direction="column" alignItems="center" justify="center">

                {this.props.match.params.CompositeID !== "new" &&
                    <Grid container direction="row" alignItems="center" justify="center" style={{ paddingTop: 10, paddingBottom: 10, background: "#FFFFFF", marginBottom: 10 }}>
                        <div style={{ fontSize: 28, fontWeight: "bolder", color: "#1a3a69", marginRight: 8 }}>
                            <Tune style={{ marginBottom: -6, fontSize: 32, paddingRight: 5 }} />
                            Parameters for:</div>
                        <div style={{ fontSize: 28, fontWeight: "bold", color: "#000000" }}>{this.state.Aircraft + ", " + this.state.SensorName + ", " + this.state.SurveyDate.split("T")[0]}</div>
                    </Grid>
                }

                {this.props.match.params.CompositeID === "new" &&
                    <div>
                        <span style={{ fontWeight: "bold" }}>Select a Sensor and Aircraft combination to create new Calibration Parameters</span>
                        <Grid container direction="row" alignItems="center" justify="space-between" style={{ paddingTop: 10, paddingBottom: 15 }}>

                            <Grid container direction="column" alignItems="center" style={{ width: 295 }}>
                                <span style={{ fontWeight: "bold" }}>SENSOR</span>
                                <Grid container direction="column" alignItems="center" justify="center" style={{ width: '100%', height: 225, borderStyle: "solid", borderWidth: 1, borderColor: "#CCCCCC" }}>
                                    {this.props.sensorEquipmentListLoading &&
                                        <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                                            <CircularProgress />
                                            <div style={{ paddingTop: 5 }}>Loading Sensors...</div>
                                        </Grid>
                                    }

                                    {sensorEquipment.length > 0 && !this.props.aircraftListLoading &&
                                        <List style={{ width: '100%', height: '100%', overflow: 'auto' }} dense={true}>
                                            {
                                                sensorEquipment.map(sensor =>
                                                    sensor.Type == "LiDAR" &&
                                                    <ListItem
                                                        button
                                                        selected={this.state.SensorName === sensor.Name}
                                                        onClick={event => this.setState({ SensorName: sensor.Name })}>
                                                        <ListItemIcon>
                                                            {sensor.Type === 'LiDAR' ?
                                                                <Wifi style={{ transform: 'rotate(180deg)', color: '#1a3a69' }} /> :
                                                                <PhotoCamera style={{ color: '#1a3a69' }} />
                                                            }
                                                        </ListItemIcon>
                                                        <ListItemText primary={sensor.Name} secondary={sensor.Type} />
                                                    </ListItem>
                                                )
                                            }
                                        </List>
                                    }
                                </Grid>
                            </Grid>

                            <Grid container direction="column" alignItems="center" style={{ width: 295 }}>
                                <span style={{ fontWeight: "bold" }}>AIRCRAFT</span>
                                <Grid container direction="column" alignItems="center" justify="center" style={{ width: '100%', height: 225, borderStyle: "solid", borderWidth: 1, borderColor: "#CCCCCC" }}>

                                    {this.props.aircraftListLoading &&
                                        <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                                            <CircularProgress />
                                            <div style={{ paddingTop: 5 }}>Loading Aircraft...</div>
                                        </Grid>
                                    }
                                    {aircraft.length > 0 && !this.props.aircraftListLoading &&
                                        <List style={{ width: '100%', height: '100%', overflow: 'auto' }} dense={true}>
                                            {
                                                aircraft.map(item =>
                                                    <ListItem
                                                        button
                                                        selected={this.state.Aircraft === item.Name}
                                                        onClick={event => this.setState({ Aircraft: item.Name, AircraftType: item.Type })}>
                                                        <ListItemIcon>
                                                            <TableAircraftTypeIcon type={item.Type} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={item.Name} secondary={item.Type === 'FixedWing' ? 'Fixed-Wing' : item.Type} />
                                                    </ListItem>
                                                )
                                            }
                                        </List>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                }

                {this.props.match.params.CompositeID !== "new" &&
                    <Grid container direction="row" alignItems="center" justify="center" style={{ paddingTop: 10, paddingBottom: 15 }}>
                        <span>
                            <span style={{ fontWeight: "bold" }}>SENSOR:</span> {this.state.SensorName}&nbsp;
                            <span style={{ fontWeight: "bold" }}>AIRCRAFT:</span> {this.state.Aircraft}&nbsp;
                            <span style={{ fontWeight: "bold" }}>SURVEY DATE (UTC):</span> {this.state.SurveyDate.split("T")[0]}
                        </span>
                    </Grid>
                }

                <span style={{ fontWeight: "bold", display: this.isEditable ? 'flex' : 'none' }}>Enter GPS Lever Arm Parameters:</span>

                <Grid container direction="column" alignItems="flex-start" style={{ width: 600, paddingTop: 10 }}>
                    <span style={{ fontWeight: "bold", fontSize: 14 }}>Reference to Primary GNSS Lever Arm (m):</span>
                    <Grid container direction="row" alignItems="center" justify="center">
                        <TextField
                            id="standard-name"
                            label="X"
                            margin="dense"
                            type="number"
                            value={this.state.PriGNSSLeverX}
                            onChange={event => this.setState({ PriGNSSLeverX: parseFloat(event.target.value) })}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                            style={{ paddingRight: 5, width: 195 }}
                        />

                        <TextField
                            id="standard-name"
                            label="Y"
                            margin="dense"
                            type="number"
                            value={this.state.PriGNSSLeverY}
                            onChange={event => this.setState({ PriGNSSLeverY: parseFloat(event.target.value) })}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                            style={{ paddingRight: 5, width: 195 }}
                        />

                        <TextField
                            id="standard-name"
                            label="Z"
                            margin="dense"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                            style={{ width: 195 }}
                            value={this.state.PriGNSSLeverZ}
                            onChange={event => this.setState({ PriGNSSLeverZ: parseFloat(event.target.value) })}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="column" alignItems="flex-start" style={{ width: 600, paddingTop: 10 }}>
                    <span style={{ fontWeight: "bold", fontSize: 14 }}>Reference to IMU Lever Arm (m):</span>
                    <Grid container direction="row" alignItems="center" justify="center">

                        <TextField
                            id="standard-name"
                            label="X"
                            value={this.state.RefToIMULeverX}
                            onChange={event => this.setState({ RefToIMULeverX: parseFloat(event.target.value) })}
                            margin="dense"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                            style={{ paddingRight: 5, width: 195 }}
                        />

                        <TextField
                            id="standard-name"
                            label="Y"
                            value={this.state.RefToIMULeverY}
                            onChange={event => this.setState({ RefToIMULeverY: parseFloat(event.target.value) })}
                            margin="dense"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                            style={{ paddingRight: 5, width: 195 }}
                        />

                        <TextField
                            id="standard-name"
                            label="Z"
                            margin="dense"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                            style={{ width: 195 }}
                            value={this.state.RefToIMULeverZ}
                            onChange={event => this.setState({ RefToIMULeverZ: parseFloat(event.target.value) })}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="column" alignItems="flex-start" style={{ width: 600, paddingTop: 10 }}>
                    <span style={{ fontWeight: "bold", fontSize: 14 }}>Reference to IMU Mounting Angles (decimal degrees):</span>
                    <Grid container direction="row" alignItems="center" justify="center">
                        <TextField
                            id="standard-name"
                            label="X"
                            margin="dense"
                            type="number"
                            value={this.state.RefToIMUMountingAnglX}
                            onChange={event => this.setState({ RefToIMUMountingAnglX: parseFloat(event.target.value) })}
                            style={{ paddingRight: 5, width: 195 }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">&deg;</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                        />

                        <TextField
                            id="standard-name"
                            label="Y"
                            margin="dense"
                            type="number"
                            value={this.state.RefToIMUMountingAnglY}
                            onChange={event => this.setState({ RefToIMUMountingAnglY: parseFloat(event.target.value) })}
                            style={{ paddingRight: 5, width: 195 }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">&deg;</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                        />

                        <TextField
                            id="standard-name"
                            label="Z"
                            margin="dense"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">&deg;</InputAdornment>,
                                readOnly: !this.isEditable
                            }}
                            style={{ width: 195 }}
                            value={this.state.RefToIMUMountingAnglZ}
                            onChange={event => this.setState({ RefToIMUMountingAnglZ: parseFloat(event.target.value) })}
                        />
                    </Grid>
                </Grid>

                {this.props.match.params.CompositeID !== "new" && !this.isEditable &&
                    <div>
                        <Grid container direction="row" alignItems="center" justify="center" style={{ paddingTop: 20 }}>

                            <div style={{ fontWeight: 'bold' }}>Instrument Files Loaded:</div>
                            {
                                (this.state.LMSInstrumentRES !== ''
                                    && this.state.LMSInstrumentRES !== undefined
                                    && this.state.LMSInstrumentTBL !== ''
                                    && this.state.LMSInstrumentTBL !== undefined
                                    && this.state.LMSInstrumentLCP !== ''
                                    && this.state.LMSInstrumentLCP !== undefined)
                                &&
                                <CheckCircleOutline style={{ fontSize: 25, color: '#006600', paddingLeft: 5 }} />
                            }
                            {
                                (this.state.LMSInstrumentRES === ''
                                    || this.state.LMSInstrumentRES === undefined
                                    || this.state.LMSInstrumentTBL === ''
                                    || this.state.LMSInstrumentTBL === undefined
                                    || this.state.LMSInstrumentLCP === ''
                                    || this.state.LMSInstrumentLCP === undefined)
                                &&
                                <ErrorOutline style={{ fontSize: 25, color: '#FF0000', paddingLeft: 5 }} />
                            }

                        </Grid>

                        {
                            (this.state.LMSInstrumentRES !== ''
                                && this.state.LMSInstrumentRES !== undefined
                                && this.state.LMSInstrumentTBL !== ''
                                && this.state.LMSInstrumentTBL !== undefined
                                && this.state.LMSInstrumentLCP !== ''
                                && this.state.LMSInstrumentLCP !== undefined)
                            &&
                            <Grid container direction="row" alignItems="center" justify="center">
                                <Button
                                    variant="contained" component="span" color="primary"
                                    onClick={event => this.props.downloadInstrumentFiles(this.props.token, this.props.match.params.CompositeID)}
                                    style={{ width: 300, height: 30, marginTop: 10 }}
                                    disabled={this.props.downloadLoading}>
                                    Download instrument files
                                {this.props.downloadLoading && <CircularProgress size={20} style={{ position: 'absolute', right: 10 }} />}
                                </Button>
                                <span style={{ fontWeight: 'bold', paddingLeft: 10, paddingTop: 10 }}>Version: </span>
                                <span style={this.props.currentLMSVersion !== null && this.props.currentLMSVersion !== this.state.LMSVersion ? { fontWeight: 'bold', paddingLeft: 10, paddingTop: 10, color: '#FF0000' } : { fontWeight: 'bold', paddingLeft: 10, paddingTop: 10 }}>{this.state.LMSVersion}</span>
                            </Grid>
                        }

                        <Grid container direction="row" alignItems="center" justify="center" style={{ paddingTop: 20 }}>
                            <span style={{ fontWeight: 'bold', paddingRight: 5 }}>Calibration Due Date (UTC):</span>
                            <span style={this.isExpired(this.state.ScheduledExpiryDate) ? { color: "#FF0000", fontWeight: 'bold' } : {}}>{this.state.ScheduledExpiryDate !== undefined ? this.state.ScheduledExpiryDate.split("T")[0] : ''}</span>
                        </Grid>
                    </div>
                }

                <Grid container direction="row" alignItems="center" justify="space-around" style={{ width: 605, paddingTop: 15 }}>
                    {this.props.match.params.CompositeID === "new" &&
                        <div>
                            <TextField
                                id="date"
                                label="Survey Date (UTC)"
                                type="date"
                                // defaultValue="2017-05-24"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ paddingRight: 15, width: 215 }}
                                onChange={event => this.setState({ SurveyDate: event.target.value })}
                            />
                        </div>
                    }
                    {this.isEditable &&
                        <TextField
                            id="ScheduledExpiryDate"
                            label="Calibration Due Date (UTC)"
                            type="date"
                            // defaultValue="2017-05-24"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: 215 }}
                            onChange={event => this.setState({ ScheduledExpiryDate: event.target.value })}
                        />
                    }
                </Grid>


                {this.isEditable &&

                    <Grid container direction="row" alignItems="center" justify="center" style={{ width: 605, paddingTop: 15 }}>



                        <input
                            disabled={
                                this.state.Aircraft === ''
                                || this.state.Aircraft === undefined
                                || this.state.SensorName === ''
                                || this.state.SensorName === undefined
                                || this.state.SurveyDate === ''
                                || this.state.SurveyDate === undefined
                            }
                            accept="*"
                            id="uploadButton"
                            webkitdirectory="true"
                            directory="true"
                            multiple type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => this.onUpload(event)}
                            ref="selectButton" />

                        <label htmlFor="uploadButton">
                            <Button
                                variant="contained" component="span" color="primary"
                                disabled={
                                    this.state.Aircraft === ''
                                    || this.state.Aircraft === undefined
                                    || this.state.SensorName === ''
                                    || this.state.SensorName === undefined
                                    || this.state.SurveyDate === ''
                                    || this.state.SurveyDate === undefined
                                }
                                onClick={this.inputReset}
                                style={{ width: 265, height: 30, marginRight: 10 }}>Upload LMS Calibration Files</Button>
                        </label>

                        {
                            (this.state.LMSInstrumentRES === ''
                                || this.state.LMSInstrumentRES === undefined
                                || this.state.LMSInstrumentTBL === ''
                                || this.state.LMSInstrumentTBL === undefined
                                || this.state.LMSInstrumentLCP === ''
                                || this.state.LMSInstrumentLCP === undefined)
                            &&
                            <HelpOutline style={{ fontSize: 36, color: '#1a3a69' }} />
                        }
                        {/* <ErrorOutline style={{ fontSize: 36, color: '#FF0000' }} /> */}
                        {
                            (this.state.LMSInstrumentRES !== ''
                                && this.state.LMSInstrumentRES !== undefined
                                && this.state.LMSInstrumentTBL !== ''
                                && this.state.LMSInstrumentTBL !== undefined
                                && this.state.LMSInstrumentLCP !== ''
                                && this.state.LMSInstrumentLCP !== undefined)
                            &&
                            <CheckCircleOutline style={{ fontSize: 36, color: '#006600' }} />
                        }

                        {/* <input
                            accept="*"
                            id="testButton"
                            webkitdirectory="true"
                            directory="true"
                            multiple type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                                let i = 4;
                                console.log('...',event.target.files[i].name);
                                let r = new FileReader();
                                r.onload = function (e) {
                                    let contents = e.target.result;
                                    console.log(JSON.stringify(contents));
                                }
                                r.readAsText(event.target.files[i]);
                            }}
                            ref="selectButton" />

                        <label htmlFor="testButton">
                            <Button
                                variant="contained" component="span" color="primary"
                                // onClick={this.inputReset}
                                style={{ width: 265, height: 30, marginRight: 10 }}>Test</Button>
                        </label> */}
                    </Grid>
                }
                {this.isEditable &&
                    <Button
                        disabled={
                            // Object.values(this.state).indexOf('') !== -1
                            // || Object.values(this.state).find(function(element){return isNaN(element)}) !== undefined
                            // || Object.values(this.state).indexOf(undefined) !== -1

                            this.state.Aircraft === ''
                            || this.state.Aircraft === undefined
                            || this.state.SensorName === ''
                            || this.state.SensorName === undefined
                            || this.state.PriGNSSLeverX === ''
                            || this.state.PriGNSSLeverX === undefined
                            || this.state.PriGNSSLeverY === ''
                            || this.state.PriGNSSLeverY === undefined
                            || isNaN(this.state.PriGNSSLeverY)
                            || this.state.PriGNSSLeverZ === ''
                            || this.state.PriGNSSLeverY === undefined
                            || isNaN(this.state.PriGNSSLeverY)
                            || this.state.RefToIMULeverX === ''
                            || this.state.RefToIMULeverX === undefined
                            || isNaN(this.state.RefToIMULeverX)
                            || this.state.RefToIMULeverY === ''
                            || this.state.RefToIMULeverY === undefined
                            || isNaN(this.state.RefToIMULeverY)
                            || this.state.RefToIMULeverZ === ''
                            || this.state.RefToIMULeverZ === undefined
                            || isNaN(this.state.RefToIMULeverZ)
                            || this.state.RefToIMUMountingAnglX === ''
                            || this.state.RefToIMUMountingAnglX === undefined
                            || isNaN(this.state.RefToIMUMountingAnglX)
                            || this.state.RefToIMUMountingAnglY === ''
                            || this.state.RefToIMUMountingAnglY === undefined
                            || isNaN(this.state.RefToIMUMountingAnglY)
                            || this.state.RefToIMUMountingAnglZ === ''
                            || this.state.RefToIMUMountingAnglZ === undefined
                            || isNaN(this.state.RefToIMUMountingAnglZ)
                            || this.state.SurveyDate === ''
                            || this.state.SurveyDate === undefined
                            || this.state.ScheduledExpiryDate === ''
                            || this.state.ScheduledExpiryDate === undefined
                            || this.state.LMSInstrumentRES === ''
                            || this.state.LMSInstrumentRES === undefined
                            || this.state.LMSInstrumentTBL === ''
                            || this.state.LMSInstrumentTBL === undefined
                            || this.state.LMSInstrumentLCP === ''
                            || this.state.LMSInstrumentLCP === undefined
                        }
                        variant="contained" component="span" color="primary"
                        style={{ width: 265, height: 30, marginTop: 15 }}
                        onClick={this.props.match.params.CompositeID !== "new" ? this.updateParams : this.createNewParams}
                    >
                        {this.props.match.params.CompositeID !== "new" ? "Update Parameters" : "Add Parameters"}
                    </Button>
                }

                <LMSValidationDialog
                    title="LMS Calibration File Validation Results"
                    errors={this.state.errors}
                    onCancel={() => { this.setState({ validationDialogVisible: false }) }}
                    open={this.state.validationDialogVisible} />

            </Grid >

        );
    }
}


const mapStateToProps = (state) => {
    return {
        token: state.token,
        paramsUpdateLoading: state.paramsUpdateLoading,
        // queryParams: state.processingQueryParams,
        params: state.paramsUpdateSuccess,
        sensorEquipmentListLoading: state.sensorEquipmentListLoading,
        sensorEquipment: state.sensorEquipment,
        aircraftListLoading: state.aircraftListLoading,
        aircraft: state.aircraft,
        downloadLoading: state.calibrationDownloadLoading,
        currentLMSVersion: state.appSettings ? state.appSettings.LMSCurrentVersion : null,
    };
};

// //this is needed to match thre returned result to properties
const mapDispatchToProps = (dispatch) => {
    return {
        clearState: () => { dispatch(resetState()) },
        paramsCreate: (token, jsonData) => { dispatch(paramsCreate(token, jsonData)) },
        paramsUpdate: (token, id, jsonData) => dispatch(paramsUpdate(token, id, jsonData)),
        fetchData: (token, id) => dispatch(paramsFetchItem(token, id)),
        downloadInstrumentFiles: (token, id) => dispatch(downloadInstrumentFiles(token, id)),
        fetchSensorsAndAircraft: (token) => {
            dispatch(aircraftFetchList(token));
            dispatch(sensorEquipmentFetchList(token))
        },
        // setSensorEquipmentQueryParams: payload => {
        //     dispatch(sensorEquipmentQueryParams(payload))
        //     dispatch(sensorEquipmentFetchList())
        // }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingParams);