import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { authoriseDownload } from './ProcessingActions'


class DownloadAuthorisation extends Component {

    constructor(props) {
        super(props);
    }

    state = {
    };

    componentDidMount() {
        this.createDownloadAuthRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.downloadAuthSuccess === null && this.props.downloadAuthSuccess !== null) {
            window.onbeforeunload = function () { }
            window.location.replace(this.props.downloadAuthSuccess);
        }
    }

    createDownloadAuthRequest = () => {
        let parts = this.props.location.pathname.substr(17).split('/');
        let bucket = parts[1];
        let key = parts.slice(2).join('/');
        this.setState({ filename: parts[parts.length - 1] })
        this.props.authoriseDownload(this.props.token, bucket, key);
    }

    render() {
        return (
            <div>
                {
                    this.props.downloadAuthHasError &&
                    <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                        <ErrorOutline style={{ fontSize: 48, color: '#FF0000' }} />
                        <p>Download Authorisation Failed.</p>
                        <p>Please log a support issue per the support icon in the top right.</p>
                    </Grid>
                }
                {
                    this.props.downloadAuthSuccess &&
                    <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                        <CheckCircleOutline style={{ fontSize: 48, color: '#006600' }} />
                        <p>Download Authorised for: <strong>{this.state.filename}</strong></p>
                    </Grid>
                }
                {
                    this.props.downloadAuthLoading &&
                    <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                        <CircularProgress />
                        <p>Authorising. Download will commence shortly...</p>
                    </Grid>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        downloadAuthLoading: state.downloadAuthLoading,
        downloadAuthSuccess: state.downloadAuthSuccess,
        downloadAuthHasError: state.downloadAuthHasError
    };
};

// //this is needed to match thre returned result to properties
const mapDispatchToProps = (dispatch) => {
    return {
        authoriseDownload: (token, bucket, key) => { dispatch(authoriseDownload(token, bucket, key)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAuthorisation);