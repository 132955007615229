import React, { useRef, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
// import { connect } from 'react-redux';
import UploadStatusIcon, { UploadStates as UPLOAD_STATES } from './UploadStatusIcon'
import { VALID_STATUS } from './UploadPanel'

//////////////////////////////////
//           Component          //
//////////////////////////////////
const ICON_NAME = {
    VALIDATING_FOLDER: 'VALIDATING_FOLDER',
    GENERATING_METADATA: 'GENERATING_METADATA',
    CHECKING_UPLOAD_PROGRESS: 'CHECKING_UPLOAD_PROGRESS',
    CHECKING_CONCURRENT_UPLOAD: 'CHECKING_CONCURRENT_UPLOAD',
    UPLOADING: 'UPLOADING',
    CHECKING_INTERITY: 'CHECKING_INTERITY',
    VALIDING_UPLOAD: 'VALIDING_UPLOAD',
}

//////////////////////////////////
//           Component          //
//////////////////////////////////
interface UploadStagesProps {
    status: string;
    uploadIntegrityCheckCounter?: number;
}

const UploadStages = (props: UploadStagesProps) => {
    // Props from Redux mapStateToProps
    const { status, uploadIntegrityCheckCounter } = props

    const initStatus = useRef<string>()
    const [isValidatingFolderDisplay, setIsValidatingFolderDisplay] = useState<boolean>(false)
    const [isGeneratingMetadataDisplay, setIsGeneratingMetadataDisplay] = useState<boolean>(false)
    const [isCheckingUploadProgressDisplay, setIsCheckingUploadProgressDisplay] = useState<boolean>(false)
    const [isCheckingConcurrentUploadDisplay, setIsCheckingConcurrentUploadDisplay] = useState<boolean>(true)
    const [isUploadingDisplay, setIsUploadingDisplay] = useState<boolean>(false)
    const [isCheckingIntegrityDisplay, setIsCheckingIntegrityDisplay] = useState<boolean>(true)
    const [isValidingUploadDisplay, setIsValidingUploadDisplay] = useState<boolean>(true)

    const pipelineMapping = {
        [VALID_STATUS.READY_FOR_UPLOAD]: () => {
            setIsValidatingFolderDisplay(true)
            setIsGeneratingMetadataDisplay(true)
            setIsUploadingDisplay(true)
            // setIsCheckingIntegrityDisplay(true)
            // setIsValidingUploadDisplay(true)
        },
        [VALID_STATUS.UPLOAD_PAUSED]: () => {
            setIsCheckingUploadProgressDisplay(true)
            setIsUploadingDisplay(true)
            // setIsCheckingIntegrityDisplay(true)
            // setIsValidingUploadDisplay(true)
        },
        [VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS]: () => {        // the same as UPLOAD_PAUSED, used when resuming
            setIsCheckingUploadProgressDisplay(true)
            setIsUploadingDisplay(true)
            // setIsCheckingIntegrityDisplay(true)
            // setIsValidingUploadDisplay(true)
        },
        [VALID_STATUS.UPLOAD_STALLED]: () => {
            setIsCheckingUploadProgressDisplay(true)
            setIsUploadingDisplay(true)
            // setIsCheckingIntegrityDisplay(true)
            // setIsValidingUploadDisplay(true)
        },
        [VALID_STATUS.DATA_READY]: () => {
            setIsGeneratingMetadataDisplay(true)
            setIsUploadingDisplay(true)
            // setIsCheckingIntegrityDisplay(true)
            // setIsValidingUploadDisplay(true)
        },
    }

    // iconStatusLookup[currentStatus][iconName] --> iconStatus
    // Default icon status is WAITING. Only declare icons that are not in WAITING status here.
    const iconStatusLookup = {
        // [VALID_STATUS.READY_FOR_UPLOAD]: {},
        [VALID_STATUS.VALIDATING_SOURCE]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.IN_PROGRESS,
        },
        [VALID_STATUS.VALIDATING_SOURCE_FAILED]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.FAILED,
        },
        [VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.IN_PROGRESS,
        },
        [VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK_FAILED]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.FAILED,
        },
        [VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS]: {
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.IN_PROGRESS,
        },
        [VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS_FAILED]: {
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.FAILED,
        },
        [VALID_STATUS.CHECKING_CONCURRENT_UPLOAD]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.IN_PROGRESS,
        },
        [VALID_STATUS.UPLOAD_QUEUED]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.IN_PROGRESS,
        },
        [VALID_STATUS.UPLOADING]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.IN_PROGRESS,
            [ICON_NAME.CHECKING_INTERITY]: uploadIntegrityCheckCounter
                                            ? UPLOAD_STATES.FAILED
                                            : undefined
        },
        [VALID_STATUS.UPLOAD_FAILED]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.FAILED,
            [ICON_NAME.CHECKING_INTERITY]: uploadIntegrityCheckCounter
                                            ? UPLOAD_STATES.FAILED
                                            : undefined
        },
        // it won't be presented as it will be redirected to the dashboard once it is cancelled
        [VALID_STATUS.UPLOAD_CANCELLED]: {                                  
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.FAILED
        },
        [VALID_STATUS.UPDATE_CANCELLED]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.FAILED
        },
        [VALID_STATUS.UPLOAD_PAUSED]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.PAUSED
        },
        // it won't be presented as it will present another resume page when it is UPLOAD_STALLED
        // it will be presented only when integrity check reaches the maximum limit. 
        [VALID_STATUS.UPLOAD_STALLED]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_INTERITY]: UPLOAD_STATES.FAILED,
        },
        [VALID_STATUS.UPLOAD_INTEGRITY_CHECK]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_INTERITY]: UPLOAD_STATES.IN_PROGRESS
        },
        [VALID_STATUS.UPLOAD_COMPLETE]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_INTERITY]: UPLOAD_STATES.COMPLETED
        },
        [VALID_STATUS.VALIDATING_UPLOAD]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_INTERITY]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.VALIDING_UPLOAD]: UPLOAD_STATES.IN_PROGRESS
        },
        [VALID_STATUS.VALIDATING_UPLOAD_FAILED]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_INTERITY]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.VALIDING_UPLOAD]: UPLOAD_STATES.FAILED
        },
        [VALID_STATUS.DATA_READY]: {
            [ICON_NAME.VALIDATING_FOLDER]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.GENERATING_METADATA]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_UPLOAD_PROGRESS]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_CONCURRENT_UPLOAD]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.UPLOADING]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.CHECKING_INTERITY]: UPLOAD_STATES.COMPLETED,
            [ICON_NAME.VALIDING_UPLOAD]: UPLOAD_STATES.COMPLETED
        },
    }

    useEffect(() => {
        if(initStatus.current === undefined) {
            initStatus.current = status
            // console.log(`Upload pipeline selected: initial status: ${initStatus.current}`)
            pipelineMapping[status]()
        }
    }, [status])

    const getIconStatus = (iconName: string, currentStatus: string=status): string => {
        const iconStatus = (iconStatusLookup[currentStatus] && iconStatusLookup[currentStatus][iconName]) || UPLOAD_STATES.WAITING
        return iconStatus
    }

    return (
        <>
            <Grid container direction="row" justify='center' alignItems='center' >
                {isValidatingFolderDisplay
                    && <UploadStatusIcon label="Validating Folder" status={getIconStatus(ICON_NAME.VALIDATING_FOLDER)} />
                }
                {isGeneratingMetadataDisplay
                    && <UploadStatusIcon label="Generating Metadata" status={getIconStatus(ICON_NAME.GENERATING_METADATA)} />
                }
                {isCheckingUploadProgressDisplay
                    && <UploadStatusIcon label="Checking Upload Progress" status={getIconStatus(ICON_NAME.CHECKING_UPLOAD_PROGRESS)} />
                }
                {isCheckingConcurrentUploadDisplay
                    && <UploadStatusIcon label="Checking Concurrent Upload" status={getIconStatus(ICON_NAME.CHECKING_CONCURRENT_UPLOAD)} />
                }
                {isUploadingDisplay
                    && <UploadStatusIcon label="Uploading" status={getIconStatus(ICON_NAME.UPLOADING)} />
                }
                {isCheckingIntegrityDisplay
                    && <UploadStatusIcon label="Checking Integrity" status={getIconStatus(ICON_NAME.CHECKING_INTERITY)} />
                }
                {isValidingUploadDisplay
                    && <UploadStatusIcon label="Validating Upload" status={getIconStatus(ICON_NAME.VALIDING_UPLOAD)} />
                }
            </Grid>
        </>
    )
}

export default UploadStages