import React, { 
    useState, useEffect, useRef, BaseSyntheticEvent, KeyboardEvent, 
    EventHandler, forwardRef, useImperativeHandle, ForwardedRef
} from 'react'
// import { connect, ConnectedProps } from 'react-redux';
import { 
    Grid, Divider, Input, Chip, Button, Badge, Dialog, DialogTitle, DialogContent, DialogActions,
    InputBase, InputLabel, InputAdornment, List, ListItem, Menu, MenuItem, Select, IconButton,
    CircularProgress
} from '@material-ui/core';
import { withStyles, WithStyles, StyledComponentProps, createStyles, Theme } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search'
import Flight from '@material-ui/icons/Flight'
import Person from '@material-ui/icons/Person'
// import ControlPoint from '@material-ui/icons/ControlPoint'
import SettingsRemote from '@material-ui/icons/SettingsRemote'
import CalendarToday from '@material-ui/icons/CalendarToday'
import Description from '@material-ui/icons/Description'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import { FilterDialogProps, SelectFilterOptions, SelectOption } from './FilterDialogTypes'
import { SensorSignal, Briefcase } from '../icons/svgIcons'
import GenericSelectFilter, { GenericSelectFilterRef } from '../common/GenericSelectFilter'
// import './processing.css'


const styles = ({ palette, transitions, spacing }: Theme) => createStyles({
    textInputFilter: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        // width: 'auto',
        width: 470,
        padding: '10px 12px',
        transition: transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }
});

interface FilterDialogPropsWithStyles extends WithStyles<typeof styles>, FilterDialogProps {}

const FilterDialog = forwardRef((props: FilterDialogPropsWithStyles, ref) => {
    const { classes } = props
    const { open, onClose, queryFilters, updateQueryFiltersCB, selectFilterOptions, filterOnFocusIndex } = props

    // const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [primaryIdTextFilterValue, setPrimaryIdTextFilterValue] = useState<string>('')
    const [isShowPrimaryIdTextFilterErrorMsg, setIsShowPrimaryIdTextFilterErrorMsg] = useState<boolean>(false)
    const [primaryIdFilterList, setPrimaryIdFilterList] = useState<Array<string>>([])
    const [aircraftTextFilterValue, setAircraftTextFilterValue] = useState<string>('')
    const [aircraftFilterList, setAircraftFilterList] = useState<Array<string>>([])
    const [projectManagerFilterList, setProjectManagerFilterList] = useState<Array<string>>([])
    const [sensorFilterList, setSensorFilterList] = useState<Array<string>>([])
    const [surveyDateStart, setSurveyDateStart] = useState<string>('')
    const [surveyDateEnd, setSurveyDateEnd] = useState<string>('')
    const [processingTemplateFilterList, setProcessingTemplateFilterList] = useState<Array<string>>([])
    const [isDeleteSurveyDateStartBtnEnabled, setIsDeleteSurveyDateStartBtnEnabled] = useState<boolean>(false)
    // const [isDeleteSurveyDateEndBtnEnabled, setIsDeleteSurveyDateEndBtnEnabled] = useState<boolean>(false)
    const [isSurveyDateEndEnabled, setIsSurveyDateEndEnabled] = useState<boolean>(false)
    const [isShowSurveyDateErrorMsg, setIsShowSurveyDateErrorMsg] = useState<boolean>(false)
    const [isSearchBtnEnabled, setIsSearchBtnEnabled] = useState<boolean>(true)
    const [isClearAllBtnEnabled, setIsClearAllBtnEnabled] = useState<boolean>(true)

    const sensorFilterRef = useRef<HTMLDivElement & GenericSelectFilterRef>(null)
    const projectManagerFilterRef = useRef<HTMLDivElement & GenericSelectFilterRef>(null)
    const processingTemplateFilterRef = useRef<HTMLDivElement & GenericSelectFilterRef>(null)
    const searchBtnRef = useRef<HTMLButtonElement>(null)

    const isSelectFilterOptionsFetchSucceed = (selectFilterOptions && 
            (selectFilterOptions.sensors && selectFilterOptions.sensors.length > 0) &&
            (selectFilterOptions.projectManagers && selectFilterOptions.projectManagers.length > 0) &&
            (selectFilterOptions.processingTemplates && selectFilterOptions.processingTemplates.length > 0)
        ) ? true : false

    useEffect(() => {
        setIsDeleteSurveyDateStartBtnEnabled(!!surveyDateStart)
        setIsSurveyDateEndEnabled(!!surveyDateStart)
        let currentDate = (new Date).toISOString().split('T')[0]
        !!surveyDateStart && setSurveyDateEnd(currentDate)
    }, [surveyDateStart])

    // useEffect(() => {
    //     setIsDeleteSurveyDateEndBtnEnabled(!!surveyDateEnd)
    // }, [surveyDateEnd])

    useEffect(() => {
        const MS_PER_DAY = 1000 * 60 * 60 * 24;         // unit: millisecond
        if(surveyDateStart && surveyDateEnd) {
            let startTimestamp = Date.parse(surveyDateStart)
            let endTimestamp = Date.parse(surveyDateEnd)
            if((endTimestamp - startTimestamp) < MS_PER_DAY) {
                setIsShowSurveyDateErrorMsg(true)
            } else {
                setIsShowSurveyDateErrorMsg(false)
            }
        } else {
            setIsShowSurveyDateErrorMsg(false)
        }

    }, [surveyDateStart, surveyDateEnd])

    useEffect(() => {
        open && initAllFilters()
    }, [open])
    
    useEffect(() => {
        setIsSearchBtnEnabled(!isShowSurveyDateErrorMsg && isSelectFilterOptionsFetchSucceed)
        setIsClearAllBtnEnabled(isSelectFilterOptionsFetchSucceed)
    }, [isShowSurveyDateErrorMsg, isSelectFilterOptionsFetchSucceed])

    const handlePrimaryIdTextFilterOnChange = (event: BaseSyntheticEvent) => {
        setPrimaryIdTextFilterValue(event.target.value.toUpperCase())
        setIsShowPrimaryIdTextFilterErrorMsg(false)
    }

    const handlePrimaryIdTextFilterOnKeyPress = (event: KeyboardEvent) => {
        const primaryIdRegExp = /^((FL|SL)\d{6}|(PRJ)\d{5})$/g
        if(event.key === 'Enter' && primaryIdTextFilterValue) {
            if(primaryIdRegExp.test(primaryIdTextFilterValue)) {
                if(primaryIdFilterList.indexOf(primaryIdTextFilterValue) == -1) {
                    setPrimaryIdFilterList(prevState => ([...prevState, primaryIdTextFilterValue]))
                }
                setPrimaryIdTextFilterValue('')
                searchBtnRef.current && (searchBtnRef.current as any).focus()
            } else {
                setIsShowPrimaryIdTextFilterErrorMsg(true)
            }
        }
    }

    const handlePrimaryIdChipOnDelete = (primaryId: string, index: number) => {
        setPrimaryIdFilterList(prevState => {
            var newState = [...prevState]
            newState.splice(index, 1)
            return newState
        })
    }

    const handleAircraftTextFilterOnKeyPress = (event: KeyboardEvent) => {
        if(event.key === 'Enter' && aircraftTextFilterValue) {
            if(aircraftFilterList.indexOf(aircraftTextFilterValue) == -1) {
                setAircraftFilterList(prevState => ([...prevState, aircraftTextFilterValue]))
            }
            setAircraftTextFilterValue('')
            searchBtnRef.current && (searchBtnRef.current as any).focus()
        }
    }

    const handleAircraftChipOnDelete = (aircraft: string, index: number) => {
        setAircraftFilterList(prevState => {
            var newState = [...prevState]
            newState.splice(index, 1)
            return newState
        })
    }

    const handleFilterDialogOnClose = () => {
        onClose()
    }

    const initAllFilters = () => {
        setPrimaryIdTextFilterValue('')
        setIsShowPrimaryIdTextFilterErrorMsg(false)
        setPrimaryIdFilterList(queryFilters.primaryIdFilterList || [])
        setAircraftTextFilterValue('')
        setAircraftFilterList(queryFilters.aircraftFilterList || [])
        setSurveyDateStart(queryFilters.surveyDateStart || '')
        setSurveyDateEnd(queryFilters.surveyDateEnd || '')
        setIsShowSurveyDateErrorMsg(false)
        setSensorFilterList(queryFilters.sensorFilterList || [])
        setProjectManagerFilterList(queryFilters.projectManagerFilterList || [])
        setProcessingTemplateFilterList(queryFilters.processingTemplateFilterList || [])
    }

    const clearAllFilters = () => {
        setPrimaryIdTextFilterValue('')
        setIsShowPrimaryIdTextFilterErrorMsg(false)
        setPrimaryIdFilterList([])
        setAircraftTextFilterValue('')
        setAircraftFilterList([])
        setSurveyDateStart('')
        setSurveyDateEnd('');
        setIsShowSurveyDateErrorMsg(false)
        sensorFilterRef && (sensorFilterRef as any).current.cleanFilter();
        projectManagerFilterRef && (projectManagerFilterRef as any).current.cleanFilter();
        processingTemplateFilterRef && (processingTemplateFilterRef as any).current.cleanFilter();
    }

    const handleFilterApplyBtnOnClick = () => {
        onClose()

        let queryFilters = {
            primaryIdFilterList,
            aircraftFilterList,
            sensorFilterList,
            projectManagerFilterList,
            surveyDateStart,
            surveyDateEnd,
            processingTemplateFilterList
        }
        updateQueryFiltersCB(queryFilters)
    }

    useImperativeHandle(ref, () => ({
        filters: {
            primaryIdFilterList,
            aircraftFilterList,
            sensorFilterList,
            projectManagerFilterList,
            surveyDateStart,
            surveyDateEnd,
            processingTemplateFilterList
        }
    }));

    return (
        <>
            <Dialog 
                open={open}
                // onClose={onClose}
                onClose={handleFilterDialogOnClose}
                // maxWidth='md'
                PaperProps={{
                    style: {
                        // backgroundColor: '#808080e6',
                        // boxShadow: 'none',
                        // minHeight: '150px',
                        minWidth: '650px',
                        borderRadius: 10,
                    },
                }}
            >
                <DialogTitle>Filters</DialogTitle>
                <DialogContent>
                    {
                        isSelectFilterOptionsFetchSucceed
                            ? <Grid container direction='column' alignItems='center' justify='center'
                                className='pl-4'
                            >
                                <Grid container direction="column" justify="center" alignItems="flex-start" className='pt-2' >
                                    <div><strong>Sensor ID, Project ID or Flight ID:</strong></div>
                                    <div className='pt-1 pb-1' >
                                        <InputBase
                                            id="primary-id-text-filter"
                                            value={primaryIdTextFilterValue}
                                            placeholder='Press the "ENTER" key to insert'
                                            onChange={handlePrimaryIdTextFilterOnChange}
                                            onKeyPress={handlePrimaryIdTextFilterOnKeyPress}
                                            classes={{
                                            //     root: classes.bootstrapRoot,
                                                input: classes.textInputFilter,
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            }
                                            autoFocus={!!(filterOnFocusIndex == 0)}
                                        />
                                    </div>
                                    {
                                        isShowPrimaryIdTextFilterErrorMsg && (
                                            <div 
                                                className='input__msg--error pl-4 pb-1'
                                            >
                                                <ErrorOutline className='pr-1' />
                                                Invalid Sensor ID, Project ID or Flight ID.
                                            </div>
                                        )
                                    }
                                    <Grid container direction="row" justify="flex-start" alignItems="center">
                                        <div className='pl-4 pb-2' style={{ lineHeight: 1.5 }} >
                                            {primaryIdFilterList && 
                                                React.Children.toArray(
                                                    primaryIdFilterList.map((primaryId, index) => {
                                                        return (
                                                            <Chip
                                                                key={index}
                                                                label={
                                                                    <>
                                                                        {
                                                                            primaryId.startsWith('FL') &&
                                                                                (<div style={{ display: 'flex', marginLeft: -11, marginRight: 6, height: 30, width: 30, borderRadius: '50%', backgroundColor: 'rgb(166, 166, 166)' }}>
                                                                                    <Flight style={{ fontSize: 26, marginLeft: 3, marginTop: 1, transform: 'rotate(45deg)', color: 'white' }}/>
                                                                                </div>)
                                                                        }
                                                                        {
                                                                            primaryId.startsWith('SL') &&
                                                                                (<div style={{ display: 'flex', marginLeft: -11, marginRight: 6, alignItems: 'center', height: 30, width: 30, borderRadius: '50%', backgroundColor: 'rgb(166, 166, 166)' }}>
                                                                                    <SensorSignal 
                                                                                        style={{ marginLeft: 2, marginTop: 1 }}
                                                                                    />
                                                                                </div>)
                                                                        }
                                                                        {
                                                                            primaryId.startsWith('PRJ') && 
                                                                                (<div style={{ display: 'flex', marginLeft: -11, marginRight: 6, alignItems: 'center', height: 30, width: 25, borderRadius: '50%', backgroundColor: 'rgb(166, 166, 166)', paddingLeft: 5 }}>
                                                                                    <Briefcase
                                                                                        width='21px'
                                                                                        height='21px'
                                                                                    />
                                                                                </div>)
                                                                        }
                                                                        <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '130px' }} >{primaryId}</p>
                                                                    </>
                                                                }
                                                                onDelete={() => handlePrimaryIdChipOnDelete(primaryId, index)}
                                                                style={{ margin: 2, maxWidth: '180px' }}
                                                            />
                                                        )
                                                    })
                                                )
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider style={{ width: '100%', marginBottom: 8 }}  />
                                <Grid container direction="column" justify="center" alignItems="flex-start" className='pt-2' >
                                    <div><strong>Aircraft:</strong></div>
                                    <div className='pt-1 pb-1' >
                                        <InputBase
                                            id="aircraft-type-text-filter"
                                            value={aircraftTextFilterValue}
                                            placeholder='Press the "ENTER" key to insert'
                                            onChange={event => setAircraftTextFilterValue(event.target.value)}
                                            onKeyPress={handleAircraftTextFilterOnKeyPress}
                                            classes={{
                                            //     root: classes.bootstrapRoot,
                                                input: classes.textInputFilter,
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Flight />
                                                </InputAdornment>
                                            }
                                            autoFocus={!!(filterOnFocusIndex == 1)}
                                        />
                                    </div>
                                    <Grid container direction="row" justify="flex-start" alignItems="center">
                                        <div className='pl-4 pb-2'>
                                            {
                                                React.Children.toArray(
                                                    aircraftFilterList.map((aircraft, index) => {
                                                        return (
                                                            <Chip
                                                                key={index}
                                                                label={
                                                                    <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '130px' }} >{aircraft}</p>
                                                                }
                                                                onDelete={() => handleAircraftChipOnDelete(aircraft, index)}
                                                                style={{ margin: 2, maxWidth: '180px' }}
                                                            />
                                                        )
                                                    })
                                                )
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider style={{ width: '100%', marginBottom: 8 }}  />
                                <Grid container direction="column" justify="center" alignItems="flex-start" className='pt-2'>
                                    <div><strong>Survey Date (UTC):</strong></div>
                                    <Grid container direction='row' justify='flex-start' alignContent='center' xs={12} 
                                        className='mt-1 mb-2'
                                    >
                                        <CalendarToday />
                                        <div style={{ width: '90%', marginTop: -12 }} >
                                            <Grid container direction="row" alignItems="center" justify="flex-start" className='pl-2 pt-0 mt-0' xs={12}>
                                                <Grid  container direction="row" alignItems="center" justify="flex-end" xs={1} >
                                                    <p className='mr-1'>Start:</p>
                                                </Grid>
                                                <Grid  container direction="row" alignItems="center" justify="flex-start" xs={3} >
                                                    <Input
                                                        style={{ width: 130 }}
                                                        name="startDate"
                                                        onChange={event => setSurveyDateStart(event.target.value)}
                                                        type="date"
                                                        value={surveyDateStart}
                                                        className='ml-1'
                                                        autoFocus={!!(filterOnFocusIndex == 2)}
                                                    />
                                                </Grid>
                                                <Grid  container direction="row" alignItems="center" justify="flex-end" xs={2} >
                                                    <p className='mr-1'>End:</p>
                                                </Grid>
                                                <Grid  container direction="row" alignItems="center" justify="flex-start" xs={4} >
                                                    <Input
                                                        style={{ width: 130 }}
                                                        name="endDate"
                                                        onChange={event => setSurveyDateEnd(event.target.value)}
                                                        type="date"
                                                        value={surveyDateEnd}
                                                        className='ml-1'
                                                        disabled={!isSurveyDateEndEnabled}
                                                    />
                                                </Grid>
                                                <Grid  direction="row" alignItems="center" justify="flex-start" xs={1} >
                                                    <IconButton 
                                                        disabled={!isDeleteSurveyDateStartBtnEnabled}
                                                        className='iconbutton__button--medium'
                                                        onClick={() => {setSurveyDateStart('');setSurveyDateEnd('')}}
                                                    >
                                                        <CancelIcon
                                                            color={isDeleteSurveyDateStartBtnEnabled ? 'primary' : 'disabled'}
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            {
                                                isShowSurveyDateErrorMsg && (
                                                    <div 
                                                        className='input__msg--error pl-2 pb-1'
                                                    >
                                                        <ErrorOutline className='pr-1' />
                                                        End date should be greater than start date.
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider style={{ width: '100%', marginBottom: 8 }} />
                                <GenericSelectFilter 
                                    label='Sensor'
                                    selectOptions={(selectFilterOptions && (selectFilterOptions as SelectFilterOptions).sensors) || []}
                                    defaultFilterList={sensorFilterList}
                                    Icon={<SettingsRemote />}
                                    handleFilterListOnChange={(filterList) => setSensorFilterList(filterList)}
                                    ref={sensorFilterRef}
                                    width={490}
                                    autoFocus={!!(filterOnFocusIndex == 3)}
                                />
                                <Divider style={{ width: '100%', marginBottom: 8 }}  />
                                <GenericSelectFilter 
                                    label='Project Manager'
                                    selectOptions={(selectFilterOptions && (selectFilterOptions as SelectFilterOptions).projectManagers) || []}
                                    defaultFilterList={projectManagerFilterList}
                                    Icon={<Person />}
                                    handleFilterListOnChange={(filterList) => setProjectManagerFilterList(filterList)}
                                    ref={projectManagerFilterRef}
                                    width={490}
                                    autoFocus={!!(filterOnFocusIndex == 4)}
                                />
                                <Divider style={{ width: '100%', marginBottom: 8 }}  />
                                <GenericSelectFilter 
                                    label='Processing Template'
                                    selectOptions={(selectFilterOptions && (selectFilterOptions as SelectFilterOptions).processingTemplates) || []}
                                    defaultFilterList={processingTemplateFilterList}
                                    Icon={<Description />}
                                    handleFilterListOnChange={(filterList) => setProcessingTemplateFilterList(filterList)}
                                    ref={processingTemplateFilterRef}
                                    width={490}
                                    autoFocus={!!(filterOnFocusIndex == 5)}
                                />
                            </Grid>
                            : <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                                <CircularProgress className='mb-3' />
                                <div style={{ paddingTop: 5 }}>Loading filter parameters...</div>
                            </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Grid container direction='row' alignItems='center' justify='space-evenly' xs={12}
                        className='pt-2 pb-2'
                    >
                        <Button variant="contained" color="secondary" 
                            onClick={clearAllFilters}
                            disabled={!isClearAllBtnEnabled}
                        >
                            Clear All
                        </Button>
                        <Button variant="contained" color="primary" 
                            onClick={handleFilterApplyBtnOnClick}
                            disabled={!isSearchBtnEnabled}
                            buttonRef={searchBtnRef}
                        >
                            Search
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
})

// export default FilterDialog
export default withStyles(styles)(FilterDialog);