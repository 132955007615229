import { Reducer, AnyAction } from 'redux'
import { UPLOADER_ACTION_TYPE } from './UploaderActions'
import {
    UploadTask, OngoingTask, UploaderStore, FetchFlightByFlightIdResponse,
    FetchSensorEquipmentListResponse
} from './UploaderTypes'

//////////////////////////////////
//            Reducers          //
//////////////////////////////////
const initState: UploaderStore = {
    isFetchingFlight: false,
    isFetchingSensorEquipment: false,
    isFetchingUploadProgress: false,
    isCheckingUploadProgress: false,
    isFetchingUploadRecord: false,
    status: 'UPLOAD_INIT',
    isUpdateExistingData: false,
    fileMetadataList: [],
    uploadTaskList: [],
    ongoingTaskLookup: {},
    completedTaskList: [],
    globalUploadProgress: {
        globalSpeed: 0,                     // unit: byte
        completedFileSize: 0,               // unit: byte
        totalFileSize: 0,                   // unit: byte
        completedFileCount: 0,
        totalFileCount: 0,
    },
}

const uploader: Reducer<UploaderStore> = (
        prevState: UploaderStore=initState, 
        action: AnyAction
    ): UploaderStore => {
    switch(action.type) {
        case UPLOADER_ACTION_TYPE.FETCH_FLIGHT_BY_FLIGHT_ID:
            return ({
                ...prevState,
                isFetchingFlight: true,

            })
        case UPLOADER_ACTION_TYPE.FETCH_FLIGHT_BY_FLIGHT_ID_SUCCEED: {
            const flight: FetchFlightByFlightIdResponse = action.flight
            return ({
                ...prevState,
                isFetchingFlight: false,
                isFetchFlightSucceed: true,
                flight
            })
        }
        case UPLOADER_ACTION_TYPE.FETCH_FLIGHT_BY_FLIGHT_ID_FAILED:
            return ({
                ...prevState,
                isFetchingFlight: false,
                isFetchFlightSucceed: false,
            })
        case UPLOADER_ACTION_TYPE.FETCH_SENSOR_EQUIPMENT_LIST: 
            return ({
                ...prevState,
                isFetchingSensorEquipment: true
            })
        case UPLOADER_ACTION_TYPE.FETCH_SENSOR_EQUIPMENT_LIST_SUCCEED: {
            const sensorEquipmentList: FetchSensorEquipmentListResponse = action.sensorEquipmentList
            return ({
                ...prevState,
                isFetchingSensorEquipment: false,
                isFetchSensorEquipmentSucceed: true,
                sensorEquipmentList
            })
        }
        case UPLOADER_ACTION_TYPE.FETCH_SENSOR_EQUIPMENT_LIST_FAILED: {
            return ({
                ...prevState,
                isFetchingSensorEquipment: false,
                isFetchSensorEquipmentSucceed: false,
            })
        }
        case UPLOADER_ACTION_TYPE.UPDATE_UPLOAD_META: {
            const uploadMeta = action.uploadMeta
            return ({
                ...prevState,
                uploadMeta: {
                    ...prevState.uploadMeta,
                    ...uploadMeta,
                }
            })
        }
        case UPLOADER_ACTION_TYPE.UPDATE_UPLOAD_STATUS:
            return ({
                ...prevState,
                status: action.status
            })

        case UPLOADER_ACTION_TYPE.ADD_UPLOAD_METADATA_LIST: 
            return ({
                ...prevState,
                fileMetadataList: action.fileMetadataList
            })
        case UPLOADER_ACTION_TYPE.ADD_UPLOAD_TASK_LIST: 
            return ({
                ...prevState,
                uploadTaskList: action.uploadTaskList
            })
        case UPLOADER_ACTION_TYPE.ADD_UPLOAD_ONGOING_TASK:
            let uploadTaskIndex = action.uploadTaskIndex
            let newOngoingTask: OngoingTask = Object.assign({}, 
                prevState.uploadTaskList[uploadTaskIndex], {status: 'READING', percentCompleted: 0})
            let newUploadTaskList = ([] as UploadTask[]).concat(prevState.uploadTaskList)         // shallow copy
            newUploadTaskList.splice(uploadTaskIndex, 1)
            return ({
                ...prevState,
                uploadTaskList: newUploadTaskList,
                ongoingTaskLookup: {
                    ...prevState.ongoingTaskLookup,
                    [newOngoingTask.fullPath]: newOngoingTask,
                }
            })
        case UPLOADER_ACTION_TYPE.FILTER_REMAINING_UPLOAD_TASK: {
            let remainingTaskKeyList: Array<string> = action.remainingTaskKeyList
            let newUploadTaskList = prevState.uploadTaskList.filter(task => 
                    remainingTaskKeyList.indexOf(task.fullPath) !== -1)
            return ({
                ...prevState,
                uploadTaskList: newUploadTaskList
            })
        }
        case UPLOADER_ACTION_TYPE.UPDATE_UPLOAD_ONGOING_TASK_STATUS: {
            // It is definitely not elegant. But need this to against the async file reader onload callback.
            if(prevState.status === 'UPLOAD_PAUSED' || prevState.status === 'UPLOAD_CANCELLED') {
                return prevState
            }
            let key = action.ongoingTaskKey
            let status = action.status
            return ({
                ...prevState,
                ongoingTaskLookup: {
                    ...prevState.ongoingTaskLookup,
                    [key]: {
                        ...prevState.ongoingTaskLookup[key],
                        status
                    }
                }
            })
        }
        case UPLOADER_ACTION_TYPE.UPDATE_UPLOAD_ONGOING_TASK_PROGRESS: {
            // It is definitely not elegant. But need this to against the async file reader onload callback.
            if(prevState.status === 'UPLOAD_PAUSED' || prevState.status === 'UPLOAD_CANCELLED') {
                return prevState
            }
            let key = action.ongoingTaskKey
            let percentCompleted = action.percentCompleted
            if(prevState.ongoingTaskLookup[key]) {
                return ({
                    ...prevState,
                    ongoingTaskLookup: {
                        ...prevState.ongoingTaskLookup,
                        [key]: {
                            ...prevState.ongoingTaskLookup[key],
                            percentCompleted
                        }
                    }
                })
            } else {                
                // Added this if..else... to fix axios onUploadProgress late update after promise has been fulfilled
                // But still not sure if this could fix the issue. Because this issue is hard to be reproduced.
                console.log(`axios onUploadProgress update after promise fulfilled: ${key}`)
                return ({
                    ...prevState
                })
            }
        }
        case UPLOADER_ACTION_TYPE.COMPLETE_UPLOAD_ONGOING_TASK: {
            let key = action.ongoingTaskKey
            let newOngingTaskLookup = Object.assign({}, prevState.ongoingTaskLookup)
            delete newOngingTaskLookup[key]
            return ({
                ...prevState,
                ongoingTaskLookup: newOngingTaskLookup,
                completedTaskList: [
                    ...prevState.completedTaskList,
                    key
                ]
            })
        }
        case UPLOADER_ACTION_TYPE.WITHDRAW_ALL_UPLOAD_ONGOING_TASKS: {
            let pausedOngoingTaskList: Array<UploadTask> = []
            Object.keys(prevState.ongoingTaskLookup).forEach(ongoingTaskKey => {
                const ongoingTask: OngoingTask = prevState.ongoingTaskLookup[ongoingTaskKey]
                const { status, percentCompleted, ...uploadTask } = ongoingTask
                pausedOngoingTaskList.push(uploadTask)
            })
            // Sorted as the original order in uploadTaskList
            pausedOngoingTaskList.sort((firstUploadTask, secondUploadTask) => {
                // Assuming it is less likely to happen. But leave it here to make the logic robust.
                if(firstUploadTask.fileMetadataIndex === secondUploadTask.fileMetadataIndex 
                        && firstUploadTask.chunkIndex === secondUploadTask.chunkIndex) {
                    return 0
                }
                if(firstUploadTask.fileMetadataIndex < secondUploadTask.fileMetadataIndex 
                        || firstUploadTask.chunkIndex < secondUploadTask.chunkIndex) {
                    return -1
                } else {
                    return 1
                }
            })
            return ({
                ...prevState,
                uploadTaskList: [
                    ...pausedOngoingTaskList,
                    ...prevState.uploadTaskList
                ],
                ongoingTaskLookup: {}
            })
        }
        case UPLOADER_ACTION_TYPE.UPDATE_GLOBAL_UPLOAD_PROGRESS: {
            const { type, currentCompletedFileSize, ...restUpdateGlobalUploadProgressRequest } = action
            const completedFileSize = Math.min(((currentCompletedFileSize ? currentCompletedFileSize : 0)
                                                    + prevState.globalUploadProgress.completedFileSize),
                                                prevState.globalUploadProgress.totalFileSize)
            const completedFileCount = Math.min(((currentCompletedFileSize ? 1 : 0)
                                                    + prevState.globalUploadProgress.completedFileCount),
                                                prevState.globalUploadProgress.totalFileCount)
            
            return ({
                ...prevState,
                globalUploadProgress: {
                    ...prevState.globalUploadProgress,
                    completedFileSize,
                    completedFileCount,
                    ...restUpdateGlobalUploadProgressRequest
                }
            })
        }
        case UPLOADER_ACTION_TYPE.FETCH_UPLOAD_PROGRESS:
            return {
                ...prevState,
                isFetchingUploadProgress: true
            }
        case UPLOADER_ACTION_TYPE.FETCH_UPLOAD_PROGRESS_SUCCEED:
            return {
                ...prevState,
                isFetchingUploadProgress: false,
                isFetchUploadProgressSucceed: true
            }
        case UPLOADER_ACTION_TYPE.FETCH_UPLOAD_PROGRESS_FAILED:
            return {
                ...prevState,
                isFetchingUploadProgress: false,
                isFetchUploadProgressSucceed: false
            }
        case UPLOADER_ACTION_TYPE.CHECK_UPLOAD_PROGRESS:
            return {
                ...prevState,
                isCheckingUploadProgress: true
            }
        case UPLOADER_ACTION_TYPE.CHECK_UPLOAD_PROGRESS_SUCCEED:
            return {
                ...prevState,
                isCheckingUploadProgress: false,
                isCheckUploadProgressSucceed: true
            }
        case UPLOADER_ACTION_TYPE.CHECK_UPLOAD_PROGRESS_FAILED:
            return {
                ...prevState,
                isCheckingUploadProgress: false,
                isCheckUploadProgressSucceed: false
            }
        case UPLOADER_ACTION_TYPE.FETCH_UPLOAD_RECORD:
            return {
                ...prevState,
                isFetchingUploadRecord: true
            }
        case UPLOADER_ACTION_TYPE.FETCH_UPLOAD_RECORD_SUCCEED:
            return {
                ...prevState,
                isFetchingUploadRecord: false,
                isFetchUploadRecordSucceed: true
            }
        case UPLOADER_ACTION_TYPE.FETCH_UPLOAD_RECORD_FAILED:
            return {
                ...prevState,
                isFetchingUploadRecord: false,
                isFetchUploadRecordSucceed: false
            }
        case UPLOADER_ACTION_TYPE.CANCEL_UPLOAD_TASK: {
            return ({
                ...initState,
                status: prevState.status,
            })
        }
        case UPLOADER_ACTION_TYPE.CLEAR_UPLOADER_STORE: {
            return ({
                ...initState
            })
        }
        case UPLOADER_ACTION_TYPE.UPDATE_EXISTING_DATA: {
            const { isUpdateExistingData } = action
            return ({
                ...prevState,
                isUpdateExistingData
            })
        }
        // case UPLOADER_ACTION_TYPE.UPDATE_GLOBAL_UPLOAD_SPEED: {
        //     let globalSpeed = action.globalSpeed
        //     return ({
        //         ...prevState,
        //         globalUploadProgress: {
        //             ...prevState.globalUploadProgress,
        //             globalSpeed
        //         }
        //     })
        // }
        default:
            return prevState
    }
}

export default uploader