export const UploadTypes = {
    LIDAR: "LiDAR",//LiDAR only
    IMAGERY: "IMAGERY",//Simultaneous imagery only
    LIDAR_IMAGERY: "LIDAR_IMAGERY",//Simultaneous imagery only
    DMC_IMAGERY: "DMC_IMAGERY",//DMC 1 imagery only
    A3_IMAGERY: "A3_IMAGERY"//A3 imagery only
}

export const UploadLabels = {
    'LiDAR' : 'LiDAR',
    'IMAGERY' : 'Medium Format Imagery',
    'DMC_IMAGERY' : 'DMC 1 Imagery',
    'A3_IMAGERY' : 'A3 Edge Imagery'
}