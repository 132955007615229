import React, { Component } from 'react';
import { TableCell, MenuItem, Select, Input, Popover, Button, ClickAwayListener, Grid, InputLabel, FormControl, Checkbox, Icon, Chip } from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import TagFaces from '@material-ui/icons/TagFaces';
import NicerTooltip from './NicerTooltip';
import { clearAllFilters } from './CompositeFilterActions';
import { connect } from 'react-redux';

class PopoverTextFilter extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        anchorEl: null,
        popoverOpen: false,
        wholeValue: "",
        valueArray: []
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate() {
        if (this.props.clearAllFilters) {
          this.setState({ valueArray: [], wholeValue: "" });
          this.props.filterCleared();
        }
      }

    escFunction = (event) => {
        if (event.keyCode === 27 && this.state.popoverOpen) {
            this.handleClose(event);
        }
    }

    handlePopoverOpen = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            popoverOpen: true
        }));
    };
    
    handleClose = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            popoverOpen: !state.popoverOpen,
        }));
    };
    
    handleClickAwayClose = event => {
        if(this.state.valueArray.length == 0) this.handleClose(event)
    };

    handleCancel = event => {
        event.persist();
        this.handleClose(event);
        if(this.state.valueArray.length > 0){
            this.setState({ valueArray: [], wholeValue: "" });
            this.props.onUpdate(this.props.name, "");
        }
    }

    handleOK = event => {
        event.persist();
        this.handleClose(event);
        let queryString = "$any" + this.state.valueArray.join(",");
        this.props.onUpdate(this.props.name, queryString);
    }

    handleDelete = item => () => {
        let values = this.state.valueArray;
        let index = values.indexOf(item);
        values.splice(index, 1);
        this.setState({ valueArray: values });
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleEnterKey = event => {
        if (event.charCode === 13) {
            let newValues = Array.from(new Set(this.state.valueArray.concat(this.state.wholeValue.split(" "))));
            newValues = newValues.filter(item => item.trim() !== "");
            this.setState({ valueArray: newValues, wholeValue: "" });

            if (this.state.wholeValue === "") {
                event.persist();
                this.handleOK(event);
            }
        }
    };

    render() {
        const componentStyle = { width: this.props.width };
        const focusOnInput = input => {
            input && input.focus();
        };
        return (
            <TableCell padding="none" style={{ position: 'relative', paddingLeft: 10, paddingRight: 1, top: -10, width: componentStyle.width }}>
                <Grid container direction="row" alignItems="center" justify="center" style={{ width: componentStyle.width, marginTop: 10, marginLeft: -5 }}>
                    {
                        this.state.valueArray.length > 0 &&
                        <NicerTooltip placement="bottom"
                            content={
                                <span>
                                    <span style={{ fontWeight: 'bold' }}>{this.props.title}:</span><span>{this.state.startDate}</span><br />
                                    {this.state.valueArray.map(item => (<span>{item}<br /></span>))}
                                </span>
                            }>
                            <Icon onClick={this.handlePopoverOpen}><CheckBox style={{ color: '#1a3a69', fontSize: 20 }} /></Icon>
                        </NicerTooltip>
                    }
                    {
                        !this.state.valueArray.length > 0 &&
                        <Icon onClick={this.handlePopoverOpen}><CheckBoxOutlineBlank style={{ color: '#757575', fontSize: 20 }} /></Icon>
                    }
                </Grid>
                <Popover
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClickAwayClose}
                    open={this.state.popoverOpen} style={{ width: '100%' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
                    {/* <ClickAwayListener onClickAway={this.handleClose}> */}
                    <Grid container direction="column" alignItems="center" justify="center" style={{ width: '200px', padding: '10px' }}>
                        <span style={{ fontSize: 12, fontWeight: 'bold' }}>{this.props.title}</span>
                        <Grid container direction="row" alignItems="center" justify="space-evenly">
                            {/* <InputLabel htmlFor="startDate" style={{ fontSize: 12 }}>{this.props.label}</InputLabel> */}
                            <Input
                                style={{ fontSize: 12, width: 125, marginBottom: 8 }}
                                onKeyPress={this.handleEnterKey}
                                inputRef={focusOnInput}
                                value={this.state.wholeValue}
                                onChange={this.handleChange('wholeValue')}
                            />
                        </Grid>
                        <Grid container direction="row" justify="space-evenly" alignItems="center">
                            {this.state.valueArray.map(item => {
                                // let icon = <TagFaces />
                                return (
                                    <Chip
                                        key={item}
                                        label={
                                            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '130px' }} >{item}</p>
                                        }
                                        onDelete={this.handleDelete(item)}
                                        style={{ margin: 2, maxWidth: '180px' }}
                                    />
                                )
                            })
                            }
                        </Grid>
                        <Grid container direction="row" alignItems="center" justify="space-around" style={{ marginTop: '5px', marginBottom: "-5px" }}>
                            <Button onClick={this.handleOK} disabled={this.state.valueArray.length === 0}>OK</Button>
                            <Button onClick={this.handleCancel}>Clear</Button>
                        </Grid>
                    </Grid>
                    {/* </ClickAwayListener> */}
                </Popover>
            </TableCell>
        );
    }
}
// export default PopoverTextFilter;

const mapStateToProps = (state) => {
    return {
        clearAllFilters: state.clearAllFilters
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        filterCleared: () => { dispatch(clearAllFilters(false)) },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(PopoverTextFilter);